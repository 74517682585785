export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    Sumiko HTS: особливості вирощування
                </p>
                <p className="card__description-text">
                    Sumiko HTS являє собою якісний гібрид європейської селекції з високими показниками врожайності. Рекомендований для вирощування в зонах лісостепу та степу України. Представлений швейцарським виробником «Сингента». «Суміко» – соняшник, який належить до середньоранніх за показниками стиглості. Оптимізований для гербіцидного добрива «Експрес» від компанії FMC.
                </p>
                <p className="card__description-text">
                    Це гібрид із групи простих, його вегетаційний період триває 103-108 днів. Насіння на перших стадіях розвитку росте в середньому темпі. Генетично близький до іншого гібрида – «НК Бріо». За показниками адаптивності – інтенсивний. Рослина виростає до 155-170 сантиметрів у висоту. Посівний матеріал дозріває в середньому темпі. Стійкий до фомопсису, фомозу, вовчка рас А-Е.
                </p>
                <p className="card__description-title">
                    Головні переваги та особливості «Суміко»
                </p>
                <p className="card__description-text">
                    Купити насіння цього соняшника від швейцарського виробника у Вінниці пропонує ТОВ «Санберрі Україна». Цей гібрид має попит у нашій країні. Добре росте також на Поліссі. Соняшник «Суміко» входить до списку найкращих за показниками стабільності врожаю серед гібридів раннього сегмента. Висота рослини залежить від забезпеченості ґрунту вологою.
                    Вирощувати насіння цього соняшника варто для подальшого виробництва олії. Цей гібрид дуже вигідний для фермерських господарств завдяки таким перевагам:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering"> стійкість до вилягання та посухи;  </li>
                    <li className="card__description-numbering"> гарна адаптованість – культура добре росте навіть за несприятливої погоди. Різкі перепади температури, палюче сонце та інші фактори ніяк не впливають на врожайність; </li>
                    <li className="card__description-numbering"> генетична витривалість до гербіцидів, зокрема тих, які містять трибенурон-метил;  </li>
                    <li className="card__description-numbering">стійкість до різних стресових ситуацій;  </li>
                    <li className="card__description-numbering"> висока толерантність до борошняної роси.  </li>
                </ul>
                <p className="card__description-text">
                    Посівний матеріал містить до 55% олії. Соняшник сприятливий до технології вирощування «Експрес», яка передбачає оброблення найвищого рівня для ефективного знищення бур’янів без шкоди для культур. Оптимальна густота відрізняється в залежності від зволоженості території посіву. Ціна на соняшник «Суміко» доступна, тому купити його може будь-хто.
                </p>
                <p className="card__description-text">
                    Під час вирощування рекомендована інтенсивна технологія оброблення ґрунту. Не варто садити соняшник на полях з агресивними расами вовчка. Якщо точно дотримуватися термінів посіву, потенціал врожайності складатиме 4,6 тони на гектар. Оптимальна температура для початку висівання – +15 градусів і вище. Слід запобігати загущенню посівів.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--6">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">6</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--6">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">6</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
