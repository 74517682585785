import logo from "../../image/logotype/logo.svg";
import logo1 from "../../image/goods.image/agrotein/image_prod_png.png";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";

export const items = [
  {
    title: 'Гранульований гумат калія',
    priceFirst: 'від 15 000 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "potassiumHumate",
    isNew: true,
    id: "1"
  },
  {
    title: 'Добриво Меліорант',
    priceFirst: 'від 10 000 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "meliorantHumate",
    isNew: true,
    id: "2"
  }
];
