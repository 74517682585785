export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    SY Arioso: особливості та характеристики
                </p>
                <p className="card__description-text">
                    Середньоранній гібрид кукурудзи з високими показниками врожайності та стійкості до несприятливих умов – SY Arioso. Представлений швейцарським виробником «Сингента». Його вирощують на невеликих, середніх чи крупних фермерських господарствах з метою отримання зерна або силосу. «Аріосо» – кукурудза, рекомендована для висаджування в зонах північного степу, лісостепу та Полісся України. Характерною рисою є висока стійкість до екстремальних погодних впливів, зокрема посухи, різкого перепаду температур.
                </p>
                <p className="card__description-text">
                    Гібрид не боїться холоду та за сприятливих умов дає гарний урожай. Вегетаційний період триває 114-129 днів. Формує качан середнього розміру, заповнений зерном зубоподібної форми. Належить до інтенсивного типу адаптивності. Не потребує спеціальних технологій під час вирощування. Купити насіння у Вінниці від офіційного виробника пропонує ТОВ «Санберрі Україна».
                </p>
                <p className="card__description-title">
                    Ключові особливості гібрида
                </p>
                <p className="card__description-text">
                    У сприятливих для росту й розвитку умовах насіння, проростаючи, формує високорослі рослини. Вони виходять сильними й міцними. Гібрид є одним із лідерів у своїй групі стиглості за виходом зеленої маси. Відзначається високим потенціалом врожайності, який зберігається навіть після заморозків або під час посухи. Зерно в період дозрівання показує велику віддачу вологи. Після проростання насіння виходить рослина типу Stay Green.
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Важливими перевагами кукурудзи «Аріосо» є:
                    </p>
                    <li className="card__description-numbering">стійкість до фузаріозу качана;</li>
                    <li className="card__description-numbering">несприйнятливість до стеблової та кореневої гнилі, вилягання;</li>
                    <li className="card__description-numbering">висока толерантність до пухирчастої сажки.</li>
                </ul>
                <p className="card__description-text">
                    Стійкість до хвороб має велике значення. Поширені недуги кукурудзи за відсутності захисту швидко вражають посіви, сильно погіршують якість обмолочуваного зерна та зменшують об’єми врожаю. Одна зернина містить приблизно 72-73% крохмалю за сприятливих умов вирощування.
                </p>
                <p className="card__description-text">
                    На сайті виробника добрив «Санберрі Україна» насіння як кукурудзи, так і соняшника продається протравленим або без спеціального оброблення. Потреба у використанні препаратів пов’язана з важливістю захисту від шкідників. Для протравлення використовують «Форс Зеа». Цей протруйник також робить неможливими появу та розвиток вторинної інфекції.
                </p>
                <p className="card__description-text">
                    «Аріосо» можна кілька разів садити на одному й тому самому місці. Це краще робити раніше, за температури +9-12 градусів на глибині загортання насіння. Для підживлення ґрунту, його збагачення вологою та повітрям, особливо під час засухи, земля додатково обробляється. Передбачається глибока основа технологія.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Холодостійкість</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
