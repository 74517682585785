import logo from "../../image/logotype/logo.svg";
import logo1 from "../../image/goods.image/agrotein/image_prod_png.png";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";

export const items = [
  {
    title: 'Агротеїн 5:19:21',
    priceFirst: 'від 39 000 грн',
    priceSecond: 'від 20 000 грн ',
    priceThird: 'від 2 500 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-5-19-21",
    isNew: false,
    id: "1"
  },
  {
    title: 'Агротеїн 0:11:21',
    priceFirst: 'від 25 000 грн',
    priceSecond: 'від 18 000 грн',
    priceThird: 'від 1 600 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-0-11-21",
    isNew: false,
    id: "2"
  },
  {
    title: 'Нітроамофоска 15:15:15',
    priceFirst: 'від 39 000 грн',
    priceSecond: 'від 20 000 грн',
    priceThird: 'від 2 500 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "nitroamofoska-15-15-15",
    isNew: false,
    id: "3"
  },
  {
    title: 'Нітроамофоска 16:16:16',
    priceFirst: 'від 44 500 грн',
    priceSecond: 'від 21 000 грн',
    priceThird: 'від 2 500 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "nitroamofoska-16-16-16",
    isNew: false,
    id: "4"
  },
  {
    title: 'Діамофоска 10:20:20',
    priceFirst: 'від 46 000 грн',
    priceSecond: 'від 23 500 грн',
    priceThird: 'від 2 500 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "diamofoska-10-20-20",
    isNew: false,
    id: "5"
  },
  {
    title: 'Сульфоамофос NP 8:24:24',
    priceFirst: 'від 52 000 грн',
    priceSecond: 'від 26 000 грн',
    priceThird: 'від 2 750 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "sulfoamofos-8-24-24",
    isNew: false,
    id: "6"
  },
  {
    title: 'Suprofos 12 NPK (S) 4:12:12 (25)',
    priceFirst: 'від 30 000 грн',
    priceSecond: 'від 15 500 грн',
    priceThird: 'від 1 500 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "suprofos-4-12-12",
    isNew: false,
    id: "7"
  },
  {
    title: 'MAG-MAKS',
    priceFirst: 'від 13 000 грн',
    priceSecond: 'від 8 000 грн',
    priceThird: 'від 750 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "mag-maks",
    isNew: false,
    id: "8"
  },
  {
    title: 'Агротеїн 4:12:20',
    priceFirst: 'від 26 000 грн',
    priceSecond: 'від 13 500 грн',
    priceThird: 'від 1 600 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-4-12-20",
    isNew: true,
    id: "9"
  },
  {
    title: 'Агротеїн 4:16:18',
    priceFirst: 'від 37 000 грн',
    priceSecond: 'від 18 500 грн',
    priceThird: 'від 2 000 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-4-16-18",
    isNew: true,
    id: "10"
  },
  {
    title: 'Агротеїн 4:10:18',
    priceFirst: 'від 35 000 грн',
    priceSecond: 'від 17 500 грн',
    priceThird: 'від 1 900 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-4-10-18",
    isNew: true,
    id: "11"
  },
  {
    title: 'Агротеїн 6:20:30',
    priceFirst: 'від 36 000 грн',
    priceSecond: 'від 18 000 грн',
    priceThird: 'від 2 100 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-6-20-30",
    isNew: true,
    id: "12"
  },
  {
    title: 'Агротеїн 10:26:26',
    priceFirst: 'від 47 000 грн',
    priceSecond: 'від 24 000 грн',
    priceThird: 'від 2 600 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-10-26-26",
    isNew: true,
    id: "13"
  },
  {
    title: 'Агротеїн 9:18:22',
    priceFirst: 'від 39 000 грн',
    priceSecond: 'від 20 000 грн',
    priceThird: 'від 2 000 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-9-18-22",
    isNew: true,
    id: "14"
  },
  {
    title: 'Агротеїн 7:17:21',
    priceFirst: 'від 36 000 грн',
    priceSecond: 'від 18 500 грн',
    priceThird: 'від 1 900 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-7-17-21",
    isNew: true,
    id: "15"
  },
  {
    title: 'Агротеїн 4:10:15',
    priceFirst: 'від 29 000 грн',
    priceSecond: 'від 15 000 грн',
    priceThird: 'від 1 700 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "agrotein-4-10-15",
    isNew: true,
    id: "16"
  },
  {
    title: 'Селітра',
    priceFirst: 'від 42 000 грн',
    priceSecond: 'від 21 500 грн',
    priceThird: 'від 2 400 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "saltpeter",
    isNew: true,
    id: "17"
  },
  {
    title: 'Карбамід',
    priceFirst: 'від 47 000 грн',
    priceSecond: 'від 24 000 грн',
    priceThird: 'від 2 800 грн',
    InStock: false,
    producer: 'Санберрі Україна',
    logo: <img className="item__logo" src={logo} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={logo1} alt="logo" />,
    link: <Link to="order"><Button /></Link>,
    inside: "urea",
    isNew: true,
    id: "18"
  }
];
