export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    Соняшник «МАС 87.А»: переваги, особливості вирощування
                </p>
                <p className="card__description-text">
                    Лінолевий гібрид соняшника з дуже високими показниками врожайності – це «МАС 87.А». Належить до середньостиглого типу, відзначається відмінними адаптаційними здібностями до різних умов вирощування. Має чудовий фітосанітарний стан, толерантний до більшості хвороб. Рекомендований для вирощування в зонах лісостепу та степу України. Представлений виробником MAS Seeds. Добре росте також на Поліссі.
                </p>
                <p className="card__description-text">
                    Рослина виростає вище середнього рівня, має трохи опуклий, похилий кошик. Період вегетації триває 112-116 днів. Головною перевагою насіння є дуже високий показник врожайності. Він складає 5,7 тонни на гектар. У цьому гібрид соняшника є рекордсменом серед інших подібних, які вирощують в Україні.
                </p>
                <div className="card__description-title">
                    Які ключові плюси гібрида
                </div>
                <p className="card__description-text">
                    Культура добре росте навіть у несприятливих кліматичних умовах. Не змінює продуктивності через різкі перепади температури, засуху, зливи тощо. Соняшник «МАС 87.А» за цвітінням середньопізній. 1 кг насіння важить 60-65 грамів. Багато фермерів хочуть купити саме цей гібрид і через його чудовий фітосанітарний профіль. Рослина несприятлива до більшості поширених хвороб. Не боїться фомопсису, білої гнилі стебла та кошика, борошнистої роси.
                </p>
                <p className="card__description-text">
                    Гібрид стійкий до вилягання й посухи, вовчка А-Е. Але слід враховувати вірогідність пошкодження насіння ґрунтовими шкідниками. Для захисту від дротяників і личинок хрущів необхідне протруєння «Круїзером». Під час росту рослина теж може постраждати від шкідників. Щоб уникнути цього, норму препарату при обробленні збільшують від 6 до 10 літрів на тону.
                </p>
                <p className="card__description-text">
                    Виробник добрив ТОВ «Санберрі Україна» пропонує купити насіння соняшника «МАС 87.А» від виробника у Вінниці за доступною ціною. Посівний матеріал продається вже обробленим від шкідників. Це сприяє збереженню високих показників врожайності на різних полях.
                </p>
                <div className="card__description-title">
                    Особливості вирощування
                </div>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Перш ніж садити соняшник MAS 87.A, варто враховувати деякі моменти:
                    </p>
                    <li className="card__description-numbering">рекомендована температура ґрунту для посіву – 10-12 градусів. Насіння закладається на глибину 8-10 сантиметрів;</li>
                    <li className="card__description-numbering">
                        до висівання та після використовують гербіциди;
                    </li>
                    <li className="card__description-numbering">
                        найкраще садити соняшник там, де раніше зростали культури, після яких у землі залишається достатньо поживних речовин та вологи. У лісостепу його висівають на полях після озимого ячменю і пшениці. У степовій зоні найбільше підходять ділянки після озимої пшениці та кукурудзи.
                    </li>
                </ul>
                <div className="card__description-text">
                    Гібрид не потребує особливих технологій вирощування. Максимально продуктивний в інтенсивних і екстенсивних умовах.
                </div>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стійкість до вилягання</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
