import "../../../CardProfile/CardProfile.scss";
import money from "../../../../../image/Company/wallet-with-card-sticking-out.png";
import deliver from "../../../../../image/Company/fast-delivery.png";
import time from "../../../../../image/Company/timer.png";
import verified from "../../../../../image/Company/shield.png";
import Header from "../../../../Header/Header";
import Footer from "../../../../Footer/Footer";
import logo1 from "../../../../../image/logotype/logo.svg";
import GoodsSliderMolibden from "../../../GoodsSlider/Molibden/GoodsSlider";
import Description from '../Molibden/Description';
import Reference from '../Molibden/Reference';
import Table from '../Molibden/Table';
import Button from "../../../../../assets/Button/ButtonBuy/ButtonBuy"
import crumbs from "../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Link } from "react-router-dom";
import { items } from '../../../../mockData/goods-data';

export default function ProductItem() {
  const targetItemId = "1";
  const targetItem = items.find(item => item.id === targetItemId);

  const [togglefirstState, settogglefirstState] = useState(1);
  const [toggleState, setToggleState] = useState(1);

  if (!targetItem) {
    return <div>Товар з вказаним id не знайдено.</div>;
  }

  const priceFirst = targetItem.priceFirst;
  const priceSecond = targetItem.priceSecond;
  const priceThird = targetItem.priceThird;
  const priceFourth = targetItem.priceFourth;

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const toggleFirstTab = (index) => {
    settogglefirstState(index);
  };

  return (
    <>
      <Header />
      <Helmet>
        <meta name="keywords" content="виробництво, Агроболік Бор + Молібден, новинка, Агроболік, Бор + Молібден, купити, Санберрі Україна, добрива" />
        <title>Купити Агроболік Бор + Молібден у магазині Санберрі Україна</title>
        <link rel="canonical" href="https://sun-ukr.com/molibden" />
        <meta name="description" content="Замовити Агроболік Бор + Молібден за ціною 4 500 грн., можна за телефоном +38 (068)-369-90-30"></meta>
      </Helmet>
      <div className="crumbs">
        <Link className="crumbs__link" to="/">
          <img className="crumbs__logo" src={crumbs} alt="crumbs" />
        </Link>
        <Link to="catalog">
          <span>/ Комплексні органо-мінеральні добрива</span>
        </Link>
        <Link to="agrobolikCard">
          <span className="crumbs__text">/ Добрива для позакореневого живлення</span>
        </Link>
        <span className="crumbs__text">/ Агроболік Бор + Молібден</span>
      </div>
      <div className="itemSeed item--new">
        <div className="itemSeed__title ">
          <h1 className="itemSeed__title--styles">Агроболік Бор + Молібден</h1>
          <span className="item__new-arr">НОВИНКА!</span>
        </div>
        <div className={"item__stock " + (true && "item__stock--new")}>
          <span className="item__stock-arr">
            в наявності
          </span>
          <span className="item__stock-road">
            Уточнюйте наявність
          </span>
        </div>
        <div className="itemSeed__container">
          <div className="itemSeed__left">
            <div className="test-1">
              <div className="itemSeed__image-container--slider">
                <GoodsSliderMolibden />
              </div>
              <div className="itemSeed__center">
                <div className="itemSeed__block">
                  <div className="item__logo">
                    <img className="itemCard__image-logo" src={logo1} alt="logo" />
                  </div>
                  <span className="item__factory-text">
                    Виробник:
                    <span className="item__factory-name"> Санберрі Україна</span>
                  </span>
                </div>
                <div className="characteristic__wrap">
                  <div className="aside__title">Основні характеристики</div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Комерційна назва</div>
                    <div className="characteristic__value">Агроболік Бор + Молібден</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Культура</div>
                    <div className="characteristic__value">Соя, ріпак, горох</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Виробник</div>
                    <div className="characteristic__value">Санберрі Україна</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Основний компонент</div>
                    <div className="characteristic__value">Гумат</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Допоміжний компонент</div>
                    <div className="characteristic__value">Фосфор, бор, молібден</div>
                  </div>
                </div>
                <div className="item__offer">
                  <div className="item__zone">
                    <div className="item__packing">
                      <span className="item__packing-text">Фасування:</span>
                      <div className="item__packing-position">
                        <div className="item__capacity-tabs">
                          <button
                            className={
                              togglefirstState === 1
                                ? "item__cap-tabs active-item__cap-tabs"
                                : "item__cap-tabs"
                            }
                            onClick={() => toggleFirstTab(1)}
                          >
                            20л
                          </button>
                          <button
                            className={
                              togglefirstState === 2
                                ? "item__cap-tabs active-item__cap-tabs"
                                : "item__cap-tabs"
                            }
                            onClick={() => toggleFirstTab(2)}
                          >
                            10л
                          </button>
                          <button
                            className={
                              togglefirstState === 3
                                ? "item__cap-tabs active-item__cap-tabs"
                                : "item__cap-tabs"
                            }
                            onClick={() => toggleFirstTab(3)}
                          >
                            1л
                          </button>
                          <button
                            className={
                              togglefirstState === 4
                                ? "item__cap-tabs active-item__cap-tabs"
                                : "item__cap-tabs"
                            }
                            onClick={() => toggleFirstTab(4)}
                          >
                            0,5л
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="item__price">
                      <div className="price__charts">
                        <span className="price__item">Роздрібна ціна</span>
                        <div className="price__taxes">
                          <div>i</div>
                          <div class="price__taxes-content fadeInUp">
                            <span>Ціни вказані з ПДВ</span>
                          </div>
                        </div>
                      </div>
                      <div className="price item__capacity-tabs-active">
                        <div
                          className={
                            togglefirstState === 1 ? "capacity  active-capacity" : "capacity"
                          }
                        >
                          {priceFirst}
                        </div>

                        <div
                          className={
                            togglefirstState === 2 ? "capacity  active-capacity" : "capacity"
                          }
                        >
                          {priceSecond}
                        </div>

                        <div
                          className={
                            togglefirstState === 3 ? "capacity  active-capacity" : "capacity"
                          }
                        >
                          {priceThird}
                        </div>

                        <div
                          className={
                            togglefirstState === 4 ? "capacity  active-capacity" : "capacity"
                          }
                        >
                          {priceFourth}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item__order">
                    <Link to="order"><Button /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-cart-promo">
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={verified} alt="verified" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Якість гарантовано!</div>
              <div className="cart-promo__label _text-caption">Санберрі Україна пропонує тільки офіційну продукцію</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={time} alt="time" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Працюємо 24/7</div>
              <div className="cart-promo__label _text-caption">Ми приймаємо ваші замовлення щодня без перерв та вихідних</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={money} alt="money" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Оплата</div>
              <div className="cart-promo__label _text-caption">Пропонуємо готівковий та безготівковий розрахунки за товар</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={deliver} alt="deliver" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Доставка</div>
              <div className="cart-promo__label _text-caption">По всій Україні Новою Поштою чи іншими службами доставки</div>
            </div>
          </div>
        </div>
        <div className="seeds-tabs">
          <button
            className={toggleState === 1 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
            onClick={() => toggleTab(1)}
          >
            Опис Товару
          </button>
          <button
            className={toggleState === 2 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
            onClick={() => toggleTab(2)}
          >
            Характеристика
          </button>
          <button
            className={toggleState === 3 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
            onClick={() => toggleTab(3)}
          >
            Схема живлення
          </button>
        </div>
        <div className="seeds-tabs-active">
          <div
            className={toggleState === 1 ? "seeds-active  active-seeds" : "seeds-active"}>
            <Description />
          </div>

          <div
            className={toggleState === 2 ? "seeds-active  active-seeds" : "seeds-active"}>
            <Reference />
          </div>

          <div
            className={toggleState === 3 ? "seeds-active  active-seeds" : "seeds-active"}>
            <Table />
          </div>
        </div>
      </div>
      <div className="culture__container">
        <h2 className="itemSeed__title--styles">РЕКОМЕНДАЦІЇ ПО ЖИВЛЕННЮ С/Г КУЛЬТУР</h2>
        <div className="culture__goods">
          <div className="culture__box">
            <Link to="cultureVegetables">
              <div className="culture__photo--bg1"></div>
            </Link>
            <p className="culture__box-text">Овочеві-культури</p>
          </div>
          <div className="culture__box">
            <Link to="cultureBerry">
              <div className="culture__photo--bg2"></div>
            </Link>
            <p className="culture__box-text">Плодово-ягідні</p>
          </div>
          <div className="culture__box">
            <Link to="cultureRaps">
              <div className="culture__photo--bg3"></div>
            </Link>
            <p className="culture__box-text">Озимовий ріпак</p>
          </div>
          <div className="culture__box">
            <Link to="cultureSoy">
              <div className="culture__photo--bg4"></div>
            </Link>
            <p className="culture__box-text">Соя</p>
          </div>
          <div className="culture__box">
            <Link to="cultureSeed">
              <div className="culture__photo--bg5"></div>
            </Link>
            <p className="culture__box-text">Зернова культура</p>
          </div>
          <div className="culture__box">
            <Link to="cultureSunflower">
              <div className="culture__photo--bg6"></div>
            </Link>
            <p className="culture__box-text">Соняшник</p>
          </div>
          <div className="culture__box">
            <Link to="cultureCorn">
              <div className="culture__photo--bg7"></div>
            </Link>
            <p className="culture__box-text">Кукурудза</p>
          </div>
          <div className="culture__box">
            <Link to="cultureSugarBeet">
              <div className="culture__photo--bg8"></div>
            </Link>
            <p className="culture__box-text">Цукровий буряк</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
