import "../../../CardProfile/CardProfile.scss";
import money from "../../../../../image/Company/wallet-with-card-sticking-out.png";
import deliver from "../../../../../image/Company/fast-delivery.png";
import time from "../../../../../image/Company/timer.png";
import verified from "../../../../../image/Company/shield.png";
import Header from "../../../../Header/Header";
import Footer from "../../../../Footer/Footer";
import goodsPage from "../../../../../image/goods.image/soy/goods.page.soy.png";
import Description from './description';
import Button from "../../../../../assets/Button/ButtonBuy/ButtonBuy"
import crumbs from "../../../../../icons/icons8-home.svg";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { items } from '../../../../mockData/soy-data';


export default function ProductItem() {
  const targetItemId = "9";
  const targetItem = items.find(item => item.id === targetItemId);

  const [togglefirstState, settogglefirstState] = useState(1);
  const [toggleState, setToggleState] = useState(1);

  if (!targetItem) {
    return <div>Товар з вказаним id не знайдено.</div>;
  }

  const priceFirst = targetItem.priceFirst;
  const priceSecond = targetItem.priceSecond;


  const toggleTab = (index) => {
    setToggleState(index);
  };

  const toggleFirstTab = (index) => {
    settogglefirstState(index);
  };

  return (
    <>
      <Header />
      <Helmet>
        <meta name="keywords" content="виробництво, насіння сої, Соя озима, Соя, купити, Санберрі Україна, насіння" />
        <title>Купити Насіння сої «Trio» у магазині Санберрі Україна</title>
        <link rel="canonical" href="https://sun-ukr.com/trio" />
        <meta name="description" content="🌱Насіння сої оптом і в роздріб ⭐ Від якісних виробників з Європи ✅ Замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10879530292"></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-10879530292');
        `}
        </script>
      </Helmet>
      <div className="crumbs">
        <Link className="crumbs__link" to="/">
          <img className="crumbs__logo" src={crumbs} alt="crumbs" />
        </Link>
        <Link to="catalogSeed">
          <span>/ Насіння</span>
        </Link>
        <Link to="soyCard">
          <span className="crumbs__text">/ Насіння сої</span>
        </Link>
        <span className="crumbs__text">/ Насіння сої «Trio»</span>
      </div>
      <div className="itemSeed item--new">
        <div className="itemSeed__title">
          <h1 className="itemSeed__title--styles">Насіння сої «Trio»</h1>
        </div>
        <div className={"item__stock " + (true && "item__stock--new")}>
          <span className="item__stock-arr">
            в наявності
          </span>
          <span className="item__stock-road">
            Уточнюйте наявність
          </span>
        </div>
        <div className="itemSeed__container">
          <div className="itemSeed__left">
            <div className="test-1">
              <div className="itemSeed__image-container">
                <img className="itemSeed__image" src={goodsPage} alt="goodsPage" />
              </div>
              <div className="itemSeed__center">
                <div className="itemSeed__block">
                  <span className="item__factory-text">
                    Виробник:
                    <span className="item__factory-name"> Канада</span>
                  </span>
                </div>
                <div className="characteristic__wrap">
                  <div className="aside__title">Основні характеристики</div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Сорт:</div>
                    <div className="characteristic__value">«Trio»</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Тип:</div>
                    <div className="characteristic__value">Соя</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Вегетаційний період:</div>
                    <div className="characteristic__value">85 - 90 днів</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Висота рослин:</div>
                    <div className="characteristic__value">90-110 см</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Вміст білка:</div>
                    <div className="characteristic__value">38-42%</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Виробник:</div>
                    <div className="characteristic__value">Канада</div>
                  </div>
                </div>
                <div className="item__offer">
                  <div className="item__zone">
                    <div className="item__packing">
                      <span className="item__packing-text">Фасування:</span>
                      <div className="item__packing_double-position">
                        <div className="item__capacity-tabs">
                          <button
                            className={
                              togglefirstState === 2 ? "itemCard__cap-tabs active-itemCard__cap-tabs" : "itemCard__cap-tabs"}
                            onClick={() => toggleFirstTab(2)}
                          >
                            1т
                          </button>
                          <button
                            className={
                              togglefirstState === 1 ? "itemCard__cap-tabs active-itemCard__cap-tabs" : "itemCard__cap-tabs"}
                            onClick={() => toggleFirstTab(1)}>
                            40кг
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="item__price">
                      <div className="price__charts">
                        <span className="price__item">Роздрібна ціна</span>
                        <div className="price__taxes">
                          <div>i</div>
                          <div class="price__taxes-content fadeInUp">
                            <span>Ціни вказані з ПДВ</span>
                          </div>
                        </div>
                      </div>
                      <div className="price item__capacity-tabs-active">
                        <div
                          className={
                            togglefirstState === 1 ? "capacity  active-capacity" : "capacity"
                          }
                        >
                          {priceSecond}
                        </div>

                        <div
                          className={
                            togglefirstState === 2 ? "capacity  active-capacity" : "capacity"
                          }
                        >
                          {priceFirst}
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="item__order">
                    <Link to="order"><Button /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-cart-promo">
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={verified} alt="verified" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Якість гарантовано!</div>
              <div className="cart-promo__label _text-caption">Санберрі Україна пропонує тільки офіційну продукцію</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={time} alt="time" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Працюємо 24/7</div>
              <div className="cart-promo__label _text-caption">Ми приймаємо ваші замовлення щодня без перерв та вихідних</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={money} alt="money" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Оплата</div>
              <div className="cart-promo__label _text-caption">Пропонуємо готівковий та безготівковий розрахунки за товар</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={deliver} alt="deliver" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Доставка</div>
              <div className="cart-promo__label _text-caption">По всій Україні Новою Поштою чи іншими службами доставки</div>
            </div>
          </div>
        </div>
        <div className="seeds-tabs">
          <button
            className={toggleState === 1 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
            onClick={() => toggleTab(1)}
          >
            Опис Товару
          </button>
        </div>
        <div className="seeds-tabs-active">
          <div
            className={toggleState === 1 ? "seeds-active  active-seeds" : "seeds-active"}>
            <Description />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
