import "./App.scss";
import AboutPage from "./components/ServicesPage/mainPage/main";
// import AboutUsPage from "./components/About-Us/About";
import Order from "./components/OrderCard/Order";
import Catalog from "./components/Сatalog/CatalogList";
import CatalogSeed from "./components/Сatalog/CatalogSeed";
import AgrobolikCard from "./components/Сatalog/Productions/Fertilizers/Agrobolick/FertilizersCard/AgrobolikCard";
import Molibden from "./components/Сatalog/CardProfile/AgrobolikList/Molibden/Molibden";
import Universal from "./components/Сatalog/CardProfile/AgrobolikList/Universal/CardProfile";
import Phosphor from "./components/Сatalog/CardProfile/AgrobolikList/Phosphor/CardProfile";
import Iodine from "./components/Сatalog/CardProfile/AgrobolikList/Iodine/CardProfile";
import Bor from "./components/Сatalog/CardProfile/AgrobolikList/Bor/CardProfile";
import Zynk from "./components/Сatalog/CardProfile/AgrobolikList/Zynk/CardProfile";
import AgroteinCard from "./components/Сatalog/Productions/Fertilizers/Agrotein/FertilizersCard/AgroteinCard";
import Agrotein1 from "./components/Сatalog/CardProfile/AgroteinList/5-19-21/CardProfile";
import Agrotein2 from "./components/Сatalog/CardProfile/AgroteinList/0-11-21/CardProfile";
import Agrotein3 from "./components/Сatalog/CardProfile/AgroteinList/15-15-15/CardProfile";
import Agrotein4 from "./components/Сatalog/CardProfile/AgroteinList/16-16-16/CardProfile";
import Agrotein5 from "./components/Сatalog/CardProfile/AgroteinList/10-20-20/CardProfile";
import Agrotein6 from "./components/Сatalog/CardProfile/AgroteinList/8-24-24/CardProfile";
import Agrotein7 from "./components/Сatalog/CardProfile/AgroteinList/suprofos-4-12-12/CardProfile";
import Agrotein8 from "./components/Сatalog/CardProfile/AgroteinList/mag-maks/CardProfile";
import Agrotein9 from "./components/Сatalog/CardProfile/AgroteinList/4-12-20/CardProfile";
import Agrotein10 from "./components/Сatalog/CardProfile/AgroteinList/4-16-18/CardProfile";
import Agrotein11 from "./components/Сatalog/CardProfile/AgroteinList/4-10-18/CardProfile";
import Agrotein12 from "./components/Сatalog/CardProfile/AgroteinList/6-20-30/CardProfile";
import Agrotein13 from "./components/Сatalog/CardProfile/AgroteinList/10-26-26/CardProfile";
import Agrotein14 from "./components/Сatalog/CardProfile/AgroteinList/9-18-22/CardProfile";
import Agrotein15 from "./components/Сatalog/CardProfile/AgroteinList/7-17-21/CardProfile";
import Agrotein16 from "./components/Сatalog/CardProfile/AgroteinList/4-10-15/CardProfile";
import Agrotein17 from "./components/Сatalog/CardProfile/AgroteinList/saltpeter/CardProfile";
import Agrotein18 from "./components/Сatalog/CardProfile/AgroteinList/urea/CardProfile";
import HumateCard from "./components/Сatalog/Productions/Fertilizers/Humate/HumateCard";
import Humate from "./components/Сatalog/CardProfile/HumatesList/Potassium/CardProfile";
import Meliorant from "./components/Сatalog/CardProfile/HumatesList/Meliorant/CardProfile";
import NPKCard from "./components/Сatalog/Productions/Fertilizers/NPK/FertilizersCard/NPKCard";
import NPK1 from "./components/Сatalog/CardProfile/NPKList/0-20-23/CardProfile";
import NPK2 from "./components/Сatalog/CardProfile/NPKList/3-18-18/CardProfile";
import NPK3 from "./components/Сatalog/CardProfile/NPKList/5-20-5/CardProfile";
import NPK4 from "./components/Сatalog/CardProfile/NPKList/6-20-0/CardProfile";
import NPK5 from "./components/Сatalog/CardProfile/NPKList/8-24-0/CardProfile";
import NPK6 from "./components/Сatalog/CardProfile/NPKList/8-24-24/CardProfile";
import NPK7 from "./components/Сatalog/CardProfile/NPKList/kas-26/CardProfile";
import NPK8 from "./components/Сatalog/CardProfile/NPKList/kas-30/CardProfile";
import NPK9 from "./components/Сatalog/CardProfile/NPKList/kas-32/CardProfile";
import SunflowerCard from "./components/Сatalog/Productions/Seed/Sunflowers/SunflowersCard/SunflowerCard";
import Brio from "./components/Сatalog/CardProfile/SunflowerList/BrioCard/Brio";
import Neoma from "./components/Сatalog/CardProfile/SunflowerList/NeomaCard/Neoma";
import Kondi from "./components/Сatalog/CardProfile/SunflowerList/KondiCard/Kondi";
import Sumiko from "./components/Сatalog/CardProfile/SunflowerList/SumikoCard/Sumiko";
import Mas87 from "./components/Сatalog/CardProfile/SunflowerList/Mac-87a/Mas-87a";
import Mas86 from "./components/Сatalog/CardProfile/SunflowerList/Mas-86cp/Mas-86cp";
import CornCard from "./components/Сatalog/Productions/Seed/Corn/CornCard/CornCard";
import Arioso from "./components/Сatalog/CardProfile/CornList/AriocoCard/Arioco";
import Kobalt from "./components/Сatalog/CardProfile/CornList/KobaltCard/Kobalt";
import Termo from "./components/Сatalog/CardProfile/CornList/TermoCard/Termo";
import Telias from "./components/Сatalog/CardProfile/CornList/TeliasCard/Telias";
import Mas24 from "./components/Сatalog/CardProfile/CornList/MAS24Card/Mas24";
import Mas30 from "./components/Сatalog/CardProfile/CornList/MAS30Card/Mas30";
import SoyCard from "./components/Сatalog/Productions/Seed/Soy/soyCard/SoyCard";
import PlusUltra from "./components/Сatalog/CardProfile/SoyList/PlusUltra/page";
import AG0702 from "./components/Сatalog/CardProfile/SoyList/AG0702/page";
import AG0506 from "./components/Сatalog/CardProfile/SoyList/AG0506/page";
import Anna from "./components/Сatalog/CardProfile/SoyList/Anna/page";
import Apollo from "./components/Сatalog/CardProfile/SoyList/Apollo/page";
import Kansas from "./components/Сatalog/CardProfile/SoyList/Kansas/page";
import Midas from "./components/Сatalog/CardProfile/SoyList/Midas/page";
import Sofia from "./components/Сatalog/CardProfile/SoyList/Sofia/page";
import Trio from "./components/Сatalog/CardProfile/SoyList/Trio/page";
import Culture from "./components/Culture/CultureList";
import CultureSeed from "./components/Culture/CultureSeed/CultureSeed";
import CultureCorn from "./components/Culture/CultureCorn/CultureCorn";
import CultureVegetables from "./components/Culture/CultureVegetables/Culture";
import CultureBerry from "./components/Culture/CultureBerry/Culture";
import CultureSoy from "./components/Culture/CultureSoy/Culture";
import CultureSunflower from "./components/Culture/CultureSunflower/Culture";
import CultureSugarBeet from "./components/Culture/CultureSugarBeet/Culture";
import CultureRaps from "./components/Culture/CultureRaps/Culture";
import KRN from "./components/ServicesPage/completion";
import Dron from "./components/ServicesPage/ServicesPage";
import Partners from "./components/Partners/Partners";
import Contact from "./components/Contact/Contact";
import MainPage from "./components/MainPage/MainPage";
import Spares from "./components/Spares/Spares";
import Blog from "./components/Blog/Blog"
import BlogBlueberry from "./components/Blog/BlogPages/BlogBlueberry/page"
import BlogCucumber from "./components/Blog/BlogPages/BlogCucumber/page"
import BlogGrape from "./components/Blog/BlogPages/BlogGrape/page"
import BlogPotato from "./components/Blog/BlogPages/BlogPotato/page"
import BlogStrawberries from "./components/Blog/BlogPages/BlogStrawberries/page"
import BlogNutrition from "./components/Blog/BlogPages/BlogNutrition/page"
import NotFound from './assets/NotFound/NotFound';
import Privacy from './assets/Privacy/Privacy';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import React, { useEffect } from "react";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/innovation">
          <ScrollToTop />
          <AboutPage />
        </Route>
        {/* <Route path="/about-us">
          <ScrollToTop />
          <AboutUsPage />
        </Route> */}
        <Route path="/completion-of-krn">
          <ScrollToTop />
          <KRN />
        </Route>
        <Route path="/drone-spraying">
          <ScrollToTop />
          <Dron />
        </Route>
        <Route path="/catalog">
          <ScrollToTop />
          <Catalog />
        </Route>
        <Route path="/catalogSeed">
          <ScrollToTop />
          <CatalogSeed />
        </Route>
        <Route path="/agrobolikCard">
          <ScrollToTop />
          <AgrobolikCard />
        </Route>
        <Route path="/molibden">
          <ScrollToTop />
          <Molibden />
        </Route>
        <Route path="/universal">
          <ScrollToTop />
          <Universal />
        </Route>
        <Route path="/phosphor">
          <ScrollToTop />
          <Phosphor />
        </Route>
        <Route path="/iodine">
          <ScrollToTop />
          <Iodine />
        </Route>
        <Route path="/bor">
          <ScrollToTop />
          <Bor />
        </Route>
        <Route path="/zinc">
          <ScrollToTop />
          <Zynk />
        </Route>
        <Route path="/order">
          <ScrollToTop />
          <Order />
        </Route>
        <Route path="/agroteinCard">
          <ScrollToTop />
          <AgroteinCard />
        </Route>
        <Route path="/agrotein-5-19-21">
          <ScrollToTop />
          <Agrotein1 />
        </Route>
        <Route path="/agrotein-0-11-21">
          <ScrollToTop />
          <Agrotein2 />
        </Route>
        <Route path="/nitroamofoska-15-15-15">
          <ScrollToTop />
          <Agrotein3 />
        </Route>
        <Route path="/nitroamofoska-16-16-16">
          <ScrollToTop />
          <Agrotein4 />
        </Route>
        <Route path="/diamofoska-10-20-20">
          <ScrollToTop />
          <Agrotein5 />
        </Route>
        <Route path="/sulfoamofos-8-24-24">
          <ScrollToTop />
          <Agrotein6 />
        </Route>
        <Route path="/suprofos-4-12-12">
          <ScrollToTop />
          <Agrotein7 />
        </Route>
        <Route path="/mag-maks">
          <ScrollToTop />
          <Agrotein8 />
        </Route>
        <Route path="/agrotein-4-12-20">
          <ScrollToTop />
          <Agrotein9 />
        </Route>
        <Route path="/agrotein-4-16-18">
          <ScrollToTop />
          <Agrotein10 />
        </Route>
        <Route path="/agrotein-4-10-18">
          <ScrollToTop />
          <Agrotein11 />
        </Route>
        <Route path="/agrotein-6-20-30">
          <ScrollToTop />
          <Agrotein12 />
        </Route>
        <Route path="/agrotein-10-26-26">
          <ScrollToTop />
          <Agrotein13 />
        </Route>
        <Route path="/agrotein-9-18-22">
          <ScrollToTop />
          <Agrotein14 />
        </Route>
        <Route path="/agrotein-7-17-21">
          <ScrollToTop />
          <Agrotein15 />
        </Route>
        <Route path="/agrotein-4-10-15">
          <ScrollToTop />
          <Agrotein16 />
        </Route>
        <Route path="/saltpeter">
          <ScrollToTop />
          <Agrotein17 />
        </Route>
        <Route path="/urea">
          <ScrollToTop />
          <Agrotein18 />
        </Route>
        <Route path="/NPKCard">
          <ScrollToTop />
          <NPKCard />
        </Route>
        <Route path="/npk-8-24-0">
          <ScrollToTop />
          <NPK5 />
        </Route>
        <Route path="/npk-5-20-5">
          <ScrollToTop />
          <NPK3 />
        </Route>
        <Route path="/npk-3-18-18">
          <ScrollToTop />
          <NPK2 />
        </Route>
        <Route path="/npk-0-20-23">
          <ScrollToTop />
          <NPK1 />
        </Route>
        <Route path="/npk-6-20-0">
          <ScrollToTop />
          <NPK4 />
        </Route>
        <Route path="/npk-8-24-24">
          <ScrollToTop />
          <NPK6 />
        </Route>
        <Route path="/kas-26">
          <ScrollToTop />
          <NPK7 />
        </Route>
        <Route path="/kas-30">
          <ScrollToTop />
          <NPK8 />
        </Route>
        <Route path="/kas-32">
          <ScrollToTop />
          <NPK9 />
        </Route>
        <Route path="/sunflowerCard">
          <ScrollToTop />
          <SunflowerCard />
        </Route>
        <Route path="/brio">
          <ScrollToTop />
          <Brio />
        </Route>
        <Route path="/neoma">
          <ScrollToTop />
          <Neoma />
        </Route>
        <Route path="/kondi">
          <ScrollToTop />
          <Kondi />
        </Route>
        <Route path="/sumiko">
          <ScrollToTop />
          <Sumiko />
        </Route>
        <Route path="/mas87">
          <ScrollToTop />
          <Mas87 />
        </Route>
        <Route path="/mas86">
          <ScrollToTop />
          <Mas86 />
        </Route>
        <Route path="/cornCard">
          <ScrollToTop />
          <CornCard />
        </Route>
        <Route path="/arioso">
          <ScrollToTop />
          <Arioso />
        </Route>
        <Route path="/kobalt">
          <ScrollToTop />
          <Kobalt />
        </Route>
        <Route path="/termo">
          <ScrollToTop />
          <Termo />
        </Route>
        <Route path="/telias">
          <ScrollToTop />
          <Telias />
        </Route>
        <Route path="/mas24">
          <ScrollToTop />
          <Mas24 />
        </Route>
        <Route path="/mas30">
          <ScrollToTop />
          <Mas30 />
        </Route>
        <Route path="/HumateCard">
          <ScrollToTop />
          <HumateCard />
        </Route>
        <Route path="/potassiumHumate">
          <ScrollToTop />
          <Humate />
        </Route>
        <Route path="/meliorantHumate">
          <ScrollToTop />
          <Meliorant />
        </Route>
        <Route path="/soyCard">
          <ScrollToTop />
          <SoyCard />
        </Route>
        <Route path="/plusUltra">
          <ScrollToTop />
          <PlusUltra />
        </Route>
        <Route path="/AG0702">
          <ScrollToTop />
          <AG0702 />
        </Route>
        <Route path="/AG0506">
          <ScrollToTop />
          <AG0506 />
        </Route>
        <Route path="/anna">
          <ScrollToTop />
          <Anna />
        </Route>
        <Route path="/apollo">
          <ScrollToTop />
          <Apollo />
        </Route>
        <Route path="/kansas">
          <ScrollToTop />
          <Kansas />
        </Route>
        <Route path="/midas">
          <ScrollToTop />
          <Midas />
        </Route>
        <Route path="/sofia">
          <ScrollToTop />
          <Sofia />
        </Route>
        <Route path="/trio">
          <ScrollToTop />
          <Trio />
        </Route>
        <Route path="/culture">
          <ScrollToTop />
          <Culture />
        </Route>
        <Route path="/cultureSeed">
          <ScrollToTop />
          <CultureSeed />
        </Route>
        <Route path="/cultureCorn">
          <ScrollToTop />
          <CultureCorn />
        </Route>
        <Route path="/cultureVegetables">
          <ScrollToTop />
          <CultureVegetables />
        </Route>
        <Route path="/cultureBerry">
          <ScrollToTop />
          <CultureBerry />
        </Route>
        <Route path="/cultureRaps">
          <ScrollToTop />
          <CultureRaps />
        </Route>
        <Route path="/cultureSoy">
          <ScrollToTop />
          <CultureSoy />
        </Route>
        <Route path="/cultureSunflower">
          <ScrollToTop />
          <CultureSunflower />
        </Route>
        <Route path="/cultureSugarBeet">
          <ScrollToTop />
          <CultureSugarBeet />
        </Route>
        <Route path="/partners">
          <ScrollToTop />
          <Spares />
        </Route>
        <Route path="/distributors">
          <ScrollToTop />
          <Partners />
        </Route>
        <Route path="/contact">
          <ScrollToTop />
          <Contact />
        </Route>
        <Route path="/blog">
          <ScrollToTop />
          <Blog />
        </Route>
        <Route path="/blueberry">
          <ScrollToTop />
          <BlogBlueberry />
        </Route>
        <Route path="/cucumber">
          <ScrollToTop />
          <BlogCucumber />
        </Route>
        <Route path="/grape">
          <ScrollToTop />
          <BlogGrape />
        </Route>
        <Route path="/potato">
          <ScrollToTop />
          <BlogPotato />
        </Route>
        <Route path="/strawberries">
          <ScrollToTop />
          <BlogStrawberries />
        </Route>
        <Route path="/nutrition">
          <ScrollToTop />
          <BlogNutrition />
        </Route>
        <Route path="/privacy">
          <ScrollToTop />
          <Privacy />
        </Route>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path='*' component={NotFound} status={404} />
      </Switch>
    </Router >
  );
}
export default App;
