import "../Blog/Blog.scss";
import "../Blog/BlogButton.scss";
import visability from "../../icons/visibility.svg";
import calendar from "../../icons/calendar_month.svg";
import { Link } from "react-router-dom";

export default function Blog(props) {

    return (
        <div className="blog__content">
            <div className="blog__section">
                <div className={props.class}></div>
                <div className="blog__desc">
                    <div className="blog__lines">
                        <div className="blog__data">
                            <img className="blog__calendar" src={calendar} alt="calendar" />
                            <p className="blog__excerpt">{props.date}</p>
                        </div>
                        <div className="blog__thumb">
                            <img className="blog__visability" src={visability} alt="visability" />
                            <p className="blog__excerpt">{props.visability}</p>
                        </div>
                    </div>
                    <div className="blog__fruiticon">
                        {props.img}
                    </div>
                    <Link to={props.link}>
                        <div className="blog__name">
                            <p>{props.title}</p>
                        </div>
                    </Link>
                    <div className="blog__text">
                        <p>{props.desc}</p>
                    </div>
                    <Link to={props.button}>
                        <div className="blog__button">
                            <button className="btn-outline">
                                <span className="btn-outline--text">детальніше</span>
                                <i></i>
                            </button>
                        </div>
                    </Link>
                </div>
            </div >
        </div >
    );
}