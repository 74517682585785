export default function reference() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Основні характеристики</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Комерційна назва</div>
                        <div className="characteristic__value">Агроболік Йод</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Виробник</div>
                        <div className="characteristic__value">Санберрі Україна</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Основний компонент</div>
                        <div className="characteristic__value">Гумат</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Допоміжний компонент</div>
                        <div className="characteristic__value">Фосфор, йод, молібден</div>
                    </div>
                </div>
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Інсектицидна дія</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Захист</div>
                        <div className="characteristic__value">до +45%</div>
                    </div>
                </div>
                <div className="aside__title">Рекомендована кількість внесень</div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Недостатнє внесення</div>
                    <div className="characteristic__value">1 - 2 рази на сезон</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Рекомендована</div>
                    <div className="characteristic__value">3 - 4 рази на сезон</div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">Немає залежності від жорсткості води;</div>
                    <div className="aside__block">Може застосовуватись з іншими препаратами;</div>
                    <div className="aside__block">Виконує фунгіцидну дію;</div>
                    <div className="aside__block">Має високу біологічну активність;</div>
                </div>
            </div>
        </div>
    );
}
