export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    «Нітроамофоска» 15-15-15 належить до гранульованих азотно-фосфорно-калійних (NPK) комплексів. Препарат у рівних пропорціях містить речовини, які є надважливими в розвитку рослини, а саме азот, фосфор, калій. Вміст кожного із вказаних елементів складає 15% від загальної маси. У разі правильного використання добрива є можливість підвищити обсяги здобутого врожаю на 30% і навіть на 70%.
                </p>
                <div className="card__description-title">
                    Варто знати
                </div>
                <p className="card__description-text">
                    Азот, що входить до складу мінерального добрива 15-15-15 «Нітроамофоска», рослина отримує через кореневу систему. Він надходить у вигляді нітратів або амонію. Елемент посилює зростання рослини, безпосередньо сприяє підвищенню врожайності.
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Важливою складовою «Нітроамофоски» 15-15-15 є фосфор. Від нього залежать:
                    </p>
                    <li className="card__description-numbering">
                        фотосинтез;
                    </li>
                    <li className="card__description-numbering">
                        транспортування енергії;
                    </li>
                    <li className="card__description-numbering">
                        диференціація клітин;
                    </li>
                    <li className="card__description-numbering">
                        формування нових точок зростання;
                    </li>
                    <li className="card__description-numbering">
                        збільшення стійкості до різноманітних несприятливих зовнішніх впливів;
                    </li>
                    <li className="card__description-numbering">
                        швидке дозрівання.
                    </li>
                </ul>
                <p className="card__description-text">
                    Калій у складі добрива 15-15-15 стимулює ферментну активність. Важливою його функцією є утримання вологи. Ця властивість зменшує вплив таких негативних чинників, як посухи, приморозки або надлишкова мінералізація ґрунту. Калій стимулює здатність рослини протистояти хворобам і підвищує якість врожаю.
                </p>
                <div className="card__description-title">
                    Коли варто використовувати
                </div>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Добриво 15-15-15 містить оптимальне співвідношення поживних елементів. Частка активних речовин складає 45% від загальної маси. Використовується на будь-якому ґрунті, навіть на виснаженому, з метою підживлення більшості сільськогосподарських культур, зокрема:
                    </p>
                    <li className="card__description-numbering">
                        соняшника;
                    </li>
                    <li className="card__description-numbering">
                        огірків;
                    </li>
                    <li className="card__description-numbering">
                        картоплі і т. ін.
                    </li>
                </ul>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Купити «Нітроамофоску» 15-15-15 варто не лише для зернових або овочів, але й для садових культур і навіть для кімнатних або балконних квітів. Використовуючи препарат, ви щоразу забезпечуєте рослину комплексним живленням, сприяєте:
                    </p>
                    <li className="card__description-numbering">
                        зміцненню стеблів;
                    </li>
                    <li className="card__description-numbering">
                        стійкості до вилягання;
                    </li>
                    <li className="card__description-numbering">
                        опірності до низьких температур;
                    </li>
                    <li className="card__description-numbering">
                        імунітету до хвороб.
                    </li>
                </ul>
                <p className="card__description-text">
                    Максимального ефекту від використання «Нітроамофоски» 15-15-15 можна досягти за умови, якщо здійснювати її локальне внесення в ґрунт, перш ніж засіяти туди насіння, а також підживлювати рослини згідно з технологією вирощування.
                </p>
                <p className="card__description-text">
                    Серед позитивних якостей «Нітроамофоски» 15-15-15 варто згадати хорошу водорозчинність, що полегшує її засвоєння рослинами. Через високу концентрацію активних речовин навіть в одній гранулі міститься достатня кількість елементів, що необхідні для живлення. Ви можете купити добриво 15-15-15 із розрахунку на декілька сезонів, адже воно добре зберігається. Гранули не злипаються, залишаються розсипчастими.
                </p>
                <p className="card__description-title">
                    Засіб використання
                </p>
                <p className="card__description-text">
                    Як основне добриво «Нітроамофоска» 15-15-15 додається до ґрунту восени, під час перекопування. Навесні робиться передпосівне внесення. Важливим заходом є підживлення, що здійснюється ранньою весною. Якщо доводиться вирощувати рослини на виснаженому ґрунті, дозування «Нітроамофоски» 15-15-15 треба збільшити на 50%.
                </p>
                <p className="card__description-text">
                    Застосування під час посіву дерев, кущів або овочевої розсади має певні особливості. Зокрема, добриво 15-15-15 змішується з ґрунтом, закладається в лунку. Далі суміш ізолюється від кореневої системи. Для цього треба використати шар чистого ґрунту. Щодо дози застосування «Нітроамофоски» 15-15-15, вона залежить від рослини.
                </p>
                <p className="card__description-text">
                    Наприклад, якщо ви садите газон, потрібно 3-3,5 кг на сотку; дерева (плодові) – 4-5 кг/сот.; ягідники – 2-3 кг/сот. В останньому випадку мікродобриво додається в міжряддя ранньою весною. Особливість використання «Нітроамофоски» 15-15-15 для кімнатних рослин полягає в тому, що препарат додається під час поливу у весняно-літній період кожні 2 тижні. Дозування складає 10-20 г на 10 л води.
                </p>
                <p className="card__description-text">
                    Більш детально про застосування комплексу ви дізнаєтеся з інструкції або після консультацій зі спеціалістами. У нашій компанії ціна «Нітроамофоски» 15-15-15 є дуже вигідною. Причина в тому, що ви купуєте товар у виробника, оминаючи посередників. Також ціна добрива 15-15-15 залежить від фасування. У нас препарат пакується в мішки 50, 600 або 1000 кг. Доставка «Нітроамофоски» 15-15-15 із Вінниці здійснюється всією Україною.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
