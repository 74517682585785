export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Соя сорту «AG 0702» є одним з найефективніших сортів для вирощування в умовах України. Цей сорт відзначається високою врожайністю, яка може досягати до 3,5 тон на гектар, що робить його популярним серед фермерів.
                </p>
                <div className="card__description-title">Особливості сорту</div>
                <p className="card__description-text">
                    Однією з ключових переваг цього сорту є високий вміст білка у зерні, який становить 38-42%. Це робить «AG 0702» ідеальним для виробництва соєвого борошна та соєвого масла, які відзначаються відмінним смаком та корисними властивостями.
                </p>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Рекомендації:
                    </p>
                    <li className="card__description-numbering">Рекомендується висівати в пізньому весняному або ранньому літньому періоді для оптимального росту та розвитку рослин.</li>
                    <li className="card__description-numbering">Обробка грунту перед висівом допоможе забезпечити найкращі умови для проростання насіння та подальшого розвитку рослин.</li>
                    <li className="card__description-numbering">Ретельний догляд під час вегетації сприятиме формуванню великого та якісного врожаю.</li>
                </ol>
                <p className="card__description-text">
                    Соя сорту «AG 0702» - це надійний вибір для отримання високоякісної продукції з високим вмістом білка.
                </p>
                <p className="card__description-text">
                    «AG 0702» також відзначається стійкістю до стресових умов, таких як засуха та хвороби, що робить його надійним вибором для вирощування в різних кліматичних умовах.
                </p>
                <p className="card__description-text">
                    Оберіть сою сорту «AG 0702» для високоякісного вирощування з високим вмістом білка та відмінним смаком продукції.
                </p>
                <div className="card__description-title">Характеристики:</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сорт:</div>
                        <div className="characteristic__value">«AG 0702»</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Тип:</div>
                        <div className="characteristic__value">Соя</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вегетаційний період:</div>
                        <div className="characteristic__value">85 - 90 днів</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Висота рослин:</div>
                        <div className="characteristic__value">90-110 см</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вміст білка:</div>
                        <div className="characteristic__value">37-39%</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Врожайність:</div>
                        <div className="characteristic__value">3,5 т/га</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">CHU:</div>
                        <div className="characteristic__value">2300</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Потенціал (т):</div>
                        <div className="characteristic__value">4</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Маса 1000 нас.:</div>
                        <div className="characteristic__value">165</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Схожість:</div>
                        <div className="characteristic__value">94%</div>
                    </div>
                </div>
                <div className="aside__title">Норма висіву</div>
                <div className="aside__content">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Норма висіву (тис. насінин):</div>
                        <div className="characteristic__value">850</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Норма висіву (кг):</div>
                        <div className="characteristic__value">150</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Реком. міжряддя (см):</div>
                        <div className="characteristic__value">12,5-30</div>
                    </div>
                </div>
                <div className="aside__title">Стійкість до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до осипання:</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до хвороб:</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Рекомендований протруйник</div>
                        <div className="characteristic__value">"Вайбранс RFC"</div>
                    </div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block"><span className="aside__block--bold">Адаптація:</span> «AG 0702» добре адаптується до різних типів ґрунтів і кліматичних умов, що робить його популярним серед фермерів з різних регіонів.</div>
                    <div className="aside__block"><span className="aside__block--bold">Висока якість продукції:</span> Продукція, виготовлена з цього сорту сої, відзначається не лише високим вмістом білка, але і відмінним смаком та корисними властивостями.</div>
                    <div className="aside__block"><span className="aside__block--bold">Технологічність:</span> Насіння «AG 0702» має високу технологічність, що робить його легкою у використанні для виробництва соєвого борошна, соєвого масла та інших продуктів.</div>
                    <div className="aside__block"><span className="aside__block--bold">Стійкість до стресів:</span> Цей сорт сої відзначається високою стійкістю до стресових умов, таких як засуха, посушливий вітер та хвороби, що дозволяє отримати стабільний врожай навіть в негативних умовах.</div>
                </div>
            </div>
        </div>
    );
}
