import "./Navigation.scss";
import { Link } from "react-router-dom";
import React from "react";

export default function Navigation() {
	return (
		<div className="navigation">
			<nav className="navigation__container">
				<ul className="navigation__list">
					{/* <li className="navigation__link navigation__link--hover">
						<Link className="navigation__link--hover" to="about-us">Про компанію</Link>
					</li> */}
					<div className="dropdown">
						<li className="navigation__link">
							<Link className="navigation__link--hover" to="catalog">Каталог</Link>
						</li>
						<div className="dropdown-content fadeInDown">
							<Link className="cultureFruitTEST" to="agrobolikCard">
								<div className="cultureFruit13"></div>
								<div className="culture__link-text">Добрива для позакореневого живлення</div>
							</Link>
							<Link className="cultureFruitTEST" to="agroteinCard">
								<div className="cultureFruit14"></div>
								<div className="culture__link-text">Гранульовані NPK</div>
							</Link>
							<Link className="cultureFruitTEST" to="humateCard">
								<div className="cultureFruit17"></div>
								<div className="culture__link-text">Гранульовані гумати</div>
							</Link>
							<Link className="cultureFruitTEST" to="NPKCard">
								<div className="cultureFruit15"></div>
								<div className="culture__link-text">Рідкі NPK</div>
							</Link>
						</div>
					</div>
					<div className="dropdown">
						<li className="navigation__link navigation__link--hover">
							<Link className="navigation__link--hover" to="catalogSeed">Насіння</Link>
						</li>
						<div className="dropdown-content fadeInDown">
							<Link to="sunflowerCard">
								<div className="cultureFruit5"></div>
								<div className="culture__link-text">Насіння соняшнику</div>
							</Link>
							<Link className="cultureFruitTEST" to="cornCard">
								<div className="cultureFruit6"></div>
								<div className="culture__link-text">Насіння кукурудзи</div>
							</Link>
							<Link className="cultureFruitTEST" to="soyCard">
								<div className="cultureFruit3"></div>
								<div className="culture__link-text">Насіння сої</div>
							</Link>
						</div>
					</div>
					<div className="dropdown">
						<li className="navigation__link navigation__link--hover">
							<Link className="navigation__link--hover" to="culture">Культура</Link>
						</li>
						<div className="dropdown-content fadeInDown">
							<Link className="cultureFruitTEST" to="cultureVegetables">
								<div className="cultureFruit"></div>
								<div className="culture__link-text">Добрива для овочевих культур</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureBerry">
								<div className="cultureFruit1"></div>
								<div className="culture__link-text">Добрива для плодово-ягідних</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureRaps">
								<div className="cultureFruit2"></div>
								<div className="culture__link-text">Добрива для озимого ріпаку</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureSoy">
								<div className="cultureFruit3"></div>
								<div className="culture__link-text">Добрива для Сої</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureSeed">
								<div className="cultureFruit4"></div>
								<div className="culture__link-text">Добрива для зернових культур</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureSunflower">
								<div className="cultureFruit5"></div>
								<div className="culture__link-text">Добрива для соняшнику</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureCorn">
								<div className="cultureFruit6"></div>
								<div className="culture__link-text">Добрива для кукурудзи</div>
							</Link>
							<Link className="cultureFruitTEST" to="cultureSugarBeet">
								<div className="cultureFruit7"></div>
								<div className="culture__link-text">Добрива для цукрового буряку</div>
							</Link>
						</div>
					</div>
					<div className="dropdown">
						<li className="navigation__link navigation__link--hover">
							<Link className="navigation__link--hover" to="blog">Блог</Link>
						</li>
						<div className="dropdown-content fadeInDown">
							<Link className="cultureFruitTEST" to="strawberries">
								<div className="cultureFruit8"></div>
								<div className="culture__link-text">Добрива для полуниці</div>
							</Link>
							<Link className="cultureFruitTEST" to="grape">
								<div className="cultureFruit9"></div>
								<div className="culture__link-text">Добрива для Винограду</div>
							</Link>
							<Link className="cultureFruitTEST" to="blueberry">
								<div className="cultureFruit10"></div>
								<div className="culture__link-text">Добриво для Лохини</div>
							</Link>
							<Link className="cultureFruitTEST" to="potato">
								<div className="cultureFruit11"></div>
								<div className="culture__link-text">Добрива для Картоплі</div>
							</Link>
							<Link className="cultureFruitTEST" to="cucumber">
								<div className="cultureFruit12"></div>
								<div className="culture__link-text">Добрива для Огірків</div>
							</Link>
							<Link className="cultureFruitTEST" to="nutrition">
								<div className="cultureFruit16"></div>
								<div className="culture__link-text">Елементи живлення</div>
							</Link>
						</div>
					</div>
					<li className="navigation__link navigation__link--hover">
						<Link className="navigation__link--hover" to="innovation">Інновації</Link>
					</li>
					<li className="navigation__link navigation__link--hover">
						<Link className="navigation__link--hover" to="distributors">Дистриб'юція</Link>
					</li>
					<li className="navigation__link navigation__link--hover">
						<Link className="navigation__link--hover" to="partners">Партнери</Link>
					</li>
					<li className="navigation__link navigation__link--hover">
						<Link className="navigation__link--hover" to="contact">Контакти</Link>
					</li>
				</ul>
			</nav>
		</div>
	);
}
