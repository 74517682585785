export default function table() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Схема живлення рослин</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Пшениця і ячмінь</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">1,5-2 л на 1 га (до початку кущення, за умови +7 °С)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (разом із гербіцидом від бур'янів)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Третє внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (флаговий лист перед викиданням колоса)</div>
                    </div>
                </div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Ріпак</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">1,5-2 л на 1 га (від температури +7 °С)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (при протравленні рослин від шкідників)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Третє внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (під час цвітіння)</div>
                    </div>
                </div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Кукурудза</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (від 6 листків справжніх)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (в фазі 12 справжніх листків)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Третє внесення</div>
                        <div className="characteristic__value">1,5-2 л на 1 га (по можливості заходу оприскувача)</div>
                    </div>
                </div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Соняшник</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (від 4 справжніх листків)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">1-2 л на 1 га (від 6 справжніх листків)</div>
                    </div>
                </div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Соя</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">по 1 л на 1 га (через кожні 2 тижні протягом усього періоду вегетації рослин)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">по 1 л на 1 га</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Третє внесення</div>
                        <div className="characteristic__value">по 1 л на 1 га</div>
                    </div>
                </div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Овочеві культури</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">по 1 л на 1 га (через кожні 2 тижні протягом усього періоду вегетації рослин)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">по 1 л на 1 га</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Третє внесення</div>
                        <div className="characteristic__value">по 1 л на 1 га</div>
                    </div>
                </div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Назва культури</div>
                        <div className="characteristic__value">Фруктово-ягідні дерева</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Перше внесення</div>
                        <div className="characteristic__value">1 л на 200 л води (розпускання бруньок)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Друге внесення</div>
                        <div className="characteristic__value">1 л на 200 л води (під час цвітіння)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Третє внесення</div>
                        <div className="characteristic__value">1 л на 200 л води (під час формування плодів)</div>
                    </div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">Має високу біологічну активність;</div>
                    <div className="aside__block">Виводить радіонукліди з живої клітини;</div>
                    <div className="aside__block">Покращує структуру ґрунту;</div>
                    <div className="aside__block">Знижує кислотність і засоленість ґрунту;</div>
                </div>
            </div>
        </div>
    );
}
