import "../BlogPagesStyle.scss";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import crumbs from "../../../../icons/icons8-home.svg";
import blueberry from "../../../../image/blog/stock-photo-fresh-blueberries-removebg-preview.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { item } from '../../../mockData/goods-data';
import Item from '../../../Item/Item';
import React from "react";

export default class BlogCucumber extends React.Component {
    constructor(props) {
        super(props);
        this.item = item;
    }

    render() {
        return (
            <div className="culture">
                <Helmet>
                    <meta
                        name="keywords"
                        content="Добрива для лохини, лохина, рекомендації та норми внесення добрив, добрива, купити"
                    />
                    <title>Добрива для лохини • Оптом і в роздріб | Sunberry Ukraine</title>
                    <link rel="canonical" href="https://sun-ukr.com/blueBerry" />
                    <meta
                        name="description"
                        content="🌱Добрива для лохини ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                    ></meta>
                </Helmet>
                <Header />
                <div className="crumbs">
                    <Link className="crumbs__link" to="/">
                        <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                    </Link>
                    <Link className="crumbs__link" to="blog">
                        <span>/ Блог</span>
                    </Link>
                    <span className="crumbs__text">/ Добрива для лохини</span>
                </div>
                <div className="culture__title">
                    <h1>Добрива для лохини</h1>
                </div>
                <div className="culture__container">
                    <div className="culture__content">
                        <p className="blog__timeline"> Дата публікації: 13.09.2022</p>
                        <p className="culture__desc">
                            Лохина – корисна ягода, джерело величезної кількості вітамінів, зовні схожа на чорницю. Раніше скуштувати її свіжою можна було лише в лісах Карпат чи на Поліссі. Проте цю рослину часто обирають городники, охочі виростити на своїй ділянці щось екзотичне та незвичайне. В процесі знадобляться добрива для лохини. Ця культура невибаглива до умов вирощування, але без своєчасного та регулярного підживлення не вийде зібрати гарний урожай.
                        </p>
                        <p className="culture__desc">
                            На відміну від інших диких сортів, ця рослина являє собою високорослі кущі. Вирощувати їх можна на грядці чи в окремому ящику. Добрий урожай можна зібрати й з одного куща, якщо враховувати особливості вирощування. Так, корені цієї ягідної культури не мають волосків та ростуть на глибину до 30 см. Система взаємодіє з грибком для отримання поживних речовин. Підгодівлю потрібно проводити для створення комфортного середовища.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <div className="blog__bg"></div>
                            </div>
                            <div className="culture__inside-right">
                                <p className="culture__heading">Як визначити нестачу живлення</p>
                                <p className="blog__desc-text">
                                    Навесні, коли з’являється листя, зав’язуються й наливаються плоди, найкраще використовувати розчини, що містять азот. Ця речовина добре засвоюється плодово-ягідними саме в цей період. Якщо азоту недостатньо, зменшується інтенсивність цвітіння, пилкова трубка росте повільно, вироджуються насіннєві зачатки. Азотовмісні препарати вносять у кілька заходів. Перший – навесні, останній – в червні, коли опадає зав’язь.
                                </p>
                                <p className="blog__desc-text">
                                    Майже так само проявляється нестача заліза. Але в такому випадку жовтіє лише молоде листя, а його жилки лишаються зеленими. Якщо на культурі помітні плями, значить, їй потрібно більше калію. Не має значення, якого саме елементу недостатньо. Коли вчасно не вжити відповідних заходів, лохина швидко загине.
                                </p>
                            </div>
                        </div>
                        <p className="culture__desc">
                            Визначити, що потрібна підживка для лохини, неважко за зовнішніми ознаками. Листя здорової рослини відрізняється насиченим забарвленням та яскравим кольором. Немає ніяких складнощів зі зростанням та розвитком, з’являється гарний урожай. Кущ, якому не вистачає азоту, навпаки, росте дуже повільно. На ньому не з’являється нових пагонів. Листя жовтіє, відмирає, ягід зав’язується дуже мало.
                        </p>
                        <p className="culture__desc">
                            Майже так само проявляється нестача заліза. Але в такому випадку жовтіє лише молоде листя, а його жилки лишаються зеленими. Якщо на культурі помітні плями, значить, їй потрібно більше калію. Не має значення, якого саме елементу недостатньо. Коли вчасно не вжити відповідних заходів, лохина швидко загине.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <p className="culture__heading">Переваги та особливості використання добрив</p>
                                <p className="culture__desc">
                                    Садова ягода дуже вимоглива до підживлення й корисних мікроелементів. Далеко не все необхідне вона отримує з ґрунту. Та не в кожній землі є потрібна їй кількість поживних речовин. Додаткового підживлення лохина потребує все більше з кожним новим сезоном. Після внесення відповідних добрив кущі отримують все, чого потребують для здоров’я, нормального розвитку та гарного плодоношення. Урожай дозріває швидше, виходить смачним та поживним.
                                </p>
                                <p className="culture__desc">
                                    Як нестача, так і надлишок підживлення дуже шкідливий для лохини. Тому вносити добрива, особливо азотні, слід дуже обережно. Якщо цієї речовини забагато, коренева система починає гнити, з’являється багато пустих пагонів без ягід. Перед внесенням спеціальних препаратів потрібно враховувати:
                                </p>
                                <ul className="blog__description-list">
                                    <li className="culture__description-numbering">
                                        найсприятливіший для цього час;
                                    </li>
                                    <li className="culture__description-numbering">
                                        потрібну кількість;
                                    </li>
                                    <li className="culture__description-numbering">
                                        пора року.
                                    </li>
                                </ul>
                                <p className="culture__desc">
                                    Найкориснішими та зручними у використанні є сполуки на базі гумінових кислот. Вони добре розчиняються у воді та можуть вноситися безпосередньо під час поливу рослин. Такі речовини вирізняє багатий склад, що містить основні речовини, яких потребує культура протягом усього періоду вегетації. Регулярне використання гуматів сприяє найбільш гармонійному розвитку рослин, покращенню смакових якостей ягід та збільшенню врожайності.
                                </p>
                                <p className="culture__desc">
                                    Перше, що слід зробити, – приділити увагу ґрунту. Оптимальним показником для зростання є помірна кислотність, у межах 3,5–5 рН. Щоб досягти його, слід використовувати мінеральні підкислювачі та мульчувати землю для стабільного повітрообміну.
                                </p>
                            </div>
                            <div className="culture__inside-right">
                                <img className="culture__inside-photo" src={blueberry} alt="blueberry" />
                            </div>
                        </div>
                        <p className="culture__heading">Які добрива використовувати</p>
                        <p className="culture__desc">
                            Для гарного росту й розвитку лохини найбільше потрібні азот, фосфор та калій. Буває, що окремих елементів достатньо в ґрунті, але іноді їх не вистачає або, навпаки, забагато. І те й інше шкодить рослині. Тому перед підживленням варто враховувати характеристики ґрунту. Вибираючи добрива, слід звертати увагу на пору року. У різні періоди потреби культури відрізняються.
                        </p>
                        <p className="culture__desc">
                            Що вносити навесні
                        </p>
                        <p className="blog__desc-text">
                            Вкрай важливою є саме весняна підгодівля. Завдяки їй кущ швидше та краще відновлюється після зими, його крона активно росте, з’являється більше молодих пагонів. Першу порцію добрив можна вносити відразу після того, як розтане сніг, коли починають набрякати бруньки. Далі наприкінці травня процедуру можна повторити, якщо є потреба. Навесні знадобляться добрива, які містять багато азоту. Ця речовина сприяє активному руху соку й нарощенню зеленої маси.
                        </p>
                        <p className="blog__desc-text">
                            Щоб зберегти оптимальну кислотність ґрунту, добрива вносяться у два етапи. Спершу додають окислювач, а вже потім, через один-два тижні, й основний препарат, можна в сухому або рідкому вигляді. Бажано вносити його прямо під корінь. Але використовувати азот варто дуже обережно. Його надлишок призводить до пересоленості ґрунту й швидкої загибелі молодих пагонів.
                        </p>
                        <p className="culture__desc">
                            Які добрива потрібні влітку
                        </p>
                        <p className="culture__desc">
                            Якщо кущі виглядають здоровими, в цей час можна обійтися без підживлення. Проте воно необхідне, коли лохина хворіє чи постраждала від шкідників. У цю пору року їй потрібні калій та фосфор, а азоту – якомога менше. Можна використовувати такі добрива:
                        </p>
                        <ul className="blog__description-list">
                            <li className="culture__description-numbering">
                                сульфат магнію. Дуже корисний для лохини, вноситься раз на рік. Для одного куща достатньо 15 грамів;
                            </li>
                            <li className="culture__description-numbering">
                                сульфат калію. Потрібен, коли рослині не вистачає калію. 100 грамів речовини розводять у 10 літрах води та обприскують культуру;
                            </li>
                            <li className="culture__description-numbering">
                                препарати, які містять фосфор, калій та магній. В них також достатньо міді, заліза, сірки.
                            </li>
                        </ul>
                        <p className="culture__desc">
                            Влітку добрива вносяться не під корінь, ними обробляють листя. Це треба робити рано вранці або ввечері, щоб уникнути сонячних опіків. Перегодовувати не варто. Гарним рішенням стануть також комплексні мінеральні добрива у гранулах від виробника ТОВ «Санберрі Україна». У їх складі – все необхідне, щоб забезпечити нормальне зростання лохини та дозрівання ягід.
                        </p>
                        <p className="culture__heading">Як правильно підживлювати</p>
                        <p className="culture__desc">
                            Важливо не лише використовувати корисні добавки, а й робити це правильно. Порошок або гранули треба додавати саме до ґрунту. Перед цим рослину поливають, а землю навколо неї перекопують. Їх також можна розводити у воді, таким чином отримують готовий розчин для обприскування. Професійні садівники радять більше уваги приділяти дорослим кущам. Молоді не потребують особливого живлення.
                        </p>
                        <div className="blog__bg2"></div>
                        <p className="blog__heading">ТОВАРИ ЯКІ РЕКОМЕНДУЄ ВИРОБНИК :</p>
                    </div>
                </div>
                <div className="goods">
                    {this.item.map(item => (
                        <Item
                            key={item.id}
                            isNew={item.isNew}
                            title={item.title}
                            InStock={item.InStock}
                            link={item.link}
                            inside={item.inside}
                            priceFirst={item.priceFirst}
                            priceSecond={item.priceSecond}
                            priceThird={item.priceThird}
                            priceFourth={item.priceFourth}
                            imgUrl={item.imgUrl}
                            table={item.table}
                            logo={item.logo}
                            description={item.description}
                            recommendations={item.recommendations}
                            id={item.id}
                        />
                    ))}
                </div>
                <Footer />
            </div >
        );
    }
}