import "./ButtonBuy.scss";

export default function ButtonBuy() {

    return (
        <button className="btn-outline">
            <span className="btn-outline--text">Купити</span>
            <i></i>
        </button>
    );
}
