import "../BlogPagesStyle.scss";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import crumbs from "../../../../icons/icons8-home.svg";
import grapes from "../../../../image/blog/stock-photo-bunch-of-red-grapes-removebg-preview.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { item } from '../../../mockData/goods-data';
import Item from '../../../Item/Item';
import React from "react";
export default class BlogGrape extends React.Component {
    constructor(props) {
        super(props);
        this.item = item;
    }

    render() {
        return (
            <div className="culture">
                <Helmet>
                    <meta
                        name="keywords"
                        content="Добрива для винограду, виноград, рекомендації та норми внесення добрив, добрива, купити"
                    />
                    <title>Добрива для винограду • Оптом і в роздріб | Sunberry Ukraine</title>
                    <link rel="canonical" href="https://sun-ukr.com/grape" />
                    <meta
                        name="description"
                        content="🌱Добрива для винограду ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                    ></meta>
                </Helmet>
                <Header />
                <div className="crumbs">
                    <Link className="crumbs__link" to="/">
                        <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                    </Link>
                    <Link className="crumbs__link" to="blog">
                        <span>/ Блог</span>
                    </Link>
                    <span className="crumbs__text">/ Добрива для винограду</span>
                </div>
                <div className="culture__title">
                    <h1>Добрива для винограду</h1>
                </div>
                <div className="culture__container">
                    <div className="culture__content">
                        <p className="blog__timeline"> Дата публікації: 12.09.2022</p>
                        <p className="culture__heading">Підживлення винограду: чим удобрити лозу навесні та восени</p>
                        <p className="culture__desc">
                            Кожна рослина для свого нормального росту та життєдіяльності потребує поживних речовин, наприклад азоту, калію, кальцію, фосфору, магнію, сірки, заліза. Також в незначній кількості ще потрібні бор, цинк, мідь, марганець тощо. Не є виключенням і виноград: усі ці речовини повинні бути у ґрунті, на якому він росте. Якщо вищезгаданих елементів буде недостатньо, лоза не зможе потішити свого господаря великими та смачними гронами. Саме тому необхідно періодично вносити добрива для винограду і таким чином впливати на формування листя, квітів, пагонів. Щоби дізнатися про наявність та кількість корисних елементів в землі свого винограднику, рекомендуємо замовити агрохімічне дослідження ґрунтів. Але існують загальні принципи підживлення винограду, про що ми вам розповімо далі.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <div className="blog__bg5"></div>
                            </div>
                            <div className="culture__inside-right">
                                <p className="culture__heading">Підживлення винограду навесні</p>
                                <p className="blog__desc-text">
                                    Кожного господаря турбує питання, чим підкормити виноград весною. Перші добрива (перегній та мінеральні добрива) варто внести вже під час посадки саджанця. Їх повинно вистачити на перші два роки життя рослини. Далі ранньою весною третього року слід починати або вносити добрива в канавки, зроблені навколо кущів, або заливати у дренажні трубки, що були вкопані при посадці. Канавки повинні мати глибину не менше 30 см, що дозволить добривам потрапити до коренів, а не розтікатися по поверхні землі. Склад комплексного добрива: у 10 л води додають по 40 г карбаміду і суперфосфату та 30 г сульфату калію. Якщо ґрунт вже містить корисні елементи, дозу добрива слід зменшити вдвічі. У травні, за два тижні до цвітіння, слід повторно підживити рослини таким же розчином.
                                </p>
                            </div>
                        </div>
                        <p className="culture__desc">
                            Якщо за певних обставин, наприклад прохолодної погоди на початку весняного сезону, перше підживлення було пропущено, тоді при настанні стабільно теплої погоди слід рясно полити кущі розчином з додаванням рослинних аквадобрив. Непогано також додатково внести і органічні добрива — компост, перегній.
                        </p>
                        <p className="culture__desc">
                            Відповідаючи на питання, чим удобрити виноград навесні, спеціалісти ще рекомендують за кілька тижнів до цвітіння підживити рослини комплексними мінеральними добривами, які можна придбати в спеціалізованих магазинах. Вони випускаються виробниками у гранульованому та рідкому вигляді.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <p className="culture__heading">Підживлення винограду попелом та курячим послідом</p>
                                <p className="culture__desc">
                                    Виростити гарну рослину без використання органічних та мінеральних добрив практично неможливо. Поживні речовини краще вносити навесні як допоміжне підживлення. Мабуть, найбільше органічних добрив міститься у курячому посліді, що має у своєму складі втричі більше фосфору та калію, ніж коров’ячий гній. А ще послід вміщує достатню кількість магнію та азоту. Таке гарне органічне добриво має лише один недолік — його не можна використовувати свіжим, бо будуть попалені корені винограду.
                                </p>
                                <p className="culture__desc">
                                    Попередня підготовка курячого посліду передбачає заливання його водою на тиждень (у пропорції 1 частина на 20 л води) та періодичне перемішування. Спосіб використання простий: поливають землю навколо рослини на відстані півметра раз на рік. Використане таким чином добриво «працює» протягом кількох сезонів.
                                </p>
                                <p className="culture__desc">
                                    Що ж стосується попелу, то його вважають природним аналогом штучних мінеральних добрив, наприклад калійних. Один з популярних рецептів саморобного добрива з його використанням такий: на три відра води взяти 1 кг попелу і розчинити у воді. Витримати три дні, періодично помішуючи рідину. Для використання 2 л настою ще розчиняють у 10 л води і поливають виноград. Так само, як і курячий послід, попіл буди підживлювати рослину протягом декількох років. Деякі виноградарі використовують комбінацію посліду та попелу у пропорції по склянці кожного на відро води.
                                </p>
                            </div>
                            <div className="culture__inside-right">
                                <img className="culture__inside-photo" src={grapes} alt="blueberry" />
                            </div>
                        </div>
                        <p className="culture__heading">Позакореневе підживлення винограду нітроамофоскою</p>
                        <p className="culture__desc">
                            Таке підживлення частіше використовують, коли уповільнився ріст листків та пагонів. Робиться воно по листю, за допомогою якого виноград може «взяти» більше корисних речовин, ніж коренями. Головне — правильно приготувати розчин та дотримуватися періодичності обробки. Також не слід забувати, що обприскування роблять до цвітіння під вечір або вранці, щоб не спричинити сонячні опіки листів через краплини розчину.
                        </p>
                        <p className="culture__desc">
                            Нітроамофоска — універсальне добриво, що містить головні елементи для гарного росту рослин — азот, фосфор та калій. Поживні речовини в ньому мають водорозчинну форму, що дозволяє рослинам легко їх засвоювати. Підживлення винограду нітроамофоскою робиться навесні. Для цього проводять обприскування листя розчином у пропорції 30 г на 10 л води.
                        </p>
                        <p className="culture__desc">
                            Після цвітіння найкращими добривами для внесення по листю є калійно-фосфорні, мікродобрива, що містять цинк, залізо, марганець для посилення імунітету, та попіл.
                        </p>
                        <p className="culture__heading">Рекомендуємо придбати нітроамофоску в нашому магазині</p>
                        <Link to="/nitroamofoska-16-16-16">
                            <p className="culture__desc--link">
                                Нітроамофоска 16:16:16
                            </p>
                        </Link>
                        <p className="culture__heading">Удобрення винограду восени</p>
                        <p className="culture__desc">
                            Як вважають досвідчені виноградарі, якщо під час вирощування елітних сортів не використовувати добрива, вони стають дикими. Так, наприклад, якщо буде недостатньо фосфору, білі мускатні сорти винограду будуть втрачати свій смак. Те ж саме буде з червоними сортами, якщо вони не отримують достатньої кількості калію, якого їм потрібно вдвічі більше, ніж білим.
                        </p>
                        <p className="culture__desc">
                            Розмірковуючи над тим, чим підживити виноград осінню, варто розуміти, що підкормку треба розпочинати ще перед початком холодів. В серпні бажано підсилити дозрівання грон внесенням хлористого калію в пропорції 15 г на 10 л води. У вересні після збору врожаю з метою підготовки рослини до зими роблять повторне підживлення. Для цього використовують сірчанокислий калій, вносячи 100 г на кожен квадратний метр під час перекопування, або роблять розчин 20–25 г на відро води та заливають у дренажну трубу (10 л на дорослу рослину).
                        </p>
                        <p className="culture__desc">
                            Прихильники органічних добрив за наявності живильного чорнозему можуть використовувати перегній (одне відро на кущ) раз на три роки. Якщо ґрунт не дуже насичений мікроелементами, його підживлюють раз на рік або на два. Так само можна використовувати й попіл, закопуючи його навколо лози і потім поливаючи землю, щоб не рознесло вітром.
                        </p>
                        <div className="culture__inside-left">
                            <div className="blog__bg6"></div>
                        </div>
                        <p className="culture__heading">Загальні правила використання добрива</p>
                        <p className="culture__desc">
                            Підкреслимо, що терміни, способи та періодичність внесення добрив треба визначати диференційовано, враховуючи властивості різних видів добрив та особливостей сортів винограду. Головне — забезпечити якнайкраще засвоєння рослиною внесених добрив, запобігти вимиванню їх легкорозчинних форм з ґрунту. Так, наприклад, перед внесенням добрив слід переконатися у достатній вологості. Це потрібно для того, щоби добрива у гранулах добре розчинилися і не обпікали корені. Якщо верхній шар сухий, перед внесенням добрива його слід заздалегідь полити. Добрива варто вносити рівномірно під кожен кущ. При надмірних опадах або сильних зливах після внесення добрив слід збільшити кількість підживлень, бо вода вимиває поживні речовини.
                        </p>
                        <p className="blog__heading">ТОВАРИ ЯКІ РЕКОМЕНДУЄ ВИРОБНИК :</p>
                    </div>
                </div>
                <div className="goods">
                    {this.item.map(item => (
                        <Item
                            key={item.id}
                            isNew={item.isNew}
                            title={item.title}
                            InStock={item.InStock}
                            link={item.link}
                            inside={item.inside}
                            priceFirst={item.priceFirst}
                            priceSecond={item.priceSecond}
                            priceThird={item.priceThird}
                            priceFourth={item.priceFourth}
                            imgUrl={item.imgUrl}
                            table={item.table}
                            logo={item.logo}
                            description={item.description}
                            recommendations={item.recommendations}
                            id={item.id}
                        />
                    ))}
                </div>
                <Footer />
            </div >
        );
    }
}