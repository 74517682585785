import "./Header.scss";
import { Link } from "react-router-dom";
import Funcional from "./Funcional";
import Navigation from "../Navigation/Navigation";
import Hamburger from "../Hamburger/Hamburger";
import Ink from "react-ink";
import emailjs from "@emailjs/browser";
import MaskedInput from 'react-text-mask'
import logo from "../../image/logotype/logo.svg";
import exit from "../../image/black_exit.jpg";
import React, { useRef, useState, useEffect } from "react";

export default function Header() {
    const [isOpen, setState] = useState("");
    const [canShow, showState] = useState(false);
    const [canExit, showExit] = useState(false);
    const [canShowHam, showStateHam] = useState(false);
    const [email, setEmail] = useState("");
    const [value, setValue] = useState("");
    const [tel, setTel] = useState("");

    function onChangeInput(e) {
        setEmail(e.target.value);
    }

    function inputValueClear(e) {
        setValue(e.target.value);
    }

    function telValueClear(e) {
        setTel(e.target.value);
    }

    const form = useRef();

    function openMenu(isOpen) {
        setState(isOpen);
        showStateHam(canShowHam ? false : true);
    }

    function changeStatePopUp() {
        showState(canShow ? false : true);
    }

    function changeStatePopAt() {
        showExit(canExit ? false : true);
    }

    useEffect(() => {
        if (canShow) {
            document.body.style.overflow = "hidden";
        } else if (canShowHam) {
            document.body.style.overflow = "hidden";
        } else if (canExit) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    });

    // useEffect(() => {
    //     setTimeout(() => {
    //         changeStatePopAt(canExit);
    //     }, 1000);
    // }, [canExit]);

    const sendEmail = (e) => {
        e.preventDefault();
        setValue("");
        setEmail("");
        setTel("");
        changeStatePopAt();
        emailjs
            .sendForm(
                "service_dhbzbzd",
                "template_wytj8ko",
                form.current,
                "54r806kZ5B5Q649la"
            )
            .then(
                (result) => {
                    changeStatePopUp(result);
                },
                (error) => {
                    console.log(error.text);
                }
            );

        changeStatePopUp();
    };

    return (
        <header className={"header__block" + (isOpen ? " header--visible" : "")} id="header">
            <Funcional />
            <div className="header__top">
                <div className="header__logo">
                    <Link to="/">
                        <img className="TM" src={logo} alt="logotype" />
                    </Link>
                    <Link to="/">
                        <div className="header__logo-title">
                            <span className="header__text-under">САНБЕРРІ УКРАЇНА</span>
                            <span className="header__text">ВСЕ У ВАС ВИРОСТЕ</span>
                        </div>
                    </Link>
                </div>
                <div className="header__wrapper">
                    <div className="header__phone">
                        <div className="header__phone--column">
                            <div className="header__photo--call"></div>
                            <a href="tel:+380683699030">+38(068)-369-90-30</a>
                        </div>
                        <div className="header__phone--column">
                            <div className="header__photo--mail"></div>
                            <a className="header__google" href="mailto:sunberry.ukr@gmail.com"> sunberry.ukr@gmail.com</a>
                        </div>
                        <div className="header__phone--wrapper">
                            <div className="header__photo--world"></div>
                            <span className="header__google">Оберіть мову: українська</span>
                        </div>
                    </div>
                    <div className="header__feedbackButton">
                        <button className="header__button-feedback" onClick={changeStatePopUp}>
                            <p className="header__button-feedback--text">Залишити заявку</p>
                            <Ink />
                        </button>
                    </div>
                </div>
                <div className="header__social-icons">
                    <a className="facebook" href="https://www.facebook.com/%D0%A1%D0%B0%D0%BD%D0%B1%D0%B5%D1%80%D1%80%D1%96-%D0%A3%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D0%B0-103192682345184" target="_blank" rel="noreferrer">
                        <div className="header__social-icons--facebook"></div>
                    </a>
                    <a className="telegram" href="https://t.me/sunberryUkr" target="_blank" rel="noreferrer">
                        <div className="header__social-icons--telegram"></div>
                    </a>
                    <a className="viber" href="viber://chat?number=%2B380683699030" target="_blank" rel="noreferrer">
                        <div className="header__social-icons--viber"></div>
                    </a>
                </div>
                <div className={"main-pop-up" + (canShow ? " show-pop-up" : "")}>
                    <div className={"pop-up" + (canShow ? " pop-up--show fadeIn" : "")}>
                        <div className="pop-up-bg"></div>
                        <div className="pop-up-info">
                            <button className="pop-up__button-exit" onClick={changeStatePopUp}>
                                <img className="pop-up__exit-icon" src={exit} alt="exit" />
                            </button>
                            <div className="pop-up__content">
                                <h3 className="pop-up__title">
                                    Консультація менеджера
                                </h3>
                                <form
                                    ref={form}
                                    onSubmit={sendEmail}
                                    action="#"
                                    method="POST"
                                    encType="multipart/form-data"
                                    className="getintouch__form">
                                    <input
                                        type="text"
                                        value={value}
                                        name="name"
                                        placeholder="Ім'я"
                                        required
                                        className="getintouch__offer"
                                        onChange={(e) => inputValueClear(e)}
                                    />
                                    <input
                                        className="getintouch__offer"
                                        type="email"
                                        value={email}
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        onChange={(e) => onChangeInput(e)}
                                    />
                                    <MaskedInput
                                        className="getintouch__offer"
                                        mask={['+', '3', '8', ' ', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
                                        pattern="^\+[0-9]{2}\s\((\d+)\)\s\d{3}\s\d{2}\s\d{2}"
                                        type="tel"
                                        name="phone"
                                        value={tel}
                                        placeholder="+38 (___) ___ __ __"
                                        required
                                        onChange={(e) => telValueClear(e)}
                                    />
                                    <input
                                        className="getintouch__offer--submit"
                                        type="submit"
                                        value="Відправити"
                                    />
                                </form>
                                <p className="pop-up__text">Залиште ваші контактні дані нижче і наш менеджер зв'яжеться з вами найближчим часом</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"pop-at" + (canExit ? " pop-at--feedback fadeIn" : "")}>
                    <h1>Дякуємо за ваше звернення!</h1>
                    <span className="pop-at--text">Ми зв'яжемося з вами найближчим часом</span>
                    <button className="pop-at--button" onClick={changeStatePopAt}>
                        Дякую
                    </button>
                </div>
            </div>
            <div className="header__container">
                <div className="header__center">
                    <Hamburger openMenu={openMenu} />
                    <div className="header__navigation navigation">
                        <Navigation />
                    </div>
                </div>
            </div>
        </header >
    );
}