import "./Item.scss";
import logo from "../../image/logotype/logo.svg";
import { useState } from "react";
import React from "react";
import { Link } from "react-router-dom";

export default function Item(props) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div className={"item " + (props.isNew && 'item--new')}>
      <div className="item__left">
        <div className="item__title">
          <Link to={props.inside}>
            <span className="title">{props.title}</span>
          </Link>
          <span className="item__new-arr">НОВИНКА!</span>
        </div>
        <div className="test-1">
          <div className="item__image-container">
            <div className="itemCard__image">
              <div className="agroteinslider__image">
                {props.imgUrl}
              </div>
            </div>
          </div>
          <div className="item__center">
            <div className="item__block">
              <img className="item__logo" src={logo} alt="logo" />
              <span className="item__factory-text">Виробник:</span>
              <span className="item__factory-name">Санберрі Україна</span>
            </div>
            <div className="item__zone">
              <div className="item__packing">
                <span className="item__packing-text">Фасування:</span>
                <div className="item__packing-position">
                  <div className="itemNPK__capacity-tabs">
                    <button className={toggleState === 1 ? "itemNPK__cap-tabs active-itemNPK__cap-tabs" : "itemNPK__cap-tabs"}
                      onClick={() => toggleTab(1)}>
                      1т
                    </button>
                    <button className={toggleState === 2 ? "itemNPK__cap-tabs active-itemNPK__cap-tabs" : "itemNPK__cap-tabs"}
                      onClick={() => toggleTab(2)}>
                      20л
                    </button>
                  </div>
                </div>
              </div>
              <div className="item__price">
                <div className="price__charts">
                  <span className="price__item">Роздрібна ціна</span>
                </div>
                <div className="price item__capacity-tabs-active">
                  <div
                    className={
                      toggleState === 1 ? "capacity  active-capacity" : "capacity"
                    }
                  >
                    {props.priceFirst}
                  </div>

                  <div
                    className={
                      toggleState === 2 ? "capacity  active-capacity" : "capacity"
                    }
                  >
                    {props.priceSecond}
                  </div>

                </div>
                <div className={"item__stock " + (props.InStock && 'item__stock--new')}>
                  <span className="item__stock-arr">в наявності</span>
                  <span className="item__stock-road">товар в дорозі</span>
                </div>
                <div className="item__suborder">
                  <div className="item__order">
                    {props.link}
                  </div>
                  <Link to={props.inside}>
                    <p className="item__desc">Детальніше</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
