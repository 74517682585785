export default function reference() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Основні характеристики</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Комерційна назва</div>
                        <div className="characteristic__value">НК Конді (NK Kondi)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Культура</div>
                        <div className="characteristic__value">Соняшник</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Виробник</div>
                        <div className="characteristic__value">Syngenta</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Група стиглості</div>
                        <div className="characteristic__value">середньостиглий</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Стійкість до вовчка</div>
                        <div className="characteristic__value">А-Е</div>
                    </div>
                </div>
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вміст олії</div>
                        <div className="characteristic__value">до 54%</div>
                    </div>
                </div>
                <div className="aside__title">Толерантність до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">
                            Комплексна толерантність до хвороб
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Фомопсис (Phomopsis)</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">
                            Біла гниль кошика (Sclerotinia)
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">
                            Біла гниль стебла (Sclerotinia)
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">
                            Рекомендована зона вирощування
                        </div>
                        <div className="characteristic__value">Лісостеп / Полісся</div>
                    </div>
                </div>
                <div className="aside__title">
                    Рекомендована густота на момент збору врожаю
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Посушливі умови</div>
                    <div className="characteristic__value">40 - 45 тис. росл./га</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Недостатнє зволоження</div>
                    <div className="characteristic__value">45 - 55 тис. росл./га</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Достатнє зволоження</div>
                    <div className="characteristic__value">55 - 60 тис. росл./га</div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">Високий потенція урожайності</div>
                    <div className="aside__block">
                        Найкращу віддачу забезпечує на родючих грунтах
                    </div>
                    <div className="aside__block">
                        Один із найбільш
                        високоврожайних гібридів з високим вмістом олії
                    </div>
                </div>
            </div>
        </div>
    );
}
