import "./SocialIcons.scss"
import { SocialIcon } from 'react-social-icons';
import viber from '../../icons/viber.svg'
import google from '../../icons/gmail.svg'
import React from 'react';

export default function SocialIcons() {
  return (
    <div className="social__icons">
      <a className="google" href="mailto:sunberry.ukr@gmail.com" target="_blank" rel="noreferrer">
        <img className="social__icons--google" src={google} alt="google" />
      </a>
      <SocialIcon url="https://www.facebook.com/%D0%A1%D0%B0%D0%BD%D0%B1%D0%B5%D1%80%D1%80%D1%96-%D0%A3%D0%BA%D1%80%D0%B0%D1%97%D0%BD%D0%B0-103192682345184" target="_blank" />
      <SocialIcon network="telegram" url="https://t.me/sunberryUkr" target="_blank" rel="noreferrer" />
      <a className="viber" href="viber://chat?number=%2B380683699030" target="_blank" rel="noreferrer">
        <img className="social__icons--viber" src={viber} alt="viber" />
      </a>
      <SocialIcon url="https://youtube.com" target="_blank" rel="noreferrer" />
    </div>
  );
}
