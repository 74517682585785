export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    NK Kobalt: особливості, характеристики
                </p>
                <p className="card__description-text">
                    Гібрид кукурудзи, який відзначається високими показниками врожайності, – NK Kobalt. Належить до типів середнього терміну дозрівання. Рекомендований для вирощування з метою отримання зерна. Активно висаджується й культивується в зонах лісостепу та степу України. Усі сортові властивості «НК Кобальт» зберігаються саме в цих регіонах. У властивих їм кліматичних умовах він підтримує стабільні показники врожайності та тішить фермерів великою кількістю готового зерна.
                </p>
                <p className="card__description-text">
                    Посівний матеріал цього середньостиглого гібрида відзначається високою стійкістю до посухи. Гібрид простого типу, представлений швейцарським виробником «Сингента». Купити його за доступною ціною для вирощування у Вінниці чи інших регіонах пропонує ТОВ «Санберрі Україна».
                </p>
                <p className="card__description-title">
                    Головні показники гібрида
                </p>
                <p className="card__description-text">
                    Після висаджування цього насіння кукурудзи виростає міцна, сильна рослина. Вона має високу стійкість до вилягання. Готове зерно відзначається зубоподібною формою. Урожайність стабільно велика, потенціал у разі дотримання всіх умов складає 14-15 тонн на гектар. «НК Кобальт» має й інші переваги:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">максимальна стійкість до посухи;</li>
                    <li className="card__description-numbering">несприйнятливість до заморозків – навіть якщо після висівання насіння температура різко знизиться, це ніяк не вплине на показники врожайності;</li>
                    <li className="card__description-numbering">толерантність до пухирчастої сажки, кореневої гнилі;</li>
                    <li className="card__description-numbering">стійкість до зараження гельмінтоспоріозом, стебловою гниллю.</li>
                </ul>
                <p className="card__description-text">
                    Гібрид несприятливий до іржі, фузаріозу качана. На початку вегетативного періоду демонструє середні темпи зростання. Листки рослини належать до еректоїдного типу, а саме відзначаються підвищеною ефективністю фотосинтезу. Потребує стандартної технології вирощування.
                </p>
                <p className="card__description-text">
                    Насіння кукурудзи «Сингента Кобальт» дає короткий, фіксований початок. З одного качана отримують близько 83% зерна. Якщо умови для росту й розвитку сприятливі, формуються два повністю господарсько-придатні качани. В одній зернині містяться приблизно 74,9% крохмалю, 8,3% протеїну та 3,8% масла. Кукурудза є теплолюбною культурою.
                </p>
                <p className="card__description-text">
                    Насіння проростає за температури +8-10 °C, а сходи з’являються за +10-12 градусів. Не варто садити посівний матеріал у холодний, перенасичений вологою ґрунт. Так рослина ростиме повільно й дасть менше сходів, ніж за більш сприятливих умов.
                </p>
                <p className="card__description-text">
                    У компанії ТОВ «Санберрі Україна» можна придбати добрива для різних сільськогосподарських культур. Тут також продається насіння соняшника від офіційних виробників.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Холодостійкість</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
