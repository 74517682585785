import './GoodsList.scss';
import { items } from '../../../mockData/Corn-data';
import Item from '../../../Item/itemCorn';
import React from 'react';

export default class GoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.items = items;
  }

  render() {
    return (
      <div className="goods">
        {this.items.map(item => (
          <Item
            key={item.id}
            isNew={item.isNew}
            title={item.title}
            producer={item.producer}
            InStock={item.InStock}
            priceFirst={item.priceFirst}
            imgUrl={item.imgUrl}
            imgLogo={item.imgLogo}
            id={item.id}
            link={item.link}
            inside={item.inside}
          />
        ))}
      </div>
    );
  }
}