import './GoodsList.scss';
import { items } from '../../../mockData/Humate-data';
import ItemHumate from '../../../Item/ItemHumate';
import React from 'react';

export default class GoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.items = items;
  }

  render() {
    return (
      <div className="goods">
        {this.items.map(item => (
          <ItemHumate
            key={item.id}
            isNew={item.isNew}
            title={item.title}
            InStock={item.InStock}
            priceFirst={item.priceFirst}
            imgUrl={item.imgUrl}
            inside={item.inside}
            logo={item.logo}
            producer={item.producer}
            link={item.link}
            table={item.table}
            id={item.id}
          />
        ))}
      </div>
    );
  }
}