export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Нітроамофоска 8:24:24 є універсальним комплексним добривом, яке підходить для різних типів ґрунту. До його складу входять азот (8 частин), фосфор (24 частини), калій (24), а також цілий комплекс інших мікро- і макроелементів, серед яких можна виділити сірку, залізо, кальцій, магній, цинк та ін.
                </p>
                <p className="card__description-text">
                    Комплекс 8:24:24 NPK офіційно називається сульфоамофосом. Він призначений для підвищення врожайності сільськогосподарських культур, а також їх захисту від вилягання. Покращує стійкість рослин до заморозків. Також сульфоамофос дозволяє захистити корені та стовбур рослини від різних грибкових захворювань (іржі, гнилі, борошнистої роси тощо).
                </p>
                <div className="card__description-title">
                    Особливості препарату
                </div>
                <p className="card__description-text">
                    Добриво 8:24:24 випускається у вигляді гранул бежевого або світло-рожевого кольору діаметром до 5–6 мм. Воно має гарну гігроскопічність, завдяки чому рівень розсипчастості не зменшується з часом. Мікро- і макроелементи, що входять до складу препарату, розчиняються у воді. При цьому вони не відразу вимиваються з гранул, тому мають пролонговану дію.
                </p>
                <p className="card__description-text">
                    NPK 8:24:24 зазвичай вносять як основне або передпосівне добриво. Роблять це за допомогою спеціальних розкидачів. При цьому потрібно дотримуватись норм внесення, щоб не допустити надлишку нітратів у ґрунті. Найбільшу ефективність препарати показують на сіроземі та чорноземі за умови регулярного зрошення, але їх можна використовувати практично на будь-яких ґрунтах.
                </p>
                <p className="card__description-text">
                    Постачаються ці комплексні добрива у мішках чи насипом. Зберігати їх рекомендується у закритих складах, щоб не допустити зниження ефективності від потрапляння води. При цьому гранули не створюють пил: вони досить тверді та міцні. Вони не горять та являються повністю вибухобезпечними. Комплекс 8:24:24 ідеально підходить для полів, на яких присутній дефіцит легкозасвоюваного калію та фосфору, а також є надлишок азоту. Його використовують при вирощуванні цукрових буряків, соняшника, багаторічних трав, озимої та ярої пшениці, картоплі, овочів та ін. Вносити його можна як перед переорюванням поля, так і в лунки під час посадки насіння або коренів.
                </p>
                <div className="card__description-title">
                    Переваги препарата
                </div>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Розглянемо, які плюси є у цього добрива:
                    </p>
                    <li className="card__description-numbering">
                        Доступна ціна на нітроамофоску 8:24:24. Вона коштує відносно недорого, тому її покупка не вдарить по вашому бюджету.
                    </li>
                    <li className="card__description-numbering">
                        Висока ефективність. Препарат прискорює зростання як класичного насіння, так і гібридів, а також підвищує врожайність сільськогосподарських культур, навіть якщо вони вирощуються не по технології або на піщаних ґрунтах.
                    </li>
                    <li className="card__description-numbering">
                        Простота використання, транспортування та зберігання. Гранули — одна з найпрактичніших форм добрива. Їх можна вносити у різний спосіб протягом усього періоду вегетації сільськогосподарських культур.
                    </li>
                </ol>
                <p className="card__description-text">
                    Також до переваг можна віднести й те, що до складу входять усі мікро- і макроелементи, які необхідні для повноцінного розвитку рослини. Часто достатньо просто внести цей комплекс, щоб забезпечити хороший урожай.
                </p>
                <p className="card__description-text">
                    Щоб купити 8:24:24 NPK, вам необхідно звернутися до компанії «Cанберрі Україна». Ми є виробником комплексних добрив, тому пропонуємо клієнтам найцікавіші умови співпраці. Наш склад та виробництво знаходяться у Вінниці, але за потреби ми відправляємо товари в інші регіони країни. Також ми проводимо аналіз ґрунту, що дає змогу підібрати оптимальні добрива саме для вашого поля.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
