export default function reference() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Основні характеристики</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Комерційна назва</div>
                        <div className="characteristic__value">МАС 30.М (MAS 30.M)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Культура</div>
                        <div className="characteristic__value">Кукурудза</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Виробник</div>
                        <div className="characteristic__value">MAS Seeds</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Призначення</div>
                        <div className="characteristic__value">зерно</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">ФАО</div>
                        <div className="characteristic__value">310</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Тип зерна</div>
                        <div className="characteristic__value">зубоподібний</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Спец. технології</div>
                        <div className="characteristic__value">відсутні</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Висота рослини</div>
                        <div className="characteristic__value">низька</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Висота кріплення качана</div>
                        <div className="characteristic__value">середня</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Флексибільність качана</div>
                        <div className="characteristic__value">MED</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сума активних T° до цвітіння</div>
                        <div className="characteristic__value">910 °C</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сума активних T° до стиглості</div>
                        <div className="characteristic__value">1800 °C</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Середня кількість зерен в ряду</div>
                        <div className="characteristic__value">28-32 шт</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Середня кількість рядів у качані</div>
                        <div className="characteristic__value">16-18 шт</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сер. маса 1000 насінин</div>
                        <div className="characteristic__value">310-340 г</div>
                    </div>
                </div>
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">
                            Стійкість до вилягання під час вегетації
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">
                            Стійкість до вилягання після вегетації
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Stay Green</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
                <div className="aside__title">Толерантність до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Пухирчата сажка</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
                <div className="aside-char--basic">
                    <div className="aside-char__head">фузаріоз</div>
                    <div className="aside-char__char">
                        <div className="aside-char__items aside-char__items--8">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="aside-char__count">8</div>
                    </div>
                </div>
                <div className="aside-char--basic">
                    <div className="aside-char__head">Летюча сажка</div>
                    <div className="aside-char__char">
                        <div className="aside-char__items aside-char__items--9">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="aside-char__count">9</div>
                    </div>
                </div>
                <div className="aside__title">Рекомендована густота на момент збору врожаю</div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Недостатнє зволоження</div>
                    <div className="characteristic__value">60 - 70 тис. росл./га</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Достатнє зволоження</div>
                    <div className="characteristic__value">70 - 80 тис. росл./га</div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">Відмінна стійкість до стресових умов</div>
                    <div className="aside__block">Високий потенціал урожайності</div>
                    <div className="aside__block">Придатний для пізнього збирання врожаю</div>
                </div>
            </div>
        </div>
    );
}
