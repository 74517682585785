import "../../../CardProfile/CardProfile.scss";
import money from "../../../../../image/Company/wallet-with-card-sticking-out.png";
import deliver from "../../../../../image/Company/fast-delivery.png";
import time from "../../../../../image/Company/timer.png";
import verified from "../../../../../image/Company/shield.png";
import Header from "../../../../Header/Header";
import Footer from "../../../../Footer/Footer";
import logo1 from "../../../../../image/logotype/logo.svg";
import goodsPage from "../../../../../image/goods.image/agrotein/image_prod_png.png";
import Description from './Description';
import Reference from './Reference';
import Button from "../../../../../assets/Button/ButtonBuy/ButtonBuy"
import crumbs from "../../../../../icons/icons8-home.svg";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { items } from '../../../../mockData/Agritein-data';

export default function ProductItem() {
    const targetItemId = "14";
    const targetItem = items.find(item => item.id === targetItemId);

    const [togglefirstState, settogglefirstState] = useState(1);
    const [toggleState, setToggleState] = useState(1);

    if (!targetItem) {
        return <div>Товар з вказаним id не знайдено.</div>;
    }

    const priceFirst = targetItem.priceFirst;
    const priceSecond = targetItem.priceSecond;
    const priceThird = targetItem.priceThird;

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const toggleFirstTab = (index) => {
        settogglefirstState(index);
    };

    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="виробництво, Агротеїн, купити, Санберрі Україна, добрива" />
                <title>Купити Агротеїн 9:18:22 у магазині Санберрі Україна</title>
                <link rel="canonical" href="https://sun-ukr.com/agrotein-9-18-22" />
                <meta name="description" content="Замовити Агротеїн 9:18:22 за ціною 39 000 грн., можна за телефоном +38 (068)-369-90-30"></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalog">
                    <span>/ Комплексні органо-мінеральні добрива</span>
                </Link>
                <Link to="agroteinCard">
                    <span className="crumbs__text">/ Гранульовані NPK</span>
                </Link>
                <span className="crumbs__text">/ Агротеїн 9:18:22</span>
            </div>
            <div className="itemSeed item--new">
                <div className="itemSeed__title">
                    <h1 className="itemSeed__title--styles">Агротеїн 9:18:22</h1>
                </div>
                <div className={"item__stock " + (false && "item__stock--new")}>
                    <span className="item__stock-arr">
                        в наявності
                    </span>
                    <span className="item__stock-road">
                        товар в дорозі
                    </span>
                </div>
                <div className="itemSeed__container">
                    <div className="itemSeed__left">
                        <div className="test-1">
                            <div className="item__image-container">
                                <img className="item__image" src={goodsPage} alt="goodsPage" />
                            </div>
                            <div className="itemSeed__center">
                                <div className="itemSeed__block">
                                    <div className="item__logo">
                                        <img className="itemCard__image-logo" src={logo1} alt="logo" />
                                    </div>
                                    <span className="item__factory-text">
                                        Виробник:
                                        <span className="item__factory-name"> Санберрі Україна</span>
                                    </span>
                                </div>
                                <div className="characteristic__wrap">
                                    <div className="aside__title">Основні характеристики</div>
                                    <div className="characteristic__container">
                                        <div className="characteristic__label">Комерційна назва</div>
                                        <div className="characteristic__value">Агротеїн 9:18:22</div>
                                    </div>
                                    <div className="characteristic__container">
                                        <div className="characteristic__label">Культура</div>
                                        <div className="characteristic__value">кукурудза, соняшник та інші</div>
                                    </div>
                                    <div className="characteristic__container">
                                        <div className="characteristic__label">Виробник</div>
                                        <div className="characteristic__value">Санберрі Україна</div>
                                    </div>
                                    <div className="characteristic__container">
                                        <div className="characteristic__label">Основний компонент</div>
                                        <div className="characteristic__value">Азот - Фосфор - Калій</div>
                                    </div>
                                </div>
                                <div className="item__offer">
                                    <div className="itemNPK__zone">
                                        <div className="item__packing">
                                            <span className="item__packing-text">Фасування:</span>
                                            <div className="item__packing-position">
                                                <div className="itemList__capacity-tabs">
                                                    <button
                                                        className={
                                                            togglefirstState === 1
                                                                ? "itemNPK__cap-tabs active-itemNPK__cap-tabs"
                                                                : "itemNPK__cap-tabs"
                                                        }
                                                        onClick={() => toggleFirstTab(1)}
                                                    >
                                                        1т
                                                    </button>
                                                    <button
                                                        className={
                                                            togglefirstState === 2
                                                                ? "itemNPK__cap-tabs active-itemNPK__cap-tabs"
                                                                : "itemNPK__cap-tabs"
                                                        }
                                                        onClick={() => toggleFirstTab(2)}
                                                    >
                                                        500кг
                                                    </button>
                                                    <button
                                                        className={
                                                            togglefirstState === 3
                                                                ? "itemNPK__cap-tabs active-itemNPK__cap-tabs"
                                                                : "itemNPK__cap-tabs"
                                                        }
                                                        onClick={() => toggleFirstTab(3)}
                                                    >
                                                        50кг
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item__price">
                                            <div className="price__charts">
                                                <span className="price__item">Роздрібна ціна</span>
                                                <div className="price__taxes">
                                                    <div>i</div>
                                                    <div class="price__taxes-content fadeInUp">
                                                        <span>Ціни вказані з ПДВ</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="price item__capacity-tabs-active">
                                                <div
                                                    className={
                                                        togglefirstState === 1 ? "capacity  active-capacity" : "capacity"
                                                    }
                                                >
                                                    {priceFirst}
                                                </div>
                                                <div
                                                    className={
                                                        togglefirstState === 2 ? "capacity  active-capacity" : "capacity"
                                                    }
                                                >
                                                    {priceSecond}
                                                </div>
                                                <div
                                                    className={
                                                        togglefirstState === 3 ? "capacity  active-capacity" : "capacity"
                                                    }
                                                >
                                                    {priceThird}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item__order">
                                        <Link to="order"><Button /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section-cart-promo">
                    <div className="cart-promo__item">
                        <div className="cart-promo__ico">
                            <img className="card-info_photo" src={verified} alt="verified" />
                        </div>
                        <div className="cart-promo__wrap">
                            <div className="cart-promo__head">Якість гарантовано!</div>
                            <div className="cart-promo__label _text-caption">Санберрі Україна пропонує тільки офіційну продукцію</div>
                        </div>
                    </div>
                    <div className="cart-promo__item">
                        <div className="cart-promo__ico">
                            <img className="card-info_photo" src={time} alt="time" />
                        </div>
                        <div className="cart-promo__wrap">
                            <div className="cart-promo__head">Працюємо 24/7</div>
                            <div className="cart-promo__label _text-caption">Ми приймаємо ваші замовлення щодня без перерв та вихідних</div>
                        </div>
                    </div>
                    <div className="cart-promo__item">
                        <div className="cart-promo__ico">
                            <img className="card-info_photo" src={money} alt="money" />
                        </div>
                        <div className="cart-promo__wrap">
                            <div className="cart-promo__head">Оплата</div>
                            <div className="cart-promo__label _text-caption">Пропонуємо готівковий та безготівковий розрахунки за товар</div>
                        </div>
                    </div>
                    <div className="cart-promo__item">
                        <div className="cart-promo__ico">
                            <img className="card-info_photo" src={deliver} alt="deliver" />
                        </div>
                        <div className="cart-promo__wrap">
                            <div className="cart-promo__head">Доставка</div>
                            <div className="cart-promo__label _text-caption">По всій Україні Новою Поштою чи іншими службами доставки</div>
                        </div>
                    </div>
                </div>
                <div className="seeds-tabs">
                    <button
                        className={toggleState === 1 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
                        onClick={() => toggleTab(1)}
                    >
                        Опис Товару
                    </button>
                    <button
                        className={toggleState === 2 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
                        onClick={() => toggleTab(2)}
                    >
                        Характеристика
                    </button>
                </div>
                <div className="seeds-tabs-active">
                    <div
                        className={toggleState === 1 ? "seeds-active  active-seeds" : "seeds-active"}>
                        <Description />
                    </div>
                    <div
                        className={toggleState === 2 ? "seeds-active  active-seeds" : "seeds-active"}>
                        <Reference />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
