import "./Production.scss";
import location from "../../icons/location-black.svg";
import AwesomeButton from "../AwesomeButton/AwesomeButton";
import bg from "../../image/production/1.jpg";
import { Link } from "react-router-dom";

export default function Production() {
    return (
        <div className="production__container">
            <div className="production__inner">
                <div className="production__title">
                    <span className="production__header">Виробництво</span>
                </div>
            </div>
            <div className="production__info info">
                <div className="info__img">
                    <div className="trade__photo"></div>
                    <img className="trade__background" src={bg} alt="bg" />
                </div>
                <div className="info__descr">
                    <div className="info__subtitlek">
                        <h4>5 фактів</h4>
                    </div>
                    <div className="info__title">
                        <h3>про Cанберрі Україна</h3>
                    </div>
                    <p>
                        Cанберрі Україна – від мрії до впевненості у високому врожаю
                    </p>
                    <div className="info__text">
                        <ol className="info__text-list">
                            <li className="info__text-numbering">
                                Власне виробництво, розташоване у Вінниці.
                            </li>
                            <li className="info__text-numbering">
                                Команда, у якій поряд із менеджерами працюють досвідчені
                                агрономи. Тому ми займаємося не лише продажами, але й
                                допомагаємо нашим клієнтам обрати якісний посівний матеріал,
                                надаємо консультації з вирощування рослин, а також реалізації
                                врожаю.
                            </li>
                            <li className="info__text-numbering">
                                Екологічні міндобрива, які здатні не лише збільшувати
                                врожайність, але й покращувати якість ґрунту. З нашими
                                продуктами ви вирощуватимете екологічний врожай.
                            </li>
                            <li className="info__text-numbering">
                                Елементи живлення кращі за гній. Добрива Sunberry Ukraine
                                містять високу концентрацію гумінових та фулієвих кислот, добре
                                засвоюються та дозволяють заощаджувати вже на етапі придбання та
                                внесення;
                            </li>
                            <li className="info__text-numbering">
                                Комплексні інноваційні рішення у сфері сільського господарства.
                                Надаємо послуги з оброблення полів за допомогою дронів,
                                реставрації та доукомплектації КРН і сівалок.
                            </li>
                        </ol>
                    </div>
                    <div className="info__button">
                        <Link to="contact">
                            <AwesomeButton />
                        </Link>
                    </div>
                    <div className="info__logo">
                        <img src={location} alt="location" />
                        <p className="info__location">
                            м. Вінниця, вул. Сергія Зулінського, 43
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
