import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "../GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from 'react-ink'
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для пшениці та інших зернових культур, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для зернових культур • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureSeed" />
                <meta
                    name="description"
                    content="🌱Добрива для пшениці та інших зернових культур ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для зернових культур</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для зернових культур</h1>
            </div>
            {/* <div className="culture__bg">
                <div className="culture__scheme"></div>
            </div> */}
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення пшениці</h2>
                <p className="culture__subtitle">
                    Кожен елемент важливий для живлення і розвитку рослиню
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення вашої пшениці
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__heading">
                        Використання гуматів у вирощуванні зернових – шлях до підвищення кількості та якості врожаю
                    </p>
                    <p className="culture__desc">
                        Зернові – найважливіші культури агропромислового комплексу України. Наша держава є житницею Європи, де вирощують зерно для власних потреб та на експорт. Сприятливі погодні умови, родючі ґрунти у поєднанні з умілим використанням комплексних добрив допомагають аграріям щорічно отримувати великі обсяги якісного врожаю. Компанія Санберрі Україна пропонує купити добрива для пшениці власного виробництва, що створені з якісної імпортованої сировини. Нашу продукцію вирізняє:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            екологічний підхід, що ґрунтується на максимальному задоволенні потреб зернових культур у мікро- та макроелементах та одночасному покращенні родючості ґрунтів;
                        </li>
                        <li className="culture__description-numbering">
                            економічність добрив, що полягає у використанні невеликих доз концентратів, що містять комплекси поживних елементів у найкращій для засвоєння рослинами формі;
                        </li>
                        <li className="culture__description-numbering">
                            застосування наукового підходу. Вся продукція проходить обов’язкове тестування та випробовується на ефективність.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        За умови постійного використання посівних площ якість ґрунтів невпинно знижується. У них майже відсутні корисні макро- та мікроелементи, здатні легко засвоюватися культурними рослинами. Саме тому використання стимуляторів росту – гуматів, які вирізняють високою концентрацією таких речовин, є вкрай актуальною. За таких умов вдається збільшити обсяги врожаю та підвищити здатність паростків пшениці та ячменя протистояти негативним факторам довкілля. Рекомендовані нами добрива використовуються способом позакореневого підживлення. За таких умов вони швидко поглинаються рослинами та ліквідують нестачу ключових елементів, що впливає на розвиток та врожайність культури.
                    </p>
                    <p className="culture__desc">
                        Специфіку використання добрив на певній ділянці зазвичай визначає аргоном, який отримує проби ґрунту та досліджує його на вміст макро- і мікроелементів у доступній для засвоєння рослинами формі. Експерти Санберрі Україна пропонують розроблену на основі власних досліджень схему використання мікродобрив для листового живлення при вирощуванні пшениці.
                    </p>
                    <p className="culture__heading">
                        Передпосівна обробка – запорука дружніх сходів
                    </p>
                    <p className="culture__desc">
                        Ми рекомендуємо використовувати рідкі добрива для зернових культур ще перед посівом. Найкраще для цього підходить Агроболік Фосфор, що є концентратом корисних сполук та діє одночасно у кількох напрямках:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            забезпечує посівний матеріал енергією проростання, завдяки чому сходи з’являються одночасно та від початку мають гарний потенціал до зростання;
                        </li>
                        <li className="culture__description-numbering">
                            стимулює розвиток коріння, що є необхідною передумовою збільшення зеленої маси;
                        </li>
                        <li className="culture__description-numbering">
                            підвищує стійкість зернових до хвороб та зменшує вплив шкідливих факторів на інтенсивність зростання, що особливо актуально у випадку використання гербіцидів та протруйників.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Агроболік Фосфор містить мікроелементи, що необхідні рослинам на початковій стадії розвитку. Він повністю розчиняється у воді, що дозволяє обробляти посівний матеріал найбільш якісно та рівномірно.
                    </p>
                    <p className="culture__heading">
                        Листові обробки зернових – важливий фактор збільшення врожайності
                    </p>
                    <p className="culture__desc">
                        Етап нарощування вегетативної маси потребує внесення азотних добрив, а їх засвоєння спричинює підвищення інтенсивності дихання. В результаті рослини можуть втратити занадто багато вологи. Нормалізація цього процесу вимагає наявності великої кількості мікроелементів. Саме тому на етапі початку кущіння від моменту появи 3 листів необхідно вносити мінеральні добрива для зернових культур. Вони сприятимуть прискоренню процесів вторинного вкорінення та допоможуть подолати стан фізіологічного стресу.
                    </p>
                    <p className="culture__desc">
                        Перша обробка по листу відбувається за умови встановлення температури повітря вище +10°C. Для неї ми рекомендуємо використовувати Агроболік Фосфор у кількості від 1,5 до 2 л/га. Комплексне органо-мінеральне добриво допоможе:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            активізувати кущіння, що стане запорукою отримання високого врожаю;
                        </li>
                        <li className="culture__description-numbering">
                            розвинути кореневу систему, що надасть рослині змоги отримувати більше вологи та поживних речовин з ґрунту;
                        </li>
                        <li className="culture__description-numbering">
                            стимулювати фотосинтез навіть за умови короткого світлового дня, недостатньої сонячної активності та низьких температур;
                        </li>
                        <li className="culture__description-numbering">
                            збільшити стійкість рослин до морозів, бактеріальних та грибкових хвороб;
                        </li>
                        <li className="culture__description-numbering">
                            збалансувати енергетичний обмін, процеси дихання та підтримати водний баланс.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Використання Агроболіку Фосфор допоможе підготувати посіви озимої пшениці до заморозків та періоду спокою. Задоволення потреб рослин в усіх необхідних мікроелементах у цей час дозволить молодим пагонам пережити стресові умови без значних втрат та якнайшвидше розпочати вегетаційний процес за настання сприятливих умов.
                    </p>
                    <p className="culture__desc">
                        Наступна обробка здійснюється на початку трубкування у стадії наявності 2-3 міжвузлів. У цей період рекомендуємо вносити Агроболік Цинк у кількості 1,5-2 л/га. Цей комплексний препарат призначений для стабілізації фізіологічних процесів, що відбуваються у рослині, та підвищення її опірної здатності спеці, посусі та морозу. Його здатність покращувати ступінь засвоєння мінеральних добрив, знижувати вміст нітратів у сільськогосподарській продукції та зменшувати вплив отрутохімікатів позитивно впливає на якість врожаю. Агроболік Цинк нетоксичний, при цьому є дієвим засобом проти широкої групи захворювань пшениці та ячменю.
                    </p>
                    <p className="culture__heading">
                        Для чого потрібна фінальна обробка пшениці
                    </p>
                    <p className="culture__desc">
                        Останню обробку рідкими добривами для зернових культур необхідно здійснювати на етапі появи флагового листка. Наші дослідження довели ефективність Агроболіку Бор, що здатен позитивно впливати на розвиток зерен та їх якість. Використання добрива стимулює закладання генеративних органів рослини, а також зменшує вплив спеки та посухи на процес запилювання квіток. Встановлено, що внесення Агроболіку Бор дозволяє збільшити врожайність пшениці та ячменя до 30% та веде до покращення якості зерна. Вміст у добриві бору, амінокислот та полісахаридів у доступній для швидкого засвоєння формі суттєво покращує якість врожаю зернових.
                    </p>
                </div>
                <p className="culture__heading">
                    Спеціальні пропозиції
                </p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight"> «Фосфор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight"> «Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight"> «Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
