import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "./GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для плодово-ягідних культур, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для сої • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureSoy" />
                <meta
                    name="description"
                    content="🌱Добрива для сої ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для сої</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для сої</h1>
            </div>
            <div className="culture__bg">
                <div className="culture__scheme-soy"></div>
            </div>
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення сої</h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення вашої сої
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__heading">
                        Екологічні та високоефективні комплексні добрива для сої
                    </p>
                    <p className="culture__desc">
                        Соя – бобова культура, що для гарного розвитку та збільшення врожайності потребує високого вмісту азоту у ґрунті. Більшу частину такої потреби (до 70%) рослина задовольняє за рахунок симбіозу з азотфіксуючими бактеріями. Окрім азоту для отримання гарного врожаю соя вимагає фосфорних, калійних добрив, мікро- та мікроелементів таких як сірка та магній. Збалансоване живлення рослини на кожному з етапів її розвитку забезпечать гумати виробництва Санберрі Україна. Це концентровані мікродобрива для сої з оптимальним складом, що гарно засвоюється культурою. Наші продукти належать до категорії екологічних. Вони не лише збільшують врожай, але й позитивно впливають на його якість, захищаючи рослини від хвороб чи несприятливих факторів, та покращують якість ґрунту. Для комплексного догляду підживлення необхідно робити кілька разів за сезон, комбінуючи різні за складом добрива.
                    </p>
                    <p className="culture__heading">
                        Перше підживлення сої
                    </p>
                    <p className="culture__desc">
                        Забезпечення гарного старту зростання культурної рослини – важлива передумова дружнього розвитку сходів та гарного врожаю. Саме тому ми радимо використовувати Агроболік Фосфор для передпосівної обробки насіння. Застосування комплексного органо-мінерального добрива від самого початку надасть вам одразу кілька вагомих переваг:
                    </p>
                    <ol className="culture__description-list">
                        <li className="culture__description-numbering">
                            Підвищить схожість насіння, зробивши паростки менш чутливими до несприятливих погодних факторів.
                        </li>
                        <li className="culture__description-numbering">
                            Створить сприятливі умови для розвитку азотфіксуючих мікроорганізмів у ґрунті.
                        </li>
                        <li className="culture__description-numbering">
                            Захистить рослини від негативного впливу використання гербіцидів на ділянці.
                        </li>
                    </ol>
                    <p className="culture__desc">
                        Агроболік Фосфор необхідно використовувати у невеликих кількостях – лише 4 л/т. Ефективність передпосадкової обробки добривом доведена у лабораторних та польових умовах. Її підтверджує практичний досвід виробників сільськогосподарської продукції та науковців.
                    </p>
                    <p className="culture__heading">
                        Листкове підживлення сої протягом періоду вегетації
                    </p>
                    <p className="culture__desc">
                        Найсуттєвіше на кількість та якість майбутнього врожаю впливають два періоди розвитку рослини: поява 2-3 трилисників 	та початок бутонізації. Найкраща підживка для сої у цей період – поєднання добрив Агроболік Фосфор та Агроболік Бор+Молібден у кількості по 1 л/га. На етапі нарощування вегетативної маси позакоренева обробка корисна для:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            закладення більшої кількості міжвузлів;
                        </li>
                        <li className="culture__description-numbering">
                            ліквідації стресу від використання гербіцидів;
                        </li>
                        <li className="culture__description-numbering">
                            стимулювання розвитку бульбочок.
                        </li>
                    </ul>
                    <p className="culture__heading">
                        На початку цвітіння використання добрив покращить утворення зав’язей та налагодить усі процеси, що відбуваються у організмі рослини:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            відрегулює водний обмін на клітинному рівні та посприяє більш раціональному використанню вологи, засвоєної з ґрунту;
                        </li>
                        <li className="culture__description-numbering">
                            покращить синтез хлорофілу та стабілізує засвоєння макроелементів;
                        </li>
                        <li className="culture__description-numbering">
                            стабілізує фотосинтез та дихання.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Бор та молібден відіграють важливу роль у синтезі ферменту нітроредуктази та молібден протеїдів. Ці мікроелементи здатні покращити синтез білка та амінокислот, що призведе до підвищення врожайності.
                    </p>
                    <p className="culture__desc">
                        Для фінальної обробки використовують Агроболік Універсал, який допоможе збільшити кількість поживних речовин у бобах та знизить концентрацію нітратів у них. Добриво зменшить ризик ураження культури фузаріозом, кореневою гниллю, переноспорозом. Норма його внесення 1,5-2л/га.
                    </p>
                    <p className="culture__desc">
                        Поміркована ціна добрив для сої від Санберрі Україна та невисокі дози використання концентратів дозволяють аграріям суттєво заощаджувати кошти, не нехтуючи питаннями кількості та якості врожаю.
                    </p>
                </div>
                <p className="culture__heading">Спеціальні пропозиції</p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Універсал»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Універсал»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Універсал»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
