export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Діамофоска 10:20:20 є комплексним мінеральним добривом, яке використовується для підвищення рівня врожайності сільськогосподарських культур. До його складу входять азот, фосфор та калій. Використовувати цей препарат можна на будь-яких видах ґрунтів, від піщаних до чорнозему. Вносять діамофоску у різний період: перед посадкою, під час активного росту чи плодоношення.
                </p>
                <p className="card__description-text">
                    Добриво 10:20:20 офіційно називається діамонійфосфатом. Цифри означають вміст активних елементів, тобто у складі присутні 10 частин азоту, 20 – фосфору та 20 – калію. Всі мікро- і макроелементи знаходяться в амонійній формі, що дозволяє пролонгувати термін їх дії. Це досягається завдяки тому, що добриво повільно вимивається, не вивітрюється та не випаровується.
                </p>
                <div className="card__description-title">
                    Особливості діамофоски
                </div>
                <p className="card__description-text">
                    Цей препарат є хімічно нейтральним, рівень його кислотності становить 7 одиниць. Випускається у вигляді круглих гранул діаметром 4–6 мм. Вони мають бежевий або рожевий колір. Рівень гігроскопічності у добрива досить низький, тому воно не злежується та не страждає від вологи, але все ж таки краще зберігати його в сухих приміщеннях. Не створює пил при тривалому зберіганні, не піддається пожежі.
                </p>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Додатково у складі діамофоски присутні:
                    </p>
                    <li className="card__description-numbering">
                        Сірка. Вона необхідна для захисту рослин від різних грибкових захворювань та шкідників (кліщів, попелиці, дротяника).
                    </li>
                    <li className="card__description-numbering">
                        Магній. Необхідний для нормального фотосинтеза та живлення. Допомагає рослинам виробляти білок.
                    </li>
                    <li className="card__description-numbering">
                        Кальцій. Є стимулятором живлення, підвищує рівень родючості рослини.
                    </li>
                    <li className="card__description-numbering">
                        Мідь. Необхідна для вироблення окисних ферментів. Дуже важлива для злакових культур, оскільки зміцнює їхній імунітет.
                    </li>
                    <li className="card__description-numbering">
                        Цинк. Прискорює процес росту та розвитку листових пластин.
                    </li>
                    <li className="card__description-numbering">
                        Залізо. Необхідно щодо різних окислювальних та відновлювальних реакцій.
                    </li>
                    <li className="card__description-numbering">
                        Бор. Покращує обмінні процеси, а також захищає сходи насіння від сухої гнилі.
                    </li>
                </ol>
                <p className="card__description-text">
                    Завдяки наявності цілого спектру мікро- і макроелементів рослини швидко ростуть і розвиваються. При цьому потрібно дотримуватись норм внесення добрив і не перевищувати їх. Інакше у ґрунті утворюється підвищений вміст нітратів, що може негативно позначитися на врожаї.
                </p>
                <div className="card__description-title">
                    Переваги препарату
                </div>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        До переваг діамофоски відносяться:
                    </p>
                    <li className="card__description-numbering">
                        Комплексна дія. У складі присутні всі необхідні для зростання та розвитку мікро- і макроелементи. Висаджені рослини будуть високими та здоровими.
                    </li>
                    <li className="card__description-numbering">
                        Збільшення врожайності на 30–70 %. Точне число залежить від інших чинників. Зокрема, від регулярного поливу, температури, кількості сонячних днів, типу ґрунту, типів вирощуваних гібридів, технологій тощо.
                    </li>
                    <li className="card__description-numbering">
                        Доступна ціна діамофоски 10:20:20. Коштує вона відносно недорого. Препарат продається в мішках та вноситься шляхом розкидання по полю ручним чи механічним способом.
                    </li>
                </ol>
                <p className="card__description-text">
                    Зазначимо, що цей препарат підходить для широкого спектра сільськогосподарських культур. Його застосовують при вирощуванні багаторічних трав, соняшника, сої, ріпака, кукурудзи, буряків, картоплі, томатів, капусти та ін.
                </p>
                <p className="card__description-text">
                    Добриво може вноситися у декілька етапів. Спочатку його розсипають по ділянці та перекопують її. Роблять це навесні, до висадки сільськогосподарських культур. Повторне внесення рекомендовано на початку або в середині літа, щоб підтримати вегетацію та покращити врожайність рослин. Допускається використання у теплицях або парниках.
                </p>
                <p className="card__description-text">
                    Якщо ви хочете купити якісну діамофоску, рекомендуємо вам звертатися до нас. Ми є компанією-виробником мінеральних добрив. Вся наша продукція має сертифікати якості та відповідності. Ми практикуємо індивідуальний підхід та пропонуємо нашим клієнтам вигідні умови співпраці. Виробництво «Cанберрі Україна» знаходиться у Вінниці, але ми можемо відправити товар до будь-яких населених пунктів країни транспортними компаніями чи перевізниками.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
