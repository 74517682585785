import "./Partners.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import truck from '../../image/distribution/truck.png'
import shield from '../../image/distribution/shield.png'
import setting from '../../image/distribution/setting.png'
import earth from '../../image/distribution/earth.png'
import bio from '../../image/distribution/bio.png'
import assortment from '../../image/distribution/assortment.png'
import bg from '../../image/distribution/1.png'
import Form from '../Form/Form'
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";

export default function Partners() {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    return (
        <div className="partners">
            <Helmet>
                <meta name="keywords" content="Вимоги до офіційних дистриб'юторів, дистриб'ютори, приєднатись" />
                <title>Приєднатись до команди Санберрі Україна</title>
                <link rel="canonical" href="https://sun-ukr.com/distributors" />
                <meta name="description" content="У нас ви можете придбати препарати як оптом, так і вроздріб, щоб забезпечити культури поживними елементами. Пропонуємо препарати для забезпечення ефективної системи живлення вашого посівую"></meta>
            </Helmet>
            <ScrollToTopOnMount />
            <Header />
            <div className="partner-bg__photo"></div>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Дистриб'ютори</span>
            </div>
            <div className="partner__contain">
                <div className="partner__contain--header">
                    <h1 className="partner__title">ТОВ «Санберрі Україна»</h1>
                    <p className="partner__text">
                        Ми з великою відповідальністю ставимося до своєї роботи та невпинно працюємо над тим, щоб стати більш ефективною компанією, щоб наші клієнти отримували від сільського господарства максимум! Наша команда зосереджена не на миттєвому отриманні прибутку, а на побудові довгострокових відносин з нашими клієнтами. Ми зацікавлені в тому, щоб наші покупці могли добре заробляти на гектарі!
                    </p>
                </div>
                <div>
                    <p className="partner__subject">Чому варто стати дистриб'ютором Санберрі Україна?</p>
                    <div className="partner__inner">
                        <div className="partner__block">
                            <div className="partner__block--inside">
                                <img className="partner__block--inside-icon" src={truck} alt="" />
                            </div>
                            <div className="partner__block--title">Швидка логістика</div>
                            <div className="partner__block--text">У нас ви можете придбати препарати як оптом, так і вроздріб, щоб забезпечити культури поживними елементами</div>
                        </div>
                        <div className="partner__block">
                            <div className="partner__block--inside">
                                <img className="partner__block--inside-icon" src={shield} alt="" />
                            </div>
                            <div className="partner__block--title">Перевірена якість</div>
                            <div className="partner__block--text">Ми пропонуємо добрива власного виробництва з імпортних складових та тільки ті препарати, дієвість яких перевірена на вітчизняних полях</div>
                        </div>
                        <div className="partner__block">
                            <div className="partner__block--inside">
                                <img className="partner__block--inside-icon" src={earth} alt="" />
                            </div>
                            <div className="partner__block--title">Співпраця зі світовими лідерами</div>
                            <div className="partner__block--text">Санберрі Україна співпрацює зі світовими виробниками високоякісних мікродобрив, щоб забезпечити вас тільки кращими препаратами для культур, які ви вирощуєте.</div>
                        </div>
                        <div className="partner__block">
                            <div className="partner__block--inside">
                                <img className="partner__block--inside-icon" src={assortment} alt="" />
                            </div>
                            <div className="partner__block--title">Широкий вибір препаратів</div>
                            <div className="partner__block--text">Санберрі Україна пропонує препарати для забезпечення ефективної індивідуальної системи живлення кожного гектара вашого посіву. </div>
                        </div>
                        <div className="partner__block">
                            <div className="partner__block--inside">
                                <img className="partner__block--inside-icon" src={bio} alt="" />
                            </div>
                            <div className="partner__block--title">Екологічний продукт</div>
                            <div className="partner__block--text">Виробництво продукції здійснюється з використанням безпечних для екології компонентів</div>
                        </div>
                        <div className="partner__block">
                            <div className="partner__block--inside">
                                <img className="partner__block--inside-icon" src={setting} alt="" />
                            </div>
                            <div className="partner__block--title">Зручний сервіс</div>
                            <div className="partner__block--text">У нас ви можете придбати препарати як оптом, так і вроздріб, щоб забезпечити культури поживними елементами</div>
                        </div>
                    </div>
                </div>
                <div className="partner__line">
                    <div className="partner__line--border"></div>
                </div>
                <div className="partner__aside">
                </div>
                <div className="partner__section">
                    <p className="partner__subtitle">
                        Які вимоги до майбутніх партнерів?
                    </p>
                    <div className="partner__box">
                        <div className="partner__section--block">
                            <p className="partner__section--heading">
                                Базові вимоги до офіційного дистриб'ютора
                            </p>
                            <ul className="partner__section--list">
                                <li className="partner__section--li">
                                    Мінімальний річний об'єм закупівлі по продукції ТОВ «Санберрі Україна» від 1 млн. грн., з ПДВ
                                </li>
                                <li className="partner__section--li">
                                    Досвід роботи з продуктами міндобрив від 3 років
                                </li>
                                <li className="partner__section--li">
                                    Професійна команда продавців: мін. 3 регіональних менеджерів з продажу, та технічні спеціалісти
                                </li>
                                <li className="partner__section--li">
                                    Наявність спеціалізованих складських приміщень для зберігання міндобрив
                                </li>
                                <li className="partner__section--li">
                                    Прозора репутація дистриб'ютора на ринку України, відсутність випадків участі дистриб'ютора у справах з фальсифікацією товарів
                                </li>
                            </ul>
                        </div>
                        <div className="partner__section--block">
                            <p className="partner__section--heading">
                                Базові вимоги до офіційного дистриб'ютора
                            </p>
                            <p className="partner__section--text">
                                Якщо Ви зацікавлені у співпраці з ТОВ «Санберрі Україна» в якості дистриб'ютора або ваша компанія відповідає вимогам до потенційних партнерів компанії зазначених вище та Ви готові далі разом рости та розвиватись, просимо надсилати свої заявки за адресою «sunberry.ukr@gmail.com» Відділ з впровадження стратегії продажів та цифрових технологій.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="partner__wrapper">
                    <div className="partner__holder">
                        <div className="partner__wrapper--block">
                            <p className="partner__wrapper--heading">
                                Що дає співпраця з ТОВ «Санберрі Україна»?
                            </p>
                            <ol className="partner__wrapper--list">
                                <li className="partner__wrapper--li">
                                    Статус офіційного дистриб'ютора ТОВ «Санберрі Україна» ;
                                </li>
                                <li className="partner__wrapper--li">
                                    Високоякісна оригінальна продукція безпосередньо від виробника;
                                </li>
                                <li className="partner__wrapper--li">
                                    Стабільність поставок продуктів;
                                </li>
                                <li className="partner__wrapper--li">
                                    Можливість планування поставки продуктів по місяцям для їх своєчасного отримання кінцевим споживачем;
                                </li>
                                <li className="partner__wrapper--li">
                                    Можливість проведення спільних маркетингових заходів.
                                </li>
                            </ol>
                        </div>
                        <div className="partner__wrapper--block">
                            <img className="partner__wrapper--photo" src={bg} alt="" />
                        </div>
                    </div>
                </div>
                <div className="partner__form">
                    <p className="partner__form--title">БАЖАЄТЕ ПРИЄДНАТИСЬ ДО КОМАНДИ ?</p>
                    <p className="partner__form--text">
                        Заповніть заявку і ми зв’яжемось з вами
                    </p>
                    <Form />
                </div>
            </div>
            <Footer />
        </div>
    );
}
