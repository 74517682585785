
import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class Maps extends React.Component {
  render() {
    const mapStyles = {
      width: "100%",
      height: "100%",
    };
    return (
      <Map
        google={this.props.google}
        zoom={7}
        style={mapStyles}
        initialCenter={{ lat: 48.30120, lng: 28.68858 }}
      >
        <Marker position={{ lat: 49.27102, lng: 28.51334 }} />
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCxtkxtUaZWhx8sytxzHjqeGsCUuGzRVGA",
})(Maps);