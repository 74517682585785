import "../../Sunflowers/SunflowersCard/SunflowerCard.scss";
import Header from "../../../../../Header/Header";
import Footer from "../../../../../Footer/Footer";
import GoodsList from '../../../../GoodsList/GoodsListSeed/GoodsListSoy';
import crumbs from "../../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FertilizersTabs() {
    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="купити, кукурудза, посівмат, соняшник, Санберрі Україна" />
                <title>Насіння кукурудзи • Купити посмівмат кукурудзи оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/soyCard" />
                <meta name="description" content="🌱Насіння сої оптом і в роздріб ⭐ Від якісних виробників з Європи ✅ Замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
                <script async src="https://www.googletagmanager.com/gtag/js?id=AW-10879530292"></script>
                <script>
                    {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
        
                        gtag('config', 'AW-10879530292');
                    `}
                </script>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalogSeed">
                    <span>/ Насіння</span>
                </Link>
                <span className="crumbs__text">/ Насіння сої</span>
            </div>
            <div className="fertilizersCard__catalog">
                <div className="fertilizersCard__title">
                    <h1>Насіння сої</h1>
                </div>
                <div>
                    <GoodsList />
                </div>
            </div>
            <Footer />
        </>
    );
}

