import "./Contact.scss";
import GoogleMap from "../GoogleMap/GoogleMap";
import React from "react";

export default function Contact() {
    return (
        <div className="contact__container">
            <div className="contact__block">
                <div className="block__left">
                    <div className="block-table__tr">
                        <div className="c-table__header">
                            Адреса
                        </div>
                        <div className="c-table__body">
                            м. Вінниця, вулиця Сергія Зулінського, 43
                        </div>
                    </div>
                    <div className="block-table__tr">
                        <div className="c-table__header">
                            Телефон
                        </div>
                        <div className="c-table__body">
                            <a href="tel:+380683699030">+38(068)-369-90-30</a>
                        </div>
                    </div>
                    <div className="block-table__tr">
                        <div className="c-table__header">
                            Електрона пошта
                        </div>
                        <div className="c-table__body">
                            <a href="mailto:sunberry.ukr@gmail.com">sunberry.ukr@gmail.com</a>
                        </div>
                    </div>
                    <div className="block-table__tr">
                        <div className="c-table__header">
                            Графік роботи
                        </div>
                        <div className="c-table__body">
                            <div className="contain__work-time">
                                <span>Пн-Пт: 09:00–18:00</span>
                            </div>
                            <div className="contain__work-time">
                                <span>Сб-Нд: вихідний</span>
                            </div>
                        </div>
                    </div>
                    <div className="block-table__tr">
                        <div className="c-table__header desktop-2 large-2">
                            Адреса поштової кореспонденції
                        </div>
                        <div className="c-table__body">
                            вулиця Сергія Зулінського, 43, Вінниця, Вінницька область, 21022
                        </div>
                    </div>
                    <div className="block-table__version">
                        <div>version: 7.78945-1</div>
                    </div>
                </div>
                <div className="block__right">
                    <GoogleMap />
                </div>
            </div>
        </div>
    );
}