import "./Main.scss";
import Services from "../Services/Services";
import Slider from "../SlickSlider/Slider";
import About from "../About/About";
import Blog from "../Blog/BlogPage";
import Scroll from "../../assets/ScrollUpButton/index";
// import FQA from "../FAQ/main/FAQ";
import Production from "../Production/Production";
import SliderPartners from "../SliderPartners/SliderPartners";
import Feedback from "../Feedback/Feedback";
import { Helmet } from "react-helmet";
import React from "react";

export default function Main() {

    return (
        <main className="main">
            <Helmet>
                <title>Санберрі Україна • Добрива від виробника | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com" />
                <meta name="keywords" content="Sunberry Ukraine, Виробництво, Інновації, Санберрі Україна, Агроболік, Агронеїн, NPK, добрива" />
                <meta name="description" content="🌱 Санберрі Україна виготовляє  органо-мінеральні добрива індивідуального складу ⭐ Займаємось комплексним вирощуванням сільськогосподарських культур, починаючи підбором найкращого насіння, закінчуючи реалізацією продукції."></meta>
            </Helmet>
            <div className="main__container">
                <Scroll />
                <section className="slider">
                    <Slider />
                </section>
                <section className="about">
                    <About />
                </section>
                <section className="production">
                    <Production />
                </section>
                <section className="sliderPartners">
                    <SliderPartners />
                </section>
                <section className="blog">
                    <Blog />
                </section>
                <section className="services">
                    <div className="services__header">
                        <h1 className="services__title">Інновації</h1>
                    </div>
                    <Services />
                </section>
                {/* <section className="main__question">
                    <div className="main__question__inner">
                        <p className="main__question-title">FQA</p>
                    </div>
                    <FQA />
                </section> */}
                <section className="Feedback" id="section5">
                    <Feedback />
                </section>
            </div>
        </main>
    );
}