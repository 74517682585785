import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "./GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для цукрового буряку, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для цукрового буряку • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureSugarBeet" />
                <meta
                    name="description"
                    content="🌱Добрива для цукрового буряку ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для цукрового буряку</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для цукрового буряку</h1>
            </div>
            {/* <div className="culture__bg">
                <div className="culture__scheme"></div>
            </div> */}
            <div className="culture__container">
                <h2 className="culture__under">
                    Дефіцит елементів живлення цукрових буряків
                </h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення вашого цукрового буряка
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__desc">
                        Цукровий буряк є культурою стратегічного значення, і його вирощування в Україні має великі перспективи. Водночас рослина потребує постійного живлення. Добрива для буряків є невіддільною складовою у вирощуванні й формуванні якісного врожаю.
                    </p>
                    <p className="culture__heading">Що потрібно знати</p>
                    <p className="culture__desc">
                        Цукровий буряк є однією з найвибагливіших сільськогосподарських рослин, адже, щоб забезпечити правильну вегетацію та здобути багатий врожай, необхідно опрацювати для нього схему якісного і своєчасного живлення. Якісні добрива цукрових буряків мають містити низку мікроелементів. Насамперед це бор і молібден, а також:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            залізо;
                        </li>
                        <li className="culture__description-numbering">
                            марганець;
                        </li>
                        <li className="culture__description-numbering">
                            мідь;
                        </li>
                        <li className="culture__description-numbering">
                            цинк;
                        </li>
                        <li className="culture__description-numbering">
                            фосфор.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Особливу потребу в мікродобривах мають цукрові буряки, які вирощуються за інтенсивною технологією, з метою отримання сталих врожаїв із високою цукристістю. У разі реалізації такої схеми в критичні фази розвитку з основним добривом потрібно вносити позакореневе живлення.
                    </p>
                    <p className="culture__heading">Важливі нюанси</p>
                    <p className="culture__desc">
                        Листові мікродобрива для цукрових буряків забезпечують:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            активність процесів росту;
                        </li>
                        <li className="culture__description-numbering">
                            усування дефіциту мікроелементів;
                        </li>
                        <li className="culture__description-numbering">
                            стимулювання засвоєння мінеральної підгодівлі;
                        </li>
                        <li className="culture__description-numbering">
                            якісне компенсування впливу стресових чинників.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Здатність цукрового буряку засвоювати мікро- і макроелементи залежить від типу ґрунту (або ґрунтового розчину), а також від вмісту добрива в ньому.
                    </p>
                    <p className="culture__heading">Зверніть увагу</p>
                    <p className="culture__desc">
                        Фахівці товариства «Санберрі Україна» пропонують якісні добрива й ефективну схему живлення цукрових буряків з огляду на етапи:
                    </p>
                    <ol className="culture__description-list">
                        <li className="culture__description-numbering">
                            Оброблення насіння.
                        </li>
                        <li className="culture__description-numbering">
                            Фаза 4-8 листків.
                        </li>
                        <li className="culture__description-numbering">
                            Фаза після 8-го листка.
                        </li>
                        <li className="culture__description-numbering">
                            Зімкнення рядка.
                        </li>
                    </ol>
                    <p className="culture__desc">
                        Інформація про дозування добрива має рекомендаційний характер. Точну дозу та час внесення має розрахувати агроном на місці після аналізу ґрунту, у якому вирощуються цукрові буряки.
                    </p>
                    <p className="culture__heading">Оброблення насіння</p>
                    <p className="culture__desc">
                        Процес передбачає внесення органо-мінерального РКД «Агроболік Фосфор» від «Санберрі Україна» в обсязі 4 л/т. Добриво пом’якшує стресовий вплив протруйників, покращує схожість цукрового буряку. Його висока ефективність підтверджується науковими дослідженнями та на практиці.
                    </p>
                    <p className="culture__desc">
                        Після оброблення добривом посівного матеріалу цукрові буряки забезпечується необхідним складом макро- і мікроелементів ще під час вегетації на ювенальній стадії. Це не лише стимулює розвиток і ріст кореневої системи, але й розвиває комплексну неспецифічну стійкість до хвороб, зокрема вірусних.
                    </p>
                    <p className="culture__heading">Інші етапи</p>
                    <p className="culture__desc">
                        На фазі 4-8 листків використовується комплекс «Агроболік Фосфор» із нормою внесення 1 л/га і «Агроболік Бор+ Молібден» – теж 1 л/га. Потреба у використанні саме цих добрив зумовлена тим, що цукровий буряк, перебуваючи на критичних фазах розвитку, має високу потребу в борі. Якщо його недостатньо, порушується гормональний, мінеральний, азотний, водний обмін. У разі нестачі бору в структурі цукрових буряків розпадаються білки, водночас накопичуються аміди й аміак, тому потрібно своєчасно внести добрива.
                    </p>
                    <p className="culture__desc">
                        Використання комплексу не лише стимулює процеси обміну, але й сприяє формуванню правильного коренеплоду, позитивно впливає на ріст рослини, а також на кількість і якість майбутнього врожаю. Цей різновид мікродобрива для цукрових буряків забезпечує їх фітогормонами й енергетичними кислотами, компенсує нестачу мікроелементів.
                    </p>
                    <p className="culture__desc">
                        Аналогічний склад використовується й на фазі після 8 листків. Він знижує стресові впливи, активізує процеси обміну, є засобом для профілактики появи дуплистих коренів і гнилих сердечок. Під час активного коренеутворення поліпшує трансфер і накопичення цукрів, стимулює фотосинтез і біосинтез, підвищує посухостійкість. Аналогічний ефект спостерігається на стадії зімкнення рядів. На цьому етапі завдяки внесенню добрива відбуваються регулювання водного балансу клітин і профілактика захворювань цукрових буряків.
                    </p>
                </div>
                <p className="culture__heading">Спеціальні пропозиції</p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 5 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">четверте внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 5 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">четверте внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 5 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">четверте внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
