export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    MAS 30.M: характеристики, особливості
                </p>
                <p className="card__description-text">
                    Середньостиглий гібрид кукурудзи з високими показниками врожайності – MAS 30.M. Під час вирощування добре та швидко адаптується до різних кліматичних умов, навіть у випадку їх різкого погіршення. «МАС 30.М» має гарні показники стресостійкості. Представлений виробником MAS Seeds. Простий гібрид, під час зростання утворює рослину типу Stay Green. Не боїться сильної посухи, стійкий до більшості властивих кукурудзі хвороб.
                </p>
                <p className="card__description-text">
                    Насіння рекомендоване для вирощування в зонах степу та лісостепу України. Зерно виходить зубоподібним, має високі показники вологовіддачі. Рослина виростає нижче середньої висоти. Потенціал врожайності складає 15 тонн на гектар. Висота кріплення початку – середня. В одному качані близько 16-18 рядів, у кожному по 28-32 зернини.
                </p>
                <p className="card__description-title">
                    Головні особливості гібрида
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        У середньостиглій групі ця кукурудза має один із найвищих показників врожайності. Інші переваги:
                    </p>
                    <li className="card__description-numbering">міцна коренева система. Так, рослина залишається стійкою до вилягання як в період вегетації, так і після його завершення;</li>
                    <li className="card__description-numbering">стійкість до пухирчастої та летючої сажки;</li>
                    <li className="card__description-numbering">несприйнятливість до фузаріозу стебла й качана;</li>
                    <li className="card__description-numbering">дуже висока толерантність до посухи;</li>
                    <li className="card__description-numbering">стійкість до гельмінтоспоріозу.</li>
                </ul>
                <p className="card__description-text">
                    Кукурудза «МАС 30.М» висаджується та вирощується з метою отримання зерна. Купити насіння від офіційного виробника у Вінниці пропонує ТОВ «Санберрі Україна». На сайті компанії продаються також комплексні добрива для сільськогосподарських культур, таких як кукурудза, соняшник та інші.
                </p>
                <p className="card__description-title">
                    Які вимоги до вирощування
                </p>
                <p className="card__description-text">
                    Садити кукурудзу найкраще тоді, коли температура ґрунту на глибині загортання насіння складає +10-12 градусів. Якщо засівати гібрид занадто рано або дуже пізно, це безпосередньо вплине на показники врожайності. Проте, якщо висаджувати MAS 30.M трохи раніше, коли ґрунт на глибині загортання насіння прогрівається до +6-8 градусів, це, за результатами досліджень, призводить до раннього цвітіння волоті.
                </p>
                <p className="card__description-text">
                    Доведено, що у випадку раннього засівання рослина в період вегетації більш раціонально використовує вологу ґрунту, стає більш стійкою до посухи та різких перепадів температур. Але перед сівбою слід оцінити вірогідність приморозків. Вони негативно впливають на стан вегетативної маси на початковому етапі розвитку гібрида. Щоб підвищити стійкість кукурудзи до холоду, насіння обробляють спеціальними препаратами, наприклад «Максим XL» + «Форс Зеа».
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">
                            Стійкість до вилягання під час вегетації
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">
                            Стійкість до вилягання після вегетації
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Stay Green</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
