import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "./GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для соняшника, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для соняшника • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureSunflower" />
                <meta
                    name="description"
                    content="🌱Добрива для соняшника ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для соняшнику</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для соняшнику</h1>
            </div>
            <div className="culture__bg">
                <div className="culture__scheme-sunflower"></div>
            </div>
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення соняшнику</h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення вашого соняшнику
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__heading">
                        Комплексні органо-мінеральні добрива для вирощування соняшнику
                    </p>
                    <p className="culture__desc">
                        Соняшник – важлива олійна культура для українських аграріїв, проте вирощування великого обсягу врожаю високої якості нерідко є проблематичним. Рослина є вибагливою до вмісту вологи у ґрунті та має подовжений період засвоєння калію, що визначає підвищені норми внесення добрив. Культура вкрай примхлива до наявності достатньої кількості мікроелементів. Наприклад, відсутність бору може призвести до часткової втрати врожаю. Через дефіцит цього мікроелементу спостерігатиметься деформація молодих листків, затримка у рості, деформація голівок соняшника. Насіння у такому випадку може формуватися нерівномірно, а на деяких суцвіттях бути навіть зовсім відсутнім. Завчасно купити мікродобрива для соняшника – важлива передумова майбутнього врожаю.
                    </p>
                    <p className="culture__desc">
                        Оскільки критично важливою у вирощуванні культури є кількість бору, слід обирати комплекси за вмістом цього мікроелементу. Його наявність дозволяє рослинам гармонійно розвиватися, що має позитивний вплив на збільшення кількості насінин та їх якості. Правильне використання добрив сприяє суттєвому збільшенню врожайності соняшника. При цьому результат підживлення тісно пов’язаний зі ступенем біологічної доступності речовин. Листові мікродобрива на основі гуматів швидко засвоюються рослинами. Вони усувають нестачу цінних мікроелементів, прискорюють процеси росту та сприяють засвоєнню мінеральних речовин із основного підживлення NPK. Добрива допоможуть паросткам подолати несприятливі погодні чинники, підвищують імунітет культури, стимулюють процеси вегетації, цвітіння та запилення.
                    </p>
                    <p className="culture__desc">
                        Доза внесення мікродобрив для соняшника розраховується в залежності від специфіки умов вирощування та вмісту корисних речовин у ґрунті на конкретному полі. Її має визначати агроном. Проте фахівці ТОВ «Санберрі Україна» розробили власну схему використання добрив, що базується на потребах соняшника на кожному із етапів його вирощування. Ми вважаємо за необхідне здійснювати обробку комплексними препаратами щонайменше 4 рази за сезон на етапах:
                    </p>
                    <ol className="culture__description-list">
                        <li className="culture__description-numbering">
                            Передпосадкової підготовки насіння.
                        </li>
                        <li className="culture__description-numbering">
                            Наявності 4-6 листочків.
                        </li>
                        <li className="culture__description-numbering">
                            Формування 6-8 листів.
                        </li>
                        <li className="culture__description-numbering">
                            Розкриття шапки.
                        </li>
                    </ol>
                    <p className="culture__desc">
                        Купити добрива для соняшника для підживлення рослини на кожному із етапів її розвитку можна на нашому сайті.
                    </p>
                    <p className="culture__heading">
                        Чим обробити насіння перед посадкою
                    </p>
                    <p className="culture__desc">
                        Обробка насіння соняшника перед висівом здійснюється з метою стимулювання проростання та знешкодження негативного впливу протруйників на посадковий матеріал. Для цього ми рекомендуємо використовувати комплексне органо-мінеральне добриво Агроболік Фосфор у кількості 4л/т. Рідка концентрована субстанція легко розчиняється у воді та може бути використана у баковій суміші, є сумісною із ЗЗР.
                    </p>
                    <p className="culture__desc">
                        Дослідження Агроболіку Фосфор на дослідній ділянці, у наукових закладах та сільськогосподарських господарствах доводять, що використання цього препарату гарантує появу дружніх сходів соняшника. Також добриво є ефективним проти грибкових захворювань, що дозволяє уникнути загибелі молодих паростків.
                    </p>
                    <p className="culture__heading">
                        Чим здійснювати перше листове підживлення соняшника
                    </p>
                    <p className="culture__desc">
                        У фазі 4-6 листочків ми рекомендуємо використовувати Агроболік Фосфор у поєднанні із Агроболік Бор. Норма використання кожного препарату по 1л/га.
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            стимулюватимуть зростання та розвиток молодих рослин;
                        </li>
                        <li className="culture__description-numbering">
                            покращать врожайність, опірну здатність та олійність культури;
                        </li>
                        <li className="culture__description-numbering">
                            пом’якшать негативний вплив спеки та посухи.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Листове підживлення дозволяє швидко компенсувати дефіцит бору, що призведе до покращення запилення квіток. Соняшник сильно страждає від посухи через високий транспіраційний коефіцієнт. Використання комплексних органо-мінеральних добрив допоможе поліпшити процеси дихання та у майбутньому прискорити накопичення жирів при дозріванні насіння. Завдяки використанню мікродобрива ґрунтова волога використовуватиметься більш ефективно, що пришвидшить обмін речовин всередині рослини. Добрива стимулюють нарощування кореневої системи, що є важливою передумовою подальшого розвитку.
                    </p>
                    <p className="culture__desc">
                        Окрім високого ступеню засвоєння рослинами, важливою рисою органо-мінеральних засобів Санберрі Україна є здатність швидко розчинятися у воді. Завдяки цьому їх зручно використовувати для позакореневого живлення. А здатність стабілізувати баковий розчин дозволяє здійснювати підживлення соняшника одночасно з іншими обробками. Мікродобрива повність розчиняються у воді, тому не здатні засмічувати форсунки обприскувача.
                    </p>
                    <p className="culture__heading">
                        Особливості другого підживлення соняшника по листу
                    </p>
                    <p className="culture__desc">
                        Для другої обробки, яку необхідно здійснювати у фазі 6-8 листків, ми рекомендуємо використовувати Агроболік Бор у кількості 1,5-2 л/га. Мікродобриво збагачене фосфором та молібденом. Підживлення соняшника на цьому етапі вирощування дозволяє:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            зменшити чутливість рослин до несприятливих факторів;
                        </li>
                        <li className="culture__description-numbering">
                            врівноважити водний баланс та процеси дихання в умовах нестачі вологи у ґрунті;
                        </li>
                        <li className="culture__description-numbering">
                            засвоювати атмосферний азот завдяки вільноживучим бактеріям;
                        </li>
                        <li className="culture__description-numbering">
                            стимулювати процеси цвітіння та запліднення;
                        </li>
                        <li className="culture__description-numbering">
                            прискорити нарощування вегетативної маси та розвиток рослини.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Підживлення соняшника у фазі 6-8 листків Агроболіком Бору сприяє збільшенню врожайності культури на 10-15%, а також підвищенню вмісту жирів у насінні.
                    </p>
                    <p className="culture__heading">
                        Які добрива для соняшника використовувати у фазі розкриття шапочки
                    </p>
                    <p className="culture__desc">
                        Останню обробку мікродобривами рекомендуємо виконувати Агроболіком Бору, використовуючи 1,5-2 л/га. Препарат відкоригує незбалансоване живлення рослини та підвищить ступінь засвоюваності поживних елементів. Агроболік Бору зменшить вміст нітратів, отрутохімікатів та важких металів у сільськогосподарській продукції, захистить соняшник від негативних погодних факторів та поширених захворювань.
                    </p>
                </div>
                <p className="culture__heading">Спеціальні пропозиції</p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
