export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    МЕЛІОРАНТ це органо-мінеральне добриво на гуматній основі з властивостями нейтралізатора кислотності ґрунту.
                </p>
                <p className="card__description-text">
                    Ефективність препарату МЕЛІОРАНТ полягає в поєднанні органічних та меліоративних властивостей. Органічні сполуки на гуматній основі препарату активізують корисну ґрунтову мікрофлору, підвищують біологічну активність ґрунту, поліпшують умови живлення рослини макро- та мікроелементами.
                </p>
                <p className="card__description-title">
                    Економічні показники використання органо-мінерального добрива МЕЛІОРАНТ:
                    <ol className="card__description-list">
                        <li className="card__description-numbering">
                            Підвищення врожайності від 15 до 40% та покращення якості вирощуваних культур. Прямий економічний ефект при використанні препарату становить 2-6 грн чистого прибутку на кожну витрачену 1 грн (в залежності від сільськогосподарської культури).
                        </li>
                        <li className="card__description-numbering">
                            Внесення 1 т / га еквівалентно внесенню 20 т / га гною по виходу органічної речовини на гуматній основі в перший рік внесення. Витрати по завантаженню, транспортуванні та внесенню 1 тонни органічного добрива, набагато менші ніж 20 тонн гною.
                        </li>
                        <li className="card__description-numbering">
                            Вміст підвищеної кількості обмінного Ca і Mg дозволяє спільно з внесенням органіки здійснювати вапнування (розкислення) грунту. Зменшення витрат на технологічні операції вапнування в умовах високої ціни енергоносіїв - додатковий економічний ефект.
                        </li>
                    </ol>
                </p>
                <p className="card__description-text">
                    Склад добрива. 10 кг міститься: 2 кг органічних сполук на гуматной основі; 3,5 кг кальцію (CaO); 0,7 кг цинку (Zn); 0,5 кг сєркі (S); 0,4 кг магнію (MgO); 0,1 кг заліза (Fe); 15 г калію (K2O); 10 г марганцю (Mn); 10 г азоту (N); 7 г фосфору (P2O5); 1,5 г міді (Cu).
                </p>
                <p className="card__description-title">
                    Комплексна дія удобрення на агрохімічні особливості родючості грунту:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        підвищує біологічну активність грунту завдяки попаданню в грунт, під час підживлення, органічних речовин, які позитивно впливають на корисну мікрофлору;
                    </li>
                    <li className="card__description-numbering">
                        гуматная частина продукту поліпшує умови мінерального живлення рослин макро - і мікроелементами, забезпечує рослину ферментами, стимуляторами росту і знижує стрес при несприятливих погодних умовах;
                    </li>
                    <li className="card__description-numbering">
                        на територіях з кислою реакцією грунту двовалентні катіони Ca і Mg покращують і сприяють відновленню грунту, а на нейтральних - зменшують негативний вп.
                    </li>
                </ul>
                <p className="card__description-text">
                    Спосіб використання: "Меліорант" рекомендується для комплексної підгодівлі різних видів газонів ( садово-паркових, партерних, спортивних рулонних та посівних), декоративних дерев і чагарників (крім рододендронів, азалій, деревовидних гортензій і хвойних), усіх видів декоративно-квітучих багаторічників (крім лаванди), кімнатних рослин, плодових дерев, ягідників, польових культур та овочевих культур. Крім цього "Меліорант-Плюс" нейтралізує кислотність грунту.
                </p>
                <p className="card__description-text">
                    Норма внесення: 10 кг на 100 м2 (1 сотка) при підгодівлі культур і 1-5 кг під дерево або кущ, в залежності від його розміру.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
