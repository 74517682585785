export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Соняшник «Неома» належить до класу середньостиглих гібридів. Він може оброблятися як класичним, так і інтенсивним способом. Відрізняється високою врожайністю, стабільністю та середньою енергією початкового розвитку. Виростає заввишки 160–170 сантиметрів. Має потужне стебло з великим листям та суцвіттям. Завдяки цьому не страждає від сильних вітрів, граду, злив тощо.
                </p>
                <p className="card__description-text">
                    Терміни дозрівання у соняшника «Неома» становлять приблизно 105–115 днів, але багато залежить від рівня освітленості, температури, якості ґрунту та поливу. Зазначимо, що для одержання гарного врожаю рослину необхідно удобрювати. Як правило, це робиться при висаджуванні. Додатково проводиться підживлення, яке покращує процес вегетації.
                </p>
                <p className="card__description-title">
                    Особливості сорту
                </p>
                <p className="card__description-text">
                    «НК Неома» стійкий до різних грибкових захворювань (фомоз, біла гниль, фомопсис та ін.). Також він не страждає від заразихи, але якщо поле сильно забруднене, то рекомендується провести попередню обробку. Цей гібрид рекомендований для вирощування по технології Clearfield. Чудово росте по всій Україні, від південних до північних регіонів.
                </p>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Розглянемо основні переваги цього сорту:
                    </p>
                    <li className="card__description-numbering">
                        Вміст олії в насінні соняшнику досягає 50 %. Це один з найкращих показників у своєму класі.
                    </li>
                    <li className="card__description-numbering">
                        Стійкість до короткочасних посух. Звичайно, краще цього не допускати, але якщо на полі немає системи зрошення, то культура може пережити надто спекотні та сухі місяці.
                    </li>
                    <li className="card__description-numbering">
                        Високий рівень урожайності. За дотримання технології та з якісним добривом з одного гектара можна зібрати 30–35 центнерів насіння.
                    </li>
                </ol>
                <p className="card__description-text">
                    Також до плюсів можна віднести і те, що гібрид досить стабільний. Він росте на будь-яких ґрунтах та дає прогнозовані врожаї.
                </p>
                <p className="card__description-title">
                    Особливості сорту
                </p>
                <p className="card__description-text">
                    При висаджуванні насіння потрібно враховувати поточну та загальну вологість поля. Якщо вона недостатня, то на гектар кількість стояння рослин не має перевищувати 40–45 тисяч. Для помірно зволожених ґрунтів норма підіймається до 50–55 000. Для вологих кількість може бути збільшена до 55–60 тисяч рослин на гектар.
                </p>
                <p className="card__description-text">
                    Якщо ви хочете купити якісне та оригінальне насіння соняшнику «НК Неома», то рекомендуємо вам звернутися до компанії «Cанберрі Україна». Ми займаємось продажем гібридів, а також виробництвом мінеральних добрив для них. Наш офіс знаходиться у Вінниці, але ми без проблем відправимо вам товари до будь-якого іншого міста країни. Також у нас можна отримати детальні консультації з будь-яких питань, пов'язаних із вирощуванням та добривом сільськогосподарських культур.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
