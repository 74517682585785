import "./ServicesPage.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import logo from "../../image/services/215741.png";
import dron from "../../image/services/2865545.png";
import dronSecond from "../../image/services/1169571.png";
import dronThird from "../../image/services/dron.png";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export default function AboutPage() {

	function ScrollToTopOnMount() {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	}

	return (
		<div>
			<Helmet>
				<meta name="keywords" content="послуги, дрони, оприскування дронами, Санберрі Україна" />
				<title>Послуги компанії Санберрі Україна - Оприскування дронами</title>
				<link rel="canonical" href="https://sun-ukr.com/innovation" />
				<meta name="description" content="Реставрація та доукомплектація КРН, Сівалок а також оприскування дронами 🌱 Рівномірне розпилення препарату, точкове внесення на проблемні ділянки."></meta>
			</Helmet>
			<ScrollToTopOnMount />
			<Header />
			<div className="crumbs">
				<Link className="crumbs__link" to="/">
					<img className="crumbs__logo" src={crumbs} alt="crumbs" />
				</Link>
				<Link className="crumbs__link" to="innovation">
					<span className="crumbs__text">/ Інновації</span>
				</Link>
				<span className="crumbs__text">/ Обприскування дроном</span>
			</div>
			<div className="servicesPage">
				<div className="servicesPage__container">
					<div className="servicesPage__headertitle">
						<h1>Обприскування дроном</h1>
					</div>
					<div className="servicesPage__above">
						<div className="servicesPage__above servicesPage__above--position">
							<span className="servicesPage__above-text">
								Збільшуємо врожайність, знижуємо собівартість
							</span>
							<div className="servicesPage__list">
								<p className="servicesPage__list-text">
									Підвищуємо урожайність на 5-10% і знижуємо собівартість
								</p>
								<p className="servicesPage__list-text">
									Зберігаємо 100% посівів за рахунок
									відсутності технічної колії
								</p>
								<p className="servicesPage__list-text">
									Заощаджуємо 10-15% на вартості препаратів за рахунок ультрамалих
									об'ємів внесення
								</p>
								<p className="servicesPage__list-text">
									Продуктивність: один дрон - 75 га/добу, бригада – 300 га/добу,
									загальна – до 15000га /добу
								</p>
							</div>
						</div>
						<div className="servicesPage__image">
							<div className="servicesPage__photo"></div>
						</div>
					</div>
					<div className="servicesPage__content">
						<div className="servicesPage__content_block">
							<div className="servicesPage__row">
								<div className="servicesPage__bg">
								</div>
							</div>
							<div className="servicesPage__content_inner">
								<p className="servicesPage__content_text">
									Для ефективного обробітку полів на етапах розвитку, коли неможливе використання наземних обприскувачів, застосовують технологію внесення ЗЗР дронами. Це сучасна методика, яка набуває популярності в Україні. Передбачає використання агродронів або БПЛА, унікального інженерно-технічного винаходу для розв’язання різних агрозавдань. Це пристрої, які традиційно використовують в агрофірмах, щоб спостерігати за полями, різноманітними посівами, рослинами, контролювати їхній стан.
								</p>
								<p className="servicesPage__content_text">
									Використання компактних квадрокоптерів дає можливість визначати стан культур, аналізувати склад, окремі характеристики ґрунту. Керувати ними дуже просто, це багатофункціональні та зручні пристрої. За допомогою дрону легко спостерігати за зростанням культури в реальному часі й отримувати оперативні дані про неї. Квадрокоптер зручний і для внесення добрив, поливу, оброблення проти шкідників і хвороб.
								</p>
							</div>
						</div>
						<div className="servicesPage__title">
							<span className="servicesPage__text">
								Коли це необхідно ?
							</span>
						</div>
						<p className="servicesPage__desc">
							<span className="servicesPage__num">01</span>
							Агродрони ефективні для внесення добрив там, де це неможливо або незручно зробити наземними машинами. Обприскування дронами підходить для невеликих посівних площ, ділянок із великою кількістю чагарників, дерев, стовпів та інших перешкод. Вагомим фактором для внесення ЗЗР за допомогою такого пристрою є велика вологість ґрунту після дощу. Також сучасна техніка допомагає розв’язати низку екологічних проблем.
						</p>
						<p className="servicesPage__desc">
							<span className="servicesPage__num">02</span>
							Дрон-обприскувач сканує та визначає рельєф місцевості в реальному часі. У ньому реалізовані функції коригування об’єму розчину, який використовується, та налаштування висоти польоту. Вибір на користь повітряного способу внесення добрив роблять і тоді, коли потрібне точкове, тобто окреме, оброблення проблемних ділянок – наприклад, тих, де поширюються хвороби.
						</p>
						<p className="servicesPage__desc">
							<span className="servicesPage__num">03</span>
							Внесення добрив за допомогою повітряного квадрокоптера зручне для полів зі складним рельєфом або неправильної форми, ґрунтів підвищеної вологості. Цей спосіб ефективний для оброблення локальних ділянок, там, де немає доріг, а також при розпилюванні добрив на розрізнені поля. Застосування БПЛА ефективне за несприятливих погодних умов.
						</p>
						<p className="servicesPage__desc">
							<span className="servicesPage__num">04</span>
							Компанія «Санберрі Україна» має практичний досвід із використання дронів і пропонує цю послугу для аграріїв. Це сучасний та зручний метод догляду за рослинами, який сприяє збільшенню врожайності на 5-10% і стовідсотковому збереженню посівів. Один дрон за добу обробляє до 75 гектарів. Об’єми внесення дуже малі, тому виходить зекономити до 10-15% на вартості препаратів.
						</p>
					</div>
					<div className="servicesPage__center">
						<div className="servicesPage__title">
							<span className="servicesPage__text">
								Навіщо вам потрібні послуги дрону?
							</span>
						</div>
						<div className="servicesPage__nomination">
							<div className="functional_inner_wrapper">
								<div className="descr_text">
									<h3 className="title">
										<img className="servicesPage__logo" src={dron} alt="dron" />
									</h3>
									<p className="descr_value">Висока якість</p>
									<p>
										Забезпечується через абсолютну відсутність технічних колій. Вони завжди утворюються, якщо вдатися до технології обробітку наземним транспортом. Коли їх немає, цілісність урожаю збільшується на 7%
									</p>
								</div>
							</div>
							<div className="functional_inner_wrapper">
								<div className="descr_text">
									<h3 className="title">
										<img className="servicesPage__logo" src={logo} alt="dron" />
									</h3>
									<p className="descr_value">Оптимальна продуктивність</p>
									<p>
										Один дрон рухається зі швидкістю 1-12 метрів за секунду й за годину здатен обробити від 5 до 12 гектарів. Його варто використовувати для великих і маленьких полів із будь-якими рослинами
									</p>
								</div>
							</div>
							<div className="functional_inner_wrapper">
								<div className="descr_text">
									<h3 className="title">
										<img className="servicesPage__logo" src={dronSecond} alt="dron" />
									</h3>
									<p className="descr_value">Ефективність</p>
									<p>
										Дрон використовує дуже малу кількість добрив, при цьому зберігає високу точність внесення. Йому потрібно на 95% менше води, ніж при застосуванні інших технологій внесення препаратів.
									</p>
								</div>
							</div>
						</div>
						<div className="servicesPage__title">
							<span className="servicesPage__text">
								Особливості надання послуги
							</span>
						</div>
						<div className="servicesPage__content">
							<p className="servicesPage__desc">
								Внесення добрив за допомогою повітряного квадрокоптера зручне для полів зі складним рельєфом або неправильної форми, ґрунтів підвищеної вологості. Цей спосіб ефективний для оброблення локальних ділянок, там, де немає доріг, а також при розпилюванні добрив на розрізнені поля. Застосування БПЛА ефективне за несприятливих погодних умов.
							</p>
							<p className="servicesPage__desc">
								Компанія «Санберрі Україна» має практичний досвід із використання дронів і пропонує цю послугу для аграріїв. Це сучасний та зручний метод догляду за рослинами, який сприяє збільшенню врожайності на 5-10% і стовідсотковому збереженню посівів. Один дрон за добу обробляє до 75 гектарів. Об’єми внесення дуже малі, тому виходить зекономити до 10-15% на вартості препаратів.
							</p>
						</div>
						<div className="servicesPage__preference preference">
							<span className="preference__title">
								Переваги внесення ЗЗР дроном
							</span>
							<div className="preference__container">
								<div className="preference__block">
									<div className="preference__block--position">
										<div className="preference__block-center">
											<div className="preference__text">Якість</div>
											Якість обробки дроном максимально ефективна за рахунок повної відсутності технологічних колій, що створює наземний транспорт при обробці площі, а це зберігає до 7% цілісності урожаю
										</div>
										<div className="preference__icon-first"></div>
									</div>
									<div className="preference__block--position">
										<div className="preference__block-center">
											<div className="preference__text">Швидкість</div>
											Середня продуктивність агро-дрона складає від 5 до 12 га за 1 год. Швидкість руху сягає від 1 м/сек до 12 м/сек
										</div>
										<div className="preference__icon-second"></div>
									</div>
									<div className="preference__block--position">
										<div className="preference__block-center">
											<div className="preference__text">Точність</div>
											Агродрони підходять для точкового обприскування ділянок. Це необхідно, коли в певних місцях посівів локалізуються хвороби або шкідники;
										</div>
										<div className="preference__icon-third"></div>
									</div>
								</div>
								<div className="">
									<img className="preference__logo" src={dronThird} alt="dronThird" />
								</div>
								<div className="preference__block">
									<div className="preference__block--position">
										<div className="preference__block-center">
											<div className="preference__text">Універсальність</div>
											Обприскування полів дронами підходить для будь-якого сільськогосподарського підприємства. Цю послугу все частіше замовляють в Україні, адже вона недорога й доступна для кожного аграрія
										</div>
										<div className="preference__icon-fourth"></div>
									</div>
									<div className="preference__block--position">
										<div className="preference__block-center">
											<div className="preference__text">Ефективність</div>
											Завдяки ультрамалооб'ємному обприскуванні дрон виконує високоточне і ефективне внесення препарату, при цьому використовуючи на 95% менше води
										</div>
										<div className="preference__icon-fifth"></div>
									</div>
									<div className="preference__block--position">
										<div className="preference__block-center">
											<div className="preference__text">Доступність</div>
											Дронами можна обробляти всі види рослинних культур. На
											сьогодні технологія доступна кожному аграрію завдяки
											доступній ціні
										</div>
										<div className="preference__icon-sixth"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="servicesPage__bottom"></div>
			</div>
			<Footer />
		</div>
	);
}
