import React from "react";
import ScrollUpButton from "react-scroll-up-button";
import "./style.scss";

export default class Index extends React.Component {
    render() {
        return (
            <div>
                <ScrollUpButton
                    ContainerClassName="AnyClassForContainer"
                    TransitionClassName="AnyClassForTransition"
                >
                </ScrollUpButton>
            </div>
        );
    }
}
