import "./ServicesPage.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export default function AboutPage() {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    return (
        <div>
            <Helmet>
                <meta name="keywords" content="послуги, реставрація та доукомплектація КРН, сівалок, Санберрі Україна" />
                <title>Послуги компанії Санберрі Україна - Реставрація та доукомплектація КРН, сівалок</title>
                <link rel="canonical" href="https://sun-ukr.com/completion-of-krn" />
                <meta name="description" content="Реставрація та доукомплектація КРН"></meta>
            </Helmet>
            <ScrollToTopOnMount />
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="innovation">
                    <span className="crumbs__text">/ Інновації</span>
                </Link>
                <span className="crumbs__text">/ Реставрація та доукомплектація КРН, сівалок</span>
            </div>
            <div className="completion">
                <div className="completion__container">
                    <div className="completion__headertitle">
                        <h1>Реставрація та доукомплектація КРН, сівалок</h1>
                    </div>
                    <div className="completion__section">
                        <p className="completion__caption">Замість більш витратної за часом та вартістю купівлі нової сільськогосподарської машини більш рентабельною є реставрація КРН. Відновлення культиватора передбачає виконання всіх необхідних робіт, щоб повернути обладнанню заводські налаштування, зробити його знову функціональним і зручним у використанні. Компанія «Санберрі Україна» – виробник добрив, який надає послуги з якісного відновлення техніки у Вінниці й інших містах.</p>
                        <p className="completion__caption">Спеціалісти займаються відновленням КРН і сівалок усіх відомих брендів, як-от John Deere, Kinze, Great Plains та інші. Використовуються сучасні матеріали та високоточне обладнання. Таке поєднання гарантує чудові результати. Проводиться також дефектація для своєчасного й точного виявлення несправностей і оперативного ремонту. Послуга включає й оцінювання придатності елементів до роботи в конкретних умовах.</p>
                        <p className="completion__caption">Як здійснюється реставрація</p>
                        <p className="completion__caption">Після перевірки й аналізу стану техніки визначають її загальну справність, стан окремих деталей і механізмів. За результатами проводиться реставрація сівалок та КРН, або доукомплектація. Послуга передбачає такі роботи:</p>
                        <ul className="card__description-list">
                            <li className="card__description-numbering">встановлення нових коліс, кріплень, фіксувальних елементів, болтів, регулювання шайб, встановлення укладальників насіння;</li>
                            <li className="card__description-numbering">налаштування прилягання, або заміна диска сошника;</li>
                            <li className="card__description-numbering">відновлення або регулювання прорізів у деталях, які труться;</li>
                            <li className="card__description-numbering">рихтування, заміна бандажа копіювального колеса;</li>
                            <li className="card__description-numbering">заміна манжет, втулки, запресування мастилом, миття підшипників для реставрації маточини кріплення диска сошника.</li>
                        </ul>
                        <p className="completion__caption">Капітальний ремонт передбачає проведення заміни й регулювання усіх пружин із подальшим піскоструменевим обробленням кожної деталі та порошковим фарбуванням. Після проведення робіт техніка набуває заводських налаштувань і готова до використання.</p>
                    </div>
                    <div className="completion__bg"></div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
