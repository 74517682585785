import "./SliderPartners.scss";
import logo__mac from "../../image/logotype/logo_makoch.png";
import logo__agro from "../../image/logotype/logo_agr.png";
import logo__pos from "../../image/logotype/logo_posivna.webp";
import logo__harvest from "../../image/logotype/harvest_logo-removebg-preview.png";
import logo__ugtehagro from "../../image/logotype/logo_ugtehagro.webp";
import logo__lair from "../../image/logotype/logo_lair.png";
import logo__ast from "../../image/logotype/logo-ast.png";
import Slider from 'react-slick';
import React, { Component } from 'react';

export default class CustomSlider extends Component {
  render() {

    const settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToScroll: 4,
      initialSlide: 0,
      slidesToShow: 4,
      responsive: [
        {
          breakpoint: 1450,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <div className="profile">
        <span className="profile__title">Партнери Санберрі Україна</span>
        <Slider {...settings}>
          <div className="page">
            <img className="slider__partners-logo" src={logo__mac} alt="logo" />
            <p className="slider__partners-text">Макош Мінерал</p>
            <a className="sliderPart__webSyte" target="blank" href="https://makosh-group.com.ua">
              <button type="button" className="sliderPart__link">
                makosh-group.com.ua
              </button>
            </a>
          </div>
          <div className="page">
            <img className="slider__partners-logo" src={logo__pos} alt="logo" />
            <p className="slider__partners-text">POSIVNA</p>
            <a className="sliderPart__webSyte" target="blank" href="https://posivna.com.ua">
              <button type="button" className="sliderPart__link">
                posivna.com.ua
              </button>
            </a>
          </div>
          <div className="page">
            <img className="slider__partners-logotype4" src={logo__agro} alt="logo" />
            <p className="slider__partners-text">АГРОСТАДІОН</p>
            <a className="sliderPart__webSyte" target="blank" href="https://agrostadion.com">
              <button type="button" className="sliderPart__link">
                agrostadion.com
              </button>
            </a>
          </div>
          <div className="page">
            <img className="slider__partners-logotype3" src={logo__harvest} alt="logo" />
            <p className="slider__partners-text">Harvest Center</p>
            <a className="sliderPart__webSyte" target="blank" href="https://harvest-center.com.ua">
              <button type="button" className="sliderPart__link">
                harvest-center.com.ua
              </button>
            </a>
          </div>
          <div className="page">
            <img className="slider__partners-logotype2" src={logo__ugtehagro} alt="logo" />
            <p className="slider__partners-text">Югтехагро</p>
            <a className="sliderPart__webSyte" target="blank" href="https://ugtehagro.net/">
              <button type="button" className="sliderPart__link">
                ugtehagro.net
              </button>
            </a>
          </div>
          <div className="page">
            <img className="slider__partners-logotype" src={logo__lair} alt="logo" />
            <p className="slider__partners-text">Лаїр</p>
            <a className="sliderPart__webSyte" target="blank" href="https://lair.com.ua">
              <button type="button" className="sliderPart__link">
                Детальніше
              </button>
            </a>
          </div>
          <div className="page">
            <img className="slider__partners-logo1" src={logo__ast} alt="logo" />
          </div>
        </Slider>
      </div>
    );
  }
}