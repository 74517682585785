import "./AwesomeButtonStyles.scss";
import React from "react";

export default function AwesomeButton() {
  
  return (
    <button className="learn-more">
      Детальніше
    </button>
  );
}

