export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    NPK 5-20-5 — рідке комплексне добриво на основі ортофосфорної кислоти. У порівнянні з іншими компонентами, містить суттєво більше фосфору.
                </p>
                <div className="card__description-title">
                    Основний склад NPK 5-20-5
                </div>
                <div className="card__description-title">
                    У складі препарату:
                </div>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        азот (N) — 5% (64 г/л);
                    </li>
                    <li className="card__description-numbering">
                        фосфор (P) — 20% (254 г/л);
                    </li>
                    <li className="card__description-numbering">
                        калій (K) — 5% (64 г/л).
                    </li>
                </ul>
                <p className="card__description-text">
                    Фосфор — важливий елемент для правильного росту рослин. Він сприяє доброму проростанню насіння та потужному розвитку кореневої системи й пагонів. При цьому рослини краще засвоюють воду та поживні речовини з ґрунту, швидше формують надземну масу.
                </p>
                <p className="card__description-text">
                    Азот потрібний рослині для накопичення маси як складова білків (будівельних матеріалів), а також для фітогормональної регуляції та роботи ферментів.
                </p>
                <p className="card__description-text">
                    Калій підвищує імунітет рослин. Його зменшення створює сприятливі умови для розвитку патогенних грибів і бактерій у тканинах.
                </p>
                <div className="card__description-title">Споживчі якості NPK 5-20-5 </div>
                <p className="card__description-text">
                    Добриво використовується для всіх видів сільськогосподарських культур — кукурудзи, озимого ріпаку, соняшнику, сої, цукрового буряку, плодово-ягідних, овочевих, зернових культур. NPK 5-20-5 забезпечує комплексне живлення рослин потрібними елементами. Його застосування дозволяє отримати такі результати:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">підвищити врожайність культур;</li>
                    <li className="card__description-numbering">
                        допомогти рослинам сформувати потужну кореневу систему та подолати стреси, які виникають на початкових етапах росту;
                    </li>
                    <li className="card__description-numbering">
                        забезпечити високу міцність стеблин і стійкість рослин до вилягання;
                    </li>
                    <li className="card__description-numbering">
                        підсилити опірність культур до несприятливих погодних умов, зокрема заморозків.
                    </li>
                </ul>
                <p className="card__description-text">
                    Крім того, препарат зменшує ураження кореневими гнилями, борошнистою росою й іржею.
                </p>
                <div className="card__description-title">
                    Переваги NPK 5-20-5
                </div>
                <p className="card__description-text">
                    Крім того, препарат зменшує ураження кореневими гнилями, борошнистою росою й іржею.
                </p>
                <p className="card__description-text">
                    Препарат вирізняє низка позитивних характеристик. Він:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        повністю розчинний;
                    </li>
                    <li className="card__description-numbering">
                        високоефективний навіть за низьких норм внесення;
                    </li>
                    <li className="card__description-numbering">
                        екологічно чистий — виготовлений з сировини високої якості, яка використовується у сферах харчування та фармацевтики;
                    </li>
                    <li className="card__description-numbering">
                        отриманий внаслідок хімічного синтезу;
                    </li>
                    <li className="card__description-numbering">
                        містить поживні елементи в легкодоступній формі;
                    </li>
                    <li className="card__description-numbering">
                        прозорий, не містить баластових солей, наприклад хлоридів і шкідливих речовин;
                    </li>
                    <li className="card__description-numbering">
                        має нейтральний показник pH;
                    </li>
                    <li className="card__description-numbering">
                        засвоюється навіть за низької температури ґрунту — від +5 °C;
                    </li>
                    <li className="card__description-numbering">
                        може застосовуватися з іншою агрохімією;
                    </li>
                    <li className="card__description-numbering">
                        добре зберігається за порівняно низьких температур;
                    </li>
                    <li className="card__description-numbering">
                        мінімізує корозію обладнання.
                    </li>
                </ul>
                <p className="card__description-text">
                    Добриво також має низький сольовий індекс, тому безпечне для проростків і зеленої маси.
                </p>
                <p className="card__description-title">
                    Рекомендації щодо застосування
                </p>
                <p className="card__description-text">
                    Перш ніж купити NPK 5-20-5, слід ознайомитися з основними рекомендаціями з його використання.
                </p>
                <p className="card__description-title">
                    Оброблення насіння
                </p>
                <p className="card__description-text">
                    Розчин наноситься на посівний матеріал перед посівними роботами.
                </p>
                <p className="card__description-text">
                    Норма — 1–3 л препарату на 1 т насіння.
                </p>
                <p className="card__description-title">
                    Стартове внесення
                </p>
                <p className="card__description-text">
                    Препарат вноситься як основне добриво перед посівом (локально або суцільно оприскувачем, трубками або спеціальною технікою), а також при посіві та під корінь (в рядок, поруч, на насіння або міжряддя). Припосівне внесення дозволяє підживити молоді рослини в період, коли вони ще не мають потужної кореневої системи та недостатньо отримують поживних речовин з ґрунту.
                </p>
                <p className="card__description-text">
                    Норма — 10–60 л/га.
                </p>
                <p className="card__description-title">
                    Позакореневе удобрення
                </p>
                <p className="card__description-text">
                    Добривом обприскують вегетативну частину рослини 1–4 рази/сезон. Часовий проміжок між обробленнями повинен складати від 7 до 21 дня.
                </p>
                <p className="card__description-text">
                    Норма — 1–6 л/га у 100–300 л/га робочого розчину.
                </p>
                <p className="card__description-title">
                    Фертигація
                </p>
                <p className="card__description-text">
                    За допомогою цієї технології рослини отримують поживні речовини через системи зрошення після їх розчинення у воді.
                </p>
                <p className="card__description-text">
                    Норма — 1–5 л/га на день. Залежить від агрономічних потреб і технологічних карт.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
