import logo1 from "../../image/logotype/logo_corn.svg";
import logo2 from "../../image/logotype/MAS Seeds.svg";
import goodsPage from "../../image/goods.image/corn/yyi2SKQwBy.png";
import goodsPage1 from "../../image/goods.image/corn/wjwx5vF0xB.jpeg";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";


export const items = [
  {
    title: "Насіння соняшнику HK Бріо (NK Brio)",
    priceFirst: 'від 6 500 грн',
    producer: "Syngenta",
    InStock: false,
    imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={goodsPage} alt="goodsPage" />,
    isNew: false,
    link: <Link to="order"><Button /></Link>,
    inside: "brio",
    id: "1"
  },
  {
    title: "Насіння соняшнику HK Неома (NK Neoma)",
    priceFirst: 'від 6 700 грн',
    producer: "Syngenta",
    InStock: false,
    imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={goodsPage} alt="goodsPage" />,
    isNew: false,
    link: <Link to="order"><Button /></Link>,
    inside: "neoma",
    id: "2"
  },
  {
    title: "Насіння соняшнику HK Конді (NK Kondi)",
    priceFirst: 'від 6 400 грн',
    producer: "Syngenta",
    InStock: false,
    imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={goodsPage} alt="goodsPage" />,
    isNew: false,
    link: <Link to="order"><Button /></Link>,
    inside: "kondi",
    id: "3"
  },
  {
    title: "Насіння соняшнику Суміко HTS (Sumiko HTS)",
    priceFirst: 'від 5 000 грн',
    producer: "Syngenta",
    InStock: false,
    imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={goodsPage} alt="goodsPage" />,
    isNew: false,
    link: <Link to="order"><Button /></Link>,
    inside: "sumiko",
    id: "4"
  },
  {
    title: "Насіння соняшнику MAC 87.A (MAS 87.A)",
    priceFirst: 'від 2 500 грн',
    producer: "MAS Seeds",
    InStock: false,
    imgLogo: <img className="itemCard__image-logo" src={logo2} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={goodsPage1} alt="goodsPage" />,
    isNew: false,
    link: <Link to="order"><Button /></Link>,
    inside: "mas87",
    id: "5"
  },
  {
    title: "Насіння соняшнику MAC 86.КП (MAS 86.CP)",
    priceFirst: 'від 2 500 грн',
    producer: "MAS Seeds",
    InStock: false,
    imgLogo: <img className="itemCard__image-logo" src={logo2} alt="logo" />,
    imgUrl: <img className="itemCard__image" src={goodsPage1} alt="goodsPage" />,
    isNew: false,
    link: <Link to="order"><Button /></Link>,
    inside: "mas86",
    id: "6"
  }
];
