export default function reference() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Основні характеристики</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Комерційна назва</div>
                        <div className="characteristic__value">Mag-MakS</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Культура</div>
                        <div className="characteristic__value">кукурудза, соняшник та інші</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Виробник</div>
                        <div className="characteristic__value">Санберрі Україна</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Основний компонент</div>
                        <div className="characteristic__value">Магній - Сірка</div>
                    </div>
                </div>
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Врожайність</div>
                        <div className="characteristic__value">до +50%</div>
                    </div>
                </div>
                <div className="aside__title">Основний компонент</div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Оксид магіню (MgO)</div>
                    <div className="characteristic__value">20%</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Триоксид сірки (SO₃)</div>
                    <div className="characteristic__value">35%</div>
                </div>
                <div className="aside__title">Норми витрат</div>
                <p className="card__description-text">
                    РКД оптимально використовується для більшості культур і типів ґрунтів.
                </p>
                <p className="card__description-text">
                    Норми внесення РКД, як й інших добрив, можуть варіюватися в залежності від
                    особливостей ґрунту, способу внесення, культури-попередника, вологи у ґрунті,
                    запланованого врожаю та інших чинників.
                </p>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">забезпечення стрімкого розвитку кореневої системи;</div>
                    <div className="aside__block">висока ефективність в посушливих умовах і при відносно низьких температурах;</div>
                    <div className="aside__block">набагато доступніші в порівнянні з сухими добривами.</div>
                </div>
            </div>
        </div>
    );
}
