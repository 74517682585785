import "./GoogleMap.scss";
import React from "react";
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

export class Maps extends React.Component {
  render() {
    const mapStyles = {
      position: "relative",
      width: "100%",
      height: "540px",
    };
    return (
      <div className="google__position">
        <Map
          google={this.props.google}
          zoom={18}
          style={mapStyles}
          initialCenter={{ lat: 49.27102, lng: 28.51334 }}
        >
          <Marker position={{ lat: 49.27102, lng: 28.51334 }} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCxtkxtUaZWhx8sytxzHjqeGsCUuGzRVGA",
})(Maps);