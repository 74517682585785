import React from "react";
import ReactDOM from "react-dom";
import Favicon from 'react-favicon'
import favicon from './favicon/favicon.ico'
import App from "./App.js";

ReactDOM.render(
	<React.StrictMode>
		<App />
		<Favicon url={favicon} />
	</React.StrictMode>,
	document.getElementById("root")
);