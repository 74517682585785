import '../../Сatalog/GoodsList/GoodsListFertilizers/GoodsList.scss';
import { items } from './goods-data';
import Item from '../../Item/Item';
import React from 'react';

export default class GoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.items = items;
  }

  render() {
    return (
      <div className="culture__goods">
        {this.items.map(item => (
          <Item
            key={item.id}
            isNew={item.isNew}
            title={item.title}
            InStock={item.InStock}
            link={item.link}
            inside={item.inside}
            priceFirst={item.priceFirst}
            priceSecond={item.priceSecond}
            priceThird={item.priceThird}
            priceFourth={item.priceFourth}
            imgUrl={item.imgUrl}
            table={item.table}
            description={item.description}
            recommendations={item.recommendations}
            id={item.id}
          />
        ))}
      </div>
    );
  }
}