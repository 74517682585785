import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "../GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from 'react-ink'
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для кукурудзи, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для кукурудзи • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureCorn" />
                <meta
                    name="description"
                    content="🌱Добрива для кукурудзи ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для кукурудзи</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для кукурудзи</h1>
            </div>
            {/* <div className="culture__bg">
                <div className="culture__scheme"></div>
            </div> */}
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення кукурудзи</h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення вашої кукурудзи
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__desc">
                        Кукурудза – цінна зернова культура, належить до родини тонконогових, є однорічною рослиною. Разом із рисом і пшеницею, це один із найголовніших злаків у світі. Трав’яниста рослина має міцне пряме стебло та великі лінійно-ланцетні листки. Використовується для фуражних, технічних, продовольчих цілей. Забезпечити гарний, якісний урожай допоможуть мікродобрива на кукурудзу. Уникнути їх використання неможливо через особливості вирощування та зростання культури.
                    </p>
                    <p className="culture__desc">
                        Кукурудза є однією зі стратегічних рослин в Україні, її вирощують майже в кожному регіоні, незалежно від розмірів господарства та клімату. На ріст і розвиток культури найбільше впливають волога й тепло. Отримати гарний врожай неможливо, якщо насадження потерпають від дефіциту корисних мікроелементів. Ними рослину забезпечують шляхом використання добрив.
                    </p>
                    <p className="culture__heading">
                        Особливості вирощування
                    </p>
                    <p className="culture__desc">
                        Кукурудза не лише теплолюбна, а й дуже вимоглива до якості ґрунту. Поживні речовини їй потрібні часто, тому й мінеральних добрив постійно використовується більше, ніж для інших зернових. Це пов’язано також з особливостями вегетаційного періоду. Увесь час, поки він триває, культура формує зелену масу у великій кількості. Саме на етапі вегетації кукурудза потребує якомога більше добрив.
                    </p>
                    <p className="culture__desc">
                        Ця рослина добре росте тільки в ґрунті, який відповідає її вимогам, перш за все за температурою. Для нормального розвитку потрібно багато вологи та сонячного світла, певна температура повітря. Якщо будь-яка умова хоч мінімально не дотримується, культура отримує менше поживних речовин і гірше засвоює їх.
                    </p>
                    <p className="culture__desc">
                        Вегетаційний період триває довго. Відповідно, органічно-мінеральні добрива для кукурудзи мають використовуватися тривалий час й у великій кількості. Дефіцит будь-якої з поживних речовин, потрібних рослині, призводить до серйозних проблем. Наприклад, коли не вистачає фосфору, листки червоніють, швидко засихають. Коли на листі з’являються білі плями, смуги, це ознака дефіциту цинку.
                    </p>
                    <p className="culture__heading">
                        Вибір добрив
                    </p>
                    <p className="culture__desc">
                        Коли рослина не отримує достатньо поживних речовин, це призводить до складнощів із фотосинтезом і запиленням, проблемами з енергетикою та диханням. Можливе не просто погіршення якості врожаю, а й значне його скорочення. Тому дуже важливо купити мікродобрива для кукурудзи, які забезпечать їй необхідне живлення протягом вегетативного періоду і завжди, коли це потрібно.
                    </p>
                    <p className="culture__desc">
                        ТОВ «Санберрі Україна» пропонує РКД (рідкі комплексні добрива) зі збалансованим складом спеціально для забезпечення стабільного росту й розвитку культури та отримання гарного врожаю. Вони містять багато магнію, а також фосфор, цинк, бор й інші речовини. Забезпечують повноцінне живлення, мають багато переваг:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            усувають дефіцит мікроелементів і запобігають його подальшому виникненню;
                        </li>
                        <li className="culture__description-numbering">
                            активізують процес зростання культури;
                        </li>
                        <li className="culture__description-numbering">
                            сприяють гарному засвоєнню всіх складових мінеральних підгодівель;
                        </li>
                        <li className="culture__description-numbering">
                            компенсують вплив негативних факторів, зменшують будь-які стреси;
                        </li>
                        <li className="culture__description-numbering">
                            покращують запилення.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Кукурудза добре росте й розвивається за постійної наявності поживних речовин. Одним із найважливіших елементів для неї є цинк. Він підвищує стійкість до заморозків і перепадів температур, бере активно участь у процесі фотосинтезу. Стабільність цвітіння та запилення залежить від достатньої кількості магнію. Препарати на його основі найкраще вносити під сівбу.
                    </p>
                    <p className="culture__desc">
                        Ще один важливий елемент – бор. Завдяки йому кукурудза краще засвоює азот і кальцій. Бор задіяний у процесі поділу клітин та формуванні клітинної мембрани, впливає на запліднення квітів, формування пилку. Якщо рослині не вистачатиме бору, є великий ризик взагалі втратити увесь урожай. Велике значення для життєдіяльності культури має й залізо. Для активного продукування зерна потрібна мідь.
                    </p>
                    <p className="culture__heading">
                        Як проводити обробіток насіння
                    </p>
                    <p className="culture__desc">
                        Спеціалісти ТОВ «Санберрі Україна» розробляють схеми внесення добрив. Перш ніж визначити норми, необхідно провести аналіз ґрунту. Від його характеристик, особливостей клімату, технології вирощування та інших факторів залежатиме, скільки готового розчину знадобиться.
                    </p>
                    <p className="culture__desc">
                        Є декілька періодів, коли мінеральні добрива для кукурудзи найбільш потрібні, а поживні речовини найкраще засвоюються. Перший етап – це підготування до засівання, коли обробляється насіння. Для цього знадобляться 4 л/т препарату «Агроболік Фосфор». Його внесення підвищує схожість насіння, посилює енергію росту, сприяє активації та зміцненню кореневої системи, посилює здатність культури протистояти негативним факторам.
                    </p>
                    <p className="culture__heading">
                        Як проходить оброблення листя
                    </p>
                    <p className="culture__desc">
                        Критично важливим для подальшого росту й розвитку кукурудзи є початок вегетаційного періоду. У цей час вона стає дуже вимогливою до наявності поживних речовин. На фазі появи 3-4 листків знадобиться 1 л «Агроболік Фосфор» та «Агроболік Цинк» на гектар. Використання цих добрив значно підвищує показники врожайності в майбутньому. Позитивні ефекти такі:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            краще засвоєння й усунення дефіциту цинку;
                        </li>
                        <li className="culture__description-numbering">
                            можливість регулювати кількість зерна;
                        </li>
                        <li className="culture__description-numbering">
                            активізація трансферу цукрів;
                        </li>
                        <li className="culture__description-numbering">
                            краще цвітіння та запліднення;
                        </li>
                        <li className="culture__description-numbering">
                            формування якісних качанів із великим, добре налитим насінням.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        На фазі 10-12 листків знадобляться 1,5-2 літри комплексу «Агроболік Цинк» на гектар. Живлення кукурудзи в цей час має бути максимально збалансованим і повноцінним, оскільки напряму впливає на кількість качанів у кожному стеблі. Багато мікроелементів потребує й коренева система, яка ще недостатньо розвинулась. Варто здійснити позакореневе живлення.
                    </p>
                    <p className="culture__heading">
                        Оброблення на етапі викидання волоті
                    </p>
                    <p className="culture__desc">
                        Це фаза, коли культура переживає особливий стрес через спеку та потребує багато макроелементів. Різкі перепади температур негативно впливають на цвітіння й запилення. Захистити кукурудзу від таких факторів допоможе «Агроболік Бор» – препарат, важливий для формування й отримання гарного врожаю. Для внесення потрібні 1,5-2 літри розчину на гектар. Так, покращиться вуглеводний і білковий обмін, нормалізується фотосинтез.
                    </p>
                </div>
                <p className="culture__heading">
                    Спеціальні пропозиції
                </p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Цинк»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
