export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    NPK 3-18-18 — ефективне трикомпонентне рідке добриво, розроблене на основі ортофосфорної кислоти. Має однаковий вміст фосфору та калію, містить також невелику кількість азоту. Насичує рослину необхідними елементами протягом усього вегетативного періоду.
                </p>
                <div className="card__description-title">
                    Основний склад РКД NPK 3-18-18
                </div>
                <p className="card__description-text">
                    Препарат має ідеально збалансований склад. Основні елементи, які в ньому містяться:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        азот (N) — 3% (42 г/л);
                    </li>
                    <li className="card__description-numbering">
                        фосфор (Р2О5) — 18% (252 г/л);
                    </li>
                    <li className="card__description-numbering">
                        калій (К2О) — 18% (252 г/л).
                    </li>
                </ul>
                <p className="card__description-text">
                    Фосфор — один із трьох найважливіших для рослин елементів. Є джерелом енергії, контролює обмінні процеси. Відмінно діє в комплексі з азотом. Завдяки цьому поєднанню рослини міцнішають, не потребуючи рясного поливу.
                </p>
                <p className="card__description-text">
                    Калій позитивно впливає на імунітет рослини. Він бере участь у процесі оновлення клітин, внаслідок якого зміцнюється епітелій і покращується стійкість культури до несприятливих чинників довкілля й інфекцій. Цей елемент забезпечує транспорт продуктів фотосинтезу з вегетативних органів до органів плодоношення.
                </p>
                <div className="card__description-title">
                    Споживчі якості NPK 3-18-18
                </div>
                <p className="card__description-text">
                    Добриво найбільш ефективне в ґрунтах з дефіцитом доступного калію. Його застосування забезпечує такі результати:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        стрімкий розвиток кореневої системи;
                    </li>
                    <li className="card__description-numbering">
                        підвищення стійкості рослин до заморозків;
                    </li>
                    <li className="card__description-numbering">
                        укріплення стебла, що запобігає виляганню;
                    </li>
                    <li className="card__description-numbering">
                        покращення імунітету культури — вона стає менш схильною до зараження борошнистою росою, іржею, кореневими гнилями;
                    </li>
                    <li className="card__description-numbering">
                        підвищення врожайності культури.
                    </li>
                </ul>
                <p className="card__description-text">
                    Добриво підходить для підживлення всіх видів сільськогосподарських культур — овочевих, зернових, плодово-ягідних.
                </p>
                <div className="card__description-title">
                    Переваги NPK 3-18-18
                </div>
                <p className="card__description-text">
                    NPK 3-18-18, купити яке можна в нашому магазині на вигідних умовах, відзначається такими особливостями:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        має оптимальний, екологічний склад — усі поживні речовини доступні рослині одразу після внесення;
                    </li>
                    <li className="card__description-numbering">
                        ефективний навіть за відносно низьких температур ґрунту та в посушливих умовах;
                    </li>
                    <li className="card__description-numbering">
                        універсальний — підходить для передпосівного, припосівного внесення й основного підживлення;
                    </li>
                    <li className="card__description-numbering">
                        може застосовуватися в технологіях POP-UP/IN-FURROW (внесення в рядок);
                    </li>
                    <li className="card__description-numbering">
                        повністю розчинний;
                    </li>
                    <li className="card__description-numbering">
                        не містить баластових речовин й інших шкідливих домішок;
                    </li>
                    <li className="card__description-numbering">
                        рівномірно наноситься та точно дозується;
                    </li>
                    <li className="card__description-numbering">
                        має низький сольовий індекс і нейтральний індекс рН;
                    </li>
                    <li className="card__description-numbering">
                        може застосовуватися з іншою агрохімією.
                    </li>
                </ul>
                <p className="card__description-text">
                    Добриво кристалізується за достатньо низьких температур.
                </p>
                <p className="card__description-title">
                    Рекомендації щодо застосування
                </p>
                <p className="card__description-text">
                    Застосовується препарат восени та навесні. При основному обробленні полів його використовують у нерозведеному вигляді. Застосування NPK 3-18-18 в передпосівну культивацію передбачає розведення у пропорції 1:1-2 (РКД:вода). Норми внесення слід коригувати, враховуючи аналіз ґрунту, попередника, генетичний потенціал росту культури, спосіб внесення й інші чинники.
                </p>
                <p className="card__description-title">
                    Оброблення насіння
                </p>
                <p className="card__description-text">
                    Норма — 0,5–2 л на 1 т посівного матеріалу. Препарат наноситься безпосередньо на насіння перед посівними роботами.
                </p>
                <p className="card__description-title">
                    Стартове внесення
                </p>
                <p className="card__description-text">
                    Норма — 20–60 л/га.
                </p>
                <p className="card__description-text">
                    Найбільш економний варіант застосування добрива — внесення в рядок. У цьому випадку препарат можна використовувати, дозуючи по 20 л/га. Якщо обробляти поля поверхнево, наприклад оприскувачем, то норма збільшується до 50 л/га і більше.
                </p>
                <p className="card__description-title">
                    Позакореневе підживлення
                </p>
                <p className="card__description-text">
                    Норма внесення — 1–6 л/га у 100–300 л/га робочого розчину.
                </p>
                <p className="card__description-text">
                    Використовується в період весняної вегетації озимих культур і для пізніших підживлень. Ефективність препарату підвищує додавання до розчину різних мікроелементів.
                </p>
                <p className="card__description-title">
                    Фертигація
                </p>
                <p className="card__description-text">
                    Норма — 1–5 л/га на день. Потрібно враховувати агрономічну потребу та технологічні карти.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
