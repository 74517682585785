import "./Blog.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import GoodsList from '../Сatalog/GoodsList/GoodsListBlog/GoodsList';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import crumbs from "../../icons/icons8-home.svg";

export default function Blog() {

    return (
        <div className="blog__block">
            <Helmet>
                <meta name="keywords" content="контакти, головний офіс, Санберрі Україна, Головний офіс компанії Санберрі Україна" />
                <title>Контакти виробника добрив Санберрі Україна</title>
                <link rel="canonical" href="https://sun-ukr.com/blog" />
                <meta name="description" content="Контакти Санберрі Україна - м. Вінниця, вулиця Сергія Зулінського, 43 ☎️ +38(068)-369-90-30."></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Блог</span>
            </div>
            <div className="contact__title">
                <h1>Блог компанії Санберрі Україна</h1>
            </div>
            <GoodsList />
            <Footer />
        </div>
    );
}