import "../BlogPagesStyle.scss";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import crumbs from "../../../../icons/icons8-home.svg";
import potato from "../../../../image/blog/stock-photo-raw-unpeeled-potatoes-removebg-preview.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { item } from '../../../mockData/goods-data';
import Item from '../../../Item/Item';
import React from "react";

export default class BlogPotato extends React.Component {
    constructor(props) {
        super(props);
        this.item = item;
    }

    render() {
        return (
            <div className="culture">
                <Helmet>
                    <meta
                        name="keywords"
                        content="Добрива для картоплі, лохина, рекомендації та норми внесення добрив, добрива, купити"
                    />
                    <title>Добрива для картоплі • Оптом і в роздріб | Sunberry Ukraine</title>
                    <link rel="canonical" href="https://sun-ukr.com/potato" />
                    <meta
                        name="description"
                        content="🌱Добрива для картоплі ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                    ></meta>
                </Helmet>
                <Header />
                <div className="crumbs">
                    <Link className="crumbs__link" to="/">
                        <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                    </Link>
                    <Link className="crumbs__link" to="blog">
                        <span>/ Блог</span>
                    </Link>
                    <span className="crumbs__text">/ Добрива для картоплі</span>
                </div>
                <div className="culture__title">
                    <h1>Добрива для картоплі</h1>
                </div>
                <div className="culture__container">
                    <div className="culture__content">
                        <p className="blog__timeline"> Дата публікації: 14.09.2022</p>
                        <p className="culture__desc">
                            Картопля є однією з найважливіших та найпопулярніших культур у сільському господарстві. Вона присутня в кожному домі, її купують протягом усього року й використовують для приготування величезної кількості страв. Ця культура – чудовий попередник для зернових та інших рослин. З неї отримують декстрин, глюкозу, крохмаль та навіть спирт.
                        </p>
                        <p className="culture__desc">
                            Щоб вчасно зібрати гарний, якісний врожай, слід регулярно та вчасно робити підживлення картоплі за допомогою спеціальних добрив. Картопля не має розвиненої кореневої системи, тому важко засвоює поживні речовини з ґрунту. Під час формування бутонів та цвітіння рослина потребує дуже великої кількості мікро- та макроелементів. Це особливо стосується ранньостиглих сортів. Додаткове підживлення необхідне й тим, які мають довгий період вегетації. Все тому, що для формування врожаю такого сорту потрібно більше часу.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <div className="blog__bg7"></div>
                            </div>
                            <div className="culture__inside-right">
                                <p className="culture__heading">Як правильно підгодовувати картоплю</p>
                                <p className="blog__desc-text">
                                    Будь-які добрива не мають довгострокового ефекту. Рослина дуже швидко вбирає усі корисні речовини, тому підживлення слід проводити кілька разів. Найкраще підходять комплексні препарати. Їх слід застосовувати при посадці під кожний окремий кущ. Агрономи стверджують, що саме в цей час культура найбільш сприйнятлива до поживних речовин.
                                </p>
                                <p className="blog__desc-text">
                                    Картопля добре реагує на комплексні добрива. Підходять для неї також органічні, що складаються з фосфору, азоту, калію, сірки, кальцію та інших елементів. При посадці робиться основне підживлення. Мінеральні добрива можна використовувати й додатково. Потреба в них є, коли бадилля жовтіє або швидко сохнуть квітки. Це свідчить про нестачу вітамінів.
                                </p>
                            </div>
                        </div>
                        <p className="culture__heading">Яке буває підживлення</p>
                        <p className="culture__desc">
                            Виділяють два типи – позакореневе й кореневе. Для картоплі найкраще використовувати обидва. Перший варіант підходить після завершення вегетаційного періоду. Позакореневе підживлення передбачає обприскування листків. До кореневої підгодівлі вдаються під час посадки. Цей спосіб сприяє формуванню великих, міцних бульб і швидкому зростанню бадилля.
                        </p>
                        <p className="culture__desc">
                            Вносити добрива потрібно в різні пори року. Навесні відразу після висаджування, до цвітіння, проводять обробку по кореню й листу. Друге підживлення робиться в липні, в час, коли листя остаточно сформоване й з’являються квіти. На цьому етапі слід не лише забезпечити рослину поживними речовинами, а й позбутися шкідників. Восени після збору врожаю проводять підгодівлю ґрунту на ділянці для наступної посадки.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <p className="culture__heading">Які добрива потрібні</p>
                                <p className="culture__desc">
                                    Мінеральні препарати для картоплі обов’язково мають містити калій, фосфор, азот, марганець, цинк, магній, бор, молібден. Найкраще поєднувати всі необхідні культурі речовини в одному складі. Найважливішими для нормального розвитку рослини й гарного врожаю є:
                                </p>
                                <ul className="blog__description-list">
                                    <li className="culture__description-numbering">
                                        марганець. Якщо використовувати добавки з підвищеним вмістом цієї речовини, вийде отримати смачну, крупну бульбу. Добрива з марганцем найкраще використовувати в період вегетації, до підгортання культури;
                                    </li>
                                    <li className="culture__description-numbering">
                                        фосфор. Його використовують для пізніх сортів, висаджених у червні. Добрива з фосфором стимулюють швидке зростання. Завдяки їм картопля росте завжди в термін, а картоплини виходять смачними;
                                    </li>
                                    <li className="culture__description-numbering">
                                        бор. Його обов’язково треба використовувати для підживлення в період цвітіння. Препарати з бором сприяють збільшенню складу крохмалю всередині бульби. Так вона виходить великою, твердою та смачною. Бор також покращує цвітіння;
                                    </li>
                                    <li className="culture__description-numbering">
                                        калій. Сприяє утворенню крохмалю, робить бульби важчими та стійкішими до пошкоджень.
                                    </li>
                                </ul>
                                <p className="culture__desc">
                                    Для обробки рослин чудово підходять комплексні добрива. Агрономи рекомендують придбати нітроамофоску, азофоску. Варто купувати й мінеральні препарати, які складаються з усіх поживних речовин, необхідних культурі на різних етапах розвитку.
                                </p>
                            </div>
                            <div className="culture__inside-right">
                                <img className="culture__inside-photo" src={potato} alt="blueberry" />
                            </div>
                        </div>
                        <p className="culture__heading">Як проводиться підгодівля під час посадки</p>
                        <p className="culture__desc">
                            Саме на цьому етапі підгодовування дає найпомітніший та відчутний ефект. Відразу після посадки рослина найкраще сприймає корисні речовини, а вони, своєю чергою, швидко роблять її міцнішою. Добриво закладають у кожну лунку, викопану для висаджування. Перед цим гній потрібно трохи просушити. Якщо він буде дуже свіжим, то почне розкладатися на сонці й спровокує загнивання молодої бульби.
                        </p>
                        <p className="culture__desc">
                            Норма внесення відрізняється в залежності від типу добрив. Так, нітроамофоски потрібно 5 кг на 1 га, а селітри – 3 кг. Лунка має бути приблизно 20 см в глибину, інакше восени бульба почне вилазити раніше й позеленіє. Добрива кладуть на дно лунки, зверху – посадковий матеріал, а далі засипають землею.
                        </p>
                        <p className="blog__desc-text">
                            Ще одна підгодівля потрібна перед цвітінням, після посадки. Так, молодий врожай на етапі формування отримає достатньо вітамінів та поживних речовин. Листкове підживлення неефективне, адже коренева система не отримає того, чого потребує. Найкраще зробити лунку між якимись трьома рослинами на ділянці й закладати туди суміш із гною та посліду, розведену у воді.
                        </p>
                        <div className="blog__bg8"></div>
                        <p className="culture__heading">Підгодівля під час цвітіння</p>
                        <p className="culture__desc">
                            Це наступний етап догляду за картоплею, він також є обов’язковим. Для підживлення квітучої рослини потрібно використовувати препарати, які містять фосфор. Для приготування потрібно взяти 20 грамів калію, змішати з 20 грамами подвійного суперфосфату та розвести цю суміш у воді. Її заливають у спеціальну лунку на ділянці, зроблену для добрива.
                        </p>
                        <p className="culture__desc">
                            Після виливання склад поширюється по всьому ґрунту. Вносити добрива на цьому етапі найкраще через тиждень після того, як на рослині з’явилися перші бутони. Суміш із калію та суперфосфату дає позитивний ефект, який полягає в наступному:
                        </p>
                        <ul className="blog__description-list">
                            <li className="culture__description-numbering">
                                захист від колорадських жуків та інших шкідників;
                            </li>
                            <li className="culture__description-numbering">
                                прискорення росту картоплі;
                            </li>
                            <li className="culture__description-numbering">
                                збільшення бульби у розмірі.
                            </li>
                        </ul>
                        <p className="blog__desc-text">
                            Підкореневі препарати, які використовуються у період цвітіння, мають містити цинк, калій, бор, залізо.
                        </p>
                        <p className="culture__heading">Як проводити позакореневе підживлення</p>
                        <p className="culture__desc">
                            Цей метод передбачає обробку, а саме обприскування листя рослини. Технологія називається також підгодовуванням по листу. Завдяки внесенню поживних речовин таким способом культура гарантовано матиме здоровий вигляд. Забезпечується надійний захист від колорадських жуків. Бадилля виходить якіснішим, а бульба росте великою, міцною та смачною.
                        </p>
                        <p className="culture__desc">
                            Для позакореневого підживлення найкраще підійде суміш з фосфору, калію та магнію. Її розводять у воді та розпилюють на листя кущів за допомогою пульверизатора. Усі елементи складу безпосередньо залучені у процес формування бадилля, а потім і росту бульби. Також дуже важливо обирати препарати, які містять азот. Цей елемент дуже важливий для картоплі. Добрива з азотом можна використовувати кілька разів за сезон, але обережно. Надлишок призводить до повільнішого утворення бутонів.
                        </p>
                        <p className="culture__heading">Як отримати гарний врожай</p>
                        <p className="culture__desc">
                            Для цієї мети найкраще підходять комплексні мікродобрива. Купити їх пропонує компанія ТОВ «Санберрі Україна». Щоб покращити врожай, проводять позакореневе підгодовування. Термін залежить від сорту. Для раннього оброблення слід проводити максимум за три тижні до збирання, середньостиглого чи пізнього – за місяць.
                        </p>
                        <p className="culture__desc">
                            Таким чином не лише збільшується кількість врожаю, а й підвищується вміст крохмалю у бульбах. Позакореневе підживлення за певний час до збору врожаю також покращує зовнішній вигляд рослини. Картоплини виходять однаковими за розміром, рівними і красивими. Їх простіше зберігати.
                        </p>
                        <p className="blog__heading">ТОВАРИ ЯКІ РЕКОМЕНДУЄ ВИРОБНИК :</p>
                    </div>
                </div>
                <div className="goods">
                    {this.item.map(item => (
                        <Item
                            key={item.id}
                            isNew={item.isNew}
                            title={item.title}
                            InStock={item.InStock}
                            link={item.link}
                            inside={item.inside}
                            priceFirst={item.priceFirst}
                            priceSecond={item.priceSecond}
                            priceThird={item.priceThird}
                            priceFourth={item.priceFourth}
                            imgUrl={item.imgUrl}
                            table={item.table}
                            logo={item.logo}
                            description={item.description}
                            recommendations={item.recommendations}
                            id={item.id}
                        />
                    ))}
                </div>
                <Footer />
            </div >
        );
    }
}