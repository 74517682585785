import "./Culture.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива по культурам від виробника • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/culture" />
                <meta
                    name="description"
                    content="🌱Рідкі мінеральні NPK добрива ⭐ Від українського виробника Sunberry Ukraine. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Культура</span>
            </div>
            <div className="culture__title">
                <h1>Каталог культур</h1>
            </div>
            <div className="culture__container">
                <div className="culture__goods">
                    <div className="culture__box">
                        <Link to="cultureVegetables">
                            <div className="culture__photo--bg1"></div>
                        </Link>
                        <p className="culture__box-text">Овочеві-культури</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureBerry">
                            <div className="culture__photo--bg2"></div>
                        </Link>
                        <p className="culture__box-text">Плодово-ягідні</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureRaps">
                            <div className="culture__photo--bg3"></div>
                        </Link>
                        <p className="culture__box-text">Озимовий ріпак</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureSoy">
                            <div className="culture__photo--bg4"></div>
                        </Link>
                        <p className="culture__box-text">Соя</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureSeed">
                            <div className="culture__photo--bg5"></div>
                        </Link>
                        <p className="culture__box-text">Зернова культура</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureSunflower">
                            <div className="culture__photo--bg6"></div>
                        </Link>
                        <p className="culture__box-text">Соняшник</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureCorn">
                            <div className="culture__photo--bg7"></div>
                        </Link>
                        <p className="culture__box-text">Кукурудза</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cultureSugarBeet">
                            <div className="culture__photo--bg8"></div>
                        </Link>
                        <p className="culture__box-text">Цукровий буряк</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
