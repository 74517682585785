import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "./GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для озимого ріпаку, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для озимого ріпаку • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureRaps" />
                <meta
                    name="description"
                    content="🌱Добрива для озимого ріпаку ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для озимого ріпаку</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для озимого ріпаку</h1>
            </div>
            {/* <div className="culture__bg">
                <div className="culture__scheme"></div>
            </div> */}
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення ріпаку</h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення вашого ріпаку
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__desc">
                        Озимий ріпак – поширена олійна культура в Україні, найбільше вирощується в регіонах правобережного лісостепу. Його насіння використовують у харчовій, миловарній, лакофарбовій промисловості, для виготовлення зеленого корму й біопалива. Середні показники врожайності в Україні нижчі, ніж по Європі загалом. Тому варто використовувати мікродобрива для озимого ріпаку й чітко дотримуватися технології вирощування рослини.
                    </p>
                    <p className="culture__desc">
                        Щоб отримати гарний врожай, потрібно своєчасно та якісно виконувати всі польові роботи. Це стосується й внесення добрив. Для повноцінного зростання ріпаку та розкриття потенціалу насіння знадобляться спеціальні препарати з мікроелементами. ТОВ «Санберрі Україна» пропонує універсальні й доступні за ціною розчини для оброблення насіння та листя. Є окремо добрива для збалансованого росту, розвитку кореневої системи та листя, проти розтріскування плодів.
                    </p>
                    <p className="culture__heading">
                        Особливості використання
                    </p>
                    <p className="culture__desc">
                        Восени озимий ріпак проходить період вегетації. У цей час потрібно продумати й ефективно використати мінеральне живлення. Від нього залежить, як проростає насіння, сходить рослина, утворюються листки й розетка, а також закладаються генеративні органи. Якщо вносити живильні препарати правильно, культура добре витримає зиму та швидко відновить вегетацію навесні.
                    </p>
                    <p className="culture__desc">
                        РКД, або рідкі комплексні добрива, – водні розчини, які включають два або більше поживних речовин. Вони містять фосфор, бор, молібден, цинк та інші макро- і мікроелементи, потрібні для стабільного розвитку, стійкості та гарного врожаю озимого ріпаку. Використовувати їх найкраще в періоди, коли рослина найбільше потребує підживлення:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            процес засівання;
                        </li>
                        <li className="culture__description-numbering">
                            період вегетації – спочатку восени, а потім повторний навесні;
                        </li>
                        <li className="culture__description-numbering">
                            поява бутонів;
                        </li>
                        <li className="culture__description-numbering">
                            дозрівання та наливання насіння.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Мікродобрива для ріпаку – це препарати комплексної дії. Вони запобігають дефіциту необхідних рослині речовин, активізують процес росту, покращують засвоєння мінеральних підгодовувальних розчинів. Якщо вчасно та правильно внести РКД у потрібній дозі, вийде забезпечити повноцінне зростання культури. Вона стане більш стійкою до стресових чинників, краще цвістиме й запилюватиметься, щоб дати гарний врожай.
                    </p>
                    <p className="culture__heading">
                        Як проводити обробіток насіння та листя
                    </p>
                    <p className="culture__desc">
                        Перш ніж вносити розчин, слід враховувати конкретні характеристики ґрунту. Від цього залежать найбільш підхожа точна доза та період внесення препарату. Усе це розраховує та визначає професійний агроном після детального аналізу ґрунту.
                    </p>
                    <p className="culture__desc">
                        Для оброблення насіння знадобиться 4 л/т «Агроболік Фосфор». Це органічно-мінеральне добриво, яке сприяє кращому проростанню насіння, зміцнює кореневу систему. Необхідне для формування в рослини стійкості до хвороб, зокрема вірусних, та негативних факторів. Наукові дослідження показують, що оброблення насіння «Агроболік Фосфор» гарантовано дає гарні сходи.
                    </p>
                    <p className="culture__desc">
                        Озимий ріпак потребує багатьох мікроелементів восени, на початку бутонізації та під час дозрівання й наливання насіння. Йому необхідні такі мікроелементи, як молібден, цинк, марганець та інші. Усі вони містяться в добривах «Агроболік Фосфор» від ТОВ «Санберрі Україна». Норма – 1 літр на гектар й 1,5-2 л/га у період дозрівання та наливу насінин. На останньому етапі застосовують «Агроболік Бор +Молібден», також 1 літр на гектар.
                    </p>
                    <p className="culture__desc">
                        Для отримання багатого та якісного врожаю рекомендується проводити обробіток мікродобривами мінімум тричі, а найкраще – у п’ять етапів. Виробник добрив «Санберрі Україна» має вигідні пропозиції. Якщо купувати відразу багато літрів препарату, можна отримати знижку (залежить від кількості).
                    </p>
                </div>
                <p className="culture__heading">Спеціальні пропозиції</p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 5 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">четверте внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 5 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">четверте внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 5 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">четверте внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор + Молібден»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
