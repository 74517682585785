import "./HumateCard.scss";
import Header from "../../../../Header/Header";
import Footer from "../../../../Footer/Footer";
import GoodsList from '../../../GoodsList/GoodsListFertilizers/GoodsListHumate';
import crumbs from "../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FertilizersTabs() {
    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="виробництво, Гранульовані гумати, гумат, купити, замовити, Санберрі Україна" />
                <title>Гранульовані гумати від виробника • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/humateCard" />
                <meta name="description" content="🌱Гранульовані гумати ⭐ Від українського виробника Sunberry Ukraine. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalog">
                    <span>/ Комплексні органо-мінеральні добрива</span>
                </Link>
                <span className="crumbs__text">/ Гранульовані гумати</span>
            </div>
            <div className="fertilizersCard__catalog">
                <div className="fertilizersCard__title">
                    <h1 className="fertilizersCard__title__above">Гранульовані гумати</h1>
                </div>
                <div className="fertilizers-tabs-active">
                    <div className="fertilizers-active  active-fertilizers fertilizers-active">
                        <GoodsList />
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}


