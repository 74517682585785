import "../BlogPagesStyle.scss";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import crumbs from "../../../../icons/icons8-home.svg";
import strawberries from "../../../../image/blog/stock-photo-fresh-strawberries-removebg-preview.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { item } from '../../../mockData/goods-data';
import Item from '../../../Item/Item';
import React from "react";
export default class BlogStrawberries extends React.Component {

    constructor(props) {
        super(props);
        this.item = item;
    }

    render() {
        return (
            <div className="culture">
                <Helmet>
                    <meta
                        name="keywords"
                        content="Добрива для полуниці, полуниця, рекомендації та норми внесення добрив, добрива, купити"
                    />
                    <title>Добрива для лохини • Оптом і в роздріб | Sunberry Ukraine</title>
                    <link rel="canonical" href="https://sun-ukr.com/strawberries" />
                    <meta
                        name="description"
                        content="🌱Добрива для полуниці ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                    ></meta>
                </Helmet>
                <Header />
                <div className="crumbs">
                    <Link className="crumbs__link" to="/">
                        <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                    </Link>
                    <Link className="crumbs__link" to="blog">
                        <span>/ Блог</span>
                    </Link>
                    <span className="crumbs__text">/ Добрива для полуниці</span>
                </div>
                <div className="culture__title">
                    <h1>Добрива для полуниці</h1>
                </div>
                <div className="culture__container">
                    <div className="culture__content">
                        <p className="blog__timeline"> Дата публікації: 11.09.2022</p>
                        <p className="culture__heading">Коли, як та чим підживити полуницю для гарного врожаю</p>
                        <p className="culture__desc">
                            Солодку полуницю або, як її ще називають, садову суницю полюбляють і дорослі, і діти, тому охоче саджають та вирощують ягоди на присадибних ділянках та приватних фермах. Ретельно обираючи сорти за врожайністю, розміром плодів та їх смаковими якостями, варто враховувати, що ці характеристики суттєво залежать від створених умов. Правильно обрана ділянка, якісна підготовка ґрунту, достатня кількість вологи, а також вчасно внесене добриво для полуниці – все це є важливими передумовами гарного зростання рослин та плодоношення. Кущі потребують догляду не лише під час формування зав’язі та достигання ягід. Гарний врожай є результатом постійного догляду за рослинами протягом усього вегетаційного періоду.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <div className="blog__bg9"></div>
                            </div>
                            <div className="culture__inside-right">
                                <p className="culture__heading">Як та чим підгодовувати садову суницю</p>
                                <p className="blog__desc-text">
                                    Кожна рослина для гармонійного розвитку потребує певної кількості макро- і мікроелементів. Зазвичай у ґрунті їх недостатньо, тому на допомогу фермерам приходять спеціалізовані добрива. Їх склад відповідає потребам певної культури на визначеному етапі її вегетації. Досвідчені садівники радять підгодовувати кущі тричі за сезон:
                                </p>
                                <ul className="culture__description-list">
                                    <li className="culture__description-numbering">
                                        навесні перед початком росту внесення добрив сприятиме пришвидшеному розвитку рослини, нарощуванню вегетативної маси, рясному цвітінню та утворенню великої кількості зав’язей;
                                    </li>
                                    <li className="culture__description-numbering">
                                        влітку після збору врожаю кущі повинні відновити свої сили та спрямувати їх на утворення вусів;
                                    </li>
                                    <li className="culture__description-numbering">
                                        восени, щоб підготувати рослину, до періоду спокою.
                                    </li>
                                </ul>
                                <p className="blog__desc-text">
                                    У кожен з цих періодів садова суниця потребую певного набору речовин для нормального росту. Найкраще добриво для полуниці – це те, яке відповідає поточним потребам рослини, вноситься вчасно та у достатній кількості.
                                </p>
                            </div>
                        </div>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <p className="culture__heading">Добрива для полуниці: які бувають</p>
                                <p className="culture__desc">
                                    Добрива для полуниці відрізняються за складом. Вони бувають:
                                </p>
                                <ul className="culture__description-list">
                                    <li className="culture__description-numbering">
                                        органічні – утворені з органічних сполук. До них належать компост, гній чи зола;
                                    </li>
                                    <li className="culture__description-numbering">
                                        мінеральні – є комплексом хімічних сполук, що потрібні рослинам;
                                    </li>
                                    <li className="culture__description-numbering">
                                        органо-мінеральні – створюються на базі гумінових кислот та сприяють покращенню якості ґрунту;
                                    </li>
                                    <li className="culture__description-numbering">
                                        комплексні – містять увесь спектр поживних речовин, які потребує культура;
                                    </li>
                                    <li className="culture__description-numbering">
                                        мікродобрива – використовуються за умови дефіциту окремих сполук у ґрунті.
                                    </li>
                                </ul>
                                <p className="culture__desc">
                                    Найкориснішими та зручними у використанні є сполуки на базі гумінових кислот. Вони добре розчиняються у воді та можуть вноситися безпосередньо під час поливу рослин. Такі речовини вирізняє багатий склад, що містить основні речовини, яких потребує культура протягом усього періоду вегетації. Регулярне використання гуматів сприяє найбільш гармонійному розвитку рослин, покращенню смакових якостей ягід та збільшенню врожайності.
                                </p>
                            </div>
                            <div className="culture__inside-right">
                                <img className="culture__inside-photo" src={strawberries} alt="strawberries" />
                            </div>
                        </div>
                        <p className="culture__heading">Вибір ґрунту для полуниці</p>
                        <p className="culture__desc">
                            Полуниця не є надто примхливою рослиною, тому може рости скрізь. Проте якщо вашою метою є отримання смачних ягід у великій кількості, варто ретельніше обирати місце для посадки. Ця культура потребує легкого зволоженого ґрунту з гарною повітропроникністю, добре росте на сонці та у легкому затінку опівдні. Якщо ж на присадибній ділянці бракує місця, цілком можливо садити рослини під деревами у садку.
                        </p>
                        <p className="culture__desc">
                            Перед висадкою розсади варто підготувати ґрунт. Для цього вам необхідно:
                        </p>
                        <ul className="blog__description-list">
                            <li className="culture__description-numbering">
                                прибрати ділянку від бур’яну;
                            </li>
                            <li className="culture__description-numbering">
                                обробити землю від шкідників, що завдають найбільшої шкоди суниці – дротянок і хрущів;
                            </li>
                            <li className="culture__description-numbering">
                                перекопати ґрунт на глибину до 30 см з додаванням органічних добрив.
                            </li>
                        </ul>
                        <p className="culture__desc">
                            Полуницю можна саджати у році двічі. Якщо ви запланували закладку ягідника на весну, варто займатися підготовкою ґрунту з осені. Оптимальний період – жовтень та початок листопаду, коли повторне заростання очищеної ділянки травою не загрожуватиме. При осінній посадці полуниці ґрунт починають обробляти за 2–3 тижні, а висаджують кущі у вересні-жовтні.
                        </p>
                        <p className="culture__desc">
                            При перекопуванні важливо не перевищувати дозування добрив. Якщо планується внесення лише органіки, достатньо буде 3–4 ц/га. Коли ви плануєте робити органо-мінеральну підкормку, норму застосування необхідно зменшити вдвічі. Використовувати добриво для полуниці необхідно у помірній кількості, оскільки надлишок поживних речовин може призвести до «жирування» рослини. За таких умов вона нарощуватиме лиш об’єм зеленої маси. Саме тому у перший рік після висадки кущів на підготовану ділянку додатково вносити добрива не варто.
                        </p>
                        <div className="blog__bg10"></div>
                        <p className="culture__heading">Які сполуки є найважливішими при вирощуванні полуниці</p>
                        <p className="culture__desc">
                            Найважливішими елементами, яких потребує полуниця під час вегетаційного періоду, є азот, фосфор та калій. Кожна з цих речовин по-своєму впливає на розвиток садової суниці:
                        </p>
                        <ul className="culture__description-list">
                            <li className="culture__description-numbering">
                                азот сприяє зростанню кущів. Про його нестачу можуть свідчити почервоніння старого листя та сповільнене зростання рослини. Молоді пагони набувають світло-зеленого кольору, а кущ виглядає слабким та хворобливим. За дефіциту азоту кущ не матиме сили дати гарний врожай;
                            </li>
                            <li className="culture__description-numbering">
                                фосфор використовується у складі комплексного підживлення. Сприяє нарощуванню коренів, стимулює процеси вкорінення та сприяє гармонійному розвитку. Брак фосфору негативно впливає на розмір та смакові якості ягід;
                            </li>
                            <li className="culture__description-numbering">
                                калій важливий на етапі формування бутонів та визрівання полуниці. Допоможе отримати великий урожай та збільшує вміст цукру у ягодах.
                            </li>
                        </ul>
                        <p className="culture__desc">
                            Як обрати добриво для полуниці, залежить від сезону та потреб рослини. Наприклад, азот зазвичай вносять навесні, оскільки саме у цей період відбувається інтенсивне нарощування вегетативної маси рослини. Для успіху у вирощуванні полуниці, крім макроелементів, важлива достатність мікроелементів. Вони використовуються у невеликих кількостях, проте суттєво впливають на розмір врожаю та його смакові якості.
                        </p>
                        <p className="blog__heading">ТОВАРИ ЯКІ РЕКОМЕНДУЄ ВИРОБНИК :</p>
                    </div>
                </div>
                <div className="goods">
                    {this.item.map(item => (
                        <Item
                            key={item.id}
                            isNew={item.isNew}
                            title={item.title}
                            InStock={item.InStock}
                            link={item.link}
                            inside={item.inside}
                            priceFirst={item.priceFirst}
                            priceSecond={item.priceSecond}
                            priceThird={item.priceThird}
                            priceFourth={item.priceFourth}
                            imgUrl={item.imgUrl}
                            table={item.table}
                            logo={item.logo}
                            description={item.description}
                            recommendations={item.recommendations}
                            id={item.id}
                        />
                    ))}
                </div>
                <Footer />
            </div >
        );
    }
}
