export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    РКД NPK 8-24-0 від «Санберрі Україна» є універсальним добривом, яке можна використовувати під час вирощування майже всіх сільськогосподарських культур. Препарат має багатий склад елементів у високій концентрації, через це він є дуже економним. Точне дозування добрива розраховується індивідуально. До уваги беруться склад ґрунту, особливості рослини та інші чинники, які впливають на вегетацію.
                </p>
                <div className="card__description-title">
                    Загальна інформація
                </div>
                <p className="card__description-text">
                    Якщо ви вирішите купити РКД NPK 8-24-0, отримаєте тривалий позитивний ефект на всіх етапах вирощування культури. Зокрема, воно здатне забезпечити рослині комплексне живлення необхідними поживними елементами.
                </p>
                <p className="card__description-text">
                    Завдяки препарату досягаються міцність стеблин, стійкість до вилягання й низької температури. Значною мірою зменшується вірогідність ураження розповсюдженими хворобами. До таких належать борошниста роса, коренева гниль, іржа. РКД NPK 8-24-0 ефективно використовується в комплексі з іншими препаратами.
                </p>
                <div className="card__description-title">
                    Що потрібно знати про добриво
                </div>
                <p className="card__description-text">
                    До складу РКД NPK 8-24-0 входять азот – в обсязі 102 г/л, оксид фосфору – 305 г/л. Фізико-хімічні показники рідкого добрива є такими:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        pH – 6,7-7,1;
                    </li>
                    <li className="card__description-numbering">
                        густина – 1,26-1,28 г/моль;
                    </li>
                    <li className="card__description-numbering">
                        температура кристалізації – нижча за 0 °C.
                    </li>
                </ul>
                <p className="card__description-text">
                    Виготовлення добрива відбувається шляхом хімічного синтезу з якісної, екологічно чистої сировини, яка знайшла застосування у фармацевтичній і харчовій промисловості. РКД NPK 8-24-0 має нейтральний pH, що мінімізує вплив на рослину.
                </p>
                <p className="card__description-text">
                    Препарат, який відповідає стандартам якості, являє собою прозорий розчин, що не має баластних домішок і шкідливих речовин. Він легко й у повному обсязі розчиняється у воді. Однією з характеристик цього рідкого добрива є висока доступність фосфору азоту й калію. Внаслідок низької температури кристалізації препарат засвоюється рослиною навіть у випадку, коли на ґрунті фіксуються приморозки.
                </p>
                <div className="card__description-title">
                    Відомості про застосування
                </div>
                <p className="card__description-text">
                    Загальний обробіток насіння передбачає витрати від 0,5 л до 2 л РКД NPK 8-24-0 на 1 тону посівного матеріалу. На стартовому етапі як передпосівне добриво воно вноситься або локально, або суцільно за допомогою обприскувача. Використовують РКД NPK 8-24-0 у кількості від 10 до 60 л/га. Воно вноситься в зону коріння, на насіння, у рядок (найбільш економічний засіб) або в міжряддя.
                </p>
                <p className="card__description-text">
                    Коли робите позакореневе підживлення, потрібно додати РКД NPK 8-24-0 із розрахунку від 1 до 6 л/га у робочий розчин обсягом від 100 до 300 л/га. Безпосереднє внесення здійснюється шляхом обприскування, під час якого струю варто направити на вегетативну частину рослини. Захід здійснюється від 1 до 4 разів упродовж сезону з інтервалом від 7 до 21 дня. Якщо ви до своїх культур застосовуєте таку технологію живлення, як фертигація, потрібно взяти препарат РКД NPK 8-24-0 у кількості від 1 до 5 л /га. Дозування формується з огляду на агрономічну потребу або технологічні карти на певну рослину.
                </p>
                <div className="card__description-title">
                    Практичне застосування
                </div>
                <p className="card__description-text">
                    Серед культур, для вирощування яких варто купити РКД NPK 8-24-0, зазначимо такі:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">кукурудза;</li>
                    <li className="card__description-numbering">картопля;</li>
                    <li className="card__description-numbering">яра пшениця;</li>
                    <li className="card__description-numbering">цукровий буряк;</li>
                    <li className="card__description-numbering">соняшник та інші.</li>
                </ul>
                <p className="card__description-text">
                    Препарат застосовують і для живлення озимих культур, наприклад рапсу або пшениці. Якщо ви це робите, калій потрібно вносити окремо.
                </p>
                <p className="card__description-text">
                    Коли вирощується кукурудза, РКД NPK 8-24-0 використовують під час посівної або безпосередньо перед початком посіву. Надважливо застосування препарату на стадії 5-7 листків, адже саме в цей період у рослини спостерігається висока потреба у фосфорі. Разом із РКД NPK 8-24-0 припускається паралельне використання інших добрив.
                </p>
                <p className="card__description-text">
                    Якщо ви вирощуєте соняшник, препарат застосовується під час посіву або перед ним, а також в період до формування кошиків. Саме тоді виникає висока потреба у фосфорі й більш помірна в калії й азоті. РКД NPK 8-24-0 задовольняє її. Вирощування цукрового буряку здійснюється на чорноземі. Препарат РКД NPK 8-24-0 застосовують під час посівів, і додатково додають калій.
                </p>
                <p className="card__description-text">
                    Для ярої пшениці внесення відбувається перед посівом або безпосередньо під час його – залежить від технологічної карти й умов вирощування. Загалом препарат варто використовувати упродовж усієї вегетації, особливо на ранній стадії, коли є потреба в насичені рослини фосфором. У разі вирощування картоплі РКД NPK 8-24-0 додають у перший період вегетації, до появи сходів.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
