export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <div className="card__description-title">
                    Застосування
                </div>
                <p className="card__description-text">
                    Рекомендується для передпосівного внесення з метою поповнення запасів ґрунту магнієм та сіркою.  Добриво особливо підходить для підживлення озимого ріпаку та озимої пшениці, цукрових буряків, картоплі, кукурудзи та багаторічних рослин. Призначене для використання на легких та кислих ґрунтах, де відбувається вимивання магнію. На легких ґрунтах рекомендується розділити дозу на 30-50% від кількості на початку осіннього періоду, а решту застосувати ранньою весною з метою запобігання вимиванню компонентів. У випадку виснажених/деградованих ґрунтів рекомендовані дози необхідно збільшити на 50%.  При вирощуванні озимого ріпаку та озимої пшениці добриво також необхідно застосовувати ранньою весною з метою відновлення запасів ґрунту після зими та підготовки рослин до ефективного використання першої дози азоту.
                </p>
                <div className="card__description-title">
                    Склад
                </div>
                <table className="card__description__table">
                    <tr>
                        <td className="card__description__td">Оксид магіню (MgO), загальний</td>
                        <td className="card__description__th-index">20%</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Триоксид сірки (SO₃), розчинний у воді</td>
                        <td className="card__description__th-index">35%</td>
                    </tr>
                </table>
                <div className="card__description-title">
                    Дозування
                </div>
                <table className="card__description__table">
                    <tr>
                        <th className="card__description__th">Культури</th>
                        <th className="card__description__th">Норма внесення добрив</th>
                    </tr>
                    <tr>
                        <td className="card__description__td">Соняшник</td>
                        <td className="card__description__th-index">120-200</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Соя, горох</td>
                        <td className="card__description__th-index">100-180</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Кукурудза</td>
                        <td className="card__description__th-index">100-180</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Цукрові буряки</td>
                        <td className="card__description__th-index">150-200</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Озима та яра пшениця</td>
                        <td className="card__description__th-index">100-150</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Озимий та ярий ріпак</td>
                        <td className="card__description__th-index">150-200</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Озимий та ярий ячмінь</td>
                        <td className="card__description__th-index">100-150</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Картопля, овочі</td>
                        <td className="card__description__th-index">150-250</td>
                    </tr>
                </table>
                <div className="card__description-title">
                    Властивості
                </div>
                <ol className="card__description-list">
                    <li className="card__description-numbering">
                        магній як основний компонент хлорофілу є необхідним у процесі фотосинтезу. Крім того, він активує дію багатьох ферментів та регулює енергетичний баланс рослин;
                    </li>
                    <li className="card__description-numbering">
                        покращує якість врожаю завдяки збільшенню вмісту жирів в олійних культурах, білків в насінні, крохмалю в картоплі та цукру в цукрових буряках;
                    </li>
                    <li className="card__description-numbering">
                        сірка збільшує урожайність за рахунок підвищення ефективності використання азоту.
                    </li>
                </ol>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
