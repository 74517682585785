import React, { useState, useEffect, useRef } from "react";

import "./RandomNumbers.scss";

export default function Test() {
    const [start] = useState(0);
    const [end] = useState(9);
    const [duration] = useState(3);
    const numberRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleIntersection = (entries) => {
            const [entry] = entries;
            setIsVisible(entry.isIntersecting);
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: "0px",
            threshold: 0.5,
        });

        const currentNumberRef = numberRef.current;

        if (currentNumberRef) {
            observer.observe(currentNumberRef);
        }

        return () => {
            if (currentNumberRef) {
                observer.unobserve(currentNumberRef);
            }
        };
    }, []);

    const [currentValue, setCurrentValue] = useState(start);

    useEffect(() => {
        if (isVisible && currentValue < end) {
            let intervalId;
            const step = (end - start) / (duration * 60);

            const updateValue = () => {
                setCurrentValue((prevValue) => Math.min(prevValue + step, end));
                if (currentValue < end) {
                    intervalId = requestAnimationFrame(updateValue);
                }
            };

            intervalId = requestAnimationFrame(updateValue);

            return () => cancelAnimationFrame(intervalId);
        }
    }, [isVisible, currentValue, end, start, duration]);

    return (
        <div className="container">
            <div className="number__text">
                <span>Років на ринку</span>
                <div ref={numberRef} className="value">{Math.floor(currentValue)}+</div>
            </div>
        </div>
    );
}
