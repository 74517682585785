import React, { useState } from "react";
import { useForm } from "react-hook-form";
import "./Form.scss";

export default function App() {

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [setField] = useState();
    const [submitted, setSubmit] = useState(false);

    const onSubmit = (data) => {
        setField(data)
        setSubmit(true)
    }

    console.log(errors.email)

    return (
        <div className="form-container">
            <form className="register-form" onSubmit={handleSubmit(onSubmit)}>

                {submitted ? <div className="success-message">Відправка успішна!</div> : null}

                <input
                    id="first-name"
                    className="form-field"
                    type="text"
                    placeholder="Ім'я"
                    {...register('firstName', { required: "Обов'язкове поле!" })}


                />

                <span className="form-validation--error">{errors.firstName?.message}</span>

                <input
                    id="last-name"
                    className="form-field"
                    type="text"
                    placeholder="Прізвище"
                    {...register('lastName', { required: "Обов'язкове поле!" })}

                />

                <span className="form-validation--error">{errors.lastName?.message}</span>

                <input
                    id="email"
                    className="form-field"
                    type="email"
                    placeholder="E-mail"
                    {...register('email', { required: "Обов'язкове поле!", pattern: { value: /^\S+@\S+$/i, message: "Неправильний формат" } })}

                />

                <span className="form-validation--error">{errors.email?.message}</span>


                <input
                    id="phone"
                    type="tel"
                    name="phone"
                    className="form-field"
                    placeholder="Телефон"
                    {...register('phone', { required: "Обов'язкове поле!", pattern: { value: /^\+[0-9]{2}\s\((\d+)\)\s\d{3}\s\d{2}\s\d{2}/, message: "Неправильний формат" } })}

                />

                <span className="form-validation--error">{errors.phone?.message}</span>


                <button className="form-field form-field-button" type="submit">
                    ВІДПРАВИТИ
                </button>
            </form>
        </div>
    );
}