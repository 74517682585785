import logo from "../../image/goods.image/NPK/NPK.png";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";

export const items = [
  {
    title: 'РКД NPK 8-24-0',
    priceFirst: 'від 46 000 грн',
    priceSecond: 'від 1 300 грн ',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "npk-8-24-0",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "1"
  },
  {
    title: 'РКД NPK 5-20-5',
    priceFirst: 'від 44 000 грн',
    priceSecond: 'від 1 100 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "npk-5-20-5",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "2"
  },
  {
    title: 'РКД NPK 3-18-18',
    priceFirst: 'від 55 000 грн',
    priceSecond: 'від 1 300 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "npk-3-18-18",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "3"
  },
  {
    title: 'РКД NPK 0-20-23',
    priceFirst: 'від 45 000 грн',
    priceSecond: 'від 1 200 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "npk-0-20-23",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "4"
  },
  {
    title: 'РКД NPK 6-20-0',
    priceFirst: 'від 44 000 грн',
    priceSecond: 'від 1 200 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "npk-6-20-0",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "5"
  },
  {
    title: 'РКД NPK 8-24-24',
    priceFirst: 'від 68 000 грн',
    priceSecond: 'від 1 700 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "npk-8-24-24",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "6"
  },
  {
    title: 'Карбамідо - аміачна суміш КАС 32',
    priceFirst: 'від 37 000 грн',
    priceSecond: 'від 1 200 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "kas-32",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "7"
  },
  {
    title: 'Карбамідо - аміачна суміш КАС 30+ 2S',
    priceFirst: 'від 36 000 грн',
    priceSecond: 'від 1 100 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "kas-30",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "8"
  },
  {
    title: 'Карбамідо - аміачна суміш КАС 26 +b/zn',
    priceFirst: 'від 30 000 грн',
    priceSecond: 'від 950 грн',
    InStock: false,
    imgUrl: <img className="itemCard__image" src={logo} alt="logo" />,
    inside: "kas-26",
    link: <Link to="order"><Button /></Link>,
    isNew: false,
    id: "9"
  }
];
