import "../../../CardProfile/CardProfile.scss";
import money from "../../../../../image/Company/wallet-with-card-sticking-out.png";
import deliver from "../../../../../image/Company/fast-delivery.png";
import time from "../../../../../image/Company/timer.png";
import verified from "../../../../../image/Company/shield.png";
import Header from "../../../../Header/Header";
import Footer from "../../../../Footer/Footer";
import logo2 from "../../../../../image/logotype/MAS Seeds.svg";
import goodsPage from "../../../../../image/goods.image/corn/wjwx5vF0xB.jpeg";
import MasDesc from '../Mas-86cp/Mas-86cpDesc';
import MasRef from '../Mas-86cp/Mas-86cpRef';
import Button from "../../../../../assets/Button/ButtonBuy/ButtonBuy"
import crumbs from "../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Item() {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
      <Header />
      <Helmet>
        <meta name="keywords" content="Гібрид, соняшник, Насіння соняшнику, MAC 86.КП, купити, Санберрі Україна, насіння" />
        <title>Насіння соняшнику MAC 86.КП (MAS 86.CP) • Купити оптом і в роздріб | Sunberry Ukraine</title>
        <link rel="canonical" href="https://sun-ukr.com/mas87" />
        <meta name="description" content="🌻Насіння соняшнику MAC 86.КП (MAS 86.CP) оптом і в роздріб ⭐ Від якісного виробника MAS Seeds ✅ Замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
      </Helmet>
      <div className="crumbs">
        <Link className="crumbs__link" to="/">
          <img className="crumbs__logo" src={crumbs} alt="crumbs" />
        </Link>
        <Link to="catalogSeed">
          <span>/ Насіння</span>
        </Link>
        <Link to="sunflowerCard">
          <span className="crumbs__text">/ Насіння соняшнику</span>
        </Link>
        <span className="crumbs__text">/ Насіння соняшнику MAC 86.КП (MAS 86.CP)</span>
      </div>
      <div className="itemSeed">
        <div className="itemSeed__title">
          <h1 className="itemSeed__title--styles">Насіння соняшнику MAC 86.КП (MAS 86.CP)</h1>
        </div>
        <div className={"item__stock " + (false && "item__stock--new")}>
          <span className="item__stock-arr">
            в наявності
          </span>
          <span className="item__stock-road">
            Уточнюйте наявність
          </span>
        </div>
        <div className="itemSeed__container">
          <div className="itemSeed__left">
            <div className="test-1">
              <div className="itemSeed__image-container">
                <img className="itemSeed__image" src={goodsPage} alt="goodsPage" />
              </div>
              <div className="itemSeed__center">
                <div className="itemSeed__block">
                  <div className="itemCorn__logo">
                    <img className="itemCard__image-logo" src={logo2} alt="logo" />
                  </div>
                  <span className="item__factory-text">
                    Виробник:
                    <span className="item__factory-name"> MAS Seeds</span>
                  </span>
                </div>
                <div className="characteristic__wrap">
                  <div className="aside__title">Основні характеристики</div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Комерційна назва</div>
                    <div className="characteristic__value">MAC 86.КП (MAS 86.CP)</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Культура</div>
                    <div className="characteristic__value">Соняшник</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Виробник</div>
                    <div className="characteristic__value">MAS Seeds</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Група стиглості</div>
                    <div className="characteristic__value">середньоранній</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Цвітіння</div>
                    <div className="characteristic__value">середньораннє</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Стійкість до вовчка</div>
                    <div className="characteristic__value">А-Е</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Тип Олійності</div>
                    <div className="characteristic__value">Л-лінолевий</div>
                  </div>
                  <div className="characteristic__container">
                    <div className="characteristic__label">Спец. технології</div>
                    <div className="characteristic__value">Clearfield Plus</div>
                  </div>
                </div>
                <div className="itemSeed__offer">
                  <div className="item__price">
                    <div className="price__charts">
                      <span className="price__item">Роздрібна ціна</span>
                      <div className="price__taxes">
                        <div>i</div>
                        <div class="price__taxes-content fadeInUp">
                          <span>Ціни вказані з ПДВ</span>
                        </div>
                      </div>
                    </div>
                    <div className="price item__capacity-tabs-active">
                      <div className="capacity  active-capacity">від 2 500 грн / п.од</div>
                    </div>
                  </div>
                  <div className="item__order">
                    <Link to="order"><Button /></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section-cart-promo">
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={verified} alt="verified" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Якість гарантовано!</div>
              <div className="cart-promo__label _text-caption">Санберрі Україна пропонує тільки офіційну продукцію</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={time} alt="time" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Працюємо 24/7</div>
              <div className="cart-promo__label _text-caption">Ми приймаємо ваші замовлення щодня без перерв та вихідних</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={money} alt="money" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Оплата</div>
              <div className="cart-promo__label _text-caption">Пропонуємо готівковий та безготівковий розрахунки за товар</div>
            </div>
          </div>
          <div className="cart-promo__item">
            <div className="cart-promo__ico">
              <img className="card-info_photo" src={deliver} alt="deliver" />
            </div>
            <div className="cart-promo__wrap">
              <div className="cart-promo__head">Доставка</div>
              <div className="cart-promo__label _text-caption">По всій Україні Новою Поштою чи іншими службами доставки</div>
            </div>
          </div>
        </div>
        <div className="seeds-tabs">
          <button
            className={toggleState === 1 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
            onClick={() => toggleTab(1)}
          >
            Опис Товару
          </button>
          <button
            className={toggleState === 2 ? "seed-tabs active-seed-tabs" : "seed-tabs"}
            onClick={() => toggleTab(2)}
          >
            Характеристика
          </button>
        </div>
        <div className="seeds-tabs-active">
          <div
            className={toggleState === 1 ? "seeds-active  active-seeds" : "seeds-active"}>
            <MasDesc />
          </div>

          <div
            className={toggleState === 2 ? "seeds-active  active-seeds" : "seeds-active"}>
            <MasRef />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
