import GoodsSliderBor from "../../Сatalog/GoodsSlider/Bor/GoodsSlider";
import GoodsSliderPhosphorus from "../../Сatalog/GoodsSlider/Phosphorus/GoodsSlider";
import GoodsSliderUniversal from "../../Сatalog/GoodsSlider/Universal/GoodsSlider";
import Button from "../../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";

export const items = [
    {
        title: 'Агроболік Фосфор',
        priceFirst: '5 200 грн',
        priceSecond: '2 700 грн',
        priceThird: '350 грн',
        priceFourth: '240 грн',
        InStock: true,
        link: <Link to="order"><Button /></Link>,
        inside: "phosphor",
        imgUrl: <GoodsSliderPhosphorus />,
        isNew: true,
        id: "1"
    },
    {
        title: 'Агроболік Універсал',
        priceFirst: '3 400 грн',
        priceSecond: '1 800 грн',
        priceThird: '240 грн',
        priceFourth: '160 грн',
        InStock: true,
        link: <Link to="order"><Button /></Link>,
        inside: "universal",
        imgUrl: <GoodsSliderUniversal />,
        isNew: false,
        id: "2"
    },
    {
        title: 'Агроболік Бор',
        priceFirst: '3 400 грн',
        priceSecond: '1 800 грн',
        priceThird: '240 грн',
        priceFourth: '160 грн',
        InStock: true,
        link: <Link to="order"><Button /></Link>,
        inside: "bor",
        imgUrl: <GoodsSliderBor />,
        isNew: false,
        id: "3"
    }
];
