export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Соя сорту «Plus Ultra» - це відмінний вибір для фермерів, які прагнуть отримати високоякісний врожай з високим вмістом білка. Цей сорт сої відзначається не лише високою врожайністю, але й стійкістю до стресових умов, таких як засуха та хвороби. Вегетаційний період становить 80 - 85 днів, після чого можна збирати врожай.
                </p>
                <div className="card__description-title">Особливості сорту</div>
                <p className="card__description-text">
                    Насіння «Plus Ultra» має високий вміст білка (38-42%), що робить його ідеальним для виробництва соєвого борошна та соєвого масла. Вирощена на основі цього сорту соя відома своїм чудовим смаком та корисними властивостями.
                </p>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Рекомендована технологія вирощування:
                    </p>
                    <li className="card__description-numbering">Вміст азоту, фосфору, калію: Рекомендується внести азот у кількості 60-80 кг/га, фосфору - 40-60 кг/га та калію - 40-60 кг/га для забезпечення оптимального росту та розвитку рослин.</li>
                    <li className="card__description-numbering">pH ґрунту: 5.5-7.0</li>
                    <li className="card__description-numbering">Вологозабезпеченість: Важливо забезпечити рослини водою під час вегетації, особливо у періоди посіву та цвітіння.</li>
                    <li className="card__description-numbering">Мікроелементи: Додаткове внесення мікроелементів, таких як залізо, марганець, цинк та мідь, може підвищити врожайність та якість продукції.</li>
                </ol>
                <p className="card__description-text">
                    Рекомендується висівати сорт «Plus Ultra» в пізньому весняному або ранньому літньому періоді для досягнення найкращих результатів. Ретельний догляд під час вегетації допоможе забезпечити формування великого та якісного врожаю.
                </p>
                <p className="card__description-text">
                    Сорт «Plus Ultra» - це ідеальний вибір для фермерів, які шукають надійний та високоякісний сорт сої з високим вмістом білка та відмінною стійкістю до стресових умов та хвороб.
                </p>
                <p className="card__description-text">
                    Оберіть сою сорту «Plus Ultra» для високоякісного вирощування з високим вмістом білка та відмінним смаком продукції.
                </p>
                <div className="card__description-title">Характеристики:</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сорт:</div>
                        <div className="characteristic__value">«Plus Ultra»</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Тип:</div>
                        <div className="characteristic__value">Соя</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вегетаційний період:</div>
                        <div className="characteristic__value">80 - 85 днів</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Висота рослин:</div>
                        <div className="characteristic__value">80-100 см</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вміст білка:</div>
                        <div className="characteristic__value">38-42%</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Врожайність:</div>
                        <div className="characteristic__value">3 т/га</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">CHU:</div>
                        <div className="characteristic__value">2250</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Потенціал (т):</div>
                        <div className="characteristic__value">5</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Маса 1000 нас.:</div>
                        <div className="characteristic__value">193</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Схожість:</div>
                        <div className="characteristic__value">90%</div>
                    </div>
                </div>
                <div className="aside__title">Норма висіву</div>
                <div className="aside__content">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Норма висіву (тис. насінин):</div>
                        <div className="characteristic__value">550</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Норма висіву (кг):</div>
                        <div className="characteristic__value">125</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Реком. міжряддя (см):</div>
                        <div className="characteristic__value">15-35</div>
                    </div>
                </div>
                <div className="aside__title">Стійкість до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до осипання:</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до хвороб:</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Рекомендований протруйник</div>
                        <div className="characteristic__value">"Вайбранс RFC"</div>
                    </div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block"><span className="aside__block--bold">Адаптація:</span> Сорт «Plus Ultra» добре адаптується до різних кліматичних умов, що робить його популярним серед фермерів з різних регіонів.</div>
                    <div className="aside__block"><span className="aside__block--bold">Стійкість до хвороб:</span> Цей сорт сої відзначається високою стійкістю до багатьох хвороб, що дозволяє зменшити використання хімічних обробок та збільшити екологічність вирощування.</div>
                    <div className="aside__block"><span className="aside__block--bold">Технологічність:</span> Насіння сорту «Plus Ultra» має високу технологічність, що дозволяє з легкістю використовувати його для виробництва соєвого борошна, соєвого масла та інших продуктів.</div>
                    <div className="aside__block"><span className="aside__block--bold">Стійкість до стресів:</span> Рослини цього сорту відзначаються високою стійкістю до стресових умов, що дозволяє забезпечити стабільний врожай навіть у негативних умовах.</div>
                </div>
            </div>
        </div>
    );
}
