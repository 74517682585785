import "./Privacy.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";

export default function Privacy() {
    return (
        <div className="privacy">
            <Helmet>
                <meta
                    name="keywords"
                    content="Політика, Політика конфіденційності, Санберрі Україна"
                />
                <title>Політика конфіденційності від компанії Санберрі Україна</title>
                <link rel="canonical" href="https://sun-ukr.com/privacy" />
                <meta
                    name="description"
                    content="Інформація, наведена на сайті, є довідковою. Публічна оферта ТОВ «Санберрі Україна» міститься за посиланням: https://sun-ukr.com/privacy"
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Політика конфіденційності</span>
            </div>
            <div className="privacy__container">
                <h1 className="privacy__title">Політика конфіденційності</h1>
                <div className="privacy__block">
                    <p className="privacy__caption">
                        Конфіденційність користувачів нашого веб-сайту sun-ukr.com має
                        велике значення для нас, і ми докладаємо всіх зусиль для
                        забезпечення її захисту. Дані правила пояснюють, як ми
                        використовуємо вашу персональну інформацію.
                    </p>
                    <p className="privacy__caption">
                        Погоджуючись із використанням файлів-cookie при першому відвідуванні
                        нашого веб-сайту sun-ukr.com, відповідно до положень даних Правил,
                        ви надаєте нам дозвіл на використання файлів-cookie при кожному
                        вашому наступному візиті.
                    </p>
                    <p className="privacy__subject">Права інтелектуальної власності</p>
                    <p>
                        Цей документ був створений з використанням шаблона із сайту SEQ
                        Legal та модифікований веб-сайтом Website Planet
                    </p>
                    <p className="privacy__subject">Збір персональних даних</p>
                    <p>
                        Збору, зберіганню та використанню підлягають наступні типи
                        персональних даних:
                    </p>
                    <ol className="privacy__list">
                        <li className="privacy__item">
                            Інформація про ваш комп’ютер, включаючи вашу IP-адресу,
                            географічне розташування, тип і версію браузера та операційну
                            систему;
                        </li>
                        <li className="privacy__item">
                            Інформація про ваші відвідування та використання цього веб-сайту,
                            включаючи реферальні джерела, протяжність візитів, переглянуті
                            сторінки та шляхи навігації по сайту;
                        </li>
                        <li className="privacy__item">
                            Інформація про адресу вашої електронної пошти, яку ви використали
                            для реєстрації на нашому веб-сайті;
                        </li>
                        <li className="privacy__item">
                            Інформація, яку ви ввели під час створення заявки на нашому
                            веб-сайті – наприклад, ваше ім’я, прізвище, контактні дані;
                        </li>
                        <li className="privacy__item">
                            Інформація, така, як ваше ім’я та адреса електронної пошти, які ви
                            вказали під час оформлення заявки;
                        </li>
                        <li className="privacy__item">
                            Інформація, яка генерується при використанні нашого веб-сайту,
                            включаючи інформацію про час, частоту та умови його використання;
                        </li>
                        <li className="privacy__item">
                            Інформація, що міститься в будь-яких повідомленнях, які ви
                            надсилали нам електронною поштою або через наш веб-сайт, включаючи
                            зміст повідомлення та метадані;
                        </li>
                        <li className="privacy__item">
                            Будь-яка інша персональна інформація, яку ви надіслали нам. Перед
                            тим, як розкрити для нас персональну інформацію третьої особи, ви
                            маєте отримати згоду цієї особи як на розкриття, так і на обробку
                            цієї інформації у відповідності до даних правил.
                        </li>
                    </ol>
                    <p>
                        Перед тим, як розкрити для нас персональну інформацію третьої особи,
                        ви маєте отримати згоду цієї особи як на розкриття, так і на обробку
                        цієї інформації у відповідності до даних правил.
                    </p>
                    <p className="privacy__subject">
                        Використання вашої персональної інформації
                    </p>
                    <p className="privacy__caption">
                        Персональна інформація, яку ви передані нам через наш веб-сайт, буде
                        використовуватися за для цілей, зазначених в цих правилах або на
                        відповідних сторінках веб-сайту. Ми можемо використовувати вашу
                        персональну інформацію в наступних цілях:
                    </p>
                    <ol className="privacy__list">
                        <li className="privacy__item">персоналізація нашого веб-сайту для вас;</li>
                        <li className="privacy__item">
                            надання вам можливості користуватися сервісами, доступними на
                            нашому веб-сайті;
                        </li>
                        <li className="privacy__item">надсилання вам товарів, придбаних через наш-веб-сайт;</li>
                        <li className="privacy__item">надання вам послуг, придбаних через наш веб-сайт;</li>
                        <li className="privacy__item">надсилання вам не маркетингових комерційних повідомлень;</li>
                        <li className="privacy__item">
                            надсилання вам електронною поштою повідомлень, які ви спеціально
                            запросили;
                        </li>
                        <li className="privacy__item">
                            надсилання вам електронної розсилки, якщо ви її замовили (ви в
                            будь-який момент можете повідомити нас, що більше не бажаєте
                            отримувати електронні розсилки від нас);
                        </li>
                        <li className="privacy__item">
                            надсилання вам маркетингових повідомлень стосовно нашої ділової
                            активності або ділової активності старанно відібраних сторонніх
                            компаній, яка, на нашу думку, може вас зацікавити, шляхом
                            публікацій або, якщо ви конкретно надали на це згоду – шляхом
                            надсилання електронної пошти або за рахунок використання подібних
                            технологій (ви в будь-який момент можете повідомити нас, що більше
                            не бажаєте отримувати маркетингові повідомлення);
                        </li>
                        <li className="privacy__item">
                            надання стороннім компаніям статистичної інформації про наших
                            користувачів (проте, ці сторонні компанії не матимуть змоги
                            ідентифікувати жодного окремого користувача з цих даних);
                        </li>
                        <li className="privacy__item">
                            обробка запитів та скарг, зроблених вами або на вас, і які
                            стосуються нашого веб-сайту;
                        </li>
                        <li className="privacy__item">
                            з метою забезпечення безпеки нашого сайту та попередження
                            шахрайства;
                        </li>
                        <li className="privacy__item">
                            з метою перевірки відповідності умовам та правилам, які
                            регламентують використання нашого веб-сайту (включаючи моніторинг
                            приватних повідомлень, надісланих через сервіс приватних
                            повідомлень нашого веб-сайту);
                        </li>
                        <li className="privacy__item">та в інших цілях.</li>
                    </ol>
                    <p className="privacy__caption">
                        Якщо ви надали персональну інформацію для публікації на нашому
                        веб-сайті, ми опублікуємо її. В іншому випадку, ми
                        використовуватимемо цю інформацію у відповідності до ліцензії, яку
                        ви нам надали.
                    </p>
                    <p className="privacy__caption">
                        Ваші налаштування конфіденційності можуть використовуватись для
                        обмеження публікації ваших персональних даних на нашому веб-сайті, і
                        можуть регулюватися за допомогою засобів управління конфіденційністю
                        на веб-сайті.
                    </p>
                    <p className="privacy__caption">
                        Без вашої чітко вираженої згоди ми не будемо передавати вашу
                        персональну інформацію жодній сторонній компанії для прямого
                        маркетингового використання цією, або будь-якою іншою сторонньою
                        компанією.
                    </p>
                    <p className="privacy__subject">Розкриття персональної інформації</p>
                    <p className="privacy__caption">
                        Ми лишаємо за собою право розкрити вашу персональну інформацію для
                        будь-якого з наших працівників, керівників, страхувальників,
                        професійних радників, агентів, постачальників або субпідрядників, в
                        об’ємі та цілях, визначених в цих правилах.
                    </p>
                    <p className="privacy__caption">
                        Ми за собою право розкрити вашу персональну інформацію для
                        будь-якого члена нашої групи компаній (сюди входять наші дочірні
                        компанії, наша кінцева холдингова компанія та всі її дочірні
                        компанії) в об’ємі та цілях, визначених в цих правилах.
                    </p>
                    <p>Ми лишаємо за собою право розкрити вашу персональну інформацію:</p>
                    <ol className="privacy__list">
                        <li className="privacy__item">в тих випадках, в яких цього від нас вимагає закон;</li>
                        <li className="privacy__item">
                            у зв’язку з будь-яким поточними або майбутніми судовими процесами;
                        </li>
                        <li className="privacy__item">
                            з метою встановлення, реалізації або захисту наших законних прав
                            (включаючи надання інформації іншим сторонам задля запобігання
                            шахрайству або зниження кредитних ризиків);
                        </li>
                        <li className="privacy__item">
                            покупцеві (або потенційному покупцеві) будь-якого бізнесу або
                            активів, які ми продаємо (або збираємося продати); та
                        </li>
                        <li className="privacy__item">
                            будь-якій особі, яка, як ми обґрунтовано вважаємо, може подати
                            запит до суду або іншого уповноваженого органу про розкриття цих
                            персональних даних і, на нашу обґрунтовану думку, цей суд або
                            уповноважений орган надасть розпорядження на розкриття цих
                            персональних даних.
                        </li>
                    </ol>
                    <p>
                        Ми не будемо розкривати вашу персональну інформацію третім особам,
                        за виключенням випадків, зазначених в цих правилах.
                    </p>
                    <p className="privacy__subject">
                        Міжнародні передачі персональної інформації
                    </p>
                    <ol className="privacy__list">
                        <li className="privacy__item">
                            Інформація, яку ми збираємо, може зберігатися, оброблятися та
                            передаватися між будь-якими країнами, в яких ми здійснюємо свою
                            діяльність, з метою надання нам можливості використовувати цю
                            інформацію у відповідності з цими правилами.
                        </li>
                        <li className="privacy__item">
                            Інформація, яку ми збираємо, може бути передана в наступні країни,
                            де немає законів із захисту даних, аналогічних тим, що діють на
                            території Європейської Економічної Зони: США, Росія, Японія, Китай
                            та Індія.
                        </li>
                        <li className="privacy__item">
                            Персональна інформація, які ви публікуєте на нашому веб-сайті,
                            через Інтернет, може бути доступна в усьому світі. Ми не можемо
                            перешкодити її використанню, або неправомірному використанню в
                            злочинних цілях, сторонніми особами.
                        </li>
                        <li className="privacy__item">
                            Погоджуючись з цими правилами, ви надаєте згоду на передачу вашої
                            персональної інформації, зазначеної в розділі Е.
                        </li>
                    </ol>
                    <p className="privacy__subject">Збереження персональної інформації</p>
                    <ol className="privacy__list">
                        <li className="privacy__item">
                            Розділ Є встановлює правила та процедури компанії щодо збереження
                            персональної інформації. Дані правила та процедури призначені для
                            забезпечення виконання нами наших юридичних зобов’язань щодо
                            збереження та видалення персональної інформації.
                        </li>
                        <li className="privacy__item">
                            Персональна інформація, яку ми обробляємо з певною метою або в
                            певних цілях не повинна зберігатися довше, ніж потрібно для
                            досягнення цієї мети або цих цілей.
                        </li>
                        <li className="privacy__item">
                            Без обмежень положень, зазначених в пункті Є-2, ми зазвичай
                            видаляємо персональну інформацію.
                        </li>
                        <li className="privacy__item">
                            Незважаючи на інші положення Розділу Є, ми будемо зберігати
                            документи (включаючи електронні документи), які містять
                            персональну інформацію:
                            <ol>
                                <li className="privacy__item">в тих випадках, в яких цього від нас вимагає закон;</li>
                                <li className="privacy__item">
                                    якщо ми вважатимемо, що ці документи можуть мати відношення до
                                    будь-якого поточного або майбутнього судового розгляду;
                                </li>
                                <li className="privacy__item">
                                    та з метою встановлення, реалізації або захисту наших законних
                                    прав (включаючи надання інформації іншим сторонам задля
                                    запобігання шахрайству або зниження кредитних ризиків).
                                </li>
                            </ol>
                        </li>
                    </ol>
                    <p className="privacy__subject">
                        Захист вашої персональної інформації
                    </p>
                    <ol className="privacy__list">
                        <li className="privacy__item">
                            Ми будемо вживати достатні технічні та організаційні заходи для
                            попередження втрати, протиправного використання чи підробки вашої
                            персональної інформації.
                        </li>
                        <li className="privacy__item">
                            Всю надану вами персональну інформацію ми будемо зберігати на
                            наших захищених (як паролем, так і фаєрволами) серверах.
                        </li>
                        <li className="privacy__item">
                            Ви підтверджуєте своє ознайомлення з тим фактом, що передача
                            інформації через Інтернет є по суті є незахищеною, і ми не можемо
                            гарантувати захист даних, надісланих через всесвітню мережу.
                        </li>
                    </ol>
                    <p className="privacy__subject">Зміни та поправки</p>
                    <p className="privacy__caption" >
                        Ми лишаємо за собою право періодично вносити зміни та поправки в ці
                        правила, та публікувати їх нову редакцію на нашому сайті. Ви повинні
                        періодично перевіряти цю веб-сторінку, щоб пересвідчитись, що
                        розумієте зміст змін, внесених до цих правил.
                    </p>
                    <p className="privacy__subject">Ваші права</p>
                    <p>
                        Ви можете надати нам вказівку надавати вам будь-яку персональну
                        інформацію про вас, яку ми маємо; надання такої інформації буде
                        здійснюватись в наступних випадках:
                    </p>
                    <ol className="privacy__list">
                        <li className="privacy__item">сплата рахунків що будуть вислані на вашу пошту;</li>
                        <li className="privacy__item">
                            надання відповідних підтверджень вашої особи ( оригінальну копію
                            рахунку на сплату за товари для підтвердження вашої сплати)
                        </li>
                    </ol>
                    <p>
                        Ми лишаємо за собою відмовити в наданні інформації за вашим запитом,
                        в межах чинного законодавства.
                    </p>
                    <p>
                        Ви маєте право надати нам вказівку не обробляти вашу персональну
                        інформацію в маркетингових цілях.
                    </p>
                    <p className="privacy__caption">
                        На практиці, ви, зазвичай, або завчасно погоджуєтесь з тим, щоб ви
                        використовували вашу персональну інформацію в маркетингових цілях,
                        або ми надамо вам можливість відмовитися від використання вашої
                        інформації в маркетингових цілях.
                    </p>
                    <p className="privacy__subject">Сторонні веб-сайти</p>
                    <p className="privacy__caption" >
                        Наш веб-сайт містить гіперпосилання на, та деталі про веб-сайти
                        сторонніх компаній та осіб. Ми не маємо інструментів керування, та
                        не несемо відповідальності за політику конфіденційності й практики
                        сторонніх осіб та компаній в цій галузі.
                    </p>
                    <p className="privacy__subject">Оновлення інформації</p>
                    <p>
                        Будь-ласка, своєчасно повідомляйте нас, якщо ваша персональна
                        інформація, яка знаходиться у нас, потребує оновлення чи виправлень.
                    </p>
                    <p className="privacy__subject">Файли-Cookies</p>
                    <p className="privacy__caption">
                        Наш веб-сайт використовує файли-cookies. Cookie — це файл, що
                        містить ідентифікатор (стрічку, яка складається з літер та цифр), і
                        який надсилається веб-сервером до веб-браузеру, та зберігається
                        браузером. В подальшому, ідентифікатор надсилається назад на сервер
                        кожного разу, коли браузер запитує веб-сторінку з серверу.
                        Файли-cookies можуть бути або «постійними» або «сеансові»: постійні
                        будуть зберігатися браузером та будуть придатними до завершення
                        терміну дії, якщо тільки не будуть видалені користувачем завчасно;
                        «сеансові» навпаки – будуть видалятися після завершення сеансу
                        роботи з сайтом або після закриття браузера. Файли-cookies зазвичай
                        не містять жодної інформації, яка ідентифікує особу користувача.
                        Проте, ваша персональна інформація, яку ми маємо, може бути
                        пов’язана з інформацією, що зберігається та отримана від
                        файлів-cookies. На нашому веб-сайті ми використовуємо лише сеансові
                        файли-cookies / лише постійні файли-cookies / як постійні, так і
                        сеансові файли-cookies.
                    </p>
                    <ol className="privacy__list">
                        <li className="privacy__item">
                            Назви файлів-cookies, які ми використовуємо на нашому веб-сайті,
                            та цілі, для яких вони використовуються, зазначені нижче:
                            <ol className="privacy__list">
                                <li className="privacy__item">
                                    На нашому веб-сайті ми використовуємо Google Analytics для
                                    <ul>
                                        <li className="privacy__item">
                                            розпізнавання комп’ютера, коли користувач відвідує
                                            веб-сайт
                                        </li>
                                        <li className="privacy__item">відстеження навігації користувачів по веб-сайту</li>
                                        <li className="privacy__item">для вдосконалення зручності користування сайтом</li>
                                        <li className="privacy__item">аналізу використання веб-сайту</li>
                                        <li className="privacy__item">адміністрування сайту</li>
                                        <li className="privacy__item">
                                            попередження шахрайства та вдосконалення безпеки сайту
                                        </li>
                                        <li className="privacy__item">персоналізація сайту для кожного користувача</li>
                                        <li className="privacy__item">
                                            цільова реклама, яка може бути цікава окремим користувачам
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li className="privacy__item">
                            Більшість браузерів надають вам можливість відмовитися від
                            використання файлів-cookies, наприклад:
                            <ol className="privacy__list">
                                <li className="privacy__item">
                                    в Internet Explorer (версія 10) ви можете заблокувати
                                    використовуючи налаштування керування файлами-cookie,
                                    доступними в меню «Інструменти» – «Опції Інтернету» –
                                    «Конфіденційність» – «Розширені» ( “Tools,” “Internet
                                    Options,” “Privacy,” “Advanced”);
                                </li>
                                <li className="privacy__item">
                                    у Firefox (версія 24) ви можете заблокувати всі файли-cookie,
                                    натиснувши «Інструменти» – «Опції» – «Конфіденційність»: у
                                    випадаючому меню оберіть пункт «Використовувати користувацькі
                                    налаштування журналу» та зніміть виділення з пункту «Прийняти
                                    файли-cookie від сайтів»;
                                </li>
                                <li className="privacy__item">
                                    та нарешті в Chrome (версія 29) ви можете заблокувати всі
                                    файли-cookie увійшовши до меню «Налаштування та управління»,
                                    та обравши «Налаштування» – «Відобразити розширені
                                    налаштування» та «Налаштування контенту», а потім обравши
                                    «Заборонити сайтам надсилати будь-які дані» під заголовком
                                    «Cookies».
                                </li>
                            </ol>
                        </li>
                        <p>
                            Блокування всіх файлів-cookiе матиме негативні наслідки на
                            зручність користування багатьма веб-сайтами. Якщо ви заблокуєте
                            файли-cookie, ви не зможете користуватися багатьма функціями
                            нашого веб-сайту.
                        </p>
                        <li className="privacy__item">
                            Ви можете видалити файли-cookie, які вже зберігаються на вашому
                            комп’ютері, наприклад:
                            <ol className="privacy__list">
                                <li className="privacy__item">
                                    в Internet Explorer (версія 10), ви маєте видаляти
                                    файли-cookie вручну (інструкцію, як це зробити, можна знайти
                                    на сайті microsoft);
                                </li>
                                <li className="privacy__item">
                                    у Firefox (версія 24), файли-cookie можна видалити перейшовши
                                    в меню «Інструменти» – «Опції» – «Конфіденційність»: у
                                    випадаючому меню оберіть пункт «Використовувати користувацькі
                                    налаштування журналу», натисніть “Показати Cookies,” а потім –
                                    “Видалити всі Cookies”;
                                </li>
                                <li className="privacy__item">
                                    в Chrome (версія 29) ви можете видалити всі файли-cookie
                                    увійшовши до меню «Налаштування та управління», та обравши
                                    «Налаштування» – «Відобразити розширені налаштування» та
                                    «Очистити дані перегляду», а перед тим оберіть пункт «Видалити
                                    файли-cookie та інші дані й плагіни сайтів».
                                </li>
                            </ol>
                        </li>
                        <li className="privacy__item">
                            Видалення файлів-cookiе матиме негативні наслідки на зручність
                            користування багатьма веб-сайтами.
                        </li>
                    </ol>
                </div>
            </div>
            <Footer />
        </div>
    );
}
