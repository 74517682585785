import './styles.scss';
import React from 'react';

const paragraphFirst = 'Це рідке комплексне органо-мінеральне добриво на основі гумінових та фульвових кислот з додаванням мікроелементів.'
const paragraphSecond = 'Це енергетичний біокомплекс, мінеральне добриво на основі трьох головних комнонентів для любої рослини  Азота, Фосфора та Калія, з додаванням гуматів та мікроелементів.'
const paragraphThird = 'Залишіть заявку на зворотній зв’язок, для того щоб наш менеджер з вами зв’язався. Всі інші питання будуть вирішені в телефоній розмові та не займуть більше 5 хвилин.'
const paragraphFourst = 'Так. Наша компанія співпрацює з усіма видами перевідзників (Нова Пошта, Делівері, САТ, Інтайм, Укрпошта, особисті автоперевезення та інші.)'
const paragraphFifth = 'Кожні 2 дні'
const paragraphSixth1 = '1. Наша система знижок прямо залежить від об’єму, замовленого вами товару. Знижки починаються від 100 л замовленого добрива "Агроболік", від 10 т добрива “Агротеїн”, від 3 кубів РКД та від 50 посівних одиниць;'
const paragraphSixth2 = '2. Надавши  відгук з фото або відео про нашу компанію та товари ви отримаєте  персональну знижку на наступне замовлення;'
const paragraphSixth3 = '3. Порадьте нашу компанію іншим людям та отримайте персональну знижку на наступне замовлення. лив хімічних речовин з мінеральних добрив;'

const data = [
    {
        title: 'Що таке "Агроболік" ?',
        paragraphFirst
    },
    {
        title: 'Що таке "Агротеїн" ?',
        paragraphSecond
    },
    {
        title: 'Я хочу спробувати добриво "Агроболік", що мені зробити ?',
        paragraphThird
    },
    {
        title: 'Можете відправити товар "Новою Поштою" ?',
        paragraphFourst
    },
    {
        title: 'Коли часто відбувається відправка товару?',
        paragraphFifth
    },
    {
        title: 'Як отримати знижку?',
        paragraphSixth1,
        paragraphSixth2,
        paragraphSixth3
    }
]

export default class FAQ extends React.Component {
    render() {
        return (
            <div {...{ className: 'wrapper' }}>
                <ul {...{ className: 'accordion-list' }}>
                    {data.map((data, key) => {
                        return (
                            <li {...{ className: 'accordion-list__item', key }}>
                                <AccordionItem {...data} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
}

class AccordionItem extends React.Component {
    state = {
        opened: false
    }

    render() {
        const {
            props: {
                paragraphFirst,
                paragraphSecond,
                paragraphThird,
                paragraphFourst,
                paragraphFifth,
                paragraphSixth1,
                paragraphSixth2,
                paragraphSixth3,
                title
            },
            state: {
                opened
            }
        } = this

        return (
            <div
                {...{
                    className: `accordion-item, ${opened && 'accordion-item--opened'}`,
                    onClick: () => { this.setState({ opened: !opened }) }
                }}
            >
                <div {...{ className: 'accordion-item__line' }}>
                    <h3 {...{ className: 'accordion-item__title' }}>
                        {title}
                    </h3>
                    <span {...{ className: 'accordion-item__icon' }} />
                </div>
                <div {...{ className: 'accordion-item__inner' }}>
                    <div {...{ className: 'accordion-item__content' }}>
                        <p {...{ className: 'accordion-item__paragraph' }}>
                            {paragraphFirst}
                            {paragraphSecond}
                            {paragraphThird}
                            {paragraphFourst}
                            {paragraphFifth}
                            {paragraphSixth1}
                            {paragraphSixth2}
                            {paragraphSixth3}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}
