import goodsPagePlusUltra from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageAnna from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageMidas from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageAG0702 from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageAG0506 from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageApollo from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageSofia from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageKansas from "../../image/goods.image/soy/goods.page.soy.png";
import goodsPageTrio from "../../image/goods.image/soy/goods.page.soy.png";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";


export const items = [
    {
        title: "Насіння сої «Plus Ultra»",
        priceFirst: '42 000 грн',
        priceSecond: '1 050 грн',
        containerFirst: '1т',
        containerSecond: '25кг',
        producer: "США",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPagePlusUltra} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "plusUltra",
        id: "1"
    },
    {
        title: "Насіння сої «Anna»",
        priceFirst: '34 000 грн',
        priceSecond: '850 грн',
        containerFirst: '1т',
        containerSecond: '25кг',
        producer: "США",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageAnna} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "anna",
        id: "2"
    },
    {
        title: "Насіння сої «Midas»",
        priceFirst: '42 000 грн',
        priceSecond: '1 050 грн',
        containerFirst: '1т',
        containerSecond: '25кг',
        producer: "США",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageMidas} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "midas",
        id: "3"
    },
    {
        title: "Насіння сої «AG 0702»",
        priceFirst: '26 000 грн',
        priceSecond: '1 040 грн',
        containerFirst: '1т',
        containerSecond: '40кг',
        producer: "Канада",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageAG0702} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "AG0702",
        id: "4"
    },
    {
        title: "Насіння сої «AG 0506»",
        priceFirst: '26 000 грн',
        priceSecond: '1 040 грн',
        containerFirst: '1т',
        containerSecond: '40кг',
        producer: "Канада",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageAG0506} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "AG0506",
        id: "5"
    },
    {
        title: "Насіння сої «Apollo»",
        priceFirst: '24 000 грн',
        priceSecond: '960 грн',
        containerFirst: '1т',
        containerSecond: '40кг',
        producer: "Канада",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageApollo} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "apollo",
        id: "6"
    },
    {
        title: "Насіння сої «Sofia»",
        priceFirst: '26 000 грн',
        priceSecond: '1 300 грн',
        containerFirst: '1т',
        containerSecond: '50кг',
        producer: "Канада",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageSofia} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "sofia",
        id: "7"
    },
    {
        title: "Насіння сої «Kansas»",
        priceFirst: '28 000 грн',
        priceSecond: '1 120 грн',
        priceThird: '700 грн',
        containerFirst: '1т',
        containerSecond: '40кг',
        containerThird: '25кг',
        producer: "Канада",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageKansas} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "kansas",
        id: "8"
    },
    {
        title: "Насіння сої «Trio»",
        priceFirst: '26 000 грн',
        priceSecond: '1 040 грн',
        containerFirst: '1т',
        containerSecond: '40кг',
        producer: "Канада",
        InStock: true,
        imgUrl: <img className="itemCardSeed__image" src={goodsPageTrio} alt="goodsPage" />,
        isNew: true,
        link: <Link to="order"><Button /></Link>,
        inside: "trio",
        id: "9"
    }
];
