export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Аміачна селітра - представляє собою кристалічний порошок або гранули білого кольору з жовтуватим відтінком, сіль азотної кислоти. Відкриття цієї речовини датується 1659 р. і належить Глауберу. Гранульовану аміачну селітру застосовують у сільському господарстві в основне внесення, при посіві культур і для всіх видів підгодівлі. Містить до 35% азоту в амонійній і нітратної формах, обидві форми азоту легко засвоюються рослинами.
                </p>
                <div className="card__description-title">
                    Загальна інформація
                </div>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Селітра використовується з метою:
                    </p>
                    <li className="card__description-numbering">
                        стимулювання зростання рослини;
                    </li>
                    <li className="card__description-numbering">
                        формування стійкості до шкідливих чинників, на кшталт вилягання або при розповсюджені хвороби;
                    </li>
                    <li className="card__description-numbering">
                        підвищення якості врожаю.
                    </li>
                </ul>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Однією з переваг Селітри є високий рівень діючих речовин. Він складає 45 % від загальної маси. У процентному співвідношенні їхнє розподілення виглядає наступним чином:
                    </p>
                    <li className="card__description-numbering">
                        азот – 34 %;
                    </li>
                </ul>
                <p className="card__description-text">
                    У менших масштабах її використовують для виробництва вибухових речовин. Аміачна селітра – являються сильним окислювачем, у сухому вигляді може вибухає від детонаторів. До механічних впливів (удар, тертя) не чутлива. При дії вогню та іскри на аміачну селітру загоряння не відбувається. При пожежах вона розкладається, виділяючи кисень, що підсилює горіння легкозаймистих предметів. При сильних пожежах складів, що містять велику кількість селітри, можливі вибухи внаслідок її бурхливого термічного розкладання та інтенсивного газоутворення.
                </p>
                <p className="card__description-text">
                    Аміачна селітрадобре розчиняється у воді і має велику гігроскопічність (здатність поглинати вологу з повітря). Це може бути причиною того, що гранули добрива розпливаються, втрачають свою кристалічну форму, відбувається злежування добрив - сипучий матеріал перетворюється на тверду монолітну масу.
                </p>
                <p className="card__description-title">
                    Відомості для аграріїв
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Селітра може використовуватися в якості основного джерела живлення і допоміжного. Добриво є ефективним на різних типах рослин, серед яких:
                    </p>
                    <li className="card__description-numbering">
                        зернові;
                    </li>
                    <li className="card__description-numbering">
                        овочі;
                    </li>
                    <li className="card__description-numbering">
                        різноманітні кущі;
                    </li>
                    <li className="card__description-numbering">
                        квіти;
                    </li>
                    <li className="card__description-numbering">
                        дерева.
                    </li>
                </ul>
                <p className="card__description-text">
                    За фізіологічними властивостями аміачна селітра належить до кислих добрив. Якщо у ґрунту реакція рН нормальна, то він не буде окислюватися. Кислі ґрунти додатково вимагають внесення карбонату кальцію. На кожних 100 кг селітри беруть 75 кг карбонату кальцію. На кислих ґрунтах також необхідно поєднувати аміачну селітру і вапнякове борошно у рівних пропорціях.
                </p>
                <p className="card__description-title">
                    Застосування аміачної селітри
                </p>
                <p className="card__description-text">
                    Головна особливість аміачної селітри — це наявність азоту в двох  формах: нітратній і амонійній, де його концентрація досягає до 35%. Завдяки своїй рухливості, азот з аміачної селітри чудово підходить для внесення по мерзлоталому ґрунту, адже здатний одразу потрапляє до рослини. Селітра є швидкодіючим добривом, тому її краще вносити весною для підживлення озимих культур.
                </p>
                <p className="card__description-text">
                    Даний вид добрив є абсолютно універсальним та може використовуватися на будь-яких типах ґрунтів. Але варто пам’ятати, що за нестачі кальцію на кислих підзолистих ґрунтах внесення аміачної селітри може викликати підкислення ґрунтового розчину. На ґрунтах, багатих основами (сірих лісових і чорноземах), навіть систематичне внесення високих доз аміачної селітри підкислення ґрунтового розчину не викликає. Місцеве підкислення - явище тимчасове, але може негативно впливати на рослини на початку росту. Амонійна форма аміачної селітри піддається процесу нітрифікації, що також призводить до тимчасового підкислення ґрунту. Проте подальша денітрифікація призводить до переходу частини нітратного азоту в газоподібний стан, що є негативним ефектом.
                </p>
                <p className="card__description-text">
                    Використання аміачної селітри покращує забезпеченість рослин нітрогеном, що сприяє активному синтезу білків і органічних речовин у рослинах та наростанню їх вегетативних органів, тим самим забезпечуючи підвищення врожаю сільськогосподарських культур та його якісних показників, подовження терміну зберігання, транспортабельності та лежкості продукцiї. Найбільша ефективність досягається при використанні аміачної селітри для ранньовесняного підживлення, а також для внесення поверхнево чи локально до посіву або при посіві ярих культур та підживлення технічних. А ось до внесення на овочеві та баштанні культури потрібно ставитись з обережністю, тому що вона може сприяти накопиченню нітратів.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
