import "./Item.scss";
import logo from "../../image/logotype/logo.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import React from "react";

export default function Item(props) {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className={"item " + (props.isNew && 'item--new')}>
      <div className="test">
        <div className="item__title">
          <Link to={props.inside}>
            <span className="title">{props.title}</span>
          </Link>
          <span className="item__new-arr">НОВИНКА!</span>
        </div>
        <div className="test-1">
          <div className="item__image-container">
            <div className="item__image">{props.imgUrl}</div>
          </div>
          <div className="item__center">
            <div className="item__block">
              <img className="item__logo" src={logo} alt="logo" />
              <span className="item__factory-text">Виробник:</span>
              <span className="item__factory-name">Санберрі Україна</span>
            </div>
            <div className="item__zone">
              <div className="item__packing">
                <span className="item__packing-text">Фасування:</span>
                <div className="item__packing-position">
                  <div className="item__capacity-tabs">
                    <button
                      className={
                        toggleState === 1
                          ? "item__cap-tabs active-item__cap-tabs"
                          : "item__cap-tabs"
                      }
                      onClick={() => toggleTab(1)}
                    >
                      20л
                    </button>
                    <button
                      className={
                        toggleState === 2
                          ? "item__cap-tabs active-item__cap-tabs"
                          : "item__cap-tabs"
                      }
                      onClick={() => toggleTab(2)}
                    >
                      10л
                    </button>
                    <button
                      className={
                        toggleState === 3
                          ? "item__cap-tabs active-item__cap-tabs"
                          : "item__cap-tabs"
                      }
                      onClick={() => toggleTab(3)}
                    >
                      1л
                    </button>
                    <button
                      className={
                        toggleState === 4
                          ? "item__cap-tabs active-item__cap-tabs"
                          : "item__cap-tabs"
                      }
                      onClick={() => toggleTab(4)}
                    >
                      0,5л
                    </button>
                  </div>
                </div>
              </div>
              <div className="item__price">
                <div className="price__charts">
                  <span className="price__item">Роздрібна ціна</span>
                </div>
                <div className="price item__capacity-tabs-active">
                  <div
                    className={
                      toggleState === 1 ? "capacity  active-capacity" : "capacity"
                    }
                  >
                    {props.priceFirst}
                  </div>

                  <div
                    className={
                      toggleState === 2 ? "capacity  active-capacity" : "capacity"
                    }
                  >
                    {props.priceSecond}
                  </div>

                  <div
                    className={
                      toggleState === 3 ? "capacity  active-capacity" : "capacity"
                    }
                  >
                    {props.priceThird}
                  </div>

                  <div
                    className={
                      toggleState === 4 ? "capacity  active-capacity" : "capacity"
                    }
                  >
                    {props.priceFourth}
                  </div>
                </div>
                <div className={"item__stock " + (props.InStock && 'item__stock--new')}>
                  <span className="item__stock-arr">в наявності</span>
                  <span className="item__stock-road">немає в наявності</span>
                </div>
                <div className="item__suborder">
                  <div className="item__order">
                    {props.link}
                  </div>
                  <Link to={props.inside}>
                    <p className="item__desc">Детальніше</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
