import "./NotFound.scss";
import React from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const NotFound = () =>

    <div className="grid-block">
        <Header />
        <div className="grid-content">
            <div className="grid-content__error">
                <p>404</p>
            </div>
            <div className="grid-content__text">
                <p>Сторінка не знайдена</p>
                <p>На жаль, такої сторінки не існує</p>
            </div>
            <div className="grid-content__button">
                <Link to="/">
                    <button className="btn-outline-success-brio btn">
                        Повернутись на головну
                    </button>
                </Link>
            </div>
        </div>
        <Footer />
    </div>

export default NotFound;