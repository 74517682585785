export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    SY Telias: особливості та характеристики
                </p>
                <p className="card__description-text">
                    Ранньостиглий гібрид кукурудзи, який відзначається високими показниками врожайності та стійкістю до стресових умов, – це SY Telias. Представлений швейцарським виробником «Сингента». Його вирощують з метою отримання зерна, крупи та силосу. На початку вегетативного періоду швидко росте, має стабільно високий потенціал врожайності. Кукурудза «Теліас» добре показує себе при використанні різних технологій вирощування. Підходить для пересіву озимих навесні.
                </p>
                <p className="card__description-text">
                    Гібрид належить до середньораннього типу зрілості. Високоадаптивний, дає зерно проміжного типу, найближче до зубоподібного. Належить до еректоїдного типу розміщення листя, отже процес фотосинтезу в рослині відбувається швидше. В зерні міститься близько 72,4-73,5% крохмалю та 8,8-9,7% білку. Вирощувати можна в усіх кліматичних регіонах України.
                </p>
                <p className="card__description-title">
                    Головні переваги гібрида
                </p>
                <p className="card__description-text">
                    Для вирощування рослини найкраще підходять поля з агрофоном середнього та високого рівнів. Коренева система «Телас» дуже міцна. Садити гібрид можна де завгодно, адже він стійкий до прикореневого та стеблового вилягання. До інших переваг варто віднести:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">високу витривалість. Посівний матеріал має високу толерантність до холоду, не боїться посухи. Йому не страшні різкі температурні коливання, тому це один з універсальних сортів;</li>
                    <li className="card__description-numbering">стійкість до пухирчастої сажки;</li>
                    <li className="card__description-numbering">несприйнятливість до кореневої та стеблової гнилі;</li>
                    <li className="card__description-numbering">толерантність до гельмінтоспоріозу й фузаріозу.</li>
                </ul>
                <p className="card__description-text">
                    Насіння найкраще росте в зонах степу, лісостепу та Полісся, хоча його можна вирощувати будь-де. Купити кукурудзу «Телас» у Вінниці пропонує компанія ТОВ «Санберрі Україна». Це виробник комплексних мінеральних добрив для різних сільськогосподарських культур. Пропонує також насіння різних гібридів соняшника.
                </p>
                <p className="card__description-title">
                    Які вимоги до вирощування
                </p>
                <p className="card__description-text">
                    Гібрид добре підходить для раннього засівання. Оптимальною температурою на глибині загортання насіння є +6-8 градусів. Кукурудза «Телас» має попит у фермерських господарствах завдяки дуже високій холодостійкості та вологовіддачі, а також швидким темпам початкового розвитку. Урожай збирають також в оптимально ранній термін.
                </p>
                <p className="card__description-text">
                    Насіння продається обробленим протруйником від шкідників і без додаткового оброблення. Для правильного вирощування й уникнення складнощів не варто використовувати високі норми гербіцидів, які належать до сульфонілсечовин.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Холодостійкість</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
