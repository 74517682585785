import "./Contact.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Vinnitsia from "../Contact/Vinnytsia";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";

export default function Contact() {

	function ScrollToTopOnMount() {
		useEffect(() => {
			window.scrollTo(0, 0);
		}, []);

		return null;
	}

	return (
		<div className="contact">
			<Helmet>
				<meta name="keywords" content="контакти, головний офіс, Санберрі Україна, Головний офіс компанії Санберрі Україна" />
				<title>Контакти виробника добрив Санберрі Україна</title>
				<link rel="canonical" href="https://sun-ukr.com/contact" />
				<meta name="description" content="Контакти Санберрі Україна - м. Вінниця, вулиця Сергія Зулінського, 43 ☎️ +38(068)-369-90-30."></meta>
			</Helmet>
			<ScrollToTopOnMount />
			<Header />
			<div className="crumbs">
				<Link className="crumbs__link" to="/">
					<img className="crumbs__logo" src={crumbs} alt="crumbs" />
				</Link>
				<span>/ Контакти</span>
			</div>
			<div className="contact__title">
				<h1>Головний офіс компанії Санберрі Україна</h1>
			</div>
			<Vinnitsia />
			<Footer />
		</div>
	);
}
