import "./About.scss";
import First from "../RandomNumbers/First";
import Second from "../RandomNumbers/Second";
import Third from "../RandomNumbers/Third";
import Fours from "../RandomNumbers/Fours";
import logo from "../../image/logotype/logotype.svg";
import logo1 from "../../image/logotype/logo.svg";
import contract from "../../image/Company/contract.png";
import recycling from "../../image/Company/recycling.png";
import money from "../../image/Company/wallet-with-card-sticking-out.png";
import speed from "../../image/Company/speedometer.png";
import timer from "../../image/Company/timer.png";
import calendar from "../../image/Company/calendar.png";
import React from "react";

export default function About() {
    return (
        <div className="about__container">
            <div className="about__first-block">
                <div className="about__logo logo">
                    <img className="logo__photo" src={logo} alt="logo" />
                    <img className="logo__photo--mobile" src={logo1} alt="logo" />
                </div>
                <div className="about__information about">
                    <h1 className="about__header">Cанберрі Україна</h1>
                    <p>
                        Cанберрі Україна – це український виробник екологічних
                        органо-мінеральних добрив. Наші розробки є унікальними, базуються на
                        сучасних знаннях та враховують всі потреби вирощуваної культури.
                        Краще підтвердження ефективності мінеральних добрив від Sunberry
                        Ukraine – щорічне зростання обсягів виробництва та реалізації
                        власної продукції вже понад 9 років.
                    </p>
                    <p>
                        Секрет такого успіху – відповідальність. Ми виготовляємо комплексні добрива з високоякісних
                        імпортних складових та здійснюємо тестування власних розробок у
                        господарстві. З нашими NPK-комплексами можна вирощувати широкий
                        спектр культур – від зернових до плодово-овочевих.
                    </p>
                    <div className="quote">
                        <span className="quote-up">
                            «Наша головна мета - здійснити вашу мрію якісно, в потрібні
                            терміни та за доступною ціною.»
                        </span>
                        <span className="quote-down">
                            Захарчук Юрій - засновник Sunberry
                        </span>
                    </div>
                </div>
            </div>
            <div className="number">
                <First />
                <Second />
                <Third />
                <Fours />
            </div>
            <div className="about__second-block">
                <div className="info_text">
                    <h2 className="info_text-position">Переваги співпраці з нами:</h2>
                </div>
                <div className="info_block">
                    <div className="info_item">
                        <img className="info_photo" src={contract} alt="contract" />
                        <h3>Індивідуальний підхід</h3>
                        <div className="info_desc">
                            Під кожен вид культур підберемо свій унікальний склад вітамінів
                        </div>
                        <div className="info_number">01</div>
                    </div>
                    <div className="info_item">
                        <img className="info_photo" src={calendar} alt="calendar" />
                        <h3>Гарант надійності</h3>
                        <div className="info_desc">
                            Високий склад гумінових і фулевих кислот дозволяють гарантувати
                            Вам максимальний результат
                        </div>
                        <div className="info_number">02</div>
                    </div>
                    <div className="info_item">
                        <img className="info_photo" src={timer} alt="timer" />
                        <h3>Заощадження ваших грошей та часу</h3>
                        <div className="info_desc">
                            10 літрів нашого продукту дорівнює 20 тонам гною при ціні в 10
                            разів нижче
                        </div>
                        <div className="info_number">03</div>
                    </div>
                </div>
                <div className="info_block-two">
                    <div className="info_item">
                        <img className="info_photo" src={recycling} alt="recycling" />
                        <h3>Повністю екологічний продукт</h3>
                        <div className="info_desc">
                            Матеріали повністю екологічні і не несуть ніякої шкоди рослинам
                        </div>
                        <div className="info_number">04</div>
                    </div>
                    <div className="info_item">
                        <img className="info_photo" src={speed} alt="speed" />
                        <h3>Оперативність</h3>
                        <div className="info_desc">
                            Можливість замовлення великих обсягів в найкоротші терміни
                        </div>
                        <div className="info_number">05</div>
                    </div>
                    <div className="info_item">
                        <img className="info_photo" src={money} alt="money" />
                        <h3>Найвигідніші умови</h3>
                        <div className="info_desc">
                            Готовність працювати на будь-яких умовах
                        </div>
                        <div className="info_number">06</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
