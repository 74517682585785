import "../BlogPagesStyle.scss";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import crumbs from "../../../../icons/icons8-home.svg";
import cucumber from "../../../../image/blog/stock-photo-cucumbers-removebg-preview.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { item } from '../../../mockData/goods-data';
import Item from '../../../Item/Item';
import React from "react";

export default class BlogCucumber extends React.Component {
    constructor(props) {
        super(props);
        this.item = item;
    }

    render() {
        return (
            <div className="culture">
                <Helmet>
                    <meta
                        name="keywords"
                        content="Добрива для огірків, огірки, рекомендації та норми внесення добрив, добрива, купити"
                    />
                    <title>Добрива для огірків • Оптом і в роздріб | Sunberry Ukraine</title>
                    <link rel="canonical" href="https://sun-ukr.com/cucumber" />
                    <meta
                        name="description"
                        content="🌱Добрива для огірків ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                    ></meta>
                </Helmet>
                <Header />
                <div className="crumbs">
                    <Link className="crumbs__link" to="/">
                        <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                    </Link>
                    <Link className="crumbs__link" to="blog">
                        <span>/ Блог</span>
                    </Link>
                    <span className="crumbs__text">/ Добрива для огірків</span>
                </div>
                <div className="culture__title">
                    <h1>Добрива для огірків</h1>
                </div>
                <div className="culture__container">
                    <div className="culture__content">
                        <p className="blog__timeline"> Дата публікації: 15.09.2022</p>
                        <p className="culture__heading">Своєчасне підживлення огірків — основа гарного врожаю</p>
                        <p className="culture__desc">
                            Кожен з городників, хто взявся за вирощування цих культур, прагне отримати гарний врожай. Але досягти цього не так просто, адже доводиться враховувати багато різноманітних факторів. Серед них стан ґрунту, температура повітря, термін посадки, полив. Багатьох при цьому турбує питання, чим підживити огірки для росту і щоб це було безпечно. Останнє дуже важливо, оскільки ми потребуємо якісних продуктів харчування. Тому добриво для огірків має бути особливо якісним.
                        </p>
                        <p className="culture__desc">
                            Підживка для огірків має відбуватися регулярно, у кілька етапів, протягом усього періоду росту рослини. Тільки так вони отримують усі необхідні макро- і мікроелементи, без яких годі і мріяти про гарне плодоношення.
                        </p>
                        <p className="culture__heading">
                            Чим підгодовувати
                        </p>
                        <p className="culture__desc">
                            Огірки, як і всі рослини, для здорового росту та життя потребують якісної води, світла, мінеральних та органічних добрив. Якщо з першими двома усе зрозуміло, то щодо добрив часто виникають питання, чим підкормити огірки.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <div className="blog__bg3"></div>
                            </div>
                            <div className="culture__inside-right">
                                <p className="culture__heading">Мінеральні добрива</p>
                                <p className="blog__desc-text">
                                    Така підкормка для огірків відіграє найважливішу роль. Для гарного росту цим рослинам необхідні фосфор, калій, азотні добрива, а також мікроелементи — марганець, цинк, магній, бор, кальцій. Кожна з цих речовин вноситься у певний період, що залежить від фаз вегетації. Спочатку, коли на рослині з’явиться 3–4 пари листя, знадобиться перша підкормка огірків — азотні добрива, тобто карбамід, аміачна селітра.
                                </p>
                                <p className="blog__desc-text">
                                    Через місяць, під час цвітіння та зав’язування плодів, більше уваги слід приділити фосфору. Для цього необхідно підкормити огірки суперфосфатом. Також для рослин корисний буде калій магнезії або сірчанокислий калій. Ну а далі знову правильна відповідь на питання, чим удобрити огірки з метою продовження вегетації та підвищення родючості, — це азот. Ці мікроелементи у правильних екологічних пропорціях містяться у добривах, які пропонує наша компанія «Санберрі Україна»
                                </p>
                            </div>
                        </div>
                        <p className="culture__heading">Органічні добрива</p>
                        <p className="culture__desc">
                            До найкращих видів органічних добрив належить настій коров’яка — він містить багато азоту, міді, сірки, заліза та калію. Непоганим варіантом є курячий послід, у якому є калій, фосфор, магній, азот. Але зауважимо, що для удобрення огірків не можна використовувати кінський навоз, він має занадто багато аміаку, що виділяє нітрати, які потім потрапляють до плодів.
                        </p>
                        <div className="culture__inside">
                            <div className="culture__inside-left">
                                <p className="culture__heading">Коли потрібно вносити добрива</p>
                                <p className="culture__desc">
                                    Для початку варто запам’ятати, що існують два основні способи підживлення огірків – внесення під корені та обприскування. При використанні першого способу не можна допускати потрапляння добрива на листя – воно може бути опаленим.
                                </p>
                                <p className="culture__desc">
                                    Підживлення огірків у відкритому ґрунті слід робити постійно, протягом усього періоду зростання цієї рослини.
                                </p>
                                <p className="culture__desc">
                                    Перша підживка огірків робиться одразу ж після появи сходів. Це допоможе рослині сформувати гарну кореневу систему та здорові і міцні стебла. Ідеальний варіант для цього періоду —використання мінеральних добрив. Пропорція розчину залежить від стадії життя рослини і дається на пакуванні, тому проблем з дозуванням не повинно бути.
                                </p>
                                <p className="culture__desc">
                                    Окрім мінеральних добрив, можна використовувати розчин сечовини (на 3 л води 1 чайна ложка), який слід вносити під корені. Міркуючи над тим, чим підживити огірки після сходів, можна звернути увагу на коров’як (0,5 кг на 10 л води). Через 7 днів можна зробити розчин для підживлення огірків нітроамофоскою (1 чайна ложка), деревною золою (1 чайна ложка) та 3 л води.
                                </p>
                            </div>
                            <div className="culture__inside-right">
                                <img className="culture__inside-photo" src={cucumber} alt="blueberry" />
                            </div>
                        </div>
                        <p className="culture__desc">
                            Коли з’явиться перший листок, може бути проведена підкормка розсади огірків листовим способом — розчином борної кислоти (5 г), питної соди (5 г) та 5 л води.
                        </p>
                        <p className="culture__desc">
                            Наступний раз комплексне добриво для огірків знадобиться через два тижні. Підживку використовують так само, аналогічні і способи внесення. У цьому періоді додатково можна використати курячий послід або перегній. Останній вносять шляхом обприскування листя.
                        </p>
                        <p className="culture__desc">
                            Далі городника має хвилювати питання, чим підживити огірки під час цвітіння. Щоб зав’язь добре сформувалась, їй потрібен калій. Тому підгодувати рослину в цей період можна комплексними добривами, які пропонує наша компанія, або дерев’яною золою (2 стакани на 10 л води). Можна зробити розчин і обприскувати ним листя.
                        </p>
                        <p className="culture__desc">
                            Наступний крок — підживлення огірків під час плодоношення. Цей розчин повинен містити азот та калій. Як тільки на рослині з'являються плоди, її удобрюють розчином нітрофоски (1 ст. ложка на 10 л води), який вносять під корінь. Через 7 днів повторюють операцію, але вже розчином коров’яку з додаванням сульфату калію (10 л води, 500 г коров’яку, 5 г калію)
                        </p>
                        <p className="culture__desc">
                            Сподіваємося, що для наших читачів тепер не буде складним питання, чим підживити огірки у відкритому грунті.
                        </p>
                        <p className="culture__heading">Народні методи підживлення огірків</p>
                        <p className="culture__desc">
                            Існує багато рецептів добрив, але не завжди їм довіряють або чекають якихось надзвичайних результатів.
                        </p>
                        <p className="culture__desc">
                            Розглянемо основні народні способи, чим підживити огірки, щоб краще росли:
                        </p>
                        <ul className="blog__description-list">
                            <li className="culture__description-numbering">
                                борна кислота. Її часто використовують, але краще не проводити експерименти, а скористатися вже готовим добривом з додаванням бору — цього корисного мікроелементу. Це пов’язано з тим, що борна кислота добре розчиняється тільки при температурі не менше 50 °С, тому, якщо можливості підігріти розчин немає, можна обпалити рослини нерозчиненою кислотою;
                            </li>
                            <li className="culture__description-numbering">
                                дріжджі. Добриво для огірків з дріжджів позитивно впливає на ріст і плодоношення. Але на додавання цього розчину також добре реагують цвілеві гриби, тому потім слід обов’язково робити фунгіцидну обробку. І ще один недолік використання дріжджів — вони «виїдають» з ґрунту багато азоту, що з часом може призвести до серйозних проблем;
                            </li>
                            <li className="culture__description-numbering">
                                зола. Один з популярних способів удобрення для огірків. Проте і він має певні недоліки. Якщо золу використовувати у великій кількості, то вона буде підлужнювати ґрунт (а огіркам краще нейтральний рН). Крім того, вона може містити важкі метали та різні отруйні домішки. Тому важливо знати походження золи;
                            </li>
                            <li className="culture__description-numbering">
                                йод і зеленка. Шукаючи, чим удобрити огірки, городники іноді звертають увагу на ці речовини, які використовують для знищення грибкових та бактеріальних захворювань. Але усе ж таки з ними треба бути обережними. Особливо з йодом. Його пари отруйні для людини, особливо у закритих приміщеннях (теплицях). Щоб він дійсно почав знищувати грибок та бактерії, концентрація повинна бути не менше 25 мл на 1 л води, але такий розчин вже буде небезпечним для плодів. До того ж використання йоду в теплиці може призвести до руйнування її плівкового покриття. Що ж стосується зеленки, то в розумних концентраціях вона працює. Але простіше придбати готове добриво для огірків на основі щавлевої кислоти;
                            </li>
                            <li className="culture__description-numbering">
                                молоко та сироватка. Вони реально працюють, але з молока краще все ж таки робити сир, а сироваткою вже обробляти рослини.
                            </li>
                        </ul>
                        <p className="culture__desc">
                            Насамкінець зазначимо, що, міркуючи, як підживлювати огірки, можна віддавати перевагу народним рецептам, але при цьому треба бути професіоналом. Набагато простіше придбати и користуватися готовими добривами від відомих виробників.
                        </p>
                        <p className="culture__heading">Підкормка огірків в теплиці</p>
                        <p className="culture__desc">
                            Рослини, що ростуть в теплицях, будуть потребувати більшої уваги, ніж ті, що на відкритому ґрунті. Це пов’язано з тим, що обсяг ґрунту обмежений, в нього не можуть потрапити поживні речовини, наприклад разом з дощовою водою або птахами. У той же час мікроклімат такого приміщення більш комфортний, ніж зовні. Усе це накладає певні обмеження на використання добрив. Якщо ними зловживати, надлишки підвищать рівень нітратів в огірках. Отже, розглянемо, чим підживити огірки в теплиці.
                        </p>
                        <p className="culture__desc">
                            Перший раз роблять підживлення розсади огірків у теплиці при появі нових листочків, тобто приблизно через 14 днів після її висадки. Розчин добрива роблять на базі аміачної селітри, тому що саме селітра допоможе плодам набрати масу. У відрі води розчиняють 25 г суперфосфату, 16 г калію, 15 г селітри. Цього об’єму вистачить для підкормки до 20 кущів. Також можна скористатися комплексними добривами від нашої компанії і тоді не хвилюватися, як удобрювати огірки.
                        </p>
                        <p className="culture__desc">
                            Другий раз потрібне підживлення огірків під час цвітіння. Для цього можна використати органічні добрива, такі як коров’як або пташиний послід. Їх розбавляють водою і вносять під корені.
                        </p>
                        <p className="culture__desc">
                            Далі, міркуючи над тим, чим підкормити огірки в теплиці, звертають увагу на час плодоношення. У цьому періоді робити це слід обережно, щоб не перебрати з добривом — це може спричинити зменшення врожаю. У якості підкормки слід обрати калійну селітру, вона зможе зменшити гіркоту плодів.
                        </p>
                        <p className="culture__desc">
                            Якщо кущі ослаблені попередніми врожаями, їх можна додатково підгодувати. Для цього використовують позакореневе підживлення огірків в теплиці — їх обприскують сечовиною або селітрою. Концентрації добрив мають бути дуже низькими. Взагалі слід пам’ятати, що тепличні рослини (огірки не є винятком) не люблять занадто великої кількості поживних речовин.
                        </p>
                        <div className="blog__bg4"></div>
                        <p className="culture__heading">Особливості вирощування огірків на балкон</p>
                        <p className="culture__desc">
                            На жаль, не всі мають свої ділянки для вирощування городніх культур. Але дехто з любителів намагається це робити на власному балконі. Для вдалого вирощування огірків у цих умовах важливо правильно підібрати сорти. Слід обирати скоростиглі гібриди, що добре переносять тінь та посуху. При цьому важливо пам’ятати, що такі сорти вимагають інтенсивнішого внесення добрив, ніж ті, що ростуть на землі. Далі ми розповімо, коли і чим підживлювати огірки, що ростуть на балконі.
                        </p>
                        <p className="culture__desc">
                            Перший раз вносять добрива через два тижні після появи сходів, другий — ще через тиждень. Для цього можна використати сечовину, розвівши її у пропорції 1 ст. ложка на 6 л води. Коли рослини дадуть квіти, тоді можна буде підживити огірки органікою. Для цього можна зробити настій пташиного посліду (1:15), дати йому настоятися протягом кількох днів та поливати рослини.
                        </p>
                        <p className="culture__desc">
                            Але особливо варто подбати про те, як підкормити огірки, що ростуть на балконі, під час плодоношення. Гарна підживка огірків у цей період — полив мінеральною водою один раз на десять днів. Якщо є питання, чим підживити огірки народними методами, то слід використати золу або настій зі шкаралупи яєць.
                        </p>
                        <p className="culture__desc">
                            Як ми бачимо, огірки, які і всі інші городні культури, потребують обов’язкової періодичної підкормки. Причому добрива для них слід чергувати, як і способи їх внесення.
                        </p>
                        <p className="culture__desc">
                            Важливо враховувати, коли підживлювати огірки, і розуміти: не існує єдиного способу внесення добрив, усі вони добре впливають на ріст рослин. Уважне спостереження за культурою дасть відповідь, як удобрити огірки, допоможе зрозуміти, скільки і якого саме добрива вона потребує та у який саме спосіб його слід вносити. У відповідності до цього слід корегувати свої дії.
                        </p>
                        <p className="blog__heading">ТОВАРИ ЯКІ РЕКОМЕНДУЄ ВИРОБНИК :</p>
                    </div>
                </div>
                <div className="goods">
                    {this.item.map(item => (
                        <Item
                            key={item.id}
                            isNew={item.isNew}
                            title={item.title}
                            InStock={item.InStock}
                            link={item.link}
                            inside={item.inside}
                            priceFirst={item.priceFirst}
                            priceSecond={item.priceSecond}
                            priceThird={item.priceThird}
                            priceFourth={item.priceFourth}
                            imgUrl={item.imgUrl}
                            table={item.table}
                            logo={item.logo}
                            description={item.description}
                            recommendations={item.recommendations}
                            id={item.id}
                        />
                    ))}
                </div>
                <Footer />
            </div >
        );
    }
}