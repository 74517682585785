import "./Slider.scss";
import React from "react";
import Slider from "react-slick";
import goods from "../../icons/goods.slider-removebg-preview.svg";
import goodsSecond from "../../image/goods.image/agrotein/image_prod_png.png";
import goodsThird from "../../image/goods.image/goods.together/universal-reverse.png";
import { Link } from "react-router-dom";
import arrowRight from "../../icons/arrow/Path right.svg";
import arrowLeft from "../../icons/arrow/Path left.svg";

export default class SimpleSlider extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const settings = {
            dots: false,
            fade: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            arrowsBlock: false,
            autoplay: true,
            autoplaySpeed: 3500,
            pauseOnHover: true,
            nextArrow: <button className="slick-arrow slick-arrow--right"><img className="arrow" src={arrowRight} alt="arrow" /></button>,
            prevArrow: <button className="slick-arrow slick-arrow--left"><img className="arrow" src={arrowLeft} alt="arrow" /></button>
        };
        return (
            <Slider {...settings}>
                <div className="slider__block">
                    <div className="slider__header">
                        <div className="slider__header--text">
                            <span className="slider__title">Агроболік</span>
                            <span className="slider__text">Cуперконцентрований органічний стимулятор росту рослин</span>
                            <Link className="agroCard__btn" to="agrobolikCard">
                                <button type="button" className="btn-outline-success btn">Детальніше</button>
                            </Link>
                        </div>
                        <div>
                            <img className="slider__logo" src={goods} alt="goods" />
                        </div>
                    </div>
                    <div className="slider__image-container--first"></div>
                </div>
                <div className="slider__block">
                    <div className="slider__header">
                        <div className="slider__header--text">
                            <span className="slider__title">Агротеїн</span>
                            <span className="slider__text">Енергетичний комплекс для рослин з активаторами росту</span>
                            <Link className="agroCard__btn" to="agroteinCard">
                                <button type="button" className="btn-outline-success btn">Детальніше</button>
                            </Link>
                        </div>
                        <div>
                            <img className="slider__logo second__logo" src={goodsSecond} alt="goodsSecond" />
                        </div>
                    </div>
                    <div className="slider__image-container--second"></div>
                </div>
                <div className="slider__block">
                    <div className="slider__header">
                        <div className="slider__header--text">
                            <span className="slider__title">Рідкі NPK</span>
                            <span className="slider__text">Cуперконцентрований органічний стимулятор росту рослин</span>
                            <Link className="agroCard__btn" to="NPKCard">
                                <button type="button" className="btn-outline-success btn">Детальніше</button>
                            </Link>
                        </div>
                    </div>
                    <div className="slider__image-container--third"></div>
                </div>
                <div className="slider__block">
                    <div className="slider__header">
                        <div className="slider__header--text-fours">
                            <div className="slider__title-fours">
                                <span className="slider__inner-text--highlighted"> Безкоштовна доставка
                                    <span className="slider__inner-text"> по підбору та використанню ©Агроболіків</span>
                                </span>
                            </div>
                            <div className="slider__goods-photo">
                                <img className="slider__logo second__logo" src={goodsThird} alt="goodsThird" />
                            </div>
                            <div className="slider__text-fours">
                                <span className="slider__inner-text">
                                    При замовленні на сайті на суму від 10 000 грн
                                    <span className="slider__inner-text--highlighted"> Безкоштовна доставка</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="slider__image-container--fours"></div>
                </div>
            </Slider>
        );
    }
}
