export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Нітроамофоска 16:16:16 — це комплексне універсальне добриво, яке використовується для підвищення врожайності різних сільськогосподарських культур. До її складу входять такі базові компоненти: азот, фосфор та калій. Саме ці елементи й необхідні рослині для повноцінного розвитку, росту та плодоношення. Нітроамофоска має формулу NH4H2PO4+NH4NO3+KCL. Вона випускається у вигляді круглих гранул бежевого або рожевого кольору.
                </p>
                <p className="card__description-text">
                    Добриво практично не злежується, якщо зберігати його у сухому місці. Препарат розчиняється у воді, але не миттєво. Завдяки цьому забезпечується тривалий ефект його дії. Концентрація активних поживних речовин у нітроамофосці сягає 50 %. Саме тому це добриво користується великою популярністю. Його легко зберігати, перевозити та використовувати. Застосовують препарат як звичайні городники чи фермери, так і великі агропромислові підприємства.
                </p>
                <div className="card__description-title">
                    Особливості використання
                </div>
                <p className="card__description-text">
                    Добриво 16:16:16 підходить для будь-яких типів ґрунту. При цьому воно максимально ефективне на сіроземі та чорноземі за умови регулярних опадів або примусового поливу. На важких ґрунтах препарат зазвичай вносять восени, у процесі оранки поля. На легких рекомендовано додавання навесні, при висадці або фінішній обробці землі. Зазначимо, що на відміну від селітри нітроамофоска повністю безпечна для зберігання.
                </p>
                <p className="card__description-text">
                    Мікро- та макроелементи, які входять до складу нітроамофоски, легко засвоюються рослинами, тому вони швидко розвиваються та дають хороший урожай. Фосфор присутній у трьох різних формах: фосфат амонію, дикальційфосфат та монокальційфосфат. Така формула дозволяє забезпечувати сільськогосподарські культури корисними речовинами протягом усього вегетаційного періоду від самої посадки.
                </p>
                <div className="card__description-title">
                    Переваги добрива
                </div>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Розглянемо, які плюси є у цього препарата:
                    </p>
                    <li className="card__description-numbering">
                        Доступна ціна на нітроамофоску 16:16:16. Коштує це добриво відносно недорого, тому ви без проблем зможете його замовити.
                    </li>
                    <li className="card__description-numbering">
                        Висока ефективність. Практика показує, що при правильному використанні нітроамофоски вдається підняти врожайність на 30–70 % залежно від якості ґрунту, рівня поливу, технологій вирощування та типу культур.
                    </li>
                    <li className="card__description-numbering">
                        Зручність зберігання та транспортування. Вносити препарат також не проблема — для цього використовується механічний чи навіть ручний спосіб.
                    </li>
                </ol>
                <p className="card__description-text">
                    Зазначимо, що безконтрольно застосовувати добрива не рекомендується. Це може призвести до накопичення у ґрунті нітратів. Обов'язково дотримуйтесь рекомендацій виробника та враховуйте, які культури ви вирощуєте (у них можуть бути різні потреби).
                </p>
                <div className="card__description-title">
                    Сфера використання
                </div>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Нітроамофоска підходить для наступних культур:
                    </p>
                    <li className="card__description-numbering">
                        Плодові та хвойні дерева. Препарат вноситься до приштамбового кола навесні з розрахунку 45 грамів на квадратний метр.
                    </li>
                    <li className="card__description-numbering">
                        Різні види ягідних кущів. Внесення також рекомендовано у весняний період у міжряддя.
                    </li>
                    <li className="card__description-numbering">
                        Полуниця та суниця. Норма — 12–15 грамів на квадратний метр або близько 1,2 кг на сотку.
                    </li>
                    <li className="card__description-numbering">
                        Картопля. Добриво вноситься при утворенні бутонів із розрахунку 2,5–3 кг на сотку.
                    </li>
                    <li className="card__description-numbering">
                        Соняшник. Зазвичай нітроамофоску вносять у період висадки насіння або перед цим процесом. Зазначимо, що якщо використовувати гібриди, то врожайність буде вищою. Норми внесення визначаються після проведення детального аналізу ґрунту.
                    </li>
                </ul>
                <p className="card__description-text">
                    Підходить препарат і для інших сільськогосподарських культур: ріпаку, цукрових буряків, ярих та озимих зернових.
                </p>
                <p className="card__description-text">
                    Якщо ви хочете купити якісні та сертифіковані добрива, то рекомендуємо вам звертатися до компанії «Cанберрі Україна». Наше виробництво розташоване у Вінниці, але ми надсилаємо товари по всій території країни. Наша компанія являється офіційним виробником. Ми пропонуємо своїм клієнтам найкращі умови на ринку та надаємо гарантію на всю продукцію.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
