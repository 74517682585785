import "./Feedback.scss";
import GoogleMapForFeedback from "../GoogleMap/GoogleMapForFeedback";
import location from "../../icons/location-1.svg";
import phone from "../../icons/phone.svg";
import Email from "../../icons/email.svg";
import time from "../../icons/time.svg";
import React, { useRef, useState } from "react";
import MaskedInput from 'react-text-mask'
import emailjs from "@emailjs/browser";

export default function ContactUs() {
  const [email, setState] = useState("");
  const [value, setValue] = useState("");
  const [tel, setTel] = useState("");

  function onChangeInput(e) {
    setState(e.target.value);
  }

  function inputValueClear(e) {
    setValue(e.target.value);
  }

  function telValueClear(e) {
    setTel(e.target.value);
  }
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setValue("");
    setState("");
    setTel("");
    emailjs
      .sendForm(
        "service_dhbzbzd",
        "template_wytj8ko",
        form.current,
        "54r806kZ5B5Q649la"
      )
      .then(
        (result) => {
          alert("Ваше повідомлення відправлено успішно");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="map__inner">
      <div className="map__google" id="map">
        <GoogleMapForFeedback />
      </div>
      <div className="getintouch" id="getintouch">
        <div className="getintouch__title">
          <hr className="getintouch__line" />
          <h2>Зворотній зв'язок</h2>
        </div>
        <div className="getintouch__left">
          <p>
            <img className="feedback__image" src={location} alt="Location" />
            вул. Сергія Зулінського, 43
          </p>
          <p>
            <img className="feedback__image" src={phone} alt="phone" />
            <a href="tel:+380683699030">+38(068)-369-90-30</a>
          </p>
          <p>
            <img className="feedback__image" src={Email} alt="Email" />
            <a href="mailto:sunberry.ukr@gmail.com"> sunberry.ukr@gmail.com</a>
          </p>
          <p>
            <img className="feedback__image" src={time} alt="time" />
            Пн-Пт: 09:00-18:00
          </p>
        </div>
        <div className="getintouch__right">
          <form
            ref={form}
            onSubmit={sendEmail}
            action="#"
            method="POST"
            encType="multipart/form-data"
            className="getintouch__form"
          >
            <input
              type="text"
              value={value}
              name="name"
              placeholder="Ім'я"
              required
              className="getintouch__name"
              onChange={(e) => inputValueClear(e)}
            />
            <input
              className="getintouch__email"
              type="email"
              value={email}
              name="email"
              id="email"
              placeholder="E-mail"
              onChange={(e) => onChangeInput(e)}
            />
            <MaskedInput
              className="getintouch__tel"
              mask={['+', '3', '8', ' ', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
              pattern="^\+[0-9]{2}\s\((\d+)\)\s\d{3}\s\d{2}\s\d{2}"
              type="tel"
              name="phone"
              value={tel}
              placeholder="Телефон"
              required
              onChange={(e) => telValueClear(e)}
            />
            <input
              className="getintouch__submit"
              type="submit"
              value="Відправити"
            />
          </form>
        </div>
      </div>
    </div>
  );
}
