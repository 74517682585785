import "./Catalog.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import crumbs from "../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";

export default function Seed() {

    return (
        <div className="catalog">
            <Header />
            <Helmet>
                <meta name="keywords" content="виробництво, продукція, каталог, купити, замовити, Санберрі Україна, насіння" />
                <title>Купити насіння Санберрі Україна – замовити: насіння - Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/catalogSeed" />
                <meta name="description" content="Виробництво Санберрі Україна – замовити: насіння - Sunberry Ukraine"></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Насіння</span>
            </div>
            <div className="culture__title">
                <h1>Каталог насіння</h1>
            </div>
            <div className="culture__container">
                <div className="culture__goods">
                    <div className="culture__box">
                        <Link to="sunflowerCard">
                            <div className="culture__photo--bg11"></div>
                        </Link>
                        <p className="culture__box-text">Насіння соняшнику</p>
                    </div>
                    <div className="culture__box">
                        <Link to="cornCard">
                            <div className="culture__photo--bg7"></div>
                        </Link>
                        <p className="culture__box-text">Насіння кукурудзи</p>
                    </div>
                    <div className="culture__box">
                        <Link to="soyCard">
                            <div className="culture__photo--bg14"></div>
                        </Link>
                        <p className="culture__box-text">Насіння сої</p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}


