export const listOfHamburgerLinks = [
	{
		name: "Головна",
		href: "/",
		isActive: true,
		id: 1
	},
	{
		name: "Каталог",
		href: "catalog",
		isActive: true,
		id: 2
	},
	{
		name: "Насіння",
		href: "catalogSeed",
		isActive: true,
		id: 3
	},
	{
		name: "Культура",
		href: "culture",
		isActive: true,
		id: 4
	},
	{
		name: "Блог",
		href: "blog",
		isActive: true,
		id: 5
	},
	{
		name: "Інновації",
		href: "innovation",
		isActive: true,
		id: 6
	},
	{
		name: "Дистриб'ютори",
		href: "distributors",
		isActive: true,
		id: 7
	},
	{
		name: "Партнери",
		href: "partners",
		isActive: true,
		id: 8
	},
	{
		name: "Контакти",
		href: "contact",
		isActive: true,
		id: 9
	}
];