import './GoodsList.scss';
import { items } from '../../../mockData/soy-data';
import Item from '../../../Item/ItemSeedSoy';
import React from 'react';

export default class GoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.items = items;
  }

  render() {
    return (
      <div className="goods">
        {this.items.map(item => (
          <Item
            key={item.id}
            isNew={item.isNew}
            title={item.title}
            InStock={item.InStock}
            link={item.link}
            inside={item.inside}
            priceFirst={item.priceFirst}
            priceSecond={item.priceSecond}
            priceThird={item.priceThird}
            containerFirst={item.containerFirst}
            containerSecond={item.containerSecond}
            containerThird={item.containerThird}
            producer={item.producer}
            imgUrl={item.imgUrl}
            id={item.id}
          />
        ))}
      </div>
    );
  }
}