import './GoodsList.scss';
import { items } from '../../../mockData/BlogList';
import Item from '../../../Item/ItemBlog';
import React from 'react';

export default class GoodsList extends React.Component {
  constructor(props) {
    super(props);
    this.items = items;
  }

  render() {
    return (
      <div className="blog__goods">
        {this.items.map(item => (
          <Item
            key={item.id}
            title={item.title}
            class={item.class}
            desc={item.desc}
            date={item.date}
            visability={item.visability}
            img={item.img}
            button={item.button}
            id={item.id}
            link={item.link}
          />
        ))}
      </div>
    );
  }
}