import "./NavigationHamburger.scss";
import { listOfHamburgerLinks } from "../../components/mockData/nav-hamburger";
import SocialIcons from "../SocialIcons/SocialIcons";
import { Link } from "react-router-dom";
import React from "react";

export default function Navigation() {
	const NavItem = (list) => (list.map(elem => (
		<li className="navigationHamburger__item" key={elem.id}>
			<Link to={elem.href}
				className={"navigationHamburger__link " + (elem.isActive && "navigationHamburger__link--hover")}>
				{elem.name}
			</Link>
		</li>
	)));
	return (
		<div className="navigationHamburger">
			<nav className="navigationHamburger__container">
				<ul className="navigationHamburger__list">
					{NavItem(listOfHamburgerLinks)}
					<div className="navigationHamburger__social">
						<SocialIcons />
					</div>
				</ul>
			</nav>
		</div>
	);
}
