import "./Catalog.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import FAQ from "../FAQ/Catalog/FAQ";
import { Link } from "react-router-dom";
import crumbs from "../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";

export default function Fertilizers() {
    return (
        <div className="catalog">
            <Helmet>
                <meta name="keywords" content="виробництво, Добрива для позакореневого живлення, каталог, купити, замовити, Санберрі Україна, Мінеральні добрива" />
                <title>Виробництво мінеральних добрив Санберрі Україна</title>
                <link rel="canonical" href="https://sun-ukr.com/catalog" />
                <meta name="description" content="Агроболік «Універсал» використовується як коректор дефіциту мікроелементів у рослин, як стимулятор їх інтенсивного росту і розвитку, як підсилювач імунітету."></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Комплексні органо-мінеральні добрива</span>
            </div>
            <div className="culture__title">
                <h1>Комплексні органо-мінеральні добрива</h1>
            </div>
            <div className="culture__container">
                <div className="culture__goods">
                    <div className="culture__box">
                        <Link to="agrobolikCard">
                            <div className="culture__photo--bg9"></div>
                        </Link>
                        <p className="culture__box-text">Добрива для позакореневого живлення</p>
                    </div>
                    <div className="culture__box">
                        <Link to="agroteinCard">
                            <div className="culture__photo--bg12"></div>
                        </Link>
                        <p className="culture__box-text">Гранульовані NPK</p>
                    </div>
                    <div className="culture__box">
                        <Link to="humateCard">
                            <div className="culture__photo--bg13"></div>
                        </Link>
                        <p className="culture__box-text">Гранульовані гумати</p>
                    </div>
                    <div className="culture__box">
                        <Link to="NPKCard">
                            <div className="culture__photo--bg10"></div>
                        </Link>
                        <p className="culture__box-text">Рідкі NPK</p>
                    </div>
                </div>
                <div className="catalog__faq">
                    <p className="catalog__faq--style">FAQ</p>
                    <FAQ />
                </div>
            </div>
            <Footer />
        </div>

    );
}


