export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    NK Kondi: особливості сорту
                </p>
                <p className="card__description-text">
                    NK Kondi – це класичний гібрид соняшнику, належить до середньостиглих сортів. Являє собою чорне насіння великого та середнього розміру. Рекомендований для вирощування в зонах степу та лісостепу України, добре росте також на Поліссі. Соняшник «Конді» є класичним лінолевим гібридом. Сорт представлений виробником «Сингента». Інтенсивний, на початковому етапі росту відзначається відмінною енергією. Має попит завдяки високому показнику врожайності – він дорівнює 9.
                </p>
                <p className="card__description-text">
                    Цей сорт однорічного соняшнику створений швейцарським брендом. Є одним із найбільш високоврожайних гібридів із підвищеним вмістом олії. Відзначається пластичністю, відмінною стабільністю. Вегетаційний період під час вирощування триває від 116 до 125 діб. Насіння дуже інтенсивно росте на початковому етапі. Купити його у Вінниці пропонує ТОВ «Санберрі Україна».
                </p>
                <p className="card__description-title">
                    Ключові характеристики продукту
                </p>
                <p className="card__description-text">
                    Це високий соняшник, виростає до 150-170 сантиметрів. Посівний матеріал універсальний, може висаджуватися в будь-який ґрунт, оброблений різними способами. Найчастіше в Україні використовують класичну технологію, саме вона є рекомендованою. Соняшник «Конді» у своїй лінійці – один із найкращих гібридів із чудовими показниками врожайності та високим вмістом олії до 54%.
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Головні переваги гібрида від виробника «Сингента»:
                    </p>
                    <li className="card__description-numbering">стійкість до різноманітних рас вовчка або заразихи. Рослині не страшні раси А-Е;</li>
                    <li className="card__description-numbering">несприйнятливість до зараження хворобами. Соняшник не боїться сірої, білої гнилі, або склеротинії, враховуючи її кошикову та стеблову форму. Також стійкий до фомозу, фомопсису;</li>
                    <li className="card__description-numbering">високий показник стійкості до посухи.</li>
                </ul>
                <p className="card__description-text">
                    Не варто садити соняшник на полі, де раніше вирощували ріпак або бобові культури. Для найбільшої врожайності достатньо використовувати класичну технологію оброблення родючого ґрунту. Важливо також чітко дотримуватися порад стосовно сівозміни. Завдяки високому вмісту олії соняшник вирощують для подальшого її збирання. Густота стояння залежить від клімату місцевості та характеристик ґрунту на ділянці.
                </p>
                <p className="card__description-text">
                    Вирощувати «НК Конді» нескладно. Його толерантність навіть до дуже сильної посухи дорівнює 7 балам із 9. Щоб уникнути залягання, не слід загущувати посіви. ТОВ «Санберрі Україна» пропонує купити посівний матеріал, оброблений добривами, а саме препаратом «Каїзер». Є можливість придбати насіння й без додаткового оброблення.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
