import GoodsSliderMolibden from "../Сatalog/GoodsSlider/Molibden/GoodsSlider";
import GoodsSliderBor from "../Сatalog/GoodsSlider/Bor/GoodsSlider";
import GoodsSliderZink from "../Сatalog/GoodsSlider/Zink/GoodsSlider";
import GoodsSliderPhosphorus from "../Сatalog/GoodsSlider/Phosphorus/GoodsSlider";
import GoodsSliderUniversal from "../Сatalog/GoodsSlider/Universal/GoodsSlider";
import GoodsSliderLodine from "../Сatalog/GoodsSlider/Lodine/GoodsSlider";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";

export const items = [
  {
    title: 'Агроболік Бор + Молібден',
    priceFirst: '7 000 грн',
    priceSecond: '3 600 грн ',
    priceThird: '420 грн',
    priceFourth: '320 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "molibden",
    imgUrl: <GoodsSliderMolibden />,
    isNew: true,
    id: "1"
  },
  {
    title: 'Агроболік Фосфор',
    priceFirst: '5 200 грн',
    priceSecond: '2 700 грн',
    priceThird: '350 грн',
    priceFourth: '240 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "phosphor",
    imgUrl: <GoodsSliderPhosphorus />,
    isNew: true,
    id: "2"
  },
  {
    title: 'Агроболік Універсал',
    priceFirst: '3 400 грн',
    priceSecond: '1 800 грн',
    priceThird: '240 грн',
    priceFourth: '160 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "universal",
    imgUrl: <GoodsSliderUniversal />,
    isNew: false,
    id: "3"
  },
  {
    title: 'Агроболік Бор',
    priceFirst: '3 400 грн',
    priceSecond: '1 800 грн',
    priceThird: '240 грн',
    priceFourth: '160 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "bor",
    imgUrl: <GoodsSliderBor />,
    isNew: false,
    id: "4"
  },
  {
    title: 'Агроболік Цинк',
    priceFirst: '3 400 грн',
    priceSecond: '1 800 грн',
    priceThird: '240 грн',
    priceFourth: '160 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "zinc",
    imgUrl: <GoodsSliderZink />,
    isNew: false,
    id: "5"
  },
  {
    title: 'Агроболік Йод',
    priceFirst: '7 000 грн',
    priceSecond: '3 600 грн',
    priceThird: '430 грн',
    priceFourth: '350 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "iodine",
    imgUrl: <GoodsSliderLodine />,
    isNew: false,
    id: "6"
  }
];


export const item = [
  {
    title: 'Агроболік Бор',
    priceFirst: '3 400 грн',
    priceSecond: '1 800 грн',
    priceThird: '240 грн',
    priceFourth: '160 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "bor",
    imgUrl: <GoodsSliderBor />,
    isNew: false,
    id: "1"
  },
  {
    title: 'Агроболік Фосфор',
    priceFirst: '5 200 грн',
    priceSecond: '2 700 грн',
    priceThird: '350 грн',
    priceFourth: '240 грн',
    InStock: true,
    link: <Link to="order"><Button /></Link>,
    inside: "phosphor",
    imgUrl: <GoodsSliderPhosphorus />,
    isNew: true,
    id: "2"
  }
];
