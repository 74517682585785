import "../../Sunflowers/SunflowersCard/SunflowerCard.scss";
import Header from "../../../../../Header/Header";
import Footer from "../../../../../Footer/Footer";
import GoodsList from '../../../../GoodsList/GoodsListSeed/GoodsListCorn';
import crumbs from "../../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FertilizersTabs() {
    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="купити, кукурудза, посівмат, соняшник, Санберрі Україна" />
                <title>Насіння кукурудзи • Купити посмівмат кукурудзи оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cornCard" />
                <meta name="description" content="🌽Насіння кукурудзи оптом і в роздріб ⭐ Від якісних виробників Syngenta та MAS Seeds ✅ Замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalogSeed">
                    <span>/ Насіння</span>
                </Link>
                <span className="crumbs__text">/ Насіння кукурудзи</span>
            </div>
            <div className="fertilizersCard__catalog">
                <div className="fertilizersCard__title">
                    <h1 className="fertilizersCard__title__above--color">Насіння кукурудзи</h1>
                </div>
                <div>
                    <GoodsList />
                </div>
                <div className="catalog__content">
                    <p className="catalog__desc">
                        Кукурудза — це популярна сільськогосподарська культура, яка має величезне стратегічне значення для України. Зерно використовується всередині країні та постачається на експорт, поповнюючи бюджет і приносячи валюту. Українські фермери засіюють культурою величезні території, але треба розуміти, що обсяг врожаю безпосередньо залежить від якості насіння кукурудзи та добрив, правильності догляду й дотримання термінів збирання. У більшості випадків українські аграрії висаджують і вирощують гібриди цієї культури. Практика показує, що вони більш пристосовані до нашого клімату, грибкових захворювань, перепадів температури й інших природних умов.
                    </p>
                    <p className="catalog__desc">
                        Також гібриди мають відмінну схожість і врожайність, а саме зерно довго зберігається та не уражається різними шкідниками. При підборі відповідних сортів потрібно враховувати розташування поля, його особливості, кліматичні умови та ін. Наприклад, якщо в регіоні недостатньо дощів, то варто вибрати гібрид, стійкий до посухи, вилягання, стресів та ін. Якщо ви не знаєте, який сорт посівної кукурудзи вам підійде, то зверніться до наших менеджерів. Вони детально проконсультують вас, допоможуть вибрати потрібні гібриди, порекомендують комплексні добрива й дадуть відповідь на всі наявні питання.
                    </p>
                    <p className="catalog__description-title">Особливості сортів</p>
                    <p className="catalog__caption">Існують два основні типи кукурудзи:</p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Харчова. Вона має великі качани з повнорозмірними зернами. Використовується для виготовлення борошна, круп, консервів, вживання у свіжому вигляді.
                        </li>
                        <li className="catalog__description-numbering">
                            Кормова. Вона має широке листя та соковиті стебла. Її використовують для годування худоби, створення силосу та ін.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Тому перед купівлею потрібно визначитися з тим, які саме сорти ви плануєте вирощувати. Далі треба звернути увагу на клімат у вашій області, стійкість гібрида до грибкових захворювань та шкідників, рівень вологовіддачі зерна та можливість його тривалого зберігання. У більшості випадків українські фермери висаджують середньостиглі сорти, бо після їхнього збирання залишається час на підсушування врожаю природним способом, без застосування сушарок. Зробити це потрібно до осінніх дощів, інакше втрати зерна можуть сягати 20-30%.
                    </p>
                    <p className="catalog__description-title">Вибір за ФАО</p>
                    <p className="catalog__desc">
                        Кукурудза має спеціальний індекс, який показує її терміни дозрівання. Він називається ФАО (Food and Agricultural Organization). Цей індекс був запроваджений 1954 року, і він безпосередньо залежить від термінів вегетації культури. Розглянемо, який поділ прийнятий у всьому світі:
                    </p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Ранньостиглий (100-200 FAO).
                        </li>
                        <li className="catalog__description-numbering">
                            Середньоранній (200-300 FAO).
                        </li>
                        <li className="catalog__description-numbering">
                            Середньостиглий (300-400).
                        </li>
                        <li className="catalog__description-numbering">
                            Середньопізній (400-500).
                        </li>
                        <li className="catalog__description-numbering">
                            Пізній (500-600).
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Вегетаційний період у ранньостиглих сортів становить близько 95 днів, у пізніх – 130-140. При виборі коефіцієнта ФАО слід враховувати місцеперебування поля, у якому висаджуватиметься кукурудза, та кліматичні особливості регіону. Це дозволить вам отримати високу врожайність й окупити всі витрати. ФАО встановлюється з урахуванням середніх температур. Так, для західної частини країни та Полісся підійдуть сорти з коефіцієнтом 150-400, для центру – 190-420, для півдня – 250-400 та ін. Враховуйте, що чим вище ФАО, тим дорожче коштують культури, тому важливо правильно підібрати гібриди, щоб не переплачувати.
                    </p>
                    <p className="catalog__desc">
                        Зазначимо, що кукурудза продається у спеціальних мішках, у яких вміщується 80 000 насінин. Ця норма називається посівною одиницею. У середньому мішка вистачає на 1-1,2 га, але все залежить від норми висіву. У південних областях вона зазвичай становить 50 000 на один гектар (у цьому випадку мішка вистачить на 1,6 га), у північних та центральних – 65 000-80 000. Потенційна врожайність кукурудзи становить 150 центнерів з одного га, але практика показує, що добитися подібних цифр досить складно. Загалом на Поліссі та в центрі збирають близько 100 центнерів з гектара, а на півдні — до 80. Це пов'язано з посушливістю цього регіону.
                    </p>
                    <p className="catalog__caption">Загалом в Україні є три агрокліматичні зони:</p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Степова. Вважається найврожайнішою. Для неї підходять сорти з високими показниками ФАО. Здебільшого розташована з південно-східної частини України, охоплюючи Одесу, Дніпро, Луганськ, Херсон та Крим. Особливістю степової зони є тривале, спекотне, сонячне літо й осінь, яка настає досить повільно, із затяжними дощами. Тут можна висаджувати гібриди з ФАО від 100 до 600, але обов'язково потрібно враховувати літній брак вологи.
                        </li>
                        <li className="catalog__description-numbering">
                            Лісостеп. До цього регіону входять центральні та північні регіони України, від Чернівців й Івано-Франківська до Сум, Харкова та Полтави. Тут степові ділянки чергуються з лісами й зеленими насадженнями. Клімат у лісостепу помірний, дощі йдуть досить часто, літо відносно спекотне, але при цьому теплові ресурси в регіоні обмежені. Для цієї ділянки оптимальними будуть ФАО на 100-400 одиниць (ранньостиглі та середньостиглі групи).
                        </li>
                        <li className="catalog__description-numbering">
                            Полісся. До цієї зони входить північ України, від Чернігова до Луцька та Житомира. Тут зростають змішані ліси. Тут досить помірний клімат, прохолодна весна, вологе літо, осінь із частими дощами. Теплові ресурси досить низькі, тому для Полісся рекомендовані сорти з ФАО 100-300.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Зазначимо, що вищезазначений поділ достатньо умовний, тому ми рекомендуємо проконсультуватися з нашими менеджерами. Вони допоможуть вам підібрати потрібні сорти для вашого поля, а також порекомендують добрива, які ідеально підходять для вирощування кукурудзи.
                    </p>
                    <p className="catalog__description-title">Морфобіологічна класифікація</p>
                    <p className="catalog__caption">Існують п'ять основних видів кукурудзи:</p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Зубоподібна. Має досить велике, плоске зерно. Вважається найпоширенішою. Зубоподібні рослини не кущаться, мають потужне стебло та великі качани. На дозрілому насінні з'являються поглиблення, які схожі на зуби. Зміст крохмалю в цих сортах — 70-75%, білка — 15%, жиру — до 6%. Застосовується для виготовлення борошна, круп і спирту. Належить до пізньостиглих сортів, дає велику кількість силосної маси.
                        </li>
                        <li className="catalog__description-numbering">
                            Крохмалиста. Саме вона й вважається однією з найдавніших. Її батьківщина – Північна та Південна Америка. Крохмалиста кукурудза пізньостигла. Величина рослин середня, стовбури добре кущаться, дають багато зеленої маси. Зерна у крохмалистих сортах округлі, з опуклою верхівкою. Містить близько 80% крохмалю, 12% білка та 5% жиру. Використовується у крохмале-патоковій промисловості, а також для виробництва спирту.
                        </li>
                        <li className="catalog__description-numbering">
                            Воскоподібна. Є різновидом зубоподібної північноамериканської. Ендосперм у неї двошаровий. Його зовнішня частина схожа на віск, через що сорт і отримав свою назву. Крохмаль містить практично чистий амілопектин, тому він дуже клейкий.
                        </li>
                        <li className="catalog__description-numbering">
                            Кукурудза, що лопається. Також вважається однією з найдавніших. Зерна в неї можуть мати рисову або перлову форму (у першому випадку верхівка дзьобоподібна, у другому — округла). Містить близько 16% білку, тому використовується для виробництва крупи, борошна, пластівців та ін. Зерна в подібної кукурудзи невеликі, вони можуть мати жовте, червоне, біле або темно-синє забарвлення.
                        </li>
                        <li className="catalog__description-numbering">
                            Цукрова. Містить трохи крохмалю, але в ній багато водорозчинного цукру. Вміст білків — на рівні 20%, жирів — 8-9%, вуглеводів — 65%. Є овочевою культурою, використовується для консервування або вживання у вареному вигляді. Кущі цукрової кукурудзи невисокі. Колір зерна може бути різним, від білого до темно-жовтого.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Існують й інші різновиди цієї сільськогосподарської культури, але здебільшого використовуються саме ті, які ми перерахували. При виборі відповідних сортів необхідно обов'язково враховувати їхню стійкість до вилягання. Якщо кукурудза має міцне, щільне стебло, яке витримує сильний вітер, зливи, град, то ви зможете зібрати хороший урожай. Також рекомендується дотримуватися норм висіву, щоб знизити втрати від грибкових захворювань.
                    </p>
                    <p className="catalog__description-title">Особливості догляду</p>
                    <p className="catalog__desc">
                        Перед висаджуванням ґрунт обробляють гербіцидами, що дозволяє захистити рослини від бур'янів. Щоб впоратися з різними захворюваннями, використовують фунгіциди, а для боротьби зі шкідниками — інсектициди. Зверніть увагу, що поле, на якому росте кукурудза, потрібно обов'язково удобрювати. Це дозволить підвищити рівень урожайності на 20-25%. Добриво зазвичай вносять комплексно. Перше внесення проводиться восени під основне оброблення землі. Потім навесні, під культивацію. Підживлення проводять тоді, коли в рослини утворилися 3-5 листків.
                    </p>
                    <p className="catalog__desc">
                        Фосфорно-калійні добрива та цинк зазвичай вносять при основному обробленні, хоча у вологих регіонах можна робити під культивацію. Кукурудза досить вимоглива до якості ґрунту та може страждати від нестачі необхідних мікро- й макроелементів. Щоб посилити рослини, в землю вносять фосфор із розрахунку 10-15 кг на один гектар. Але правильніше все ж таки провести необхідні аналізи якості землі та підібрати комплекс добрив саме під ваше поле.
                    </p>
                    <p className="catalog__desc">
                        Для того, щоб кукурудза добре росла, необхідно провести ретельне механічне оброблення ґрунту. У такому разі в культури розвинеться потужний корінь із безліччю бічних розгалужень, який надійно утримуватиме рослину. Зазвичай він проникає у ґрунт на глибину до 30 сантиметрів. Щоб покращити живлення, поле необхідно розпушувати. Також добре допомагає техніка лущення. Завдяки їй також зменшується випаровування вологи, покращується поглинання природних опадів.
                    </p>
                    <p className="catalog__desc">
                        Лущення проводять боронами або дисковими культиваторами на глибину до 14 сантиметрів. Це додатково допомагає прибрати старе коріння та бур'яни. У деяких випадках може проводитися безвідвальний обробіток (до 25 см). Весною поле боронують. Якщо є бур'яни, то проводять передпосівну культивацію на глибину до 10 см. Висаджування проводять у період, коли ґрунт прогріється до +8-10 градусів. Зазначимо: деякі гібриди починають проростати й за +5. Щоб отримати здорові сходи, насіння висаджують у вологий ґрунт.
                    </p>
                    <p className="catalog__caption">
                        Загалом догляд за полем виглядає так:
                    </p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Коткування при малій вологості. Це дозволяє підвищити кількість пророслих зерен й отримати найкращий урожай.
                        </li>
                        <li className="catalog__description-numbering">
                            Боронування. Проводиться до появи сходів. Зазвичай кукурудзу боронують на глибину до 3-4 см. Також процес проводиться з появою 2-3 листків, а потім 4-5.
                        </li>
                        <li className="catalog__description-numbering">
                            Оброблення простору між рядами. Воно допомагає підвищити пухкість ґрунту та знищує бур'яни.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Збір урожаю проводиться, коли вологість зерен опускається нижче 40%. Зазвичай це стається у вересні, але все залежить від клімату та регіону. Збирання необхідно завершити за 5-7 днів, щоб зерна не пересохли.
                    </p>
                    <p className="catalog__description-title">Захист посівів від бур'янів</p>
                    <p className="catalog__desc">
                        Сміттєві трави здатні знизити врожайність культури на 30-40%, тому дуже важливо правильно боротися з ними. Причому це потрібно робити протягом усього періоду вегетації. Бур'яни не лише висмоктують із ґрунту поживні речовини, а й активно споживають воду, якої в багатьох регіонах недостатньо.
                    </p>
                    <p className="catalog__desc">
                        Зазначимо, що гібриди по-різному реагують на гербіциди. Тому обов'язково читайте інструкцію або проконсультуйтеся з менеджерами, купуючи препарати. Спочатку в поле вносять ґрунтові гербіциди, які активно знищують східні бур'яни. Вони діють приблизно 2 місяці після внесення. По посівах можна вносити гербіциди після того, як у культури розвинулися 3 листки. Повторне оброблення проводиться після появи 5-6 листків. Зазвичай використовують сульфонілсечовину, ацетохлор, нікосульфурон та інші речовини. Також непогано себе зарекомендували препарати комплексної дії.
                    </p>
                    <p className="catalog__caption">
                        Щоб гербіциди діяли максимально ефективно, необхідно:
                    </p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Ретельно розбивати груди землі на полі. Це також дає змогу збільшити рівень входів.
                        </li>
                        <li className="catalog__description-numbering">
                            Усунути стеблини й коріння попередніх культур, які вирощувалися на цьому полі. В крайньому разі їх потрібно подрібнити.
                        </li>
                        <li className="catalog__description-numbering">
                            Проводити посів на рекомендовану виробником гібридів кукурудзи глибину.
                        </li>
                        <li className="catalog__description-numbering">
                            Обов'язково дотримуватися рекомендацій щодо норм витрати гербіциду. Не потрібно намагатися його розвести або, навпаки, використати концентрат.
                        </li>
                        <li className="catalog__description-numbering">
                            Для обприскування застосовувати чисту воду, а не забруднену. Рівень рН має бути нейтральним.
                        </li>
                        <li className="catalog__description-numbering">
                            Проводити обробку за сухої погоди. Якщо в цей день або на наступний піде дощ, то він просто змиє препарат і він не працюватиме.
                        </li>
                        <li className="catalog__description-numbering">
                            Здійснювати обприскування в безвітряні дні. Річ у тім, що краплі досить дрібні, їх може просто здувати. У безвітряну погоду бур'яни будуть покриті препаратом з усіх боків, що підвищить ефективність дії гербіциду.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Дотримуючись цих простих правил, ви зможете ефективно боротися з бур'янами на кукурудзяному полі. Це дозволить отримати максимальний урожай та якісне зерно, яке зберігатиметься максимально довго.
                    </p>
                    <p className="catalog__description-title">Як замовити насіння</p>
                    <p className="catalog__desc">
                        Якщо вам потрібні якісні гібриди, які ідеально ростуть на наших полях, звертайтеся до компанії «Санберрі Україна» у Вінниці. Ми займаємося продажем посівного матеріалу, а також добрив власного виробництва. Уся наша продукція має сертифікати відповідності, ми гарантуємо її високу якість та пропонуємо вам вигідні ціни на всі товари.
                    </p>
                    <p className="catalog__desc">
                        Наші менеджери детально проконсультують вас з будь-яких питань та допоможуть купити насіння кукурудзи або інших сільськогосподарських культур. Також ми запропонуємо вам добрива, які ідеально підійдуть для вашого поля: ми проведемо відповідні аналізи та підберемо потрібні пропорції. Звертайтеся — ми маємо дуже багатий досвід у сільському господарстві й готові до взаємовигідної співпраці.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

