import { Link } from "react-router-dom";

export default function description() {
  return (
    <div className="card__description-basic">
      <div className="card__description">
        <p className="card__description-text">
          Агроболік Бор + Молібден – комплексне висококонцентроване добриво на
          основі бору з високим вмістом молібдену, що повністю на 100% усуває
          всі симптоми нестачі молібдену у рослин, стимулює бурхливий ріст
          зелені, на 100 % захищає від нітратів.
        </p>
        <p className="card__description-text">
          Молібден – один з найрідкісніших і найдорожчих мінералів. З усіх
          мікроелементів найкраще впливає на розвиток хрестоцвітних (капуста,
          редис, ріпак, мімоза, цвітна капуста) і бобових рослин (соя, горох,
          сочевиця, акація). На бобових культурах він сприяє розвитку
          бульбочкових бактерій для біологічної фіксації азоту.
        </p>
        <p className="card__description-text">
          100% водорозчинний препарат. Містить екстракт гуматів з леонардиту
          (високо-окисненого виду бурого вугілля), гумати та амінокислоти з
          океанічних бурих водоростей і комплекс мікроелементів природного
          походження у легкодоступній (біодоступній) для рослин формі. У складі
          переважають біологічно активні компоненти: гумінові та фульвові
          кислоти, які підвищують концентрацію гумусу і родючість ґрунту
        </p>
        <div className="card__description-title">
          Основний склад біостимулятор росту рослин Агроболік® Бор + Молібден
          від Санберрі Україна:
        </div>
        <table className="card__description__table">
          <tr>
            <th className="card__description__th">Склад</th>
            <th className="card__description__th">Показник</th>
          </tr>
          <tr>
            <td className="card__description__td">
              Гумінові та фульвові кислоти
            </td>
            <td className="card__description__th-index">8%</td>
          </tr>
          <tr>
            <td className="card__description__td">Полісахариди</td>
            <td className="card__description__th-index">4,5%</td>
          </tr>
          <tr>
            <td className="card__description__td card__description__td--link"><Link to="nutrition">Молібден (Mo)</Link></td>
            <td className="card__description__th-index">3%</td>
          </tr>
          <tr>
            <td className="card__description__td card__description__td--link"><Link to="nutrition">Бор</Link></td>
            <td className="card__description__th-index">5%</td>
          </tr>
          <tr>
            <td className="card__description__td">Хелатант ЕДТА</td>
            <td className="card__description__th-index">8%</td>
          </tr>
          <tr>
            <td className="card__description__td">Фосфор (P)</td>
            <td className="card__description__th-index">11%</td>
          </tr>
          <tr>
            <td className="card__description__td card__description__td--link"><Link to="nutrition">Калій (K)</Link></td>
            <td className="card__description__th-index">5%</td>
          </tr>
          <tr>
            <td className="card__description__td card__description__td--link"><Link to="nutrition">
              Мікроелементи: I, Co, V, Mn, Ni, Cu, Ti, F, Na, Se, Br, Cr, Ge,
              Si, S, Ca, W, Pt, As, Sn, Ba, Al, Cl
            </Link>
            </td>
            <td className="card__description__th-index">1%</td>
          </tr>
        </table>
        <div className="card__description-title">
          Споживчі якості Агроболік® Бор + Молібден:
        </div>
        <p className="card__description-text">
          Використовується як коректор дефіциту мікроелементів у рослин,
          стимулятор їх інтенсивного росту та розвитку, підсилювач імунітету.
          Препарат розвиває стійкість рослин до специфічних захворювань,
          прискорює дозрівання, збільшує якість і кількість врожаю. Гарантує
          стабільний потужний ріст.
        </p>
        <ul className="card__description-list">
          <li className="card__description-numbering">
            збільшує схожість насіння;
          </li>
          <li className="card__description-numbering">
            підвищує врожайність на 15-30%;
          </li>
          <li className="card__description-numbering">
            коригує незбалансоване живлення;
          </li>
          <li className="card__description-numbering">
            стимулює швидкий ріст зеленої вегетативної маси;
          </li>
          <li className="card__description-numbering">
            підвищує доступність та засвоюваність поживних елементів;
          </li>
          <li className="card__description-numbering">
            збільшує вміст крохмалю, білка і вітамінів, хлорофілу в листках;
          </li>
          <li className="card__description-numbering">
            підвищує стійкість рослин до кліматичних впливів (холод, спека,
            посуха) і техногенних факторів (при гербіцидному отруєнні діє як
            антидот);
          </li>
          <li className="card__description-numbering">
            знижує вміст нітратів, важких металів на 50-60% і токсичний вплив
            отрутохімікатів;
          </li>
          <li className="card__description-numbering">
            різко знижує захворюваність рослин фітофторою, паршею, мокрою
            гниллю, переноспорозом, борошнистою росою, фузаріозом, кореневою
            гниллю, вяненням та ін.
          </li>
        </ul>
        <div className="card__description-title">Переваги:</div>
        <ul className="card__description-list">
          <li className="card__description-numbering">нетоксичний;</li>
          <li className="card__description-numbering">
            має високу біологічну активність;
          </li>
          <li className="card__description-numbering">
            виводить радіонукліди з живої клітини;
          </li>
          <li className="card__description-numbering">
            стимулює швидкий ріст зеленої вегетативної маси;
          </li>
          <li className="card__description-numbering">
            може застосовуватися з іншими препаратами, добривами, засобами
            захисту рослин;
          </li>
          <li className="card__description-numbering">
            покращує структуру ґрунту;
          </li>
          <li className="card__description-numbering">
            знижує кислотність і засоленість ґрунту;
          </li>
          <li className="card__description-numbering">
            зручний у застосуванні;
          </li>
          <li className="card__description-numbering">
            термін придатності необмежений.
          </li>
        </ul>
        <div className="card__description-title">
          Рекомендації щодо застосування:
        </div>
        <p className="card__description-text">
          Агроболік® «Бор + Молібден» термін очікування результату 5-10 днів,
          для позитивного ефекту потрібно повторити обробку 3-4 рази за сезон.
          Рекомендована для внесення температура зовнішнього середовища від 14
          °С
        </p>
      </div>
      <div className="card__description-preference">
        <div className="aside__title">Агрохімічні характеристики</div>
        <div className="aside__content">
          <div className="aside-char">
            <div className="aside-char__head">Потенціал врожайності</div>
            <div className="aside-char__char">
              <div className="aside-char__items aside-char__items--9">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="aside-char__count">9</div>
            </div>
          </div>
          <div className="aside-char">
            <div className="aside-char__head">Стабільність урожаю</div>
            <div className="aside-char__char">
              <div className="aside-char__items aside-char__items--8">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="aside-char__count">8</div>
            </div>
          </div>
          <div className="aside-char">
            <div className="aside-char__head">Енергія початкового росту</div>
            <div className="aside-char__char">
              <div className="aside-char__items aside-char__items--8">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="aside-char__count">8</div>
            </div>
          </div>
          <div className="aside-char">
            <div className="aside-char__head">Ефективність</div>
            <div className="aside-char__char">
              <div className="aside-char__items aside-char__items--9">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="aside-char__count">9</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
