export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <div className="card__description-title">
                    РКД NPK 8-24-24: особливості, призначення, переваги
                </div>
                <p className="card__description-text">
                    Для основного внесення та підживлення озимих культур, інших рослин, плодово-ягідних дерев підходять 8-24-24 – NPK, або комплексні добрива, виробництва ТОВ «Санберрі Україна».
                </p>
                <p className="card__description-text">
                    Ефективний у сільському господарстві препарат універсальний, відзначається рівномірним співвідношенням фосфору та калію. Забезпечує швидкий розвиток і потужне зростання рослин, гарантує гарний, якісний урожай. Використовується для будь-яких культур, які вирощують в Україні.
                </p>
                <p className="card__description-text">
                    Кожна гранула добрива містить цілий комплекс поживних мікро- та макроелементів в оптимальному співвідношенні. Усі речовини – у формі, добре засвоюваній рослинами та доступній для них. Міцні гранули стійкі до розламування, під час зберігання й засівання утворюють менше пилу. Препарат підходить для застосування на ґрунтах із низьким вмістом калію та фосфору, для профілактики дефіциту.
                </p>
                <div className="card__description-title">
                    Особливості використання
                </div>
                <p className="card__description-text">
                    У добривах від ТОВ «Санберрі Україна» використовується чиста ортофосфорна кислота. Це екологічний препарат без токсичних домішок, який добре засвоюється різними культурами. У складі немає нітратів. Азот міститься у двох популярних формах – амідній і аміачній. Використання рідких комплексних добрив NPK пов’язане з особливостями росту рослин, нездатних споживати фосфор «про запас».
                </p>
                <p className="card__description-text">
                    Купити NPK 8-24-24 варто й для некореневого підживлення, особливо у холодну весну. За температури не вище ніж 10 ˚C коріння сільськогосподарської культури не отримує достатньо фосфору та азоту. Використання РКД допоможе зменшити дефіцит, поліпшить загальний стан і прискорить темпи росту рослини. Препарат використовується також для обробітку насіння перед висіванням.
                </p>
                <p className="card__description-text">
                    У багатьох культур є фази росту, коли вони особливо потребують поживних речовин. Дефіцит мікро- та макроелементів у ці періоди не лише погіршує якість врожаю, а й може призвести до його повного знищення. Застосовувати розчин найкраще там, де низький вміст азоту й фосфору. Добрива поступово вивільняють поживні речовини, вони надходять у ґрунт і блокуються металами.
                </p>
                <p className="card__description-text">
                    Купити 8-24-24 NPK варто для різних культур. Його використовують для озимих, ярої пшениці, кукурудзи, цукрового буряку, картоплі, плодово-ягідних дерев. Гранули препарату не лише міцні, а й усі мають однаковий розмір. Це дає можливість точно й акуратно вносити їх у ґрунт із мінімальними втратами. Готового препарату потрібно не так багато в порівнянні зі звичайними складами.
                </p>
                <div className="card__description-title">
                    Які головні переваги
                </div>
                <p className="card__description-text">
                    NPK 8-24-24 – сучасний, простий і зручний у використанні препарат. ТОВ «Санберрі Україна» пропонує прийнятні ціни та знижки в залежності від кількості. Норма внесення розробляється з огляду на технологію, тип і характеристики ґрунту. Найкраще попередньо зробити аналіз, цим займаються професійні агрономи. Основні плюси добрив такі:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        сприяють швидкому наростанню вегетативної маси;
                    </li>
                    <li className="card__description-numbering">
                        підвищують стійкість рослини до посухи, температурних перепадів. Можуть використовуватися в посушливих районах, вноситись у прохолодну землю;
                    </li>
                    <li className="card__description-numbering">
                        роблять культури більш толерантними до іншої агрохімії, заморозків;
                    </li>
                    <li className="card__description-numbering">
                        сприяють швидкому та якісному розростанню кореневої системи;
                    </li>
                    <li className="card__description-numbering">
                        на 20-30% знижують ступінь зараження борошнистою росою, іржею та іншими хворобами.
                    </li>
                </ul>
                <p className="card__description-text">
                    РКД зручно зберігати й перевозити. Немає потреби в герметичній тарі. Несприятливі погодні умови ніяк не впливають на процес внесення та засвоєння рослиною поживних речовин. В агрономічній практиці небаластні добрива змішують з водою, розчинами твердих препаратів і рідкими складами з підвищеним вмістом азоту без аміаку.
                </p>
                <p className="card__description-text">
                    Добриво можна застосовувати як основне або для підживлення, оброблення насіння, захисту від вилягання. Його використовують навесні та восени, зокрема для технічних видів культур. Склад добре засвоюється навіть у прохолодну погоду, за низької температури. Не містить вільного аміаку, тому не викликає корозію обладнання й не шкодить зеленим насадженням. Може використовуватися разом зі стимуляторами росту та різними видами мікроелементів.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
