export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    «Агротеїн 6:20:30» являє собою цінний азотно-фосфорно-калійний добривний комплекс – NPK. Препарат сприяє розвитку майже усіх аграрних культур, які вирощують в Україні. Однією з переваг добрив, що належать до групи NPK, є можливість використання на будь-якому ґрунті, навіть коли він є виснаженим (в цьому разі дозування препарату збільшується на 50 %). Якщо ви маєте за мету підвищити врожайність рослин, які садите на своїх ділянках, варто купити «Агротеїн 6:20:30». У разі його використання згідно з технологією вирощування, результат вас не розчарує.
                </p>
                <div className="card__description-title">
                    Загальна інформація
                </div>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        «Агротеїн 6:20:30» використовується з метою:
                    </p>
                    <li className="card__description-numbering">
                        стимулювання зростання рослини;
                    </li>
                    <li className="card__description-numbering">
                        формування стійкості до шкідливих чинників, на кшталт вилягання або при розповсюджені хвороби;
                    </li>
                    <li className="card__description-numbering">
                        підвищення якості врожаю.
                    </li>
                </ul>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Однією з переваг «Агротеїну 6:20:30» є високий рівень діючих речовин. Він складає 45 % від загальної маси. У процентному співвідношенні їхнє розподілення виглядає наступним чином:
                    </p>
                    <li className="card__description-numbering">
                        азот – 6 %;
                    </li>
                    <li className="card__description-numbering">
                        фосфор – 20 %;
                    </li>
                    <li className="card__description-numbering">
                        калій – 30 %.
                    </li>
                </ul>
                <p className="card__description-text">
                    Азот безпосередньо впливає на зростання рослини, внаслідок чого підвищуються обсяги врожаю. Також «Агротеїн 6:20:30» містить фосфор, що є важливим елементом для будь-якої рослини. Він здійснює вплив на процеси фотосинтезу, забезпечення енергією. Внаслідок цього спостерігається формування нових точок зросту та швидке дозрівання рослини. Посилюється також стійкість до негативних чинників.
                </p>
                <p className="card__description-text">
                    Ще одним вкрай важливим елементом, що міститься в «Агротеїні 6:20:30» є калій. Він здатен стимулювати активність ферментів. Добре утримуючи вологу, він захищає рослину від впливу посухи, низьких температур або надмірної кількості мінеральних речовин у ґрунті. Елемент сприяє підвищенню імунітету рослини, що зменшує ризики ураження хворобами.
                </p>
                <p className="card__description-title">
                    Відомості для аграріїв
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        «Агротеїн 6:20:30» може використовуватися в якості основного джерела живлення і допоміжного. Добриво є ефективним на різних типах рослин, серед яких:
                    </p>
                    <li className="card__description-numbering">
                        зернові;
                    </li>
                    <li className="card__description-numbering">
                        овочі;
                    </li>
                    <li className="card__description-numbering">
                        різноманітні кущі;
                    </li>
                    <li className="card__description-numbering">
                        квіти;
                    </li>
                    <li className="card__description-numbering">
                        дерева.
                    </li>
                </ul>
                <p className="card__description-text">
                    Застосовується у аграрних господарствах, де вирощуються соняшник, огірки, картопля, цукрові буряки, а також різноманітні гібриди. «Агротеїном 6:20:30» підживлюють не лише харчові, але й декоративні рослини, що є складовими подвір’їв і газонів.
                </p>
                <p className="card__description-title">
                    Детальніше про застосування добрива
                </p>
                <p className="card__description-text">
                    Перед тим як використати «Агротеїн 6:20:30», треба уважно вивчити інструкції, адже особливості з обробкою того чи іншого різновиду рослин можуть бути різним. Загалом, коли здійснюється вирощування зернових овочевих культур із насіння, препарат вноситься до грунту восени, коли здійснюється перекопування ділянки.
                </p>
                <p className="card__description-text">
                    Чергове додавання  добрива відбувається навесні, безпосередньо перед тим, як сіяти насіння. Також робиться весняне живлення. Якщо ви маєте намір садити овочеву розсаду, кущі або дерева, препарат потрібно змішати з ґрунтом. Отриману масу при внесенні потрібно відокремити від кореневої системи. Робиться це за допомогою ґрунтового шару, що вимощується навкруги коренів.
                </p>
                <p className="card__description-text">
                    Взагалі існує багато різних варіантів внесення добрива. Ці способи використовуються в залежності від типу ґрунту (чорноземні, глиноземні, піщані тощо). Звичайно, на технологію підживлення впливає і тип рослин, вирощування якої здійснюється. Наприклад, є певні умови для польових, овочевих культур або коренеплодів. Зовсім інші передбачені для дерев, ягідників або кімнатних квітів. Також враховується період вегетації тієї чи іншої культури.
                </p>
                <p className="card__description-text">
                    Застосовуючи «Агротеїн 6:20:30», варто не лише уважно читати інструкції до препарату, але й спілкуватися з цього приводу з фахівцями. У нас в «Санберрі Україна» можна не лише придбати «Агротеїн 6:20:30» за ціною виробника, але й отримати детальні консультації по використанню. Ми не тільки реалізуємо добрива, але і роздробляємо і виробляємо їх. «Агротеїн 6:20:30» випускається у різному фасуванні, від 50 кг до 1 тони. Доставку з міста Вінниці забезпечуємо по всій Україні, також даємо гарантію на товар.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
