export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    «Агротеїн 0:11:21» належить до групи гранульованих NPK-добрив (азотно-фосфорно-калійних). На відміну від більшості аналогів, препарат не містить азоту. До складу мікродобрива включені фосфор (11 % від загальної маси) і калій (21 %). Купити «Агротеїн 0:11:21» варто як фермерам, так і власникам дачних ділянок, які садять різні культури, від польових злаків до фруктових дерев. Також добриво ефективне, коли здійснюєте вирощування газонів і декоративних рослин.
                </p>
                <div className="card__description-title">
                    Про властивості препарату
                </div>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Фосфор, що міститься в «Агротеїні 0:11:21»:
                    </p>
                    <li className="card__description-numbering">
                        стимулює процеси, що пов’язані з фотосинтезом;
                    </li>
                    <li className="card__description-numbering">
                        забезпечує клітини енергією;
                    </li>
                    <li className="card__description-numbering">
                        сприяє появі нових точок росту.
                    </li>
                </ul>
                <p className="card__description-text">
                    Завдяки вказаним процесам пришвидшується зростання рослини, збільшується її врожайність.
                </p>
                <p className="card__description-text">
                    Щодо калію, який теж є важливою складовою «Агротеїна 0:11:21», цей хімічний елемент здійснює сильний вплив на обмін речовин, зокрема, на білково-вуглеводний. Він стимулює активність рослинних ферментів. Саме завдяки наявності калію в «Агротеїні 0:11:21» регулюються продихи на листках, і всі ці процеси загалом позитивно впливають на стійкість до хвороб і якість врожаю.
                </p>
                <p className="card__description-text">
                    Важливою особливістю калію є його властивість накопичувати й утримувати вологу. Отже, рослина, на якій використовується «Агротеїн 0:11:21», має хорошу стійкість до посухи, низьких температур або негативного хімічного впливу (останнє явище може спостерігатися в разі зайвої мінералізації ґрунту).
                </p>
                <p className="card__description-text">
                    Ефективність препарату пояснюється високим вмістом активних речовин. Навіть в одній гранулі є достатньо компонентів для забезпечення живлення. «Агротеїн 0:11:21» має дуже хороші водорозчинні якості, отже, він легко засвоюється рослинами. Ви можете купити «Агротеїн 0:11:21» на декілька років вперед, адже гранули зберігаються впродовж тривалого часу, навіть не втрачаючи розсипчастості.
                </p>
                <p className="card__description-title">
                    Засоби застосування препарату
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        «Агротеїн 0:11:21» здійснює ефективне підживлення майже всіх рослинних культур та їхніх гібридів з тих, що вирощуються в Україні, зокрема:
                    </p>
                    <li className="card__description-numbering">
                        соняшник;
                    </li>
                    <li className="card__description-numbering">
                        цукровий буряк;
                    </li>
                    <li className="card__description-numbering">
                        картопля;
                    </li>
                    <li className="card__description-numbering">
                        різноманітні плодові та ін.
                    </li>
                </ul>
                <p className="card__description-text">
                    На практиці існує багато засобів його застосування. На їхній вибір впливає не лише різновид рослини, але й тип ґрунту. Наприклад, якщо ви плануєте засівати насіння на глиноземі, треба вносити добриво восени. У легший, піщаний ґрунт препарат варто додавати навесні.
                </p>
                <p className="card__description-text">
                    Найбільший ефект спостерігається в разі здійснення комплексного підживлення. Особливо це важливо тоді, коли препарат використовується як основне джерело енергії. У такому випадку перше додавання мікродобрива треба зробити восени. Кількість залежить від культури, вирощування якої ви здійснюєте. Потім треба додати препарат безпосередньо перед посівною. Незабаром знадобиться зробити ще одне підживлення – весняне.
                </p>
                <p className="card__description-title">
                    Звертайте увагу на нюанси
                </p>
                <p className="card__description-text">
                    Вище було наведено один із прикладів ефективного використання «Агротеїну 0:11:21». Загалом для кожної рослини або групи рослин є власна технологія внесення мікродобрива.
                </p>
                <p className="card__description-text">
                    Наприклад, перед тим як садити дерева, кущі або розсаду, треба змішати частку добрива у кількості, яку потребує рослина, що вирощується, із ґрунтом. Але під час внесення отриману суміш варто відокремити від кореневої системи. З цією метою робиться прошарок з ґрунту.
                </p>
                <p className="card__description-text">
                    Під час вирощування кімнатних квітів «Агротеїн 0:11:21» варто вносити у вигляді водного розчину. За браком азоту в складі добрива він може додаватися окремо, незалежно від типу вирощуваної рослини.
                </p>
                <p className="card__description-text">
                    Щоби купити «Агротеїн 0:11:21», звертайтеся безпосередньо до виробника. Широкий вибір добрив пропонує наша компанія «Санберрі Україна». Ціна «Агротеїна 0:11:21» залежить від фасування, адже препарат пакується в мішки масою:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        50 кг;
                    </li>
                    <li className="card__description-numbering">
                        600 кг;
                    </li>
                    <li className="card__description-numbering">
                        1000 кг.
                    </li>
                </ul>
                <p className="card__description-text">
                    Наша команда працює у Вінниці, але доставку добрив здійснюємо по всій Україні. Надаємо гарантію на «Агротеїн 0:11:21», також готові проконсультувати з усіх питань щодо його застосування, адже ніхто цього не зробить краще, ніж виробник.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
