export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    NPK 0-20-23 — двокомпонентне рідке добриво на основі ортофосфорної кислоти з високим вмістом калію. Підходить для різних типів ґрунтів і видів сільськогосподарських культур. Забезпечує їх живлення протягом усього вегетативного періоду.
                </p>
                <div className="card__description-title">
                    Основний склад РКД NPK 0-20-23
                </div>
                <p className="card__description-text">
                    У складі препарату 20% фосфору (P), 23% калію (K). Добриво не містить азоту. NPK 0-20-23 в більшості складається з біологічно активних компонентів, а саме гумінових і фульвових кислот, які стимулюють та підтримують повноцінне живлення рослин. Усі елементи знаходяться в легкодоступній для рослин формі.
                </p>
                <p className="card__description-text">
                    Калій — незамінний елемент для рослин, який бере участь у регулюванні водного режиму культур. Він підтримує сприятливі для життя клітини фізико-хімічні властивості протоплазми, що має велике значення для нормального обміну речовин. За допомогою калію рослини ефективніше поглинають азот.
                </p>
                <p className="card__description-text">
                    Фосфор бере участь в енергетичному обміні — у процесі фотосинтезу, синтезу вуглеводів, білків і жирів. Збільшує стійкість рослин до несприятливих умов навколишнього середовища. Покращує якість плодів.
                </p>
                <div className="card__description-title">
                    Споживчі якості NPK 0-20-23
                </div>
                <div className="card__description-text">
                    Добриво швидко забезпечує потреби культур сполуками фосфору та калієм. Його основна дія:
                </div>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        сприяє розвитку потужної кореневої системи;
                    </li>
                    <li className="card__description-numbering">
                        покращує швидкість та якість формування надземної частини;
                    </li>
                    <li className="card__description-numbering">
                        прискорює перехід рослин до репродуктивної фази розвитку;
                    </li>
                    <li className="card__description-numbering">
                        забезпечує стійкість рослин до морозів і засух;
                    </li>
                    <li className="card__description-numbering">
                        позитивно впливає на міцність стебел і стійкість рослин до вилягання;
                    </li>
                    <li className="card__description-numbering">
                        підвищує стійкість рослин до різних хвороб, зокрема борошнистої роси та іржі у злакових, а також збудників гнилей в овочевих культур і коренеплодів;
                    </li>
                    <li className="card__description-numbering">
                        скорочує період дозрівання рослин, що дає значущий економічний ефект;
                    </li>
                    <li className="card__description-numbering">
                        Препарат підвищує урожайність, забезпечує товарний вигляд та високу якість врожаю.
                    </li>
                </ul>
                <div className="card__description-title">Переваги NPK 0-20-23</div>
                <p className="card__description-text">
                    Купити NPK 0-20-23 варто з таких причин:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        повністю розчиняється у воді;
                    </li>
                    <li className="card__description-numbering">
                        не містить баластових домішок й інших шкідливих компонентів;
                    </li>
                    <li className="card__description-numbering">
                        має нейтральний рівень рН;
                    </li>
                    <li className="card__description-numbering">
                        сумісний з іншою агрохімією;
                    </li>
                    <li className="card__description-numbering">
                        рівномірно вноситься та точно дозується;
                    </li>
                    <li className="card__description-numbering">
                        має низький сольовий індекс;
                    </li>
                    <li className="card__description-numbering">
                        має поліпшені властивості зберігання в холодну погоду.
                    </li>
                </ul>
                <p className="card__description-text">
                    Препарат повністю безпечний для рослин у разі дотримання рекомендованих норм внесення.
                </p>
                <div className="card__description-title">
                    Рекомендації щодо застосування
                </div>
                <p className="card__description-text">
                    NPK 0-20-23 використовується для передпосівного оброблення насіння та на різних етапах розвитку культур. Основні рекомендації щодо його застосування:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        стартове внесення (в рядок) — 20–50 л/га;
                    </li>
                    <li className="card__description-numbering">
                        основне удобрення — 50–300 л/га;
                    </li>
                    <li className="card__description-numbering">
                        підживлення в міжряддя — 30–50 л/га;
                    </li>
                    <li className="card__description-numbering">
                        позакореневе підживлення (обприскування листя) — 10–30 л/га;
                    </li>
                    <li className="card__description-numbering">
                        оброблення посівного матеріалу — 1–3 л/т.
                    </li>
                </ul>
                <p className="card__description-text">
                    При позакореневому підживленні можна додавати різні мікроелементи. Норми внесення слід регулювати, враховуючи особливості ґрунту, рівень його зволоженості, заплановану врожайність, культуру-попередника та ін. Не варто перевищувати максимальні рекомендовані дозування. Надлишок мікроелементів шкідливіший, ніж їх нестача.
                </p>
                <p className="card__description-text">
                    Для фертигації готують розчин. Для цього на 1000 л води додають 5 л добрива. За весь вегетативний період в середньому використовують від 50 до 100 л/га поливної суміші. Враховуючи агрономічну потребу, за добу норма може складати від 1 до 10 л/га.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
