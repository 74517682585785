export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    MAS 24.C: особливості, характеристики
                </p>
                <p className="card__description-text">
                    Середньоранній гібрид кукурудзи з високими показниками врожайності та чудовими якісними характеристиками зерна – це MAS 24.C. Добре витримує різке підвищення температури, стійкий до посухи. Представлений виробником MAS Seeds. Під час дозрівання швидко віддає багато вологи. За сприятливих умов початок MAS 24.C має велику кількість зерна. Рекомендований для вирощування в зонах лісостепу та степу України.
                </p>
                <p className="card__description-text">
                    Рослина типу Stay Green, виростає високою. Гібрид має листки середнього розміру, товстий і довгий початок на короткій ніжці. Його вирощують з метою отримання зерна, рідше силосу. Висота кріплення качана – середня. У початку виходить близько 16-18 рядів. Зерно кремнисто-зубоподібного типу, в одному ряду 24-28 зернин. На початку вегетативного періоду кукурудза має високу енергію, тому швидко росте.
                </p>
                <p className="card__description-title">
                    Головні особливості гібрида
                </p>
                <p className="card__description-text">
                    Потенціал врожайності складає 15 тонн на гектар. MAS 24.C належить до простих гібридів. Зерно має відмінні кормові властивості. Вегетативний період триває 120 днів. Кукурудза добре адаптується до різних умов. Врожайність не погіршується навіть у дуже сильну посуху. Можна використовувати екстенсивну або інтенсивну технологію вирощування. Маса 1000 зерен складає 330-350 грамів. Вони мають високу вологовіддачу.
                </p>
                <p className="card__description-text">
                    Кукурудза толерантна до більшості захворювань, властивих культурі. Має генетичну стійкість до пухирчастої сажки. Не сприятлива до фузаріозу стебла й качана. Толерантна до летючої сажки. MAS 24.C стійкий до вилягання, головні, гельмінтоспоріозу. Купити гібрид від офіційного виробника у Вінниці пропонує ТОВ «Санберрі Україна». У каталозі цієї компанії доступні також добрива, насіння соняшника.
                </p>
                <p className="card__description-title">
                    Які вимоги до вирощування
                </p>
                <ul className="card__description-list">
                    <p className="card__description-text">
                        Щоб насіння краще сприймало ґрунт, контактувало з ним і давало багато сходів, знадобиться закоткування поля після сівби. Щоб садити гібрид ефективно й мати добрий урожай, потрібно виконати ще кілька важливих дій:
                    </p>
                    <li className="card__description-numbering">боронування засадженої ділянки;</li>
                    <li className="card__description-numbering">застосування культиваторів для знищення бур’янів;</li>
                    <li className="card__description-numbering">підгортання й розпушування;</li>
                    <li className="card__description-numbering">підживлення.</li>
                </ul>
                <p className="card__description-text">
                    Через 5-6 днів після того, як була засіяна кукурудза MAS 24.C, проводиться боронування. У цей час вже проросли бур’яни. Для оброблення підійде середня або легка борона. Процедура виконується поперек рядків. Якщо провести боронування 203 рази до появи перших сходів, вдасться знищити до 70-80% бур’янів. Додаткове оброблення проводиться на другій-третій та четвертій-п’ятій фазах появи листків.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">
                            Стійкість до вилягання під час вегетації
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">
                            Стійкість до вилягання після вегетації
                        </div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Stay Green</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
