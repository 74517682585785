export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    NK Termo: особливості та характеристики
                </p>
                <p className="card__description-text">
                    Гібрид кукурудзи, який належить до середньостиглого типу та відзначається високими показниками врожайності, – це NK Termo. Представлений швейцарським виробником «Сингента». Під час дозрівання зерно швидко віддає велику кількість вологи. Рекомендований для вирощування в зонах лісостепу та степу України. «НК Термо» дає найкращі показники врожайності саме за таких кліматичних умов. Передбачає мінімальну та традиційну технології оброблення.
                </p>
                <p className="card__description-text">
                    Середньостиглий гібрид вирощують з метою отримання зерна. Середній показник врожайності становить 125,3 центнера на гектар. Якщо умови вирощування сприятливі, на рослині формуються два качани, повністю господарсько-придатні. Зерно має зубоподібну форму. Насіння найкраще проростає та сходить на ґрунтах, які відзначається високим агрофоном. Купити посівний матеріал кукурудзи, соняшника та добрива для них від офіційного виробника у Вінниці пропонує ТОВ «Санберрі Україна».
                </p>
                <p className="card__description-title">
                    Головні переваги гібрида
                </p>
                <p className="card__description-text">
                    Для стабільного зростання й розвитку та отримання гарного врожаю зерна висівати насіння потрібно в оптимально ранній термін. Серед представників фермерських господарств багато охочих купити посівний матеріал «НК Термо». Усе завдяки таким перевагам:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">висока стійкість до стресових умов, таких як посуха, різке зниження або підвищення температури;</li>
                    <li className="card__description-numbering">несприйнятливість до пухирчастої сажки;</li>
                    <li className="card__description-numbering">толерантність до стеблової та кореневої гнилі, вилягання;</li>
                    <li className="card__description-numbering">стійкість до гельмінтоспоріозу.</li>
                </ul>
                <p className="card__description-text">
                    Насіння кукурудзи «НК Термо» відзначається підвищеним коефіцієнтом використання поживних речовин. У зоні лісостепу на момент збирання рекомендована густота складає 60-70 тисяч рослин на гектар. У північному степу достатньо 45-55 рослин. Усе залежить також від вологозабезпечення ґрунту. Якщо вона достатня, густота стояння буде високою.
                </p>
                <p className="card__description-title">
                    Які є рекомендації з вирощування
                </p>
                <p className="card__description-text">
                    Культура найкраще реагує на землю, яка має високий агрофон. Саме в такі ґрунти її варто висаджувати. Насіння кукурудзи «Термо» найкраще засівати рано. Оптимальною температурою глибини загортання посівного матеріалу є +6-8 градусів. Гібрид належить до монокультур. Це означає, що можна повторно вирощувати його на одному й тому самому місці та навіть не боятися зниження показників врожайності.
                </p>
                <p className="card__description-text">
                    Перш ніж садити насіння, слід враховувати характеристики ґрунту. Від нього залежить глибина загортання. Наприклад, на важкому суглинку посівний матеріал закладають на 4-5 сантиметрів у землю.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Холодостійкість</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
