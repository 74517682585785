import "./Order.scss";
import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import logo from "../../image/logotype/logo.svg";
import { Link } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import { Helmet } from "react-helmet";

export default function Test() {
  const [email, setState] = useState("");
  const [value, setValue] = useState("");
  const [tel, setTel] = useState("");
  const [text, setText] = useState("");
  const [quantity, setQuantity] = useState("");

  function onChangeInput(e) {
    setState(e.target.value);
  }

  function inputValueClear(e) {
    setValue(e.target.value);
  }

  function telValueClear(e) {
    setTel(e.target.value);
  }

  function quantityValueClear(e) {
    setQuantity(e.target.quantity);
  }

  function textValueClear(e) {
    setText(e.target.quantity);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setValue("");
    setState("");
    setTel("");
    setText("");
    setQuantity("");
    emailjs
      .sendForm(
        "service_dhbzbzd",
        "template_wytj8ko",
        form.current,
        "54r806kZ5B5Q649la"
      )
      .then(
        (result) => {
          alert("Ваше повідомлення відправлено успішно");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="order">
      <Helmet>
        <meta name="keywords" content="Форма замовлення, адреса, телефон, email, товар, відправити" />
        <title>Форма замовлення товару в компанії Санберрі Україна</title>
        <link rel="canonical" href="https://sun-ukr.com/order" />
        <meta name="description" content="Заповніть форму і наш менеджер зв'яжеться з Вами."></meta>
      </Helmet>
      <div className="order__heading">
        <Link to="/">
          <div className="order__logo">
            <img className="TM" src={logo} alt="logo" />
          </div>
        </Link>
        <div className="heading__title title">
          <p className="title__item">Форма замовлення</p>
          <span className="title__item-title">
            Заповніть форму і наш менеджер зв'яжеться з Вами
          </span>
        </div>
        <div className="cart__form">
          <form
            className="form"
            ref={form}
            onSubmit={sendEmail}
            action="#"
            method="POST"
            encType="multipart/form-data"
          >
            <div className="form__header">
              <div className="form__authorization">
                <input
                  className="form__input form__input--hover"
                  type="text"
                  value={value}
                  name="name"
                  placeholder="Ім'я"
                  required
                  onChange={(e) => inputValueClear(e)}
                />
                <input
                  className="form__input form__input--hover"
                  type="email"
                  value={email}
                  name="email"
                  id="email"
                  placeholder="Е-mail"
                  onChange={(e) => onChangeInput(e)}
                />
                <MaskedInput
                  className="form__input form__input--hover"
                  mask={['+', '3', '8', ' ', '(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
                  pattern="^\+[0-9]{2}\s\((\d+)\)\s\d{3}\s\d{2}\s\d{2}"
                  type="tel"
                  name="phone"
                  value={tel}
                  placeholder="Телефон"
                  required
                  onChange={(e) => telValueClear(e)}
                />
                {/* <input
                  className="form__input form__input--hover"
                  type="text"
                  value={text}
                  name="text"
                  placeholder="назва компанії"
                  required
                  onChange={(e) => textValueClear(e)}
                /> */}
                <select className="select form__input form__input--hover select__input" name="text" value={text} onChange={(e) => textValueClear(e)} >
                  <option className="select_item" value="" disabled selected hidden>Покупець</option>
                  <option className="select_item" value="Юридична особа">Юридична особа</option>
                  <option className="select_item" value="Фізична особа">Фізична особа</option>
                </select>
                <textarea
                  className="form__input form__input--hover form__input--comment"
                  type="text"
                  value={quantity}
                  name="quantity"
                  placeholder="коментар"
                  onChange={(e) => quantityValueClear(e)}
                />
              </div>
            </div>
            <div className="order__submit">
              <button className="order__button" type="submit" value="Submit">
                Відправити
              </button>
              <Link to="/">
                <button className="order__button">
                  Повернутись назад
                </button>
              </Link>
            </div>
          </form>
          <div className="order__right">
            <div className="order__right-order">
              <div className="order__contact-info">
                <span className="order__info-title">Адреса</span>
                <span className="order__info-content">
                  м. Вінниця, вул. Сергія Зулінського, 43
                </span>
              </div>
              <div className="order__contact-info">
                <span className="order__info-title">Email</span>
                <span className="order__info-content">
                  sunberry.ukr@gmail.com
                </span>
              </div>
              <div className="order__contact-info">
                <span className="order__info-title">Телефон</span>
                <span className="order__info-content">+38(068)-369-90-30</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
