export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    «НК Бріо» — це один із найпопулярніших гібридів соняшнику в Україні та світі. Його можна вирощувати практично на будь-яких ґрунтах, при цьому він стійкий до грибкових захворювань, фомопсису, заразихи та інших болячок. Є середньостиглим. Стійкий до посухи, тому може рости у південних та центральних регіонах України. Гібрид адаптований до вирощування за класичною та інтенсивною технологією.
                </p>
                <div className="card__description-title">Особливості сорту</div>
                <p className="card__description-text">
                    Соняшник «Бріо» зростає заввишки до 160–170 сантиметрів. При цьому він не вилягає через сильний вітер або дощі. У нього щільний, міцний стовбур з великим листям. Вміст олії в насінні високий (доходить до 52 відсотків). Стабільність та потенціал урожаю — 9 одиниць.
                </p>
                <ol className="card__description-list">
                    <p className="card__description-text">
                        Також з особливостей можна виділити:
                    </p>
                    <li className="card__description-numbering">Хорошу врожайність. За мінімальних витрат ви отримаєте максимальний результат.</li>
                    <li className="card__description-numbering">Невибагливість. «НК Бріо» може вирощуватись практично по всій Україні, від півдня до півночі.</li>
                    <li className="card__description-numbering">Мінімальну потреба у добривах. Для соняшника рекомендуються спеціальні комплекси, які створюють з урахуванням якості та особливостей ґрунту.</li>
                </ol>
                <p className="card__description-text">
                    Цей гібрид — оптимальний вибір для фермера, який хоче отримати гарний урожай. Він відрізняється середньою енергією зростання на початкових етапах розвитку, але потім швидко набирає масу. Має високу стабільність навіть при культивації у складних умовах.
                </p>
                <div className="card__description-title">Поради щодо вирощування</div>
                <p className="card__description-text">
                    Розглянемо загальні рекомендації агрономів щодо культивації цієї культури:
                </p>
                <ol className="card__description-list">
                    <li className="card__description-numbering">
                        Для отримання максимальної врожайності варто використати інтенсивну технологію вирощування.
                    </li>
                    <li className="card__description-numbering">
                        Обов'язково проводити сівозміну ділянки. Не допускайте загущення посівів. Стандартна норма висіву — 45–55 тисяч рослин на гектар.
                    </li>
                    <li className="card__description-numbering">
                        Використовуйте пестициди, щоб заглушити бур'яни. Вони витягують з ґрунту вологу та мікро- і макроелементи, тим самим пригнічуючи культуру.
                    </li>
                </ol>
                <p className="card__description-text">
                    Також потрібно дотримуватися всіх рекомендацій виробника. Висадку роблять в оптимальні терміни, коли ґрунт уже прогрівся до +8–10 градусів. Не варто намагатися вирощувати культуру, якщо ваше поле заражене агресивною расою заразихи. Спочатку потрібно позбутися її, використовуючи спеціальні препарати.
                </p>
                <p className="card__description-text">
                    Якщо ви хочете купити соняшник «Бріо», рекомендуємо вам звертатися до нас. Ми пропонуємо нашим клієнтам тільки якісне насіння за доступними цінами. Наші менеджери дадуть відповідь на всі наявні питання, допоможуть вам вибрати відповідні сорти та комплексні добрива. Наш склад знаходиться у Вінниці, але ми доставляємо товари по всій території України найзручнішим для клієнтів способом.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--6">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">6</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
