import "./Blog.scss";
import { Link } from "react-router-dom";

export default function BlogPage() {

    return (
        <div className="blog__wrapper">
            <div className="main_blog__content-container">
                <p className="main_blog__content-container--text">Блог</p>
            </div>
            <div className="blog__content-container">
                <Link className="blog__image-content blog-content-strawberries" to="strawberries">
                    <div className="blog__image-content__date">
                        <span className="blog__image-content__day">Новинка!</span>
                    </div>
                </Link>
                <div className="blog__content-container-wrap">
                    <p className="blog__content-date">06.06.2022</p>
                    <h3 className="blog__content-main">Добрива для полуниці</h3>
                    <span className="blog__image-content__title">Солодку полуницю або, як її ще називають, садову суницю полюбляють і дорослі, і діти, тому охоче саджають та вирощують ягоди на присадибних ділянках та приватних фермах. Ретельно обираючи сорти за врожайністю, розміром плодів та їх смаковими якостями...</span>
                    <Link to="strawberries">
                        <button className="button button-primary">
                            <p className="">Детальніше</p>
                        </button>
                    </Link>
                </div>
            </div>
            <div className="blog__content-container">
                <Link className="blog__image-content blog-content-grape" to="grape">
                    <div className="blog__image-content__date">
                        <span className="blog__image-content__day">Новинка!</span>
                    </div>
                </Link>
                <div className="blog__content-container-wrap">
                    <p className="blog__content-date">27.06.2022</p>
                    <h3 className="blog__content-main">Добрива для Винограду</h3>
                    <span className="blog__image-content__title">Кожна рослина для свого нормального росту та життєдіяльності потребує поживних речовин, наприклад азоту, калію, кальцію, фосфору, магнію, сірки, заліза. Також в незначній кількості ще потрібні бор, цинк, мідь, марганець тощо.</span>
                    <Link to="grape">
                        <button className="button button-primary">
                            <p className="">Детальніше</p>
                        </button>
                    </Link>
                </div>
            </div>
            <div className="blog__content-container">
                <Link className="blog__image-content blog-content-blueberry" to="blueberry">
                    <div className="blog__image-content__date">
                        <span className="blog__image-content__day">Новинка!</span>
                    </div>
                </Link>
                <div className="blog__content-container-wrap">
                    <p className="blog__content-date">22.07.2022</p>
                    <h3 className="blog__content-main">Добрива для лохини</h3>
                    <span className="blog__image-content__title">Лохина – корисна ягода, джерело величезної кількості вітамінів, зовні схожа на чорницю. Раніше скуштувати її свіжою можна було лише в лісах Карпат чи на Поліссі. Проте цю рослину часто обирають городники, охочі виростити на своїй ділянці щось екзотичне та незвичайне...</span>
                    <Link to="blueberry">
                        <button className="button button-primary">
                            <p className="">Детальніше</p>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}