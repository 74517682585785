import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "./GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для плодово-ягідних культур, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для плодово-ягідних • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureBerry" />
                <meta
                    name="description"
                    content="🌱Добрива для плодово-ягідних культур ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для плодово-ягідних</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для плодово-ягідних</h1>
            </div>
            {/* <div className="culture__bg">
                <div className="culture__scheme"></div>
            </div> */}
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення плодово-ягідних</h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення ваших плодово-ягідних дерев
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                </Link>
                <div className="culture__content">
                    <p className="culture__desc">
                        На великих і малих сучасних ділянках яблуні, груші та інші плодово-ягідні дерева висаджують щільно й обробляють для раннього, швидкого та рясного плодоношення. В таких умовах вони потребують більше поживних елементів. Добрива для плодово-ягідних слід вносити за зваженою, детально продуманою схемою. Є певні фази зростання та формування врожаю, коли спеціальні препарати потрібні деревам у ще більшій кількості, ніж зазвичай.
                    </p>
                    <p className="culture__desc">
                        Наукові дослідження показують, що правильне використання добрив у саду сприяє збільшенню врожаю на 25-50%. Компанія «Санберрі Україна» – виробник розчинів «Агроболік Універсал» та «Агроболік Бор» для обробітку плодово-ягідних дерев. Ці препарати забезпечують рослини всіма необхідними для зростання та плодоношення речовинами. Містять молібден, марганець, залізо, фосфор, мідь, бор й інші мікроелементи.
                    </p>
                    <p className="culture__heading">Як вибрати добрива</p>
                    <ul className="culture__description-list">
                        <p className="culture__desc">
                            Сучасні інтенсивні сади передбачають особливий підхід під час використання спеціальних розчинів для підживлення. У різні фази фенологічного розвитку потреби рослин у корисних речовинах відрізняються. Вибираючи добрива для плодово-ягідних культур, варто враховувати такі фактори:
                        </p>
                        <li className="culture__description-numbering">
                            особливості клімату в регіоні, де знаходиться сад, характеристики ґрунту;
                        </li>
                        <li className="culture__description-numbering">
                            критичні фази розвитку культури, коли її потреба в елементах живлення зростає. Це фази набрякання бруньок, цвітіння, інтенсивний ріст і дозрівання плодів, а також етап після збору врожаю;
                        </li>
                        <li className="culture__description-numbering">
                            тривалість вирощування насаджень на одному місці – мається на увазі те, як довго конкретна ділянка використовується під плодово-ягідні;
                        </li>
                        <li className="culture__description-numbering">
                            засвоєння корисних препаратів рослиною.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Компанія «Санберрі Україна» пропонує купити добрива для плодових дерев, які належать до типу листових. Їх використовують на початкових етапах розвитку культури, у різних фазах.
                    </p>
                    <p className="culture__heading">Особливості використання</p>
                    <p className="culture__desc">
                        Навесні, коли з’являється листя, зав’язуються й наливаються плоди, найкраще використовувати розчини, що містять азот. Ця речовина добре засвоюється плодово-ягідними саме в цей період. Якщо азоту недостатньо, зменшується інтенсивність цвітіння, пилкова трубка росте повільно, вироджуються насіннєві зачатки. Азотовмісні препарати вносять у кілька заходів. Перший – навесні, останній – в червні, коли опадає зав’язь.
                    </p>
                    <p className="culture__desc">
                        Для активного росту та зміцнення кореневої системи необхідне внесення фосфору. Цей елемент сприяє появі нових суцвіть та якісному наливанню плодів. Поповнювати запаси фосфору по листу потрібно протягом шести тижнів після появи квіток. Так плоди краще наливатимуться та вийдуть достатньо великими, не дрібними.
                    </p>
                    <p className="culture__desc">
                        Фосфор є малорухливим елементом, тому добрива з ним закладають на певну глибину. Можливе й поверхневе внесення. Тоді живлення плодових дерев забезпечуватиметься вже в наступні роки вирощування. Спеціалісти ТОВ «Санберрі Україна» пропонують найбільш оптимальну схему для внесення добрив. Вона розробляється індивідуально, в залежності від конкретних потреб і характеристик ділянки.
                    </p>
                    <p className="culture__heading">Головні переваги</p>
                    <ul className="culture__description-list">
                        <p className="culture__desc">
                            Листові мікродобрива виробництва ТОВ «Санберрі Україна» доступні за ціною для будь-якого аграрія та гарантовано збільшують якість і кількість врожаю з плодових та ягідних дерев. Позитивна дія препаратів полягає в таких моментах:
                        </p>
                        <li className="culture__description-numbering">
                            усувають нестачу мікроелементів. Дефіцит потрібних речовин не лише погіршує врожайність. Через нього рослини стають більш вразливими до різних хвороб й уражень шкідниками;
                        </li>
                        <li className="culture__description-numbering">
                            активізують процеси росту. Це важливо як у період появи листя, так і під час набухання бруньок і зав’язування плодів;
                        </li>
                        <li className="culture__description-numbering">
                            сприяють гарному засвоєнню мінеральних розчинів для підгодовування;
                        </li>
                        <li className="culture__description-numbering">
                            компенсують вплив негативних чинників, до яких сприятливі плодові дерева;
                        </li>
                        <li className="culture__description-numbering">
                            збільшують цвітіння, покращують запилення.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Варто враховувати особливості засвоєння рослиною мікро- та макроелементів у різні етапи розвитку. На сприйняття деревами поживних речовин впливають і певні чинники. Це тип ґрунту на ділянці, використовуваний розчин і речовини для живлення в доступній для конкретної культури формі.
                    </p>
                    <p className="culture__heading">Як правильно вносити</p>
                    <p className="culture__desc">
                        «Агроболік Універсал» в садах зі щільною посадкою дерев рекомендується застосовувати в три етапи. Для фруктово-ягідних дерев це період розпускання бруньок, цвітіння та формування плодів. Оптимальне співвідношення інгредієнтів – 1 літр препарату на 200 літрів води. Норма – 2-4 літри на 1 гектар насаджень. Така ж сама схема внесення добрива «Агроболік Бор». У кожному із запропонованих складів достатньо фосфору, калію, полісахаридів, інших поживних речовин і мікроелементів.
                    </p>
                    <p className="culture__desc">
                        ТОВ «Санберрі Україна» має вигідні за ціною пропозиції для аграріїв. В залежності від потрібної кількості добрив, при замовленні призначається знижка. «Агроболік» треба вносити мінімум у 3-4 етапи, щоб задовольнити всі потреби рослини в різні фази розвитку.
                    </p>
                </div>
                <p className="culture__heading">Спеціальні пропозиції</p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
