import "../../GoodsSlider/GoodsSlider.scss";
import React from "react";
import Slider from "react-slick";
import arrowRight from "../../../../icons/arrow/Path right black.svg";
import arrowLeft from "../../../../icons/arrow/Path left black.svg";
import first from "../../../../image/goods.image/agrobolik/Phosphorus/Фос_20л.jpg";
import third from "../../../../image/goods.image/agrobolik/Phosphorus/Фос_1л.jpg";

export default class SimpleSlider extends React.Component {
    render() {
        const settings = {
            dots: false,
            fade: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            arrowsBlock: false,
            autoplay: false,
            autoplaySpeed: 3000,
            pauseOnHover: true,
            nextArrow: <button className="slick-arrow slick-arrow--right"><img className="arrow" src={arrowRight} alt="arrow" /></button>,
            prevArrow: <button className="slick-arrow slick-arrow--left"><img className="arrow" src={arrowLeft} alt="arrow" /></button>
        };
        return (
            <div className="sliderAB">
                <Slider {...settings}>
                    <div className="sliderAB__image-first">
                        <img className="first" src={first} alt="" />
                    </div>
                    <div className="sliderAB__image-third">
                        <img className="third" src={third} alt="" />
                    </div>
                </Slider>
            </div>
        );
    }
}
