export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <div className="card__description-title">
                    Карбамідо - аміачна суміш КАС 26 +b/zn
                </div>
                <p className="card__description-text">
                    КАС — високоякісне мінеральне добрива для всіх видів ґрунтів та під будь-які сільськогосподарські культури, представляє суміш водних розчинів карбаміду та аміачної селітри, що містить амонійний та нітратний азот аміачної селітри та амідний азот карбаміду. КАС 26 +b/zn - це рідке азотне добрива, що містить 26% азоту, відноситься до рідких азотних добрив.
                </p>
                <p className="card__description-text">
                    Технологія отримання розчину КАС заснована на змішуванні у певному співвідношенні водних розчинів карбаміду та аміачної селітри.
                </p>
                <p className="card__description-text">
                    При внесенні як основне добриво КАС 26 +b/zn є високоефективним рідким добривом, за властивостями рівноцінним аміачній селітрі або карбаміду за дією на всі види сільськогосподарських культур (рослин) для підвищення врожайності.
                </p>
                <div className="card__description-title">
                    Економічна ефективність використання КАС 26 +b/zn.
                </div>
                <div className="card__description-title">
                    Використання рідкого добрива КАС 26 +b/zn. Технологічність. (Це можна закинути в переваги збоку)
                </div>
                <p className="card__description-text">
                    Однією з головних переваг карбамідо -аміачного добрива КАС 26 +b/zn є його висока технологічність:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        Внесення азотного добрива КАС як рідких добрив рівномірніше, ніж твердих, гранульованих;
                    </li>
                    <li className="card__description-numbering">
                        Внесення КАС бажано поєднувати з використанням пестицидів в одній баковій суміші. Це дозволить скоротити витрати на догляд за посівами;
                    </li>
                    <li className="card__description-numbering">
                        Доцільно використовувати для дробових підживлень вегетуючих рослин. При цьому одночасно відбувається і кореневе і позакореневе підживлення. Залежно від фаз розвитку культурних рослин і обладнання КАС, що застосовується, розбавляють водою в необхідному співвідношенні або вносять не розбавляючи;
                    </li>
                    <li className="card__description-numbering">
                        Внесення рідкого добрива КАС 26 +b/zn добре поєднується з мікроелементами.
                    </li>
                </ul>
                <div className="card__description-title">
                    Застосування  КАС 26 +b/zn.
                </div>
                <p className="card__description-text">
                    Рідке добриво карбамідо -аміачної суміші КАС можна вносити двома основними способами – кореневим та позакореневим, за типом техніки – наземна та авіаційна техніка.
                </p>
                <p className="card__description-text">
                    Необхідність позакореневих підживлень добривом КАС 26 +b/zn обумовлюється такими факторами:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        Cтресовими ситуаціями (низькі температури, заморозки, нестача вологи тощо);
                    </li>
                    <li className="card__description-numbering">
                        Внесення КАС бажано поєднувати з використанням пестицидів в одній баковій суміші. Це дозволить скоротити витрати на догляд за посівами;
                    </li>
                    <li className="card__description-numbering">
                        Уповільнення інтенсивності засвоєння елементів живлення кореневою системою, що уповільнює темпи зростання та розвитку.
                    </li>
                </ul>
                <p className="card__description-text">
                    При низьких температурах та/або нестачі вологи у ґрунті поглинання поживних речовин значно сповільнюється. Досить часто критичні періоди щодо нестачі макроелементів та мікроелементів у зернових наступають у фазі виходу у трубку – колошення. Внаслідок інтенсивного, швидкого наростання вегетативної маси запаси легкодоступних елементів живлення із ґрунту вичерпуються або їх засвоєння «не встигає за темпами росту рослин». У такій ситуації необхідні позакореневі (листові) підживлення КАСом .
                    При цьому ступінь (відсоток) та швидкість засвоєння елементів живлення з добрива КАС 26 +b/zn через листову поверхню значно вища, ніж при засвоєнні з добрив, внесених у ґрунт. Для цих цілей найкраще підходить розчин КАС 26 +b/zn, розчинений водою. Амідна форма азоту швидко проникає через листову поверхню зернових колосових та інших культур.
                </p>
                <p className="card__description-text">
                    Листове підживлення рослин КАСом доцільно поєднувати із внесенням мікроелементів та (або) засобів захисту рослин.
                </p>
                <p className="card__description-text">
                    КАС 26 +b/zn дозволяє скоригувати підживлення в ключові фази розвитку рослини для корекції, посилення факторів, що бракують, у розвитку рослин і отримання необхідних характеристик готової товарної продукції (білок, клейковина, пивоварний ячмінь і т.д.).
                </p>
                <p className="card__description-text">
                    КАС 26 +b/zn – є додатковим інструментом у харчуванні сільськогосподарських культур за умов високоінтенсивного виробництва.
                </p>
                <div className="card__description-title">
                    КАС можна використовувати в такі терміни та способи:
                </div>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        Восени - застосування під основну обробку;
                    </li>
                    <li className="card__description-numbering">
                        Навесні - застосування під передпосівну обробку.
                    </li>
                    <li className="card__description-numbering">
                        Уповільнення інтенсивності засвоєння елементів живлення кореневою системою, що уповільнює темпи зростання та розвитку.
                    </li>
                </ul>
                <p className="card__description-text">
                    Застосовується рідке добриво КАС 26 +b/zn у період вегетації сільськогосподарських культур для кореневої та позакореневої підживлення. Норми витрати та дози внесення КАС 26 +b/zn залежать від виду сільськогосподарської культури, терміну внесення та способу, також враховується тип попередника та інші фактори.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
