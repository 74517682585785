import Header from "../Header/Header";
import Main from "../Main/Main";
import Footer from "../Footer/Footer";
import React from 'react';

export default function MainPage() {
	return (
		<div>
			<Header />
			<Main />
			<Footer />
		</div>
	);
}