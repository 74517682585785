export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-title">
                    Головні особливості насіння соняшнику «МАС 86.CP»
                </p>
                <p className="card__description-text">
                    Середньоранній гібрид лінолевого типу із високими показниками врожайності – це «МАС 86.CP». Відзначається гарною адаптованістю до кліматичних умов, навіть несприятливих, добре росте в різних ґрунтах. На стартовому етапі росту демонструє велику інтенсивність. Добре запиляється комахами, стійкий до більшості поширених захворювань соняшника. Рекомендований для вирощування в зонах степу, Полісся, лісостепу України. Представлений виробником MAS Seeds.
                </p>
                <p className="card__description-text">
                    Рослина виростає до середнього показника висоти. Має випуклий, дуже похилий кошик. Період вегетації триває 108-112 днів. Насіння соняшника найкраще садити в помірно та достатньо зволожених ґрунтах. Висівають гібрид зазвичай рано, адже на початковому етапі він росте дуже інтенсивно. Купити його від офіційного виробника за доступною ціною в Україні пропонує виробник добрив ТОВ «Санберрі Україна».
                </p>
                <div className="card__description-title">
                    Головні переваги гібрида
                </div>
                <p className="card__description-text">
                    Культура розроблена для вирощування шляхом Clearfield Plus та дозволяє оцінити всі її переваги. Цей спосіб передбачає використання гербіциду «Євро Лайтнінг плюс». Соняшник «МАС 86.СР» високо цінується у фермерських господарствах завдяки:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">стабільній продуктивності за різних погодних умов. Достатньо дотримуватися умов висівання й вибирати оптимальні кліматичні зони;</li>
                    <li className="card__description-numbering">надзвичайно високій стійкості до вовчка рас А-Е;</li>
                    <li className="card__description-numbering">несприятливості до білої гнилі стебла та кошика;</li>
                    <li className="card__description-numbering">толерантності до фомозу, фомопсису, борошнистої роси, склеротиніозу;</li>
                    <li className="card__description-numbering">високому вмісту олії – 47-50%.</li>
                </ul>
                <p className="card__description-text">
                    Посівний матеріал продається обробленим протруйником «Круїзер». Це необхідно для його захисту від шкідників, здатних сильно зіпсувати якість як самого насіння, так і рослини.
                </p>
                <div className="card__description-title">
                    Особливості вирощування культури
                </div>
                <p className="card__description-text">
                    Перед висіванням соняшника завжди оцінюють загрозу від вовчка. Там, де поля заражені цією агресивною рослиною-паразитом, слід використовувати технологію Clearfield. Це необхідно для ефективного захисту культури. Показник врожайності MAS 86.CP складає 25-30 центнерів на гектар засіяної площі. Він залишається стабільним за різних кліматичних умов, якщо дотримуватися рекомендацій до посіву.
                </p>
                <p className="card__description-text">
                    Необхідна просторова ізоляція полів у сівозміні – понад 1000 метрів. Такої відстані потрібно дотримуватися як для нових посівів, так і для тих, що висаджувалися в минулі роки. Цей самий показник є рекомендованим також між товарними й насіннєвими посівами цього гібрида соняшнику.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стійкість до вилягання</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
