import "./Services.scss";
import AwesomeButton from "../AwesomeButton/AwesomeButton";
import { Link } from "react-router-dom";
import first from "../../image/background-image/AdobeStock_321605471.jpeg";
import second from "../../image/background-image/SMB320-Agricultural-drone.jpg";
import React from "react";

export default function Services() {
    return (
        <div className="services__container">
            <div className="services__left">
                <div className="services__functional-left">
                    <span className="services__text">
                        Реставрація та доукомплектація КРН, Сівалок
                    </span>
                    <Link to="completion-of-krn">
                        <AwesomeButton />
                    </Link>
                </div>
                <img className="photo" src={first} alt="first" />
            </div>
            <div className="services__right">
                <img className="dron" src={second} alt="second" />
                <div className="services__functional-right">
                    <span className="services__text">
                        Оприскування дронами
                    </span>
                    <Link to="drone-spraying">
                        <AwesomeButton />
                    </Link>
                </div>
            </div>
        </div>
    );
}