export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    «Trio» є сортом сої, який відзначається високою врожайністю та вмістом білка. Завдяки вегетаційному періоду тривалістю 103 - 108 днів, цей сорт може дати до 3,5 тонн продукції з гектара.
                </p>
                <div className="card__description-title">Особливості сорту</div>
                <p className="card__description-text">
                    Однією з головних переваг «Trio» є високий вміст білка у зерні, який становить 38-42%. Це робить його ідеальним для виробництва соєвого борошна та соєвого масла.
                </p>
                <p className="card__description-text">
                    «Trio» також відзначається стійкістю до стресових умов, таких як засуха та хвороби. Цей сорт сої може бути успішно вирощений в різних кліматичних умовах, що робить його популярним серед фермерів.
                </p>
                <p className="card__description-text">
                    Рекомендується висівати сою сорту «Trio» в пізньому весняному або ранньому літньому періоді для досягнення найкращих результатів. Дотримання правильного агротехнічного режиму під час вегетації допоможе забезпечити формування великого та якісного врожаю.
                </p>
                <p className="card__description-text">
                    Оберіть сою сорту «Trio» для високоякісного вирощування з високим вмістом білка та відмінним смаком продукції.
                </p>
                <div className="card__description-title">Характеристики:</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сорт:</div>
                        <div className="characteristic__value">«Trio»</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Тип:</div>
                        <div className="characteristic__value">Соя</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вегетаційний період:</div>
                        <div className="characteristic__value">103 - 108 днів</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">CHU:</div>
                        <div className="characteristic__value">2450</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Потенціал (т):</div>
                        <div className="characteristic__value">4,8</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Маса 1000 нас.:</div>
                        <div className="characteristic__value">152</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Схожість:</div>
                        <div className="characteristic__value">94%</div>
                    </div>
                </div>
                <div className="aside__title">Норма висіву</div>
                <div className="aside__content">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Норма висіву (тис. насінин):</div>
                        <div className="characteristic__value">675</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Норма висіву (кг):</div>
                        <div className="characteristic__value">110</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Реком. міжряддя (см):</div>
                        <div className="characteristic__value">15-45</div>
                    </div>
                </div>
                <div className="aside__title">Стійкість до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до осипання:</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до хвороб:</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Рекомендований протруйник</div>
                        <div className="characteristic__value">"Вайбранс RFC"</div>
                    </div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block"><span className="aside__block--bold">Адаптація:</span> «Trio» добре адаптується до різних типів ґрунтів і кліматичних умов, що робить його популярним серед фермерів з різних регіонів.</div>
                    <div className="aside__block"><span className="aside__block--bold">Висока якість продукції:</span> Продукція, виготовлена з цього сорту сої, відзначається не лише високим вмістом білка, але і відмінним смаком та корисними властивостями.</div>
                    <div className="aside__block"><span className="aside__block--bold">Технологічність:</span> Насіння «Trio» має високу технологічність, що робить його легкою у використанні для виробництва соєвого борошна, соєвого масла та інших продуктів.</div>
                    <div className="aside__block"><span className="aside__block--bold">Стійкість до стресів:</span> Цей сорт сої відзначається високою стійкістю до стресових умов, таких як засуха, посушливий вітер та хвороби, що дозволяє отримати стабільний врожай навіть в негативних умовах.</div>
                </div>
            </div>
        </div>
    );
}
