export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <div className="card__description-title">
                    Застосування
                </div>
                <p className="card__description-text">
                    Добриво Suprofos 12 NPK (S) 4-12-12 (25) рекомендується вносити передпосівом та одночасно з посівом для усіх сільськогосподарських культур на всіх типах ґрунтів. Містить сірку, що сприяє кращому засвоєнню азоту та підвищенню врожайності культур. Підвищує стійкість рослин до факторів навколишнього середовища, підвищує якість та кількість урожаю.
                </p>
                <div className="card__description-title">
                    Склад
                </div>
                <table className="card__description__table">
                    <tr>
                        <td className="card__description__td">Азот (N), загальний</td>
                        <td className="card__description__th-index">4%</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">П'ятиокис фосфору (P₂O₅), загальний, в т.ч. 8% розчинного у воді</td>
                        <td className="card__description__th-index">12%</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Оксид калію (K₂O), розчинний у воді</td>
                        <td className="card__description__th-index">12%</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Триоксид сірки (SO₃), загальний</td>
                        <td className="card__description__th-index">25%</td>
                    </tr>
                </table>
                <div className="card__description-title">
                    Дозування
                </div>
                <table className="card__description__table">
                    <tr>
                        <th className="card__description__th">Культури</th>
                        <th className="card__description__th">Норма внесення добрив</th>
                    </tr>
                    <tr>
                        <td className="card__description__td">Соняшник</td>
                        <td className="card__description__th-index">150-300 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Соя, горох</td>
                        <td className="card__description__th-index">120-200 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Кукурудза</td>
                        <td className="card__description__th-index">120-250 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Цукрові буряки</td>
                        <td className="card__description__th-index">150-300 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Озима та яра пшениця</td>
                        <td className="card__description__th-index">120-250 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Озимий та ярий ріпак</td>
                        <td className="card__description__th-index">150-300 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Озимий та ярий ячмінь</td>
                        <td className="card__description__th-index">120-250 кг/га</td>
                    </tr>
                    <tr>
                        <td className="card__description__td">Картопля, овочі</td>
                        <td className="card__description__th-index">110-220 кг/га</td>
                    </tr>
                </table>
                <div className="card__description-title">
                    Властивості
                </div>
                <ol className="card__description-list">
                    <li className="card__description-numbering">
                        високоякісне комплексне добриво для широкого спектру польових культур для передпосівного та припосівного внесення;
                    </li>
                    <li className="card__description-numbering">
                        підходить для всіх типів ґрунтів, незалежно від їх механічного складу;
                    </li>
                    <li className="card__description-numbering">
                        Поживні речовини знаходяться у формі, яка легко засвоюється рослинами.
                    </li>
                </ol>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
