export default function reference() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Основні характеристики</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Комерційна назва</div>
                        <div className="characteristic__value">MAC 86.КП (MAS 86.CP)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Культура</div>
                        <div className="characteristic__value">Соняшник</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Виробник</div>
                        <div className="characteristic__value">MAS Seeds</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Група стиглості</div>
                        <div className="characteristic__value">середньоранній</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Цвітіння</div>
                        <div className="characteristic__value">середньораннє</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Стійкість до вовчка</div>
                        <div className="characteristic__value">А-Е</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Тип Олійності</div>
                        <div className="characteristic__value">Л-лінолевий</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Спец. технології</div>
                        <div className="characteristic__value">Clearfield Plus</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Висота рослини</div>
                        <div className="characteristic__value">середня</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Форма кошика</div>
                        <div className="characteristic__value">випукла</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Нахил кошика</div>
                        <div className="characteristic__value">дуже похилий</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Сер. маса 1000 насінин</div>
                        <div className="characteristic__value">55 г</div>
                    </div>
                </div>
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                <div className="aside-char--basic">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Стійкість до вилягання</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Вміст олії</div>
                        <div className="characteristic__value">до 47-50%</div>
                    </div>
                </div>
                <div className="aside__title">Толерантність до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Фомопсис (Phomopsis)</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Біла гниль кошика (Sclerotinia)</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Біла гниль стебла (Sclerotinia)</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Несправжня борошниста роса</div>
                        <div className="characteristic__value">RM9</div>
                    </div>
                </div>
                <div className="aside__title">Рекомендована густота на момент збору врожаю</div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Недостатнє зволоження</div>
                    <div className="characteristic__value">50 - 55 тис. росл./га</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Достатнє зволоження</div>
                    <div className="characteristic__value">60 тис. росл./га</div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">Високо толерантний до основних хвороб</div>
                    <div className="aside__block">Стабільно високий рівень продуктивності</div>
                    <div className="aside__block">Всі переваги технології Clearfield Plus</div>
                </div>
            </div>
        </div>
    );
}
