import "./Spares.scss";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import logo__mac from "../../image/logotype/logo_makoch.png";
import logo__agro from "../../image/logotype/logo_agr.png";
import logo__pos from "../../image/logotype/logo_posivna.webp";
import logo__harvest from "../../image/logotype/harvest_logo-removebg-preview.png";
import logo__ugtehagro from "../../image/logotype/logo_ugtehagro.webp";
import logo__ast from "../../image/logotype/logo-ast.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import crumbs from "../../icons/icons8-home.svg";
import { Link } from "react-router-dom";

export default function Spares() {

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    return null;
  }

  return (
    <>
      <Helmet>
        <meta name="keywords" content="партнери, наші партнери, санберрі" />
        <title>Партнери компанії Санберрі Україна</title>
        <link rel="canonical" href="https://sun-ukr.com/partners" />
        <meta name="description" content="Партнери компанії Санберрі України. Приєднуйтесь та ставайте нашими партнерами і Ви."></meta>
      </Helmet>
      <ScrollToTopOnMount />
      <Header />
      <div className="spares-bg__photo"></div>
      <div className="crumbs">
        <Link className="crumbs__link" to="/">
          <img className="crumbs__logo" src={crumbs} alt="crumbs" />
        </Link>
        <span>/ Партнери</span>
      </div>
      <div className="spares">
        <div className="spares__container">
          <div className="sparesContact__top">
            <div className="sparesContact__top-block">
              <div className="sparesContact__top--border"></div>
            </div>
            <div className="sparesContact__top-text">
              <h1 className="spares__title">Наші Надійні Партнери</h1>
            </div>
            <div className="sparesContact__top-block">
              <div className="sparesContact__top--border"></div>
            </div>
          </div>
          <div className="spares__inner">
            <div className="spares__under-block">
              <span>Разом досягаємо великих результатів!</span>
            </div>
            <div className="spares__block">
              <span className="spares__block-text">“Наші партнери - це важлива складова нашого успіху. Ми спільно вирішуємо завдання, створюємо інновації та досягаємо нових висот у бізнесі. Разом ми надійні та неперевершені”</span>
            </div>
            <div className="spares__content">
              <div className="spares__portfolio">
                <div className="spares__portfolio--logotype">
                  <img className="slider__partners-logo" src={logo__mac} alt="logo" />
                </div>
                <div className="spares__portfolio--address">
                  <p className="slider__partners-text">Макош Мінерал</p>
                  <p className="slider__partners-text">с. Зарванці</p>
                  <a className="spares__webSyte" target="blank" href="https://makosh-group.com.ua">
                    <button type="button" className="sliderPart__link">
                      makosh-group.com.ua
                    </button>
                  </a>
                </div>
                <div className="spares__portfolio--info">
                  <span>Компанія "Макош" - один із лідерів на ринку мінеральних добрив та добрив по листу для агровиробників України. Вже 9 років допомагаємо українським аграріям примножувати врожаї.</span>
                </div>
              </div>
              <div className="spares__portfolio">
                <div className="spares__portfolio--logotype">
                  <img className="slider__partners-logotype4" src={logo__agro} alt="logo" />
                </div>
                <div className="spares__portfolio--address">
                  <p className="slider__partners-text">АГРОСТАДІОН</p>
                  <p className="slider__partners-text">м. Вінниця</p>
                  <div className="spares__wrapper">
                    <a className="spares__webSyte" target="blank" href="https://agrostadion.com">
                      <button type="button" className="sliderPart__link">
                        agrostadion.com
                      </button>
                    </a>
                  </div>
                </div>
                <div className="spares__portfolio--info">
                  <span>Наше завдання - повне та своєчасне забезпечення фермерських господарств та приватних землевласників якісними засобами захисту рослин, добривами та насінням овочевих культур. Тісна співпраця - запорука успіху та досягненню бажаного резутьтату!</span>
                </div>
              </div>
              <div className="spares__portfolio">
                <div className="spares__portfolio--logotype">
                  <img className="slider__partners-logotype2" src={logo__ugtehagro} alt="logo" />
                </div>
                <div className="spares__portfolio--address">
                  <p className="slider__partners-text">Югтехагро</p>
                  <p className="slider__partners-text">м. Запоріжжя</p>
                  <div className="spares__wrapper">
                    <a className="spares__webSyte" target="blank" href="https://ugtehagro.net/">
                      <button type="button" className="sliderPart__link">
                        ugtehagro.net
                      </button>
                    </a>
                  </div>
                </div>
                <div className="spares__portfolio--info">
                  <span>Продаж сільгосптехніки зростає разом з попитом на неї, підприємства на гідному рівні пропонують жниварки, косарки, підбирачі, комбайни різних класів, моделей і вартості. Відомо, що купуючи найкраще, ми економимо, тому зупинити свій вибір варто на якісному, сертифікованому обладнанні, яке пропонує компанія ТОВ «Югтехагро».</span>
                </div>
              </div>
              <div className="spares__portfolio">
                <div className="spares__portfolio--logotype">
                  <img className="slider__partners-logo" src={logo__pos} alt="logo" />
                </div>
                <div className="spares__portfolio--address">
                  <p className="slider__partners-text">POSIVNA</p>
                  <p className="slider__partners-text">м. Київ</p>
                  <div className="spares__wrapper">
                    <a className="spares__webSyte" target="blank" href="https://posivna.com.ua">
                      <button type="button" className="sliderPart__link">
                        posivna.com.ua
                      </button>
                    </a>
                  </div>
                </div>
                <div className="spares__portfolio--info">
                  <span>Посівна - аграрний інтернет-магазин, де вигідно купувати посівний матеріал і засоби захисту рослин. Ми розвиваємо аграрний бізнес, маючи довгострокову стратегію. </span>
                </div>
              </div>
              <div className="spares__portfolio">
                <div className="spares__portfolio--logotype">
                  <img className="slider__partners-logotype3" src={logo__harvest} alt="logo" />
                </div>
                <div className="spares__portfolio--address">
                  <p className="slider__partners-text">HarVest Center</p>
                  <p className="slider__partners-text">м. Вінниця</p>
                  <div className="spares__wrapper">
                    <a className="spares__webSyte" target="blank" href="https://harvest-center.com.ua">
                      <button type="button" className="sliderPart__link">
                        harvest-center.com.ua
                      </button>
                    </a>
                  </div>
                </div>
                <div className="spares__portfolio--info">
                  <span>Наша місія: запропонувати якісні пестициди, добрива, насіння, обладнання для поливу, укривні матеріали, субстрати тощо за доступними цінами. Надати консультації з приводу систем захисту та підживлення на основі сучасних технологій вирощування.</span>
                </div>
              </div>
              <div className="spares__portfolio">
                <div className="spares__portfolio--logotype">
                  <img className="slider__partners-logo1" src={logo__ast} alt="logo" />
                </div>
                <div className="spares__portfolio--address">
                  <p className="slider__partners-text">АСТРЕЯ-НС</p>
                  <p className="slider__partners-text">м. Вінниця</p>
                  <div className="spares__wrapper">
                    <a className="spares__webSyte" target="blank" href="https://lair.com.ua">
                      <button type="button" className="sliderPart__link">
                        lair.com.ua
                      </button>
                    </a>
                  </div>
                </div>
                <div className="spares__portfolio--info">
                  <span>"Астрея-НС" була заснована в 2009 році  Завдяки великому асортименту, надійності та високому рівню обслуговування, компанія швидко завоювала популярність серед фермерських господарств.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
      <Footer />
    </>
  );
}
