import fruit from "../../icons/blog/icon_strawberries.png";
import grapes from "../../icons/blog/grapes.png";
import bilberry from "../../icons/blog/bilberry.png";
import potato from "../../icons/blog/potatoes.png";
import cucumber from "../../icons/blog/cucumber.png";
import nutrition from "../../icons/blog/iron.png";

export const items = [
    {
        title: 'Добрива для полуниці',
        desc: 'Солодку полуницю або, як її ще називають, садову суницю полюбляють і дорослі, і діти, тому охоче саджають та вирощують ягоди на присадибних ділянках та приватних фермах. Ретельно обираючи сорти за врожайністю, розміром плодів та їх смаковими якостями...',
        class: 'blog__col',
        date: '06.06.2022',
        visability: '598',
        button: 'strawberries',
        link: 'strawberries',
        img: <img className="blog__fruit" src={fruit} alt="bg_fruit" />,
        id: "1"
    },
    {
        title: 'Добрива для Винограду',
        desc: 'Кожна рослина для свого нормального росту та життєдіяльності потребує поживних речовин, наприклад азоту, калію, кальцію, фосфору, магнію, сірки, заліза. Також в незначній кількості ще потрібні бор, цинк, мідь, марганець тощо.',
        class: 'blog__col1',
        date: '27.06.2022',
        visability: '116',
        button: 'grape',
        img: <img className="blog__fruit" src={grapes} alt="bg_fruit" />,
        id: "2"
    },
    {
        title: 'Добриво для Лохини',
        desc: 'Лохина – корисна ягода, джерело величезної кількості вітамінів, зовні схожа на чорницю. Раніше скуштувати її свіжою можна було лише в лісах Карпат чи на Поліссі. Проте цю рослину часто обирають городники, охочі виростити на своїй ділянці щось екзотичне та незвичайне...',
        class: 'blog__col2',
        date: '22.07.2022',
        visability: '458',
        button: 'blueberry',
        img: <img className="blog__fruit" src={bilberry} alt="bg_fruit" />,
        id: "3"
    },
    {
        title: 'Добрива для Картоплі',
        desc: 'Картопля є однією з найважливіших та найпопулярніших культур у сільському господарстві. Вона присутня в кожному домі, її купують протягом усього року й використовують для приготування величезної кількості страв...',
        class: 'blog__col3',
        date: '09.09.2022',
        visability: '12',
        button: 'potato',
        img: <img className="blog__fruit" src={potato} alt="bg_fruit" />,
        id: "4"
    }, {
        title: 'Добрива для Огірків',
        desc: 'Кожен з городників, хто взявся за вирощування цих культур, прагне отримати гарний врожай. Але досягти цього не так просто, адже доводиться враховувати багато різноманітних факторів. Серед них стан ґрунту, температура повітря, термін посадки, полив...',
        class: 'blog__col4',
        date: '14.10.2022',
        visability: '21',
        button: 'cucumber',
        img: <img className="blog__fruit" src={cucumber} alt="bg_fruit" />,
        id: "5"
    }, {
        title: 'Роль елементів живлення в розвитку рослини',
        desc: 'Кожен з елементів живлення, які споживають рослини, відіграє свою роль в обміні речовин та в процесах росту і розвитку.',
        class: 'blog__col5',
        date: '18.10.2022',
        visability: '563',
        button: 'nutrition',
        img: <img className="blog__fruit" src={nutrition} alt="bg_fruit" />,
        id: "6"
    }
];
