export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Карбамід (сечовина) CO(NH2)2 — найбільш концентроване з твердих азотних добрив, що містить 46% азоту.  Увесь азот  знаходиться в амідній формі, водорозчинне, повільнодіюче, безнітратне добриво з майже нейтральною реакцією.
                </p>
                <div className="card__description-title">
                    Загальна інформація
                </div>
                <p className="card__description-text">
                    Застосовується в системах удобрення в усіх ґрунтово-кліматичних зонах як в основне внесення, так і для підживлення.
                </p>
                <p className="card__description-text">
                    У системі удобрення ярих зернових культур вносять під передпосівну культивацію.
                </p>
                <p className="card__description-text">
                    Більш ефективно карбамід слідує використовувати під культури з довгим вегетаційним періодом.У ґрунті амідна форма трансформується в аміачну, а пізніше – в нітратну. Процеси амоніфікації та нітрифікації  відбуваються повільно, за температур + 20°С і більше, тому азот з карбаміду більш рівномірно засвоюється рослинами впродовж вегетації — пролонгована дія добрив.  Амідний азот не вимивається з ґрунту, його втрати – зовсім мінімальні.
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        азот – 46 %;
                    </li>
                </ul>
                <ol className="card__description-list">
                    <p className="card__description-title">
                        Переваги:
                    </p>
                    <li className="card__description-numbering">
                        Добре засвоюється рослинами.
                    </li>
                    <li className="card__description-numbering">
                        Швидкодія (під час обприскування білок рослин отримує азот вже через 2 доби).
                    </li>
                    <li className="card__description-numbering">
                        Добре розчиняється у воді (за температури 35 градусів розчиняється майже 60%).
                    </li>
                    <li className="card__description-numbering">
                        Активне розкладання в грунті.
                    </li>
                    <li className="card__description-numbering">
                        Застосовується для позакореневого та кореневого підживлення.
                    </li>
                    <li className="card__description-numbering">
                        Живить рослини.
                    </li>
                    <li className="card__description-numbering">
                        Бореться зі шкідниками.
                    </li>
                    <li className="card__description-numbering">
                        Підвищує врожайність плодових дерев (обприскуючи навесні уповільнюється цвітіння та мінімізується опадання квіток за весняних заморозків).
                    </li>
                    <li className="card__description-numbering">
                        Не викликає опіку листя навіть при 10% концентрації у водному розчині.
                    </li>
                    <li className="card__description-numbering">
                        Чинить найменший окислюючий вплив на ґрунт в порівнянні з  іншими азотними добривами.
                    </li>
                    <li className="card__description-numbering">
                        Ефективний для застосування на піщаних і супіщаних грунтах.
                    </li>
                    <li className="card__description-numbering">
                        Значно покращує зовнішній вигляд плодів і рослин.
                    </li>
                </ol>
                <ol className="card__description-list">
                    <p className="card__description-title">
                        Недоліки:
                    </p>
                    <li className="card__description-numbering">
                        При неправильному внесенні в ґрунт може знизити схожість насіння і пошкодити пагони (через виділення аміаку необхідно вносити в грунт раніше посівної).
                    </li>
                    <li className="card__description-numbering">
                        Вимагає суворого дотримання умов зберігання (може злежатися).
                    </li>
                    <li className="card__description-numbering">
                        Не  сумісний з вапном і золою.
                    </li>
                    <li className="card__description-numbering">
                        При грануляції утворюється біурет (після внесення в грунт, біурет за 15 днів розкладається).
                    </li>
                </ol>
                <div className="card__description-title">
                    Варіанти застосування карбаміду
                </div>
                <p className="card__description-text">
                    Для ярих зернових культур вносять в передпосівну культивацію. Обов’язкове закладення в грунт, щоб запобігти втраті азоту. Слід врахувати, що при внесенні на 15 см в грунт, втрати азоту зменшуються в два рази, ніж при закладенні на 5 см. Втрати азоту при поверхневому внесенні, без змішування з землею, можуть досягати 50%.
                </p>
                <p className="card__description-text">
                    Витрати матеріалу не повинні перевищувати 2,5 ц на 1 га.
                </p>
                <p className="card__description-text">
                    В сучасних умовах «ризикованого» землеробства (за наявності нестабільних погодних умов), внесення добрив в грунт стає малоефективним. Вирішенням питання може стати застосування стабілізованого карбаміду (карбамід з інгібітором уреази). Використовуючи це з’єднання можна продовжити фазу амонійного азоту в грунті від 4 до 8 тижнів. В результаті рослини отримують азот дозовано. При використанні інгібітора уреази значно зменшується виділення токсичного аміаку.
                </p>
                <p className="card__description-text">
                    Найвища ефективність сечовини виявляється в умовах використання методу обприскування листя.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
