import "./Item.scss";
import { Link } from "react-router-dom";

export default function Item(props) {

  return (
    <div className={"item " + (props.isNew && 'item--new')}>
      <div className="itemCorn__left">
        <div className="item__title">
          <Link to={props.inside}>
            <span className="title">{props.title}</span>
          </Link>
          <span className="item__new-arr">НОВИНКА!</span>
        </div>
        <div className="test-1">
          <div className="itemCorn__image-container">
            <div className="itemCorn__image">
              <div className="agroteinslider__image">
                {props.imgUrl}
              </div>
            </div>
          </div>
          <div className="itemCorn__center">
            <div className="item__block">
              <div className="itemCorn__logo">{props.imgLogo}</div>
              <span className="item__factory-text">Виробник: <span className="item__factory-name">{props.producer}</span> </span>
            </div>
            <div className="item__zone">
              <div className="item__packing">
                <span className="item__packing-text">Фасування:</span>
                <div className="item__packing-position">
                  <div className="itemCorn__capacity-tabs">
                    <button className="itemCorn__cap-tabs active-itemCorn__cap-tabs">1 п.о.</button>
                  </div>
                </div>
              </div>
              <div className="item__price">
                <div className="price__charts">
                  <span className="price__item">Роздрібна ціна</span>
                </div>
                <div className="price item__capacity-tabs-active">
                  <div className="capacity  active-capacity">
                    {props.priceFirst}
                  </div>
                </div>
                <div className={"item__stock " + (props.InStock && 'item__stock--new')}>
                  <span className="item__stock-arr">в наявності</span>
                  <span className="item__stock-road">Уточнюйте наявність</span>
                </div>
              </div>
            </div>
            <div className="item__suborder">
              <div className="item__order">
                {props.link}
              </div>
              <Link to={props.inside}>
                <p className="item__desc">Детальніше</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
