import "./Header.scss";
import { Link } from "react-router-dom";
import NavigationHamburger from "../Navigation/NavigationHamburger";
import logo from "../../image/logotype/logo.svg";
import React from "react";

export default function Funcional() {
	return (
		<>
			<div className="header__bottom fadeIn">
				<div className="header__logo">
					<Link to="/">
						<img className="TM" src={logo} alt="logotype" />
					</Link>
				</div>
				<div className="header__navigation navigation">
					<NavigationHamburger />
				</div>
			</div>
		</>
	);
}
