export default function reference() {
    return (
        <div className="card__reference-basic">
            <div className="card__reference">
                <div className="aside__title">Основні характеристики</div>
                <div className="characteristic__wrap">
                    <div className="characteristic__container">
                        <div className="characteristic__label">Комерційна назва</div>
                        <div className="characteristic__value">СИ Теліас (SY Telias)</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Культура</div>
                        <div className="characteristic__value">Кукурудза</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Виробник</div>
                        <div className="characteristic__value">Syngenta</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Призначення</div>
                        <div className="characteristic__value">зерно</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">ФАО</div>
                        <div className="characteristic__value">220</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Тип зерна</div>
                        <div className="characteristic__value">проміжний</div>
                    </div>
                    <div className="characteristic__container">
                        <div className="characteristic__label">Спец. технології</div>
                        <div className="characteristic__value">відсутні</div>
                    </div>
                </div>
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Холодостійкість</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Толерантність до посухи</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Швидкість вологовіддачі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
                <div className="aside__title">Толерантність до хвороб</div>
                <div className="aside__content">
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Кореневі та стеблові гнилі</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char--basic">
                        <div className="aside-char__head">Пухирчата сажка</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                </div>
                <div className="aside__title">Рекомендована густота на момент збору врожаю</div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Посушливі умови</div>
                    <div className="characteristic__value">40 - 55 тис. росл./га</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Недостатнє зволоження</div>
                    <div className="characteristic__value">60 - 80 тис. росл./га</div>
                </div>
                <div className="characteristic__container">
                    <div className="characteristic__label">Достатнє зволоження</div>
                    <div className="characteristic__value">60 - 80 тис. росл./га</div>
                </div>
            </div>
            <div className="card__reference-preference">
                <div className="aside__title--new">Переваги</div>
                <div className="asede__head">
                    <div className="aside__block">Високоврожайний і стабільний</div>
                    <div className="aside__block">Швидкий ріст на початку вегетації</div>
                </div>
            </div>
        </div>
    );
}
