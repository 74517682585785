import "./SunflowerCard.scss";
import Header from "../../../../../Header/Header";
import Footer from "../../../../../Footer/Footer";
import GoodsList from '../../../../GoodsList/GoodsListSeed/GoodsListSunflower';
import crumbs from "../../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FertilizersTabs() {
    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="купити, насіння, посівмат, соняшник, Санберрі Україна" />
                <title>Насіння соняшника • Купити посмівмат соняшника оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/sunflowerCard" />
                <meta name="description" content="🌻Насіння соняшника оптом і в роздріб ⭐ Від якісних виробників Syngenta та MAS Seeds ✅ Замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalogSeed">
                    <span>/ Насіння</span>
                </Link>
                <span className="crumbs__text">/ Насіння соняшнику</span>
            </div>
            <div className="fertilizersCard__catalog">
                <div className="fertilizersCard__title">
                    <h1 className="fertilizersCard__title__above--color">Насіння соняшнику</h1>
                </div>
                <div>
                    <GoodsList />
                </div>
                <div className="catalog__content">
                    <p className="catalog__desc">
                        Соняшник — це популярна олійна сільськогосподарська культура, яку вирощують багато українських фермерів. Вона відзначається високою врожайністю та приносить стабільний дохід. Головне — знайти хороший посівмат соняшника, а також підібрати добрива, які підходять для вашої землі. Отримати детальні консультації з усіх питань ви можете в наших менеджерів — зателефонуйте нам, і ми допоможемо вам вибрати підхожу продукцію за доступними цінами.
                    </p>
                    <p className="catalog__desc">
                        Найбільшу популярність в українських фермерів має насіння гібридів. Практика показує, що вони більш стійкі до хвороб і грибка, а також можуть зростати в будь-яких регіонах країни (зокрема й у посушливих). Вони однорідні за висотою та швидкістю дозрівання, а також мають рівень врожайності на 20-30% більше, ніж у класичних сортів. Саме тому й рекомендується вибирати гібриди — вони простіші в догляді, дають якісний урожай і не вимагають особливих умов для зростання.
                    </p>
                    <p className="catalog__description-title">Технології вирощування</p>
                    <p className="catalog__caption">
                        В Україні використовується три основні технології культивації цієї сільськогосподарської культури:
                    </p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Стандартна. Передбачає використання ґрунтових гербіцидів, які дозволяють ефективно боротися з молодими бур'янами. Відзначається дуже гарною врожайністю: за умови правильного догляду можна зібрати до 40 тонн насіння з кожного гектара. Підходить як для південних областей, так і для центру або півночі України.
                        </li>
                        <li className="catalog__description-numbering">
                            Clearfield (у перекладі з англійської означає «чисте поле»). У методиці використовуються гербіциди типу «Євро-лайтнінг», які дозволяють обробляти поля після появи сходів. Вони ефективні як проти класичних бур'янів, так і проти заразихи.
                        </li>
                        <li className="catalog__description-numbering">
                            Express Sun. Ця техніка передбачає використання системних гербіцидів, які містять трибенурон-метил. Вони ефективно борються з осотом й іншими двочастковими бур'янами. Зазвичай використовуються один раз за сезон.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Зазначимо, що кожна технологія має свої переваги й особливості. Різновид оброблення підбирають, враховуючи стан поля, тип культур, які вирощувалися минулого року, різновиди бур'янів у місцевості та ін. Практика показує, що правильно підібрана технологія допомагає підвищити врожайність на 20-25 відсотків.
                    </p>
                    <p className="catalog__desc">
                        Якщо говорити про сівозміну, то слід зазначити, що соняшник — культура досить агресивна, вона активно висмоктує з ґрунту корисні мікро- та макроелементи. Зазвичай цикл повернення рослини на поле становить 7-8 років, що дозволяє досягти гарного врожаю в майбутньому. Перед соняшником рекомендується висаджувати кукурудзу, озиму пшеницю, а також картоплю. Після збору врожаю варто залишити поле під парою або засіяти його соєю, яка насичує ґрунт азотом.
                    </p>
                    <p className="catalog__desc">
                        Висаджування насіння здійснюють на глибину 4-5 сантиметрів на важких ґрунтах, на 5-6 — на легких. Землю обов'язково обробляють перед посівом, не тільки переорюючи, а й розпушуючи її. Великі груди ґрунту порушують схожість та збивають рядність зростання. Якщо посадка проводиться в посушливих місцях, то роблять прикочування поверхні. Завдяки цьому забезпечується більш якісний контакт насіння та ґрунту.
                    </p>
                    <p className="catalog__description-title">Особливості посадки</p>
                    <p className="catalog__desc">
                        Оптимальними умовами для посівного соняшника є нагрівання землі на глибину 10 сантиметрів до +10 градусів, а також наявність ґрунтової вологи. Якщо садити насіння раніше, ніж земля прогріється до потрібної температури, схожість знижується на 20-30 відсотків, а ризики підхопити грибкові інфекції зростають. Якщо пізніше, то рослинам може не вистачити вологи, через що період вегетації збільшиться. Оптимальний варіант — проведення посіву одразу після культивації ґрунту (але не пізніше ніж через 3-4 години після процедури).
                    </p>
                    <p className="catalog__desc">
                        При висаджуванні потрібно обов'язково дотримуватися рекомендованих значень густоти. Якщо їх перевищити, то рослини надто витягуються та вилягають до збирання врожаю. Також збільшуються ризики поширення грибкових хвороб. Якщо густота буде недостатньою, то між соняшником зростатиме бур'ян, споживаючи необхідну вологу та корисні мікро- й макроелементи. Відстань між рядами, яка рекомендується агрономами та виробниками, — 70 сантиметрів. У деяких випадках можна використовувати суцільний спосіб засівання, але для цього потрібен якісний посівний матеріал, правильно підібрані стартові гербіциди та сучасна техніка для збирання врожаю. Фермери, які освоїли такі технології, одержують до 50 центнерів насіння з гектара поля.
                    </p>
                    <p className="catalog__desc">
                        Для того, щоб зібрати максимальний урожай, необхідно використовувати якісні та правильно підібрані добрива. На один центнер насіння необхідно приблизно 5 кілограмів азоту, 2,5 кг фосфору та близько 10 кг калію. Також дуже важлива наявність бору (близько 1,2 кг на гектар) — якщо його не вистачає, то врожайність падає на 20%, так само як і вміст олії в насінні. Купувати та бездумно вносити добрива не рекомендується. Краще зверніться до фахівців компанії «Санберрі Україна». Ми проведемо аналіз ґрунту й підберемо склади, які ідеально підходять для вашого поля. Враховуйте, що рослині потрібні сірка, кальцій та інші мікро- і макроелементи, тому добрива мають бути комплексними.
                    </p>
                    <p className="catalog__description-title">Особливості збирання та зберігання</p>
                    <p className="catalog__desc">
                        Вегетаційний період у соняшників триває приблизно 80-120 днів в залежності від сорту, регіону, вологості, температури та ін. Ранні гібриди мають меншу врожайність, але при цьому вони повністю формують кошики й насіння до приходу максимальної спеки. Стандартні та пізні сорти більш урожайні. При цьому їх потрібно висаджувати так, щоб вони встигли сформуватися до літньої спеки й посухи.
                    </p>
                    <p className="catalog__desc">
                        Збирати соняшник потрібно тоді, коли вологість насіння становить приблизно 12-14%. Визначити це можна як лабораторним способом, так за виглядом рослини. У неї практично повністю висихає листя, навіть кошик стає бурим. Якщо пропустити оптимальний час для збирання, то врожайність може знизитися на 10%. Зазначимо, що зібрати насіння потрібно до того, як почнуться постійні осінні дощі.
                    </p>
                    <p className="catalog__desc">
                        Залишки рослин не рекомендується збирати докупи та спалювати. Їх рівномірно розподіляють по полю та дають їм перепріти. Вони швидко перетворюються на органіку й можуть використовуватися для підвищення вмісту мікро- і макроелементів у ґрунті.
                    </p>
                    <p className="catalog__description-title">Яке насіння купити</p>
                    <p className="catalog__desc">
                        Усе залежить від того, де знаходиться ваше поле, які на ньому умови та інше. У нашому каталозі ви знайдете широкий асортимент посівного соняшника від перевірених виробників. Насіння відзначається високим коефіцієнтом схожості, гарною врожайністю, стійкістю до різних захворювань і посухи. У разі потреби ми детально проконсультуємо вас з усіх питань та допоможемо підібрати сорти, які покажуть найкращий результат у вашому полі.
                    </p>
                    <p className="catalog__caption">
                        Ми пропонуємо:
                    </p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Низькі ціни, особливі умови для постійних клієнтів, цікаві пропозиції кожного місяця.
                        </li>
                        <li className="catalog__description-numbering">
                            Індивідуальний підхід, детальні консультації та допомогу з підбором насіння або добрив.
                        </li>
                        <li className="catalog__description-numbering">
                            Швидке відвантаження товарів із Вінниці, зручну форму оплати, надання всіх необхідних документів.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Дзвоніть — ми готові до взаємовигідної співпраці та зробимо все, щоб ви отримали гарний врожай. Ми не перший рік займаємося виготовленням добрив і продажем посівмату, тому дуже добре знаємося на всіх нюансах вирощування як соняшника, так і інших сільськогосподарських культур.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

