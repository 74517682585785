import "../BlogPagesStyle.scss";
import Header from "../../../Header/Header";
import Footer from "../../../Footer/Footer";
import crumbs from "../../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import React from "react";

export default function BlogNutrition() {
    return (
        <div className="culture" >
            <Helmet>
                <meta
                    name="keywords"
                    content="елементи живлення, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Роль елементів живлення в розвитку рослини • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/nutrition" />
                <meta
                    name="description"
                    content="🌱Роль елементів живлення в розвитку рослини ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Блог</span>
                </Link>
                <span className="crumbs__text">/ Роль елементів живлення в розвитку рослини</span>
            </div>
            <div className="culture__title">
                <h1>Роль елементів живлення в розвитку рослини</h1>
            </div>
            <div className="culture__container">
                <div className="blog__holder">
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Mn</p>
                            <p className="culture__element--style">Марганець</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Марганець бере активну участь в процесі фотосинтезу, а саме, в його киснеутворюючій системі, і грає основну роль в перенесенні електронів. Важливих функцій марганцю – участь в окисно-відновних реакціях. Марганець конче потрібен для реплікації і функціонування ДНК і РНК-полімерази
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Zn</p>
                            <p className="culture__element--style">Цинк</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Цинк у рослині відповідає за метаболізм вуглеводів, фосфатів і протеїнів, утворення ДНК ,контролює синтез амінокислоти триптофану.Цинк впливає на проникність мембран, стабілізує клітинні компоненти та системи мікроорганізмів, підвищує стійкість рослин до сухого і жаркого клімату, грибкових і бактеріальних захворювань
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">B</p>
                            <p className="culture__element--style">Бор</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Бор регулює білковий та вуглеводний обмін, сприяє пересуванню й накопиченню вуглеводів, крохмалю, сахарози в коренях та листках.Бор відповідає за запилення та запліднення квіток, визначаючи кількість плодів і насінин, підвищує стійкість до захворювань. Бор входить до складу клітинної стінки, завдяки чому сприяє поліпшенню якості продукції. Бор рухається виключно з водним током по ксилемі, тобто від кореневої системи до точки росту, залежно від інтенсивності транспірації рослини, чим пояснюється необхідність періодичних позакореневих підживлень
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Mo</p>
                            <p className="culture__element--style">Молібден</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Молібден входить до ферментів нітрогенази і нітратредуктази, які безпосередньо беруть участь у метаболізмі азоту.Молібден, як і залізо, необхідний для синтезу леггемоглобіну (білка – переносника кисню в бульбочках). Його дефіцит призводить до зміни кольору бульбочок на жовтий або сірий (нормальна забарвлення червоне). Молібден відповідає у рослині за стійкість до низьких температур та дефіциту води, формування квіток та пилку
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Fe</p>
                            <p>Залізо</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Залізо входить до складу ферментів, а також бере участь в синтезі хлорофілу, в диханні і в обміні речовин.Залізо відіграє активну роль в окисно-відновних реакціях хлоропластів, мітохондрій і пероксисоми, а також виконує багато інших функцій в рослинах
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">N</p>
                            <p className="culture__element--style">Азот</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Азот поряд з киснем, вуглецем та воднем є основних елементів живлення для рослин.Основна його маса надходить з ґрунту та повітря. Проте додаткове позакореневе внесення відносно невеликої кількості азоту дозволяє активізувати фотосинтез та стимулювати кореневу систему, покращити засвоєння інших важливих елементів і сполук
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">S</p>
                            <p className="culture__element--style">Сірка</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Сірка бере активну участь в окисно-відновних процесах, активації ензимів, білковому обміні.Вона сприяє фіксації азоту з атмосфери шляхом посилення утворення бульбочок у бобових. Сірка покращує засвоєння азоту, запобігає утворенню небілкових форм азотистих сполук (нітратів, нітритів та ін.) в товарній продукції, чим і забезпечує її високу екологічність. В рослині основними місцями локалізації сірки є листки та насіння, (стебла та коріння — менше), тому позакореневе підживлення сіркою є ефективним способом коригування сульфатного балансу
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Mg</p>
                            <p className="culture__element--style">Магній</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Магній входить до складу хлорофілу, сприяє обміну речовин в клітині, активує ферментні системи, незамінний в процесі дихання, істотно впливає на окислювально-відновні процеси, що протікають в рослинах.Магній також відіграє важливу роль у синтезі білків, посилює мобільність фосфатів в грунті та надходження їх в тканини рослини, істотно збільшує утворення вуглеводів в рослинах, підвищує тургор клітин, сприяє вивільненню пов’язаної в ґрунті води
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">K</p>
                            <p className="culture__element--style">Калій</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Калій –це «елемент якості» урожаю. Калій є вкрай важливим для регулювання водного балансу у рослинах, забезпечення стійкості до хвороб.Внесення калію по листу забезпечує «паливом» мітохондрії – «теплоелектростанції» клітин
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Cu</p>
                            <p className="culture__element--style">Мідь</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Мідь відповідає у рослині за фотосинтез, концентрацію інгібіторів росту, водний обмін та перерозподіл вуглеводів.Мідь відіграє специфічну роль в житті рослин: регулює фотосинтез і концентрацію утворюються в рослині інгібіторів росту, водний обмін і перерозподіл вуглеводів, входить до складу ферментів, підвищує стійкість рослин до вилягання і сприяє їх морозо-, жаро- та посухостійкості
                            </p>
                        </div>
                    </div>
                    <div className="culture__section section">
                        <div className="culture__element">
                            <p className="culture__element--weight">Co</p>
                            <p className="culture__element--style">Кобальт</p>
                        </div>
                        <div className="section__text">
                            <p className="section__text--weight">
                                Основну функцію кобальту пов’язують з його участю в фіксації атмосферного азоту в бульбах бобових і небобових рослин.Під впливом кобальту стимулюється розвиток рослинних тканин, що містять бактероїди, підвищується рухливість бактероидів в бульбах бобових рослин. Кобальт накопичується в пилку та суттєво впливає на його проростання, бере безпосередню участь в обміні ауксинів, допомагає розтягуватися клітинним оболонкам, сприяє надходженню в клітини азоту, активізує ферменти
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
}
