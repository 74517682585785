import "../ServicesPage.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import stock from "../../../image/background-image/AdobeStock_321605471.jpeg";
import dron from "../../../image/background-image/SMB320-Agricultural-drone.jpg";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

export default function AboutPage() {

    function ScrollToTopOnMount() {
        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        return null;
    }

    return (
        <div>
            <Helmet>
                <meta name="keywords" content="послуги, дрони, оприскування дронами, Санберрі Україна" />
                <title>Послуги компанії Санберрі Україна - Оприскування дронами</title>
                <link rel="canonical" href="https://sun-ukr.com/innovation" />
                <meta name="description" content="Реставрація та доукомплектація КРН, Сівалок а також оприскування дронами 🌱 Рівномірне розпилення препарату, точкове внесення на проблемні ділянки."></meta>
            </Helmet>
            <ScrollToTopOnMount />
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <span>/ Інновації</span>
            </div>
            <div className="innovation">
                <div className="servicesPage__headertitle">
                    <h1>Послуги компанії Санберрі Україна</h1>
                </div>
                <div>
                    <div className="functional_inner_wrap functional_inner_left">
                        <div className="serv_item_descr clearfix">
                            <div className="descr_text">
                                <h3 className="title">01</h3>
                                <Link to="completion-of-krn">
                                    <p className="descr_value">Реставрація та доукомплектація КРН, Сівалок</p>
                                </Link>
                                <p>Замість більш витратної за часом та вартістю купівлі нової сільськогосподарської машини більш рентабельною є реставрація КРН</p>
                                <Link to="completion-of-krn">
                                    <p className="serv__link">Перейти</p>
                                </Link>
                            </div>
                            <Link to="completion-of-krn">
                                <div className="descr_img">
                                    <img className="img_desc" src={stock} alt='stock' />
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="functional_inner_reverce functional_inner_right">
                        <div className="serv_item_descr clearfix">
                            <div className="descr_text">
                                <h3 className="title">02</h3>
                                <Link to="drone-spraying">
                                    <p className="descr_value">Обприскування дронами</p>
                                </Link>
                                <p>Послуги з обприскування полів дронами у Вінниці</p>
                                <Link to="drone-spraying">
                                    <p className="serv__link">Перейти</p>
                                </Link>
                            </div>
                            <Link to="drone-spraying">
                                <div className="descr_img">
                                    <img className="img_desc" src={dron} alt='stock' />
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
