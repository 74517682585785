export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <p className="card__description-text">
                    Гумат калію — це екологічно чисте, органічне добриво, що підвищує екологічну чистоту, поживні та смакові властивості сільськогосподарських культур, пришвидшує дозрівання та збільшує врожайність рослин, підвищує імунітет та захищає рослини від негативного впливу навколишніх факторів.
                </p>
                <p className="card__description-text">
                    Основу гумату калію складає високоякісний леонардит, що містить цінні гумінові речовини: суміш високомолекулярних органічних сполук, які утворюються при розпаданні решток рослинного та тваринного походження та їх подальшій гуміфікації.
                </p>
                <p className="card__description-text">
                    Технологічна лінія компанії дозволяє виробляти дрібнодисперсний гумат калію природнім некаталізаціонним способом, використовуючи природні властивості леонардиту та високоякісного лугу без додавання хімічних елементів.
                </p>
                <p className="card__description-title">
                    Використання:
                </p>
                <p className="card__description-text">
                    Гумат калію застосовується на всіх етапах росту та розвитку рослин із метою укріплення їх кореневої системи, підвищення імунітету та покращення урожайності.
                </p>
                <p className="card__description-text">
                    Гумат калію покращує хіміко-фізичні властивості ґрунту, знижує накопичені у ґрунті важкі метали та пестициди, відновлює його родючість.
                </p>
                <p className="card__description-title">
                    ПЕРЕВАГИ ВИКОРИСТАННЯ
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        Покращує ґрунтову мікрофлору, стимулує роботу ґрунтових мікроорганізмів;
                    </li>
                    <li className="card__description-numbering">
                        Покращує засвоєння рослинами поживних речовин;
                    </li>
                    <li className="card__description-numbering">
                        Зменшує кількість накопичених у ґрунті важких металів та пестицидів;
                    </li>
                    <li className="card__description-numbering">
                        Стимулює ріст та розвиток рослин;
                    </li>
                    <li className="card__description-numbering">
                        Зміцнює імунітет, захищає рослини від негативного впливу навколишнього середовища;
                    </li>
                    <li className="card__description-numbering">
                        Прискорює строки дозрівання плодів, підвищує врожайність до 60%;
                    </li>
                    <li className="card__description-numbering">
                        Підвищує строки зберігання урожаю;
                    </li>
                    <li className="card__description-numbering">
                        100% органічне добриво;
                    </li>
                    <li className="card__description-numbering">
                        Захищає від негативного впливу УФ випромінювання;
                    </li>
                    <li className="card__description-numbering">
                        Використовується з усіма видами добрив та ЗЗР для усіх типів росли.
                    </li>
                </ul>
                <p className="card__description-text">
                    Наявність гумінових речовин в добривах дозволяє регулювати практично всі найважливіші властивості ґрунтів.
                </p>
                <p className="card__description-text">
                    По-перше, вони формують забарвлення і, отже, тепловий режим. Це особливо важливо для холодних глинистих грунтів, які під дією гумату стають теплішими.
                </p>
                <p className="card__description-text">
                    По-друге, тривала обробка грунтів гуміновими кислотами сприяє поліпшенню їх структури. Потрапляючи в грунт, вони утворюють гумати кальцію і магнію, що зв'язують механічні елементи ґрунти і відіграють роль органо-мінеральних містків між агрегатами.
                </p>
                <p className="card__description-text">
                    В-третіх важливим властивістю гумінових речовин є їх висока іонообмінна активність. Ця якість забезпечує їм здатність регулювати процеси міграції компонентів мінерального живлення в системі грунт-рослина.
                </p>
                <p className="card__description-text">
                    По-четверте, обробка гуматами підвищує влагонасыщение грунту. Це має особливе значення для піщаних ґрунтів, водоутримувальна здатність яких під дією гумату збільшується більш, ніж у 10 разів. На цих же принципах засноване застосування препаратів в якості меліорантів. При правильному застосуванні гумату кількість гумусу в ґрунті збільшується на 0,05% в рік.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
