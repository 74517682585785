export default function description() {
    return (
        <div className="card__description-basic">
            <div className="card__description">
                <div className="card__description-title">
                    РКД NPK 6-20-0: особливості, склад, використання
                </div>
                <p className="card__description-text">
                    Для більшості типів ґрунтів і культур, які вирощують в Україні, використовуються NPK 6-20-0. Це двокомпонентні рідкі добрива у вигляді розчину, створені на основі ортофосфорної кислоти. Відзначаються збільшеною кількістю фосфору у складі, а також наявністю азоту в помірних пропорціях. Норми внесення різні, визначаються агрономами, в залежності від типу та характеристик ґрунту, технології, показників вологості, цільової врожайності тощо.
                </p>
                <p className="card__description-text">
                    Добриво використовується для кореневого та некореневого, листового підживлення, крапельного внесення, фертигації. Екологічно чисте, застосовується восени та навесні. Підходить для посушливих регіонів. Препарат гарно засвоюється навіть у холодному ґрунті, є повністю безпечним для сільськогосподарського обладнання та навколишнього середовища.
                </p>
                <div className="card__description-title">
                    Де використовується
                </div>
                <p className="card__description-text">
                    NPK-добрива рекомендовані для кореневого живлення озимих на етапі кущіння, виходу в трубку. Необхідні для полів, які з осені не отримували достатньо фосфору. Препарат використовується в дозі 5-20 літрів на гектар. Забезпечує гарний врожай озимих, збільшує показники на 9-14%. Готовий продукт виходить багатим на білок і клейковину. Можливе застосування для кореневого живлення разом із ґрунтовими гербіцидами й іншою агрохімією.
                </p>
                <p className="card__description-text">
                    Купити NPK 6-20-0 варто й для некореневого підживлення, особливо у холодну весну. За температури не вище ніж 10 ˚C коріння сільськогосподарської культури не отримує достатньо фосфору та азоту. Використання РКД допоможе зменшити дефіцит, поліпшить загальний стан і прискорить темпи росту рослини. Препарат використовується також для обробітку насіння перед висіванням.
                </p>
                <p className="card__description-text">
                    Купити NPK 6-20-0 варто й для некореневого підживлення, особливо у холодну весну. За температури не вище ніж 10 ˚C коріння сільськогосподарської культури не отримує достатньо фосфору та азоту. Використання РКД допоможе зменшити дефіцит, поліпшить загальний стан і прискорить темпи росту рослини. Препарат використовується також для обробітку насіння перед висіванням.
                </p>
                <p className="card__description-text">
                    NPK 6-20-0 найкраще підходить для таких рослин:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        соняшник. Зазвичай застосовується навесні під час посіву або перед ним, для забезпечення потреб рослини у фосфорі та калійному живленні на ранньому етапі формування кошиків;
                    </li>
                    <li className="card__description-numbering">
                        кукурудза. Розчин варто застосовувати для оброблення насіння та у вегетативний період, коли формується зелена маса. Якщо вміст фосфору та калію буде низьким, можлива загибель врожаю. У цей час кукурудза особливо чутлива до негативних факторів і потребує якісного живлення;
                    </li>
                    <li className="card__description-numbering">
                        яра пшениця. Найкраще використовувати NPK на ранніх стадіях розвитку, коли рослина найбільш чутлива до дефіциту фосфору;
                    </li>
                    <li className="card__description-numbering">
                        цукровий буряк. Якщо його висівають на чорноземи, можна обійтись без додаткового калійного підживлення;
                    </li>
                    <li className="card__description-numbering">
                        звичайні сорти картоплі. Використовується під час першої вегетації та до появи сходів.
                    </li>
                </ul>
                <p className="card__description-text">
                    В агрономічній практиці рідкі добрива використовують для підживлення плодово-ягідних дерев, озимого ріпаку, пшениці, сої, овочів.
                </p>
                <div className="card__description-title">Які переваги</div>
                <p className="card__description-text">
                    Добрива виробництва «Санберрі Україна» належать до небаластних і не містять нерозчинних елементів. Завдяки цьому норми внесення порівняно невеликі при високій ефективності складу. Витрати на гектар залишаються такими самими, як і при використанні звичайних добрив. NPK 6-20-0 – РКД, які відзначаються великою кількістю переваг:
                </p>
                <ul className="card__description-list">
                    <li className="card__description-numbering">
                        забезпечення швидкого розвитку кореневої системи. Ефективність рідких комплексних добрив завжди вища в порівнянні зі звичайними;
                    </li>
                    <li className="card__description-numbering">
                        розчинність, точне дозування, рівномірне та просте внесення;
                    </li>
                    <li className="card__description-numbering">
                        можливість використання на посушливих ґрунтах і за низької температури;
                    </li>
                    <li className="card__description-numbering">
                        стимулює швидкий ріст зеленої вегетативної маси;
                    </li>
                    <li className="card__description-numbering">
                        доступна ціна;
                    </li>
                    <li className="card__description-numbering">
                        нейтральний pH, однаковий склад кожної окремої краплі.
                    </li>
                </ul>
                <p className="card__description-text">
                    Добрива підвищують стійкість різних видів рослин до вилягання, виникнення борошнистої роси та інших хвороб. NPK-комплекси від ТОВ «Санберрі Україна» забезпечують гарний врожай різних культур, від зернових до плодово-ягідних.
                </p>
            </div>
            <div className="card__description-preference">
                <div className="aside__title">Агрохімічні характеристики</div>
                <div className="aside__content">
                    <div className="aside-char">
                        <div className="aside-char__head">Потенціал врожайності</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Стабільність урожаю</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--7">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">7</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Енергія початкового росту</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--8">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">8</div>
                        </div>
                    </div>
                    <div className="aside-char">
                        <div className="aside-char__head">Ефективність</div>
                        <div className="aside-char__char">
                            <div className="aside-char__items aside-char__items--9">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="aside-char__count">9</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
