import "./AgroteinCard.scss";
import Header from "../../../../../Header/Header";
import Footer from "../../../../../Footer/Footer";
import GoodsListAgrotein from '../../../../GoodsList/GoodsListFertilizers/GoodsListAgrotein';
import crumbs from "../../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FertilizersTabs() {
    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="виробництво, Гранульованих NPK, NPK, РКД, Агротеїн, Нітроамофоска, Діамофоска, замовити, Сульфоамофос, Санберрі Україна" />
                <title>Гранульовані NPK добрива від виробника • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/agroteinCard" />
                <meta name="description" content="🌱Гранульовані мінеральні NPK добрива ⭐ Від українського виробника Sunberry Ukraine. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalog">
                    <span>/ Комплексні органо-мінеральні добрива</span>
                </Link>
                <span className="crumbs__text">/ Гранульовані NPK</span>
            </div>
            <div className="fertilizersCard__catalog">
                <div className="fertilizersCard__title">
                    <h1 className="fertilizersCard__title__above">Гранульовані NPK</h1>
                    <span className="fertilizersCard__title__under">енергетичний комплекc для рослин</span>
                </div>
                <div className="fertilizers-tabs-active">
                    <div
                        className="fertilizers-active  active-fertilizers fertilizers-active">
                        <GoodsListAgrotein />
                    </div>
                </div>
                <div className="catalog__content">
                    <p className="catalog__desc">
                        Компанія «Cанберрі Україна» пропонує купити гранульовані комплексні добрива власного виробництва, які виготовляються по оригінальній технології, що забезпечує їх високу ефективність на різних типах ґрунтів.
                    </p>
                    <p className="catalog__desc">
                        Гранульованими називають поживні суміші, що поставляються виробниками у вигляді дрібних сфер (правильної або нерівної форми) та призначені для внесення у ґрунт в період його підготовки до посіву насіння або висадки рослин. По мірі надходження вологи вони поступово розчиняються, насичуючи коріння сільськогосподарських або декоративних культур потрібними для їх росту й вегетації елементами. Їх активно використовують при вирощуванні салатної зелені, овочів, ягід та навіть квітів як на відкритому повітрі, так і в теплицях.
                    </p>
                    <p className="catalog__description-title">Види гранульованих добрив</p>
                    <p className="catalog__caption">В залежності від складу таку продукцію можна розділити на дві великих категорії, а саме:</p>
                    <ol>
                        <li className="catalog__description-numbering">
                            Органічні склади. Їх виготовлення може здійснюватися на основі перегною. компосту, торфу, а також деяких скошених рослин (наприклад, бобових культур). Крім того до складу таких поживних речовин нерідко додаються допоміжні компоненти. Це бор та марганець, мідь й кобальт, а також деякі мікроорганізми.
                        </li>
                        <li className="catalog__description-numbering">
                            Мінеральні добрива. Є речовинами неорганічного походження, які виготовляються виключно у заводських умовах. Забезпечують ефективне насичення ґрунту недостатніми корисними компонентами залежно від виду культур, що вирощуються на земельній ділянці.
                        </li>
                    </ol>
                    <p className="catalog__desc">
                        Крім того, гранульовані комплексні добрива, придбати які у широкому асортименті пропонує наша компанія, розділяються на однокомпонентні (калійні, азотні та інше) та комплексні. Другий вид сумішей є більш затребуваним у сільськогосподарських підприємств, оскільки містять у своєму складі як макро-, так і мікроелементи. Це забезпечує комплексне насичення ґрунту потрібними рослинам речовинами.
                    </p>
                    <p className="catalog__description-title">Переваги продукції</p>
                    <p className="catalog__caption">
                        Завдяки сучасним технологіям виробництва й використанню якісних компонентів комплексні добрива у гранулах від компанії «Cанберрі Україна»:
                    </p>
                    <ul>
                        <li className="catalog__description-numbering">
                            мають добру розчинність для ефективного живлення рослин;
                        </li>
                        <li className="catalog__description-numbering">
                            містять набір всіх необхідних елементів для підживлення рослин, для яких призначаються;
                        </li>
                        <li className="catalog__description-numbering">
                            сприяють поліпшенню якості продукції та підвищують стійкість культур до негативних кліматичних умов;
                        </li>
                        <li className="catalog__description-numbering">
                            виключають ймовірність надмірного накопичення нітратів у плодах або зелені;
                        </li>
                        <li className="catalog__description-numbering">
                            призводять до утримання вологи у верхніх шарах ґрунту.
                        </li>
                    </ul>
                    <p className="catalog__desc">
                        Крім того, регулярне використання таких добрив дає можливість підтримувати високий рівень родючості с/г наділу, в тому числі за рахунок накопичення гумусу у землі.
                    </p>
                    <p className="catalog__description-title">Як замовити добрива</p>
                    <p className="catalog__desc">
                        Для придбання комплексних гранульованих добрив у потрібних обсягах буде достатньо звернутися до менеджерів нашого магазину по вказаним номером телефону. Спеціалісти нададуть усі необхідні консультації та допоможуть з вибором найбільш відповідного складу залежно від наявних потреб. Доставка продукції може бути виконана не тільки у Вінниці, але й по всій території України.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}


