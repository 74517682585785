import logo1 from "../../image/logotype/logo_corn.svg";
import logo2 from "../../image/logotype/MAS Seeds.svg";
import goodsPage from "../../image/goods.image/corn/qbyHGQcOhx.png";
import goodsPage1 from "../../image/goods.image/corn/vdNT2eOvoT.jpeg";
import Button from "../../assets/Button/ButtonBuy/ButtonBuy"
import { Link } from "react-router-dom";


export const items = [
    {
        title: "Гібрид кукурудзи НК Кобальт (NK Kobalt)",
        priceFirst: 'від 2 500 грн',
        producer: "Syngenta",
        InStock: false,
        imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
        imgUrl: <img className="itemCard__image" src={goodsPage} alt="logo" />,
        isNew: false,
        link: <Link to="order"><Button /></Link>,
        inside: "kobalt",
        id: "1"
    },
    {
        title: "Гібрид кукурудзи СИ Аріосо (SY Arioso)",
        priceFirst: 'від 2 500 грн',
        producer: "Syngenta",
        InStock: false,
        imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
        imgUrl: <img className="itemCard__image" src={goodsPage} alt="logo" />,
        isNew: false,
        link: <Link to="order"><Button /></Link>,
        inside: "arioso",
        id: "2"
    },
    {
        title: "Гібрид кукурудзи НК Термо (NK Termo)",
        priceFirst: 'від 2 500 грн',
        producer: "Syngenta",
        InStock: false,
        imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
        imgUrl: <img className="itemCard__image" src={goodsPage} alt="logo" />,
        isNew: false,
        link: <Link to="order"><Button /></Link>,
        inside: "termo",
        id: "3"
    },
    {
        title: "Гібрид кукурудзи СИ Теліас (SY Telias)",
        priceFirst: 'від 2 500 грн',
        producer: "Syngenta",
        InStock: false,
        imgLogo: <img className="itemCard__image-logo" src={logo1} alt="logo" />,
        imgUrl: <img className="itemCard__image" src={goodsPage} alt="logo" />,
        isNew: false,
        link: <Link to="order"><Button /></Link>,
        inside: "telias",
        id: "4"
    },
    {
        title: "Гібрид кукурудзи МАС 24.Ц (MAS 24.C)",
        priceFirst: 'від 2 500 грн',
        producer: "MAS Seeds",
        InStock: false,
        imgLogo: <img className="itemCard__image-logo" src={logo2} alt="logo" />,
        imgUrl: <img className="itemCard__image" src={goodsPage1} alt="logo" />,
        isNew: false,
        link: <Link to="order"><Button /></Link>,
        inside: "mas24",
        id: "5"
    },
    {
        title: " Гібрид кукурудзи МАС 30.М (MAS 30.M)",
        priceFirst: 'від 2 500 грн',
        producer: "MAS Seeds",
        InStock: false,
        imgLogo: <img className="itemCard__image-logo" src={logo2} alt="logo" />,
        imgUrl: <img className="itemCard__image" src={goodsPage1} alt="logo" />,
        isNew: false,
        link: <Link to="order"><Button /></Link>,
        inside: "mas30",
        id: "6"
    }
];
