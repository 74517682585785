import "../Culture.scss";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import GoodsList from "./GoodsList";
import crumbs from "../../../icons/icons8-home.svg";
import { Link } from "react-router-dom";
import Ink from 'react-ink'
import { Helmet } from "react-helmet";
import React from "react";

export default function CultureSeed() {
    return (
        <div className="culture">
            <Helmet>
                <meta
                    name="keywords"
                    content="Добрива для картоплі і овочів, культура, рекомендації та норми внесення добрив, добрива, купити"
                />
                <title>Добрива для картоплі і овочів • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/cultureVegetables" />
                <meta
                    name="description"
                    content="🌱Добрива для картоплі і овочевих культур ⭐ Рекомендації та норми внесення добрив. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."
                ></meta>
            </Helmet>
            <Header />
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link className="crumbs__link" to="culture">
                    <span>/ Культура</span>
                </Link>
                <span className="crumbs__text">/ Добрива для овочевих культур</span>
            </div>
            <div className="culture__title">
                <h1>Добрива для овочевих культур</h1>
            </div>
            {/* <div className="culture__bg">
                <div className="culture__scheme"></div>
            </div> */}
            <div className="culture__container">
                <h2 className="culture__under">Дефіцит елементів живлення овочевих культур</h2>
                <p className="culture__subtitle">
                    Кожна поживна речовина важлива для живлення рослин.
                </p>
                <div className="culture__block">
                    <div className="culture__element">
                        <p className="culture__element--weight">Fe</p>
                        <p>Залізо</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mn</p>
                        <p className="culture__element--style">Марганець</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Zn</p>
                        <p className="culture__element--style">Цинк</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">B</p>
                        <p className="culture__element--style">Бор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Cu</p>
                        <p className="culture__element--style">Мідь</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">P</p>
                        <p className="culture__element--style">Фосфор</p>
                    </div>
                    <div className="culture__element">
                        <p className="culture__element--weight">Mo</p>
                        <p className="culture__element--style">Молібден</p>
                    </div>
                </div>
                <div className="culture__text">
                    <span className="culture__text--styles">
                        Основні добрива від компанії Санберрі Україна
                    </span>
                    <span className="culture__text--styles">
                        Які знадобляться Вам для підживлення ваших овочевих культур
                    </span>
                </div>
                <GoodsList />
                <Link to="order">
                    <button type="button" className="btn-outline-success-brio btn">
                        Отримати консультацію
                    </button>
                </Link>
                <div className="culture__content">
                    <p className="culture__heading">
                        Екологічні добрива для овочевих культур Санберрі Україна – важлива передумова гарного врожаю
                    </p>
                    <p className="culture__desc">
                        Картопля – одна з тих овочевих культур, що найбільш потребують внесення комплексних органо-мінеральних добрив. Слабко розвинена коренева система не здатна здобувати поживні речовини з ґрунту у кількості, достатній для формування гарного врожаю крупних бульб. Несприятливі погодні фактори у поєднанні із браком корисних речовин призводять до недоотримання врожаю та погіршення його якості. Мінеральні добрива для овочів, призначені для позакореневого підживлення, швидко задовольняють потреби овочевих культур у корисних макро- та мікроелементах.
                    </p>
                    <p className="culture__desc">
                        Мікродобрива від Санберрі Україна виготовляються у формі гуматів, що добре засвоюється картоплею та овочевими культурами завдяки природному походженню корисних елементів. Поживні речовини діють як стимулятор росту та потужний захисник від несприятливих факторів довкілля. Агроболіки є концентрованими речовинами у вигляді розчину та вирізняються невеликою нормою внесення. Купити комплексні мікродобрива за привабливою ціною можна на нашому офіційному сайті.
                    </p>
                    <p className="culture__heading">
                        У який період необхідно вносити добрива
                    </p>
                    <p className="culture__desc">
                        Протягом періоду вегетації потреби картоплі у поживних речовинах змінюються. Їх своєчасне задоволення – запорука гармонійного розвитку та отримання господарем гарного врожаю. Саме тому вносити добрива необхідно протягом усього сезону вирощування:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            перед посівом;
                        </li>
                        <li className="culture__description-numbering">
                            у фазі подовження стебла;
                        </li>
                        <li className="culture__description-numbering">
                            на початку цвітіння;
                        </li>
                        <li className="culture__description-numbering">
                            у період розвитку бульб.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Якісні міндобрива для овочів захистять рослини від впливу несприятливих умов та сприятимуть їх гармонійному розвитку на кожному із етапів вирощування.
                    </p>
                    <p className="culture__heading">
                        Як проводити передпосівну підготовку картоплі
                    </p>
                    <p className="culture__desc">
                        Метою передпосівної обробки картоплі є прискорення схожості та захист від шкідливого впливу протруйників. Для обробки насіння Ароболік Фосфор використовують у кількості 4л/т. Застосування добрива для овочів на цьому етапі забезпечить:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            швидкий старт росту рослини;
                        </li>
                        <li className="culture__description-numbering">
                            активний розвиток кореневої системи;
                        </li>
                        <li className="culture__description-numbering">
                            стійкість до хвороб;
                        </li>
                        <li className="culture__description-numbering">
                            здатність протистояти стресовим факторам;
                        </li>
                        <li className="culture__description-numbering">
                            достатній для гармонійного розвитку обсяг мікро- і мікроелементів у ґрунті.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Ефективність Агроболіку Фосфор доведена науковими дослідженнями та практичним досвідом, здобутим на експериментальних ділянках та у сільськогосподарських господарствах. Обробка картоплі комплексом дозволяє отримати одночасні ранні сходи.
                    </p>
                    <p className="culture__heading">
                        Оброблення овочів у фазі подовження стебла
                    </p>
                    <p className="culture__desc">
                        У період активного нарощування вегетативної маси рослини потребують комплексного підживлення. На цьому етапі Ароболік Фосфор допоможе:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            задовольнити потреби картоплі у мікроелементах;
                        </li>
                        <li className="culture__description-numbering">
                            покращити засвоєння корисних речовин з ґрунту;
                        </li>
                        <li className="culture__description-numbering">
                            стимулювати нарощування кореневої системи та листового апарату;
                        </li>
                        <li className="culture__description-numbering">
                            збалансувати енергетику рослини;
                        </li>
                        <li className="culture__description-numbering">
                            покращити процеси дихання та фотосинтезу;
                        </li>
                        <li className="culture__description-numbering">
                            підвищити опірну здатність бактеріальним та грибковим захворюванням.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        Добрива для овочевих культур добре засвоюються та швидко компенсують дефіцит цинку і заліза, що часто виникає у період нарощування листової маси. Завдяки цьому вдається досягти вирівнювання та збільшення розміру бульб, покращити їх кількість та якість. Ароболік Фосфор можна застосовувати разом із іншими препаратами цинку та заліза. Норма внесення становить 1л/га способом обробки по листу.
                    </p>
                    <p className="culture__heading">
                        Внесення добрив для овочів у період цвітіння
                    </p>
                    <p className="culture__desc">
                        Під час квітко утворення картопля потребує підвищеної концентрації бору, яку задовольняє міндобриво для овочів Агроболік Фосфор у поєднанні із Агроболік Бор у розрахунку  по 1л/га. Тут речовина міститься у оптимальних для засвоєння формах – чотирьох координованих внутрішньокомплексних з’єднаннях: моноетаноламін та діетаноламін тригидроксибораті. Завдяки цьому макро- та мікроелементи:
                    </p>
                    <ul className="culture__description-list">
                        <li className="culture__description-numbering">
                            швидко засвоюються овочевими культурами;
                        </li>
                        <li className="culture__description-numbering">
                            покращують цвітіння та вегетативний розвиток кущів, сприяє бічному розгалуженню стебел;
                        </li>
                        <li className="culture__description-numbering">
                            стимулюють утворення бульб та накопиченню у них вуглеводнів;
                        </li>
                        <li className="culture__description-numbering">
                            допомагають рослинам підтримувати водний баланс, найбільш раціонально використовуючи вологу із ґрунту та покращуючи здатність до її утримання;
                        </li>
                        <li className="culture__description-numbering">
                            нормалізують обмін речовин.
                        </li>
                    </ul>
                    <p className="culture__desc">
                        На даному етапі розвитку рослини Ароболік Фосфор здатен мінімізувати негативний вплив несприятливих факторів – посухи, спеки, тимчасового зниження температури, використання на ділянці гербіцидів. На фоні цього покращується стійкість овочів до захворювань та активізується їх ріст.
                    </p>
                    <p className="culture__heading">
                        Користь від внесення добрив на етапі розвитку бульб
                    </p>
                    <p className="culture__desc">
                        Етап формування бульб є одним із найбільш відповідальних. У цей період рекомендують вносити Агроболік Бор у підвищеній кількості – 1,5-2 л/га. Концентрат допоможе знизити ступінь впливу теплового стресу та стимулює дозрівання плодів. Мінеральні добрива для овочів сприятимуть переміщенню цукру у репродуктивні органи, що покращить смакові якості врожаю та збільшить його розмір.
                    </p>
                </div>
                <p className="culture__heading">
                    Спеціальні пропозиції
                </p>
                <div className="culture__holder">
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Minimum»</p>
                        <p className="offer offer__text">від 499 до 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 3%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Standart»</p>
                        <p className="offer offer__text">від 1 000 до 2 999 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 7%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="culture__offer">
                        <p className="offer offer__title">Пакет «Sunberry»</p>
                        <p className="offer offer__text">від 3 000 до 5 000 літрів</p>
                        <p className="offer offer__text">Для Вашого 1 га полів потрібно 4 етапи внесення «Агроболік»</p>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">обробка насіння</p>
                            <p className="offer offer__goods--text">Агроболіка «Фосфор» 4 л/т.</p>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">перше внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer offer__goods--text">друге внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Фосфор + бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__goods">
                            <p className="offer__goods--text">третє внесення</p>
                            <div className="offer__goods--position">
                                <p className="offer offer__goods--text">Агроболік</p>
                                <p className="offer offer__goods--text offer__goods--weight">«Бор»</p>
                            </div>
                        </div>
                        <div className="offer offer__text">
                            <p>При замовлені даного пакету</p>
                            <p className="offer__text--weight">Знижка 15%</p>
                        </div>
                        <div className="culture__button">
                            <Link to="order">
                                <button type="button" className="btn-outline-success-culture btn">
                                    купити
                                    <Ink />
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="culture__button">
                    <Link to="order">
                        <button type="button" className="btn-outline-success-brio btn">
                            Залишились запитання?
                        </button>
                    </Link>
                </div>
            </div>
            <Footer />
        </div>
    );
}
