import "./NPKCard.scss";
import Header from "../../../../../Header/Header";
import Footer from "../../../../../Footer/Footer";
import GoodsList from '../../../../GoodsList/GoodsListFertilizers/GoodsListNPK';
import crumbs from "../../../../../../icons/icons8-home.svg";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export default function FertilizersTabs() {
    return (
        <>
            <Header />
            <Helmet>
                <meta name="keywords" content="виробництво, Рідкі NPK, NPK, РКД, купити, замовити, Санберрі Україна" />
                <title>Рідкі добрива NPK від виробника • Оптом і в роздріб | Sunberry Ukraine</title>
                <link rel="canonical" href="https://sun-ukr.com/NPKCard" />
                <meta name="description" content="🌱Рідкі мінеральні NPK добрива ⭐ Від українського виробника Sunberry Ukraine. ✅ Якісні добрива оптом та в роздріб - замовляйте за телефоном ☎️ +38(068)-369-90-30."></meta>
            </Helmet>
            <div className="crumbs">
                <Link className="crumbs__link" to="/">
                    <img className="crumbs__logo" src={crumbs} alt="crumbs" />
                </Link>
                <Link to="catalog">
                    <span>/ Комплексні органо-мінеральні добрива</span>
                </Link>
                <span className="crumbs__text">/ Рідкі NPK</span>
            </div>
            <div className="fertilizersCard__catalog">
                <div className="fertilizersCard__title">
                    <h1 className="fertilizersCard__title__above">Рідкі NPK</h1>
                    <span className="fertilizersCard__title__under">енергетичний комплекc для рослин</span>
                </div>
                <div className="fertilizers-tabs-active">
                    <div className="fertilizers-active  active-fertilizers fertilizers-active">
                        <GoodsList />
                    </div>
                </div>
                <div className="catalog__content">
                    <p className="catalog__desc">
                        Для вирощування багатьох сільськогосподарських культур і отримання гарних врожаїв застосовують НПК добрива (комплексні фосфорно-азотно-калійні). Призначені для мінерального кореневого та позакореневого підгодовування рослин на будь-яких ґрунтах.
                    </p>
                    <p className="catalog__desc">
                        Компанія Санберрі Україна (головний офіс розташований у Вінниці) пропонує купити екологічно безпечні комплекси для підживлення рослин. Виробник високоякісних мінеральних добрив проводить безкоштовні консультації агронома, забезпечує безперебійне постачання, оперативно доставляє замовлення. Працює професійно та надійно!
                    </p>
                    <p className="catalog__description-title">Склад НПК</p>
                    <p className="catalog__caption">Мінеральні добрива NPK містять три головних компоненти, що забезпечують ґрунт необхідними поживними речовинами. До складу входять:</p>
                    <ul>
                        <li className="catalog__description-numbering">
                            азот (N): покращує ріст листя та стебла рослин;
                        </li>
                        <li className="catalog__description-numbering">
                            фосфор (Р): відповідає за розвиток кореня;
                        </li>
                        <li className="catalog__description-numbering">
                            калій (К): сприяє кращому цвітінню й плодоношенню.
                        </li>
                    </ul>
                    <p className="catalog__desc">
                        Описані мікроелементи найважливіші для отримання якісного врожаю й попередження втрат.
                    </p>
                    <p className="catalog__description-title">Різновиди НПК</p>
                    <p className="catalog__caption">
                        Існує кілька видів добрив. Залежно від кількості та пропорції підживлювальних і допоміжних компонентів розрізняють NPK:
                    </p>
                    <ul>
                        <li className="catalog__description-numbering">
                            збагачені: комплекси, в яких показник вмісту допоміжних солей нижче 60%;
                        </li>
                        <li className="catalog__description-numbering">
                            нейтральні: допоміжних сольових речовин 60-70%;
                        </li>
                        <li className="catalog__description-numbering">
                            збіднені: солей допоміжних більше 70%
                        </li>
                    </ul>
                    <p className="catalog__desc">
                        Добрива бувають двохкомпонентними (суперфосфат, калійна селітра, амофос) та трьохкомпонентними (нітрофоска, амофоска, азофоска, діамофоска). За формою випуску – гранульовані та рідкі НПК.
                    </p>
                    <p className="catalog__description-title">Внесення рідких NPK</p>
                    <p className="catalog__desc">
                        В агрономії для різних культур рідкі комплексні добрива вносять, зважаючи на певні чинники. Так, аналізуючи клімат, показники родючості ґрунту, вид рослини, визначають середні норми застосування розчинів.
                    </p>
                    <ul>
                        <li className="catalog__description-numbering">
                            Розсада. Потребує регулярного використання комплексних добрив.
                        </li>
                        <li className="catalog__description-numbering">
                            Овочі, листові культури, ягоди. Нітроамофоску та азофоску вносять протягом травня-липня кожні 10 днів.
                        </li>
                        <li className="catalog__description-numbering">
                            Дерева. Підживлюють діамофоскою з травня до середини літа раз на 10 днів.
                        </li>
                    </ul>
                    <p className="catalog__desc">
                        У масштабах фермерських господарств розчин вносять штанговим обприскувачем.
                    </p>
                    <p className="catalog__desc">
                        Головна перевага комплексних добрив в тому, що не треба купувати різні види продукту й витрачати час на змішування в потрібній пропорції.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

